import { SelectFormik } from 'components/FormikComponents';
import { ErrorMessage, useFormikContext } from 'formik';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import { getStyles } from '../../../utils/getStyles';
import DropdownMenu from 'components/DropdownMenu';
import styles from '../../styles.module.css';
import { useMemo } from 'react';
import SelectValueLabel from 'types/Shared/SelectValueLabel';
import { useAppQuery } from 'store/use-app-query';
import { Language } from 'types/ApiModels/Users';
import Race from 'types/ApiModels/Users/Race';
import Ethnicity from 'types/ApiModels/Users/Ethnicity';
import Religion from 'types/ApiModels/Users/Religion';
import Sex from 'types/ApiModels/Users/Sex';

const mapValueLabel = (x: { name: string; id: number | string }): SelectValueLabel => ({
  label: x.name,
  value: x.id,
});

const DemographicInfo = () => {
  const { errors, touched, values } = useFormikContext<InvitePatient>();
  const openError = useMemo(() => !!errors.demographicInfo, [errors]);
  const { data: languages } = useAppQuery<Language[]>('languages');
  const { data: religions } = useAppQuery<Religion[]>('religions');
  const { data: ethnicity } = useAppQuery<Ethnicity[]>('ethnicities');
  const { data: races } = useAppQuery<Race[]>('races');
  const { data: maritalStatus } = useAppQuery<MaritalStatus[]>('maritalStatus');
  const { data: sex } = useAppQuery<Sex[]>('sex');

  /**
   * Options
   */
  const languageOptions = useMemo(() => {
    return languages?.map(mapValueLabel);
  }, [languages]);
  const raceOptions = useMemo(() => {
    return races?.map(mapValueLabel);
  }, [races]);
  const religionOptions = useMemo(() => {
    return religions?.map(mapValueLabel);
  }, [religions]);
  const ethnicityOptions = useMemo(() => {
    return ethnicity?.map(mapValueLabel);
  }, [ethnicity]);
  const maritalStatusOptions = useMemo(() => maritalStatus?.map(mapValueLabel), [maritalStatus]);
  const genderOptions = useMemo(() => sex?.map(mapValueLabel), [sex]);

  /**
   * Selected option
   */
  const selectedLanguage = useMemo(() => {
    const l = languages?.find((l) => l.id === values.demographicInfo.language);
    if (l) return { label: l.name, value: l.id };
  }, [languages, values.demographicInfo]);

  const selectedReligion = useMemo(() => {
    const l = religions?.find((l) => l.id === values.demographicInfo.religion);
    if (l) return { label: l.name, value: l.id };
  }, [religions, values.demographicInfo]);
  const selectedRace = useMemo(() => {
    const l = races?.find((l) => l.id === values.demographicInfo.religion);
    if (l) return { label: l.name, value: l.id };
  }, [races, values.demographicInfo]);
  const selectedEthnicity = useMemo(() => {
    const l = ethnicity?.find((l) => l.id === values.demographicInfo.religion);
    if (l) return { label: l.name, value: l.id };
  }, [ethnicity, values.demographicInfo]);
  const selectedMaritalStatus = useMemo(() => {
    const l = maritalStatus?.find((l) => l.id === values.demographicInfo.maritalStatus);
    if (l) return { label: l.name, value: l.id };
  }, [maritalStatus, values.demographicInfo.maritalStatus]);
  const selectedGender = useMemo(() => {
    const l = sex.find((l) => l.id === values.demographicInfo.genderIdentity);
    if (l) return { label: l.name, value: l.id };
  }, [genderOptions, values.demographicInfo.genderIdentity]);

  return (
    <DropdownMenu name="Demographic Info" open={openError}>
      <div className="d-flex flex-column gap">
        <div className={styles['row']}>
          <div className="d-flex gap px-3 pt-3 mb-2">
            <div className={`d-flex ${styles['form-label']} font-size-medium`}>Gender identity</div>
            <div className={`flex-grow-1 d-flex flex-column gap`}>
              <SelectFormik
                options={genderOptions}
                name={`demogrphicInfo.genderIdentity`}
                placeholder="Select gender identity..."
                styles={{
                  ...getStyles(errors, touched, `demographicInfo.genderIdentity`),
                  width: '100%',
                }}
              />
              <ErrorMessage name={`demographicInfo.genderIdentity`}>
                {(msg) => <div className="text-danger font-size-small">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="d-flex gap mb-2 text-nowrap">
              <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                Marital Status
              </div>
              <div className={`flex-grow-1 d-flex flex-column gap`}>
                <SelectFormik
                  options={maritalStatusOptions}
                  name={`demographicInfo.maritalStatus`}
                  placeholder="Select marital status..."
                  styles={{
                    ...getStyles(errors, touched, `demographicInfo.maritalStatus`),
                    width: '100%',
                  }}
                  value={selectedMaritalStatus}
                />
                <ErrorMessage name={`demographicInfo.maritalStatus`}>
                  {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
            <div className="d-flex gap mb-2 text-nowrap">
              <div className={`d-flex ${styles['form-label']} font-size-medium`}>Race</div>
              <div className={`flex-grow-1 d-flex flex-column gap`}>
                <SelectFormik
                  options={raceOptions}
                  name={`demographicInfo.race`}
                  placeholder="Select race..."
                  styles={{
                    ...getStyles(errors, touched, `demographicInfo.race`),
                    width: '100%',
                  }}
                  value={selectedRace}
                />
                <ErrorMessage name={`demographicInfo.race`}>
                  {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['row']}>
          <div className="d-flex gap px-3 pt-3 mb-2">
            <div
              style={{ flexBasis: '13%' }}
              className={`d-flex ${styles['form-label']} font-size-medium`}
            >
              Ethnicity
            </div>
            <div className={`flex-grow-1 d-flex flex-column gap`}>
              <SelectFormik
                options={ethnicityOptions}
                name={`demographicInfo.ethnicity`}
                placeholder="Select ethnicity..."
                styles={{
                  ...getStyles(errors, touched, `demographicInfo.ethnicity`),
                  width: '100%',
                }}
                value={selectedEthnicity}
              />
              <ErrorMessage name={`demographicInfo.ethnicity`}>
                {(msg) => <div className="text-danger font-size-small">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="d-flex gap px-3 mb-2 text-nowrap" style={{ width: '40%' }}>
              <div className={`d-flex ${styles['form-label']} font-size-medium`}>Language</div>
              <div className={`flex-grow-1 d-flex flex-column gap`}>
                <SelectFormik
                  options={languageOptions}
                  name={`demographicInfo.language`}
                  placeholder="Select language..."
                  styles={{
                    ...getStyles(errors, touched, `demographicInfo.language`),
                    width: '100%',
                  }}
                  value={selectedLanguage}
                />
                <ErrorMessage name={`demographicInfo.language`}>
                  {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
            <div className="d-flex gap px-3 mb-2 text-nowrap">
              <div className={`d-flex ${styles['form-label']} font-size-medium`}>Religion</div>
              <div className={`flex-grow-1 d-flex flex-column gap`}>
                <SelectFormik
                  options={religionOptions}
                  name={`demographicInfo.religion`}
                  placeholder="Select religion..."
                  styles={{
                    ...getStyles(errors, touched, `demographicInfo.religion`),
                    width: '100%',
                  }}
                  value={selectedReligion}
                />
                <ErrorMessage name={`demographicInfo.religion`}>
                  {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DropdownMenu>
  );
};
export default DemographicInfo;
