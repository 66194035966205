import Button from 'components/Button';
import Card from 'components/Card';
import EditDeleteActionButtons from 'components/EditDeleteActionButtons';
import GenericTable from 'components/GenericTable';
import VyTracModal from 'components/Modal';
import SearchFilterHeader from 'components/SearchFilterHeader';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAdministrationContext,
  useAdministrationContextV2,
  useAdminSpecialties,
} from 'screens/Administration/store';
import {
  setCurrentSpecialtyId,
  setSpecialties,
  toggleShowProvidersBySpecialty,
  toggleShowSpecialtyAdd,
  toggleSpecialtyShowEdit,
} from 'screens/Administration/store/Specialties';
import {
  appendAddedSpecialty,
  appendDeletedSpecialty,
  appendUpdatedSpecialty,
  clearAllSpecialtyChanges,
  setResetSpecialtyForm,
  setSubmitSpecialtiesForm,
} from 'screens/Administration/store/Specialties/specialtyActionCreators';
import {
  addSpecialtyBulk,
  deleteSpecialtyBulk,
  searchSpecialties,
  updateSpecialtyBulk,
} from 'services/providersService';
import Specialty from 'types/ApiModels/Providers/Specialty';
import GenericColumn from 'types/Shared/GenericColumn';
import { debouncePromiseValue } from 'util/utils';
import { AddNewSpecialtyModal, EditSpecialtyModal, ProvidersBySpecialtyModal } from './Modals';
import styles from './styles.module.css';
import { mapSpecialtyToRow } from './utils';

const AdminSpecialtyActions = () => {
  const {
    administrationStateV2: {
      specialties: {
        resetSpecialtiesForm,
        updatedSpecialties,
        deletedSpecialties,
        addedSpecialties,
        submitSpecialtiesForm,
      },
    },
    dispatch,
  } = useAdministrationContextV2();
  const dirty =
    updatedSpecialties.length > 0 || deletedSpecialties.length > 0 || addedSpecialties.length > 0;
  return (
    <div className="d-flex gap-sm">
      <Button
        label="cancel"
        variant="cancel"
        disabled={!dirty}
        onClick={() => resetSpecialtiesForm()}
      />
      <Button
        label="save changes"
        variant="confirm"
        disabled={!dirty}
        onClick={() => submitSpecialtiesForm()}
      />
      <Button label="add new specialty" onClick={() => dispatch(toggleShowSpecialtyAdd())} />
    </div>
  );
};
interface SpecialtyRow {
  id: number;
  name: string;
  providers_count: number;
  action: string;
}
const specialtyColumns = (
  handleProviderCountClick,
  handleSpecialtyDelete,
  handleSpecialtyEdit
): GenericColumn[] => [
  {
    title: 'id',
    key: 'id',
    hidden: true,
  },
  {
    title: 'Specialty name',
    key: 'name',
    headerClasses: 'flex-grow-1',
    tdClassName: 'flex-grow-1',
    render: (row) => <div className="w-100">{row.name}</div>,
  },
  {
    title: 'Providers',
    key: 'providers_count',
    render: (row) => (
      <button className="link" onClick={() => handleProviderCountClick(row.id)}>
        {row.providers_count}
      </button>
    ),
    headerClasses: styles['flex-basis-5'],
    tdClassName: styles['flex-basis-5'],
    cellTextAlign: 'left',
  },
  {
    title: 'Action',
    key: 'action',
    headerClasses: styles['flex-basis-5'],
    tdClassName: styles['flex-basis-5'],
    render: (row) => (
      <EditDeleteActionButtons
        handleDelete={() => handleSpecialtyDelete(row.id)}
        handleEdit={() => handleSpecialtyEdit(row.id)}
      />
    ),
  },
];

const AdminSpecialty = () => {
  const [
    {
      list: specialtiesList,
      showAddSpecialty,
      showEditSpecialty,
      showProvidersBySpecialty,
      currentSpecialtyId,
      updatedSpecialties,
      addedSpecialties,
      deletedSpecialties,
    },
    dispatch,
  ] = useAdminSpecialties();

  const [specialtiesFromTable, setSpecialtiesFromTable] = useState<Specialty[]>([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { setActions } = useAdministrationContext();

  useEffect(() => {
    setSpecialtiesFromTable(specialtiesList);
  }, [specialtiesList]);

  const specialtiesRows: SpecialtyRow[] = useMemo(
    () => specialtiesFromTable?.map(mapSpecialtyToRow) ?? [],
    [specialtiesFromTable]
  );

  const handleProviderCountClick = (specialtyId: number) => {
    dispatch(setCurrentSpecialtyId(specialtyId));
    dispatch(toggleShowProvidersBySpecialty());
  };

  const handleSpecialtyDelete = (specialtyId) => {
    setSpecialtiesFromTable((st) => st.filter((s) => s.id !== specialtyId));
    dispatch(appendDeletedSpecialty(specialtyId));
  };

  const handleSpecialtyEdit = (specialtyId) => {
    dispatch(setCurrentSpecialtyId(specialtyId));
    dispatch(toggleSpecialtyShowEdit());
  };

  useEffect(() => {
    setActions(AdminSpecialtyActions);
  }, []);

  useEffect(() => {
    dispatch(
      setResetSpecialtyForm(() => {
        setSpecialtiesFromTable(specialtiesList);
        dispatch(clearAllSpecialtyChanges());
      })
    );
  }, [dispatch, specialtiesList]);

  const commitUpdateSpecialties = useCallback(async () => {
    if (!updatedSpecialties.length) return;
    try {
      const updatedDistinct = updatedSpecialties.flatMap((us) => {
        const uneditedMatch = specialtiesList.find((s) => s.id === us.id);
        if (uneditedMatch.name === us.name) return [];
        return [us];
      });
      await updateSpecialtyBulk(updatedDistinct);
    } catch (error) {
      //handle error show at app level
      console.error(error);
    }
  }, [specialtiesList, updatedSpecialties]);

  const commitDeleteSpecialties = useCallback(async () => {
    if (!deletedSpecialties.length) return;
    try {
      await deleteSpecialtyBulk(deletedSpecialties);
    } catch (error) {
      console.error(error);
    }
  }, [deletedSpecialties]);

  const commitAddSpecialties = useCallback(async () => {
    if (!addedSpecialties.length) return;
    try {
      const added = await addSpecialtyBulk(addedSpecialties);
      setSpecialtiesFromTable((st) => {
        //have to look with name since it does not have id yet
        const copy = [...st];
        added.forEach((as) => {
          const updatedIdx = copy.findIndex((s) => s.name === as.name);
          st[updatedIdx] = as;
        });
        return copy;
      });
    } catch (error) {
      console.error(error);
    }
  }, [addedSpecialties]);

  const handleSubmitSpecialtyChanges = useCallback(async () => {
    await Promise.allSettled([
      await commitUpdateSpecialties(),
      await commitAddSpecialties(),
      await commitDeleteSpecialties(),
    ]);
    dispatch(clearAllSpecialtyChanges());
    dispatch(setSpecialties(specialtiesFromTable));
    setShowConfirmModal(false);
  }, [
    commitAddSpecialties,
    commitDeleteSpecialties,
    commitUpdateSpecialties,
    dispatch,
    specialtiesFromTable,
  ]);

  useEffect(() => {
    dispatch(
      setSubmitSpecialtiesForm(() => {
        setShowConfirmModal(true);
      })
    );
  }, [dispatch, handleSubmitSpecialtyChanges]);

  const handleAddSpecialty = (values: { name: string }) => {
    const newSpecialty = { id: 0, is_active: true, name: values.name, provider_count: 0 };
    setSpecialtiesFromTable((st) => [...st, newSpecialty]);
    dispatch(appendAddedSpecialty(newSpecialty));
  };

  const handleEditSpecialty = (values: { name: string }) => {
    const editedSpecialtyIdx = specialtiesFromTable.findIndex((st) => st.id === currentSpecialtyId);
    const copy = [...specialtiesFromTable];
    copy[editedSpecialtyIdx] = { ...copy[editedSpecialtyIdx], name: values.name };
    setSpecialtiesFromTable(copy);
    dispatch(appendUpdatedSpecialty(copy[editedSpecialtyIdx]));
  };

  const [search, setSearch] = useState('');
  const [searchResult, setSearchResult] = useState<Specialty[]>([]);
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const fetchSearchSpecialties = async (search: string) => {
    try {
      const searchResult = await searchSpecialties(search);
      setSearchResult(searchResult.results);
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedSearchProviders = useMemo(
    () => debouncePromiseValue(fetchSearchSpecialties, 500),
    []
  );

  useEffect(() => {
    if (!search) return;
    debouncedSearchProviders(search);
  }, [debouncedSearchProviders, search]);

  const specialtiesSearchResultRows = useMemo(
    () => searchResult.map(mapSpecialtyToRow),
    [searchResult]
  );

  const columns = useMemo(
    () => specialtyColumns(handleProviderCountClick, handleSpecialtyDelete, handleSpecialtyEdit),
    [handleProviderCountClick, handleSpecialtyDelete, handleSpecialtyEdit]
  );

  return (
    <>
      <Card
        className="card-bg-border"
        headers={[
          <SearchFilterHeader
            handleSearch={handleSearch}
            search={search}
            onFiltersClick={() => undefined}
            title="Provider specialties"
            searchPlaceholder="Search specialties..."
            key="provider-specialties-header"
          />,
        ]}
      >
        <GenericTable
          columns={columns}
          data={search ? specialtiesSearchResultRows : specialtiesRows}
          headerRowClassName="d-flex gap align-items-center"
          rowClassName="d-flex gap align-items-center"
        />
      </Card>
      <AddNewSpecialtyModal
        show={showAddSpecialty}
        onClose={() => dispatch(toggleShowSpecialtyAdd())}
        handleSubmit={handleAddSpecialty}
      />
      <EditSpecialtyModal
        show={showEditSpecialty}
        onClose={() => dispatch(toggleSpecialtyShowEdit())}
        currentSpecialtyId={currentSpecialtyId}
        specialtiesFromTable={specialtiesFromTable}
        handleSubmit={handleEditSpecialty}
      />
      <ProvidersBySpecialtyModal
        show={showProvidersBySpecialty}
        onClose={() => dispatch(toggleShowProvidersBySpecialty())}
      />
      <VyTracModal
        title="Warning"
        body="Are you sure you want to save changes?"
        footer={
          <div className="d-flex w-100 justify-content-between">
            <Button label="Cancel" variant="cancel" onClick={() => setShowConfirmModal(false)} />
            <Button label="Confirm" variant="confirm" onClick={handleSubmitSpecialtyChanges} />
          </div>
        }
        centered
        onClose={() => setShowConfirmModal(false)}
        show={showConfirmModal}
      />
    </>
  );
};
export default AdminSpecialty;
