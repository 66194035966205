import { Conversation, Message, Paginator, Participant } from '@twilio/conversations';
import { ConversationAction, ConversationActionTypes } from './conversations-action-types';
import { TwilioConversation } from './conversations-reducer';

export const upsertConversation = (conversation: TwilioConversation): ConversationAction => ({
  type: ConversationActionTypes.UPSERT_CONVERSATION,
  payload: { conversation },
});

export const addConversation = (conversation: TwilioConversation): ConversationAction => ({
  type: ConversationActionTypes.ADD_CONVERSATION,
  payload: { conversation },
});

export const setConversations = (conversations: TwilioConversation[]): ConversationAction => ({
  type: ConversationActionTypes.SET_CONVERSATIONS,
  payload: { conversations },
});

/**
 * Action that either bubbles a conversation that's outside of current pagination or updates one that is in sight currently
 * @returns
 */
export const setLastMessage = (conversation: TwilioConversation): ConversationAction => ({
  type: ConversationActionTypes.SET_LAST_MESSAGE,
  payload: { conversation },
});

export const setParticipants = (participants: Participant[]): ConversationAction => ({
  type: ConversationActionTypes.SET_PARTICIPANTS,
  payload: { participants },
});

export const setUnreadCount = ({
  conversationSID,
  unreadCount,
}: {
  conversationSID: string;
  unreadCount: number;
}): ConversationAction => ({
  type: ConversationActionTypes.SET_UNREAD_COUNT,
  payload: { conversationSID, unreadCount },
});

export const setConversationsPaginator = (
  paginator: Paginator<Conversation>
): ConversationAction => ({
  type: ConversationActionTypes.SET_CONVERSATIONS_PAGINATOR,
  payload: { paginator },
});

export const appendConversations = (conversations: TwilioConversation[]): ConversationAction => ({
  type: ConversationActionTypes.APPEND_CONVERSATIONS,
  payload: { conversations },
});

export const removeConversation = (conversationSID: string): ConversationAction => ({
  type: ConversationActionTypes.REMOVE_CONVERSATION,
  payload: { conversationSID },
});
