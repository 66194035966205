import ContactInformation from './ContactInformation';
import Address from './Address';
import EmergencyContacts from './EmergencyContacts';
import CareChampions from './CareChampions';
import Insurance from './Insurance';
import HealthScoring from './HealthScoring';
import DemographicInfo from './DemographicInfo';

export const Step3 = () => {
  return (
    <>
      <ContactInformation />
      <Address />
      <DemographicInfo />
      <EmergencyContacts />
      <CareChampions />
      <Insurance />
      <HealthScoring />
    </>
  );
};
export default Step3;
