import { ReactNode, useMemo } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import './styles.css';

interface VytracModalProps extends ModalProps {
  title?: ReactNode;
  body: ReactNode;
  footer?: ReactNode;
  onClose: () => void;
  onConfirm?: () => void;
  show: boolean;
  headerClassName?: string;
  modalWrapper?: ({ children }) => JSX.Element;
  centered?: boolean;
  backdrop?: boolean;
  size?: 'sm' | 'lg' | 'xl';
  bodyClassName?: string;
}

export default function VyTracModal({
  title = '',
  body,
  footer = null,
  onClose,
  show,
  headerClassName = '',
  modalWrapper = null,
  centered,
  backdrop,
  size,
  bodyClassName = '',
  ...props
}: VytracModalProps) {
  const ModalWrapper = useMemo(() => modalWrapper, [modalWrapper]);
  return (
    <Modal
      contentClassName="modal overflow-visible"
      show={show}
      onHide={onClose}
      centered={centered}
      backdrop={backdrop}
      size={size}
      {...props}
    >
      {title && (
        <Modal.Header closeButton>
          <Modal.Title as="div" className={headerClassName}>
            {title}
          </Modal.Title>
        </Modal.Header>
      )}
      {modalWrapper ? (
        <ModalWrapper>
          <Modal.Body className={`overflow-visible ${bodyClassName || ''}`}>{body}</Modal.Body>
          {footer && <Modal.Footer>{footer}</Modal.Footer>}
        </ModalWrapper>
      ) : (
        <>
          <Modal.Body className={`${bodyClassName || ''}`}>{body}</Modal.Body>
          {footer && <Modal.Footer>{footer}</Modal.Footer>}
        </>
      )}
    </Modal>
  );
}
