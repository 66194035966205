import Button from 'components/Button';
import VytracModal from 'components/Modal';
import moment, { Moment } from 'moment';
import React from 'react';

interface IDateConfirmationModal {
  dateValue: Moment;
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
}
const DateConfirmationModal = ({
  dateValue,
  show,
  onConfirm,
  onCancel,
  onClose,
}: IDateConfirmationModal) => {
  return (
    <VytracModal
      show={show}
      onClose={onClose}
      title="Date Confirmation"
      centered
      body={
        <div className="font-size-big">
          Are you sure you wish to set <b>{moment(dateValue).format('MM.DD.YYYY')}</b> as the care
          plan activation date?
        </div>
      }
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="cancel" variant="delete" onClick={onCancel} />
          <Button label="confirm" variant="confirm" onClick={onConfirm} />
        </div>
      }
    />
  );
};
export default DateConfirmationModal;
