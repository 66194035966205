import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import DropdownMenu from 'components/DropdownMenu';
import styles from '../../styles.module.css';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import { TextInputFormik } from 'components/FormikComponents';
import { getStyles } from '../../../utils/getStyles';
import { AddRow } from '../../Steps';
import { useMemo } from 'react';
const Allergies = () => {
  const { values, touched, errors } = useFormikContext<InvitePatient>();
  const openError = useMemo(() => errors.allergies?.length > 0 || false, [errors]);
  return (
    <DropdownMenu name="Allergies" open={openError}>
      <FieldArray validateOnChange={false} name="allergies">
        {({ push, remove }) => (
          <>
            {values.allergies && values.allergies.length > 0 ? (
              values.allergies.map((_, index) => (
                <div className="d-flex flex-column gap" key={index}>
                  <div className={styles['row']}>
                    <div className="d-flex gap px-3 pt-3 mb-2">
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Allergy name
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <TextInputFormik
                          className="w-100"
                          name={`allergies[${index}].name`}
                          style={getStyles(errors, touched, `allergies[${index}].name`)}
                        />
                        <ErrorMessage name={`allergies[${index}].name`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon fill="#8FAAC3" stroke="#8FAAC3" />
                      </div>
                    </div>
                  </div>
                  <div className={styles['row']}>
                    <div className="d-flex gap px-3 mb-2">
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Allergy details
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <TextInputFormik
                          className="w-100"
                          name={`allergies[${index}].details`}
                          style={getStyles(errors, touched, `allergies[${index}].details`)}
                        />
                        <ErrorMessage name={`allergies[${index}].details`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  {index === values.allergies.length - 1 && (
                    <AddRow name="allergy" onClick={() => push({ name: '', details: '' })} />
                  )}
                </div>
              ))
            ) : (
              <AddRow name="allergy" onClick={() => push({ name: '', details: '' })} />
            )}
          </>
        )}
      </FieldArray>
    </DropdownMenu>
  );
};
export default Allergies;
