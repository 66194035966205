import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { FieldArray, useFormikContext } from 'formik';
import { FC } from 'react';
import Select, { MultiValue } from 'react-select';

interface SelectFormikProps {
  name: string;
  options: { value: number | string; label: string }[];
  defaultValue?: { value: number | string; label: string }[];
  placeholder?: string;
  styles?: React.CSSProperties;
  onChange?: (values: MultiValue<{ value: number | string; label: string }>) => void;
  onClick?: () => void;
}

const MultiSelectFormik: FC<SelectFormikProps> = ({
  options,
  name,
  defaultValue,
  placeholder,
  styles,
  onChange,
  onClick,
}: SelectFormikProps) => {
  const { setFieldValue, values } = useFormikContext();
  return (
    <FieldArray
      name={name}
      render={() => (
        <div onClick={onClick}>
          <Select
            isMulti
            name={name}
            options={options}
            value={options?.filter((option) => values[name]?.indexOf(option.value) >= 0)}
            defaultValue={defaultValue}
            onChange={
              onChange
                ? onChange
                : (values: MultiValue<{ value: number; label: string }>) => {
                    setFieldValue(
                      name,
                      values.filter((e) => e.value).map((v) => v.value)
                    );
                  }
            }
            placeholder={placeholder}
            styles={{
              ...vytracSelectStyle,
              control: (base, state) => ({ ...vytracSelectStyle.control(base, state), ...styles }),
            }}
          />
        </div>
      )}
    />
  );
};
export default MultiSelectFormik;
