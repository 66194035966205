import React from 'react';
import styles from './styles.module.css';

const MiniatureHeader = () => {
  const days: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return (
    <div className="d-flex w-100">
      {days.map((day: string) => (
        <div className={styles.dayElement} key={day}>
          {day}
        </div>
      ))}
    </div>
  );
};
export default MiniatureHeader;
