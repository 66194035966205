import { useEffect, useMemo, useState } from 'react';
import { usePatientFormikContext } from 'screens/Patients/store';
import { setPatientActions } from 'screens/Patients/store/PatientCarePlan/patientCarePlanActionCreators';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { getPopulations } from 'services/administrationService';
import {
  createPatientActions,
  deletePatientActions,
  partialUpdateSinglePatient,
} from 'services/patientService';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import PopulationPatient from 'types/ApiModels/Patients/PopulationPatient';
import { ITemplateCarePlanAction } from 'types/ApiModels/Templates/CarePlan';
import { transformToPatientAction } from 'util/calendarUtils/transformers/transformToPatientAction';

const usePopulationManagement = () => {
  const { selectedPatient, setSelectedPatient } = usePatientFormikContext();
  const {
    dispatch,
    patientState: {
      patientCarePlan: {
        patientActions,
        carePlanState: { updated_at },
      },
    },
  } = usePatientContext();

  const [allPopulations, setAllPopulations] = useState<PopulationWithCount[]>([]);

  const blockStartDate = useMemo(() => {
    return new Date(selectedPatient.adherence.care_plan_from ?? updated_at);
  }, [selectedPatient.adherence.care_plan_from, updated_at]);

  useEffect(() => {
    const fetchPopulations = async () => {
      try {
        const array = await getPopulations();
        setAllPopulations(array);
      } catch (e) {
        throw e;
      }
    };

    fetchPopulations();
  }, []);

  const importActions = async (actions: ITemplateCarePlanAction[]) => {
    const absoluteActions: IPatientCarePlanAction[] = [];
    actions.forEach((action) =>
      absoluteActions.push(transformToPatientAction(action, blockStartDate))
    );

    try {
      await createPatientActions(selectedPatient.id, absoluteActions);
      dispatch(setPatientActions([...patientActions, ...absoluteActions]));
    } catch (e) {
      throw e;
    }
  };

  const addPopulations = async (actions: ITemplateCarePlanAction[]) => {
    const addedPopulationIds: Set<number> = new Set(actions.map((action) => action.template));
    const addedPopulations = allPopulations
      .filter((p) => addedPopulationIds.has(p.id))
      .map((p) => p as PopulationPatient);

    const updatedPopulationsIds = [
      ...selectedPatient.populations.map((p) => p.id),
      ...addedPopulationIds,
    ];
    const updatedPopulations: PopulationPatient[] = [
      ...(selectedPatient.populations as PopulationPatient[]),
      ...addedPopulations,
    ];

    try {
      await partialUpdateSinglePatient(selectedPatient.id, { populations: updatedPopulationsIds });
      setSelectedPatient({
        ...selectedPatient,
        populations: updatedPopulations,
      });
    } catch (e) {
      throw e;
    }
  };

  const deleteActions = async (populationId: number) => {
    const updatedPatientActions = patientActions.filter(
      (actions) => actions.template !== populationId
    );
    const deletedIds = patientActions
      .filter((actions) => actions.template === populationId)
      .map((action) => action.id);

    try {
      await deletePatientActions(selectedPatient.id, deletedIds);
      dispatch(setPatientActions(updatedPatientActions));
    } catch (e) {
      throw e;
    }
  };

  const deletePopulation = async (populationId: number) => {
    await deleteActions(populationId);

    const updatedPopulations = selectedPatient.populations.filter((p) => p.id !== populationId);
    console.log(updatedPopulations);
    try {
      await partialUpdateSinglePatient(selectedPatient.id, {
        populations: updatedPopulations.map((p) => p.id),
      });
      setSelectedPatient({
        ...selectedPatient,
        populations: updatedPopulations,
      });
    } catch (e) {
      throw e;
    }
  };

  return { importActions, addPopulations, deletePopulation };
};
export default usePopulationManagement;
