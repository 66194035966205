import EditDeleteActionButtons from 'components/EditDeleteActionButtons';
import { FC } from 'react';
import { Form } from 'react-bootstrap';
import styles from './styles.module.css';

interface PatientSettingsItemProps {
  is_active: boolean;
  name: string;
  is_default: boolean;
  handleDefaultchange: () => void;
  handleActiveChange: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
  containerClassName?: string;
  is_readonly?: boolean;
}

const PatientSettingsItem: FC<PatientSettingsItemProps> = ({
  is_active,
  name,
  is_default,
  handleActiveChange,
  handleDefaultchange,
  handleDelete,
  handleEdit,
  is_readonly = false,
  containerClassName = '',
}: PatientSettingsItemProps) => {
  return (
    <div
      className={`d-flex justify-content-between bg-light-gray rounded p-2 ${containerClassName}`}
    >
      <div className="d-flex gap-lg align-items-center">
        <input
          type="radio"
          radioGroup="activity-templates"
          onChange={handleDefaultchange}
          value={is_default?.toString() ?? 'false'}
          checked={is_default}
          className={styles['square-checkbox']}
        />
        <p className="m-0 p-0">{name}</p>
      </div>
      <div className="d-flex gap-lg">
        {is_readonly ? null : (
          <EditDeleteActionButtons handleDelete={handleDelete} handleEdit={handleEdit} />
        )}
        <div onClick={handleActiveChange} className="cursor-pointer">
          <Form.Check type="switch" checked={is_active} readOnly />
        </div>
      </div>
    </div>
  );
};
export default PatientSettingsItem;
