import SatisfactionBar from 'components/SatisfactionBar';
import React from 'react';
import styles from './styles.module.css';

interface ISatisfactionPreview {
  value: number;
}

const SatisfactionPreview = ({ value }: ISatisfactionPreview) => {
  return (
    <div className={styles.main}>
      <div className={styles.title}>satisfaction</div>
      <SatisfactionBar value={value} />
    </div>
  );
};

export default SatisfactionPreview;
