import AdherenceGrid from 'components/AdherenceGrid';
import React from 'react';
import Adherence from 'types/ApiModels/Patients/Adherence';
import { convertAdherenceToBooleanArray, getCarePlanDayDiff } from 'util/adherenceUtils';
import styles from './styles.module.css';

interface IAdherencePreview {
  adherence: Adherence;
}

const AdherencePreview = ({ adherence }: IAdherencePreview) => {
  const checkCount = adherence.adherence_days.length;
  const totalCount = getCarePlanDayDiff(adherence) + 1;
  const booleanAdherence = convertAdherenceToBooleanArray(adherence);

  return (
    <div className={styles.main}>
      <div>
        <div className={styles.title}>adherence</div>
        <hr className="my-0" />
        {booleanAdherence.length ? (
          <div className={styles.text}>
            {checkCount} days of {totalCount}
          </div>
        ) : (
          <div className={styles.disabledText}>No adherence available</div>
        )}
      </div>
      {booleanAdherence.length > 0 && (
        <div className={styles.adherenceGrid}>
          <AdherenceGrid adherence={adherence} />
        </div>
      )}
    </div>
  );
};
export default AdherencePreview;
