import { UmbralType, IGoalThreshold, IGoalThresholdUmbral } from 'types/ApiModels/Patients/Goal';
import { ThresholdFormikKeys, ThresholdFormikValues } from './thresholdFormikUtils';

const createFormikInitialValue = (
  isDoubleThreshold: boolean,
  goalThreshold: IGoalThreshold
): ThresholdFormikValues => {
  const getUmbral = (type: UmbralType): IGoalThresholdUmbral => {
    return goalThreshold.threshold_umbrals.find((umbral) => umbral.type === type);
  };

  return {
    [ThresholdFormikKeys.UPPER_RED_ZONE_TOP]: getUmbral(UmbralType.RED_ZONE_UPPER)?.max_value,
    [ThresholdFormikKeys.UPPER_RED_ZONE_BOTTOM]: getUmbral(UmbralType.RED_ZONE_UPPER)?.min_value,
    [ThresholdFormikKeys.UPPER_YELLOW_ZONE_BOTTOM]: getUmbral(UmbralType.YELLOW_ZONE_UPPER)
      ?.min_value,
    [ThresholdFormikKeys.LOWER_YELLOW_ZONE_TOP]: getUmbral(UmbralType.YELLOW_ZONE_LOWER).max_value,
    [ThresholdFormikKeys.LOWER_RED_ZONE_TOP]: getUmbral(UmbralType.RED_ZONE_LOWER).max_value,
    [ThresholdFormikKeys.LOWER_RED_ZONE_BOTTOM]: getUmbral(UmbralType.RED_ZONE_LOWER).min_value,
    [ThresholdFormikKeys.DOUBLE_THRESHOLD]: isDoubleThreshold,
  };
};

export default createFormikInitialValue;
