import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { validateContext } from 'util/utils';
import { ConversationAction } from './conversations-action-types';
import { twilioConversationsInitialState, TwilioConversationsState } from './conversations-reducer';

interface ConversationContextState {
  conversationState: TwilioConversationsState;
  dispatch: Dispatch<SetStateAction<ConversationAction>>;
}
const conversationContextInitialState: ConversationContextState = {
  conversationState: twilioConversationsInitialState,
  dispatch: null,
};

export const ConversationsContext = createContext<ConversationContextState>(
  conversationContextInitialState
);

export const useConversationsContext = () => {
  const context = useContext(ConversationsContext);
  validateContext(context);
  return context;
};
