import { Tagitem } from 'components/Tagitem';
import React from 'react';
import PopulationPatient from 'types/ApiModels/Patients/PopulationPatient';
import styles from './styles.module.css';

interface IPopulationPreview {
  populations: PopulationPatient[];
}
const PopulationPreview = ({ populations }: IPopulationPreview) => {
  return (
    <div className={styles.main}>
      <div className={styles.title}>population</div>
      {populations.length > 0 ? (
        populations.map((population: PopulationPatient, idx: number) => (
          <Tagitem text={population.name} key={`population-tag-item-${idx}`} />
        ))
      ) : (
        <div className={styles.title} style={{ textTransform: 'none', whiteSpace: 'nowrap' }}>
          No populations assigned
        </div>
      )}
    </div>
  );
};
export default PopulationPreview;
