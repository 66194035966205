import { ProviderUpdateRequest } from 'types/ApiModels/Providers/Provider';
import { convertDatepickToDate } from 'util/dateUtils';
import type { ProviderDetailsFormValues } from '../ProviderDetails';

export const mapProviderFormToProvider = (
  values: ProviderDetailsFormValues,
  isActive: boolean
): ProviderUpdateRequest => {
  const {
    identification: {
      firstName: first_name,
      accessRoles: roles_id,
      dateOfBirth,
      gender: sex,
      lastName: last_name,
      middleName: middle_name,
      title: title_id,
    },
    personalInformation: {
      addressInformation,
      contactInformation: { email, phoneNumber1: phone_number1, phoneNumber2: phone_number2 },
    },
    specialties: speciality_id,
  } = values;
  return {
    first_name,
    address: addressInformation.map((a) => ({
      home: a.home,
      apt: a.apt,
      state: a.state,
      city: a.city,
      zip_code: a.zipCode,
    })),
    date_birth: convertDatepickToDate(dateOfBirth).toISOString(),
    email,
    languages_id: [],
    last_name,
    middle_name,
    phone_number1,
    phone_number2,
    roles_id,
    sex,
    speciality_id,
    title_id,
    // photo: 'photoplaceholder',
    is_active: isActive,
  };
};
