import { Button, Col, Row } from 'react-bootstrap';
import passwordLogo from 'assets/images/password.png';
import { useLoginContext } from '../store';
import { loginViews } from 'types/Login';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { ForgotPasswordValidationSchema } from '../ValidationSchemas';
import { getStyles } from '../LoginForm/util';
import { sendVerificationCodeEmail } from 'services/userService';
import { setBreadcrumTitle } from 'util/themeUtils';

interface ForgotPasswordValues {
  email: string;
}

const ForgotPassword = () => {
  const { changeView, setUserEmail, setIsResettingPassword } = useLoginContext();

  const handleSubmit = ({ email }: ForgotPasswordValues) => {
    sendVerificationCodeEmail(email);
    setUserEmail(email);
    setIsResettingPassword(true);
    changeView(loginViews.token);
  };
  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <div>
        <Row className="vertical-container">
          <Col className="line-container">
            <div className="green-line"></div>
          </Col>
          <Col className="line-container">
            <div className="grey-line"></div>
          </Col>
        </Row>
        <div className="centered-logo">
          <div className="logo-container">
            <img className="shield-logo" alt="" src={passwordLogo} />
          </div>
        </div>
        <p className="text-left normal-text dynamic-font-heading" style={{ marginTop: 30 }}>
          Forgot my password
        </p>
        <p className="text-left smaller-text dynamic-font-normal">
          Enter the email you used to register your {setBreadcrumTitle()} account.
        </p>
      </div>
      <div className="d-flex flex-column flex-grow-1">
        <Formik
          initialValues={{ email: '' }}
          onSubmit={handleSubmit}
          validationSchema={ForgotPasswordValidationSchema}
          validateOnBlur
        >
          {({ touched, errors }) => (
            <Form className="d-flex flex-column gap-lg flex-grow-1 justify-content-between">
              <div className="d-flex flex-grow-1">
                <div className="d-flex flex-grow-1 flex-column justify-content-center">
                  <p className="grey-text dynamic-font-normal">Email</p>
                  <Field
                    name="email"
                    className="text-field dynamic-font-normal"
                    style={touched['email'] && getStyles(errors, 'email')}
                  />
                  <ErrorMessage name="email">
                    {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
                  </ErrorMessage>
                </div>
              </div>
              <Row className="d-flex justify-content-between">
                <Button
                  onClick={() => changeView(loginViews.login)}
                  type="button"
                  variant="light"
                  className="dynamic-font-normal text-bold"
                >
                  CANCEL
                </Button>
                <Button type="submit" className="dynamic-font-normal text-bold">
                  NEXT
                </Button>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default ForgotPassword;
