import Button from 'components/Button';
import { useNotesKeywords } from 'screens/Administration/store/hooks';

const NotesKeywordsActions = () => {
  const [
    {
      createdTemplates,
      deletedTemplates,
      updatedTemplates,
      submitTemplates,
      currentDefaultKeywordTemplateId,
      resetTemplateChanges,
    },
    ,
  ] = useNotesKeywords();
  const isDirty =
    Boolean(createdTemplates.length || deletedTemplates.length || updatedTemplates.length) ||
    currentDefaultKeywordTemplateId !== null;
  return (
    <div className="d-flex gap-sm">
      <Button label="cancel" variant="dull" disabled={!isDirty} onClick={resetTemplateChanges} />
      <Button
        label="save changes"
        variant="confirm"
        disabled={!isDirty}
        onClick={submitTemplates}
      />
    </div>
  );
};
export default NotesKeywordsActions;
