import { FC } from 'react';
import { Link } from 'react-router-dom';

interface CarePlanEditElementProps {
  id: number;
}

const CarePlanEditElement: FC<CarePlanEditElementProps> = ({ id }: CarePlanEditElementProps) => {
  return (
    <div>
      <Link to="#" className="link">
        Edit
      </Link>
    </div>
  );
};
export default CarePlanEditElement;
