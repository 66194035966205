import { FC } from 'react';
import { LabeledField, PhoneNumberFormik, TextInputFormik } from 'components/FormikComponents';
import DatePickerFormik from 'components/FormikComponents/date-picker-formik';

const fieldByType = {
  datepicker: DatePickerFormik,
  text: TextInputFormik,
  phoneNumber: PhoneNumberFormik,
};

export interface FormikField {
  name: string;
  label: string;
  type?: keyof typeof fieldByType;
  className?: string;
}

interface FormikBuilderProps {
  formikFields: FormikField[];
}

const FormikBuilder: FC<FormikBuilderProps> = ({ formikFields }: FormikBuilderProps) => {
  return (
    <div className="d-flex flex-wrap gap-lg py-3">
      {formikFields.map((f) => {
        const FieldComponent = fieldByType[f?.type || 'text'];
        return (
          <LabeledField name={f.name} label={f.label} key={f.name}>
            <FieldComponent name={f.name} inputClassName={f?.className} />
          </LabeledField>
        );
      })}
    </div>
  );
};
export default FormikBuilder;
