import { createContext, useContext } from 'react';
import { RegisterViews } from 'types/Register';

interface RegisterProviderContextState {
  changeView: (view: RegisterViews) => void;
  setRegisterValues: (values: any) => void;
  registerValues: any;
}
const initialLoginContextState = {
  changeView: (view) => undefined,
  registerValues: {},
  setRegisterValues: (values) => undefined,
};

export const RegisterProviderContext =
  createContext<RegisterProviderContextState>(initialLoginContextState);

export const useRegisterProviderContext = () => useContext(RegisterProviderContext);
