import { useQuery } from 'react-query';
import { getPopulations } from 'services/administrationService';
import { getEncounterCategories } from 'services/encounterService';
import { getInsurances, getPatientDevicesMeasureTypes } from 'services/patientService';
import {
  getEthnicityOptions,
  getLanguages,
  getMaritalStatusOptions,
  getRaceOptions,
  getReligionOptions,
  getSexOptions,
} from 'services/userService';

type AppQueryKey =
  | 'sex'
  | 'languages'
  | 'ethnicities'
  | 'races'
  | 'religions'
  | 'populations'
  | 'insurances'
  | 'devicesMeasureTypes'
  | 'encounterCategories'
  | 'maritalStatus';

const fetchers: Record<AppQueryKey, unknown> = {
  sex: getSexOptions,
  languages: getLanguages,
  ethnicities: getEthnicityOptions,
  insurances: getInsurances,
  religions: getReligionOptions,
  races: getRaceOptions,
  populations: getPopulations,
  devicesMeasureTypes: getPatientDevicesMeasureTypes,
  encounterCategories: getEncounterCategories,
  maritalStatus: getMaritalStatusOptions,
};

/**
 * use to query app-level options (useful for dropdown data)
 * @param key
 * @returns
 */
export const useAppQuery = <T>(key: AppQueryKey) => {
  if (!fetchers[key])
    throw new Error(`The key you tried to access (${key}) does not have a fetcher associated`);
  return useQuery<T>(key, fetchers[key]);
};
