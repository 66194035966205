import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PopulationModalData } from './utils';
import { isEmpty, isEqual } from 'lodash';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import DropdownMenu from 'components/DropdownMenu';
import styles from './styles.module.css';
import Activity from './Activity';
import { Tagitem } from 'components/Tagitem';
import { ReactComponent as EyeIcon } from 'assets/icons/hide_population.svg';
import { ITemplateCarePlan, ITemplateCarePlanAction } from 'types/ApiModels/Templates/CarePlan';
import { ActionException } from 'types/ApiModels/CarePlan/action';

interface PopulationsBodyProps {
  populations: number[];
  actionsByDay: PopulationModalData[];
  setActionsByDay: React.Dispatch<React.SetStateAction<PopulationModalData[]>>;
  patientPopulations?: ITemplateCarePlan[];
  setActionExceptions: React.Dispatch<React.SetStateAction<ActionException[]>>;
  setCarePlanActions: React.Dispatch<
    React.SetStateAction<(ITemplateCarePlanAction & { template?: number })[]>
  >;
  carePlanActions: (ITemplateCarePlanAction & { template?: number })[];
}
const PopulationsBody = ({
  populations: populationIds,
  actionsByDay,
  setActionsByDay,
  patientPopulations,
  setActionExceptions,
  setCarePlanActions,
  carePlanActions,
}: PopulationsBodyProps) => {
  // Days of the week
  const weekDays = useMemo(() => {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  }, []);

  // Map of populations present in the patient to a boolean
  const [showPopulation, setShowPopulation] = useState({});

  // Effect to populate the map of populations to show
  useEffect(() => {
    if (isEmpty(showPopulation) && patientPopulations?.length > 0) {
      const newSetShowPopulation = {};
      patientPopulations?.forEach((population) => {
        newSetShowPopulation[population.name] = populationIds.includes(population.id);
      });
      setShowPopulation(newSetShowPopulation);
    }
  }, [showPopulation, populationIds, patientPopulations]);

  // Called when user click on a population already present in the patient
  const handleTogglePopulation = useCallback(
    (index) => {
      const newShowPopulation = { ...showPopulation };
      newShowPopulation[patientPopulations?.[index].name] =
        !newShowPopulation[patientPopulations?.[index].name];
      setShowPopulation(newShowPopulation);
    },
    [showPopulation, patientPopulations]
  );

  // Called when an action switch is toggled
  const handleToggleAction = useCallback(
    (day, actionIndex, value) => {
      // Deep copy the actions in the modal
      const newActions = [...actionsByDay];

      // Set the toggle value
      newActions[day].actions[actionIndex].toggled = value;

      // Set the new actions
      setActionsByDay(newActions);

      // If the switch was toggled off then
      if (!value) {
        // Add an exception on the current action at the current day and time
        setActionExceptions((prev) => [
          ...prev,
          {
            actionId: newActions[day].actions[actionIndex].id,
            day: newActions[day].actions[actionIndex].day_of_week || day,
            time: newActions[day].actions[actionIndex].time,
            exception: {
              day_of_week: newActions[day].actions[actionIndex].day_of_week || day,
              week_number: null,
              time: `${newActions[day].actions[actionIndex].time}:${
                newActions[day].actions[actionIndex].minutes < 10 ? '0' : ''
              }${newActions[day].actions[actionIndex].minutes}:00`,
            },
          },
        ]);
        // Else if the switch was toggled on then
      } else {
        // Remove the exception on the current action at the current day and time
        setActionExceptions((prev) =>
          prev.filter(
            (item) =>
              !(
                item.actionId === newActions[day].actions[actionIndex].id &&
                item.day === day &&
                item.time === newActions[day].actions[actionIndex].time
              )
          )
        );
      }
    },
    [actionsByDay, setActionsByDay, setActionExceptions]
  );

  // State to style the selected action
  const [selectedActivity, setSelectedActivity] = useState('');

  // Called when a drag of an action ends
  const onDragEnd = useCallback(
    (idx: number) => (result: DropResult) => {
      // If was dropped outside then do nothing
      if (!result.destination) return;

      // Deep copy the actions of the current day
      const items = [...actionsByDay[idx].actions];

      //Get and remove from place the dragged action
      const [reorderedItemOriginal] = items.splice(result.source.index, 1);

      //Deep copy the object to use later
      const reorderedItem = { ...reorderedItemOriginal };

      // If action was moved to somewhere different from the top then set time to the previous time
      if (result.destination.index > 0) {
        reorderedItemOriginal.time = items[result.destination.index - 1].time;
        // Else set time as the previous top
      } else {
        reorderedItemOriginal.time = items[result.destination.index].time;
      }

      // Insert the reordered item at the new position
      items.splice(result.destination.index, 0, {
        ...reorderedItemOriginal,
        id: null,
        recurrence: null,
      });
      // Set the new actions
      const newActions = [...actionsByDay];
      newActions[idx].actions = items;
      setActionsByDay(newActions);
      // Set the selected activity to the new position
      setSelectedActivity(`day[${idx}]_${result.destination.index}`);

      //If the action isn't new then we are going to disconnect the action from the original action and create a new one
      if (typeof reorderedItem.id === 'number') {
        // Create a new action with the same properties except for the id
        const newAction = { ...carePlanActions.find((a) => a.id === reorderedItem.id) };

        newAction.relative_start_date = {
          day_of_week: reorderedItemOriginal.day_of_week,
          week_number: reorderedItemOriginal.week_number,
          time: `${reorderedItemOriginal.time}:${reorderedItemOriginal.minutes < 10 ? '0' : ''}${
            reorderedItemOriginal.minutes
          }:00`,
        };
        // Insert the created action to the care plan actions, we set this to every week
        setCarePlanActions((prev) => [
          ...prev,
          {
            ...newAction,
            id: null,
            recurrence: { ...newAction.recurrence, repeats_type: 'every_week' },
          },
        ]);

        // Create a new exception for the dragged action original id
        const newException = {
          actionId: newAction.id,
          day: reorderedItem.day,
          time: reorderedItem.time,
          exception: {
            day_of_week: reorderedItem.day,
            week_number: reorderedItem.week_number,
            time: `${reorderedItem.time}:${reorderedItem.minutes < 10 ? '0' : ''}${
              reorderedItem.minutes
            }:00`,
          },
        };
        setActionExceptions((prev) => [
          ...prev.filter((e) => !isEqual(newException, e)),
          newException,
        ]);
      }
    },
    [actionsByDay, setActionsByDay, setActionExceptions, setCarePlanActions, carePlanActions]
  );

  return (
    <div>
      <div className="d-flex gap flex-wrap p-2">
        {patientPopulations?.map((pop, index) => (
          <Tagitem
            key={index}
            onClick={() => handleTogglePopulation(index)}
            icon={
              <EyeIcon
                fill={showPopulation[patientPopulations?.[index].name] ? 'none' : '#393E48'}
                stroke={showPopulation[patientPopulations?.[index].name] ? 'none' : 'white'}
              />
            }
            className={`${styles['header']} cursor-pointer`}
            text={pop.name}
          />
        ))}
      </div>
      <div className={styles['week-days']}>
        {weekDays.map((day, idx) => (
          <DropdownMenu
            key={day}
            name={day}
            nameClassName={styles['week-day']}
            containerClassName={styles['week-day-container']}
            openedClassName={styles['week-day-opened']}
            nameStyle={{ fontWeight: actionsByDay?.[idx]?.conflicts ? '700' : null }}
          >
            <DragDropContext
              onDragStart={(initial) => {
                setSelectedActivity(`day[${idx}]_${initial.source.index}`);
              }}
              onDragEnd={onDragEnd(idx)}
            >
              <Droppable droppableId={`day[${idx}]`}>
                {(provided) => (
                  <div
                    className="position-relative"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    <div className={styles['verticalLine']}></div>
                    {actionsByDay[idx]?.actions.map((activity, index) => (
                      <Draggable
                        key={`day[${idx}]_${index}_${
                          activity.id ||
                          `${activity.activity}_${activity.name}_${activity.time}_${idx}_${index}`
                        }`}
                        draggableId={`day[${idx}]_${index}`}
                        index={index}
                        isDragDisabled={activity.readonly}
                      >
                        {(provided) => (
                          <div
                            onClick={() => setSelectedActivity(`day[${idx}]_${index}`)}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {(!patientPopulations || showPopulation[activity.name] !== false) && (
                              <Activity
                                selected={selectedActivity === `day[${idx}]_${index}`}
                                time={activity.time}
                                activityName={activity.activity}
                                population={activity.name !== 'Other' && activity.name}
                                toggled={activity.toggled}
                                day={idx}
                                index={index}
                                setToggled={(value) => handleToggleAction(idx, index, value)}
                                label={activity.label}
                                readonly={activity.readonly}
                              />
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </DropdownMenu>
        ))}
      </div>
    </div>
  );
};
export default PopulationsBody;
