import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import styles from './styles.module.css';
import { isEmpty } from 'lodash';
import { checkFormHasAnyErrors } from 'util/formikUtils';
interface StepHeaderProps {
  step: number;
}
const StepHeader = ({ step }: StepHeaderProps) => {
  const { touched, errors, validateForm, values } = useFormikContext();
  useEffect(() => {
    validateForm(values);
  }, [step]);
  const hasErrors = useMemo(() => {
    return checkFormHasAnyErrors(touched, errors);
  }, [touched, errors]);
  const firstStepClassName = useMemo(() => {
    if (step === 0) return `${styles['current-step']} ${styles['step']}`;
    else return `${styles['passed-step']} ${styles['step']}`;
  }, [step]);
  const secondStepClassName = useMemo(() => {
    if (step === 0) return `${styles['empty-step']} ${styles['step']}`;
    if (step === 1) return `${styles['current-step']} ${styles['step']}`;
    else return `${styles['passed-step']} ${styles['step']}`;
  }, [step]);
  const thirdStepClassName = useMemo(() => {
    if (step === 0 || step === 1) return `${styles['empty-step']} ${styles['step']}`;
    return `${styles['current-step']} ${styles['step']}`;
  }, [step]);

  return (
    <div className={styles['container']}>
      <div className={firstStepClassName}>
        <div>Patient identification</div>
        <div style={{ borderColor: hasErrors && step === 0 && '#F5485C' }}>
          <div style={{ backgroundColor: hasErrors && '#F5485C' }}></div>
        </div>
      </div>
      <div className={`${styles['divider']} ${step > 0 ? styles['passed'] : ''}`}></div>
      <div className={secondStepClassName}>
        <div>Medical history</div>
        <div style={{ borderColor: hasErrors && step === 1 && '#F5485C' }}>
          <div style={{ backgroundColor: hasErrors && '#F5485C' }}></div>
        </div>
      </div>
      <div className={`${styles['divider']} ${step > 1 ? styles['passed'] : ''}`}></div>{' '}
      <div className={thirdStepClassName}>
        <div>Patient profile</div>
        <div style={{ borderColor: hasErrors && step === 2 && '#F5485C' }}>
          <div style={{ backgroundColor: hasErrors && '#F5485C' }}></div>
        </div>
      </div>
    </div>
  );
};
export default StepHeader;
