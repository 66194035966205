import PatientDetail from 'components/patient/PatientDetail';
import PatientProfileRing from 'components/patient/PatientProfileRing/PatientProfileRing';
import { usePatientFormikContext } from 'screens/Patients/store';
import ActivityTracker from './components/ActivityTracker';
import AdherencePreview from './components/AdherencePreview';
import BillingPreview from './components/BillingPreview';
import CareTeamPreview from './components/CareTeamPreview';
import HistoryPreview from './components/HistoryPreview';
import PatientCommunications from './components/PatientCommunications';
import PatientToDo from './components/PatientToDo';
import PopulationPreview from './components/PopulationPreview';
import SatisfactionPreview from './components/SatisfactionPreview';
import Score from './components/Score';
import StartDate from './components/StartDate';
import styles from './styles.module.css';

interface IPatientPreview {
  selectedTab: string;
}

const PatientPreview = ({ selectedTab }: IPatientPreview) => {
  const { selectedPatient } = usePatientFormikContext();

  if (!selectedPatient) return <></>;

  return (
    <div className={styles.main}>
      <div className={`d-flex ${styles.row}`}>
        <ActivityTracker patientId={selectedPatient.id} selectedTab={selectedTab} />
        <div style={{ borderRight: 'none', paddingTop: '16px' }}>
          <PatientProfileRing currentPatient={selectedPatient} />
        </div>
        <div className={styles.patientDetail}>
          <PatientDetail currentPatient={selectedPatient} showActive={true} />
        </div>
        <PatientCommunications currentPatient={selectedPatient} />
        <PatientToDo alerts={selectedPatient.alerts} />
        <CareTeamPreview careTeam={selectedPatient.care_team} />
        <Score value={selectedPatient.health_score} />
        <AdherencePreview adherence={selectedPatient.adherence} />
        <StartDate value={selectedPatient.date_created} />
        <BillingPreview />
      </div>
      <div className={`d-flex ${styles.row}`}>
        <SatisfactionPreview value={Number(selectedPatient.satisfaction)} />
        <PopulationPreview populations={selectedPatient.populations} />
        <HistoryPreview
          records={[]}
          plural="Surgeries"
          singular="Surgery"
          title="surgery history"
        />
        <HistoryPreview
          records={selectedPatient.familyhistory}
          plural="Records"
          singular="Record"
          title="family history"
        />
        <HistoryPreview records={[]} plural="Allergies" singular="Allergy" title="Allergies" />
      </div>
    </div>
  );
};

export default PatientPreview;
