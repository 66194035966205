import Button from 'components/Button';
import PlatformRules from './PlatformRules';
import styles from './styles.module.css';

export const RulesEngineActions = () => {
  const AbstractButton = (props) => <Button {...props} className="mx-1" />;

  return (
    <div className="d-flex">
      <AbstractButton label="cancel" variant="cancel" />
      <AbstractButton disabled label="save changes" variant="confirm" />
      <AbstractButton label="add new rule" color="#1890FF" />
    </div>
  );
};

const RulesEngine = () => (
  <div className={styles.main}>
    <PlatformRules />
  </div>
);

export default RulesEngine;
