import { PatientSettingItem } from 'types/ApiModels/Administration';
import { TrackingActivityTemplate } from 'types/ApiModels/Administration';
import { PaginatedResponse } from 'types/ApiModels/General';
import {
  PatientActivitiesAction,
  PatientActivitiesActionTypes,
} from './patient-activities-action-types';

export const setBook = (
  book: Record<number, PaginatedResponse<PatientSettingItem>>
): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.SET_BOOK_PA,
  payload: {
    book,
  },
});

export const setTemplates = (templates: TrackingActivityTemplate[]): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.SET_TEMPLATES_PA,
  payload: {
    templates,
  },
});

export const setTemplateSubmit = (submit): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.SET_TEMPLATES_SUBMIT_PA,
  payload: {
    submit,
  },
});

export const clearTemplateChanges = (): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.CLEAR_CHANGES_PA,
});

export const upsertCreated = (createdId: number): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.UPSERT_CREATED_PA,
  payload: {
    createdId,
  },
});

export const removeCreated = (createdId: number): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.REMOVE_CREATED_PA,
  payload: {
    createdId,
  },
});

export const upsertUpdated = (updatedId: number): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.UPSERT_UPDATED_PA,
  payload: {
    updatedId,
  },
});

export const appendDeleted = (deletedId: number): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.APPEND_DELETED_PA,
  payload: {
    deletedId,
  },
});

export const setCurrentTemplateDefaultId = (id: number): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.SET_CURRENT_TEMPLATE_DEFAULT_PA,
  payload: {
    id,
  },
});

export const setResetTemplateChanges = (reset: () => void): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.SET_RESET_CHANGES_PA,
  payload: {
    reset,
  },
});

export const upsertTemplate = (templates: TrackingActivityTemplate[]): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.UPSERT_TEMPLATES_PA,
  payload: {
    templates,
  },
});

export const syncRemoveTemplates = (templateIds: number[]): PatientActivitiesAction => ({
  type: PatientActivitiesActionTypes.SYNC_REMOVE_TEMPLATES_PA,
  payload: {
    templateIds,
  },
});
