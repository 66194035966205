import { useEffect, useReducer } from 'react';
import Card from 'components/Card';
import StickyNote from 'components/StickyNote';
import { setStickyNotes, stickyNotesInitialState, stickyNotesReducer } from '../store/sticky-notes';
import StickyNotesCardHeader from './sticky-notes-card-header';
import styles from './styles.module.css';

const TeamNotesStickyNotes = ({ stickyNotes: stickyNotesProp }) => {
  const [{ stickyNotes }, dispatch] = useReducer(stickyNotesReducer, stickyNotesInitialState);

  useEffect(() => {
    dispatch(setStickyNotes(stickyNotesProp));
  }, [stickyNotesProp]);

  return (
    <Card
      className="card-bg-border w-100"
      bodyClassName={`${styles['sticky-note-card']}`}
      headers={[<StickyNotesCardHeader stickyNotesLength={stickyNotes.length} />]}
    >
      {stickyNotes.length ? (
        stickyNotes.map(({ title, content, notificationDate, id }) => (
          <StickyNote
            key={id}
            title={title}
            content={content}
            notificationDate={new Date(notificationDate)}
          />
        ))
      ) : (
        <p className=" text-center font-italic">
          There are no sticky notes right now. Start adding some!
        </p>
      )}
    </Card>
  );
};
export default TeamNotesStickyNotes;
