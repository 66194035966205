import DatePicker from 'components/DatePicker';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { usePatientFormikContext } from 'screens/Patients/store';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { partialUpdateCarePlan } from 'services/patientService';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import DateConfirmationModal from './DateConfirmationModal';

const DateActivation = () => {
  const {
    patientState: {
      patientCarePlan: {
        carePlanState: { activation_date },
      },
    },
  } = usePatientContext();
  const { selectedPatient } = usePatientFormikContext();

  const [momentValue, setMomentValue] = useState<Moment>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);

  useEffect(() => {
    if (!activation_date) {
      setMomentValue(null);
    } else {
      setMomentValue(moment(activation_date, 'YYYY-MM-DD'));
    }
  }, [activation_date]);

  const onDateChange = (pickerValue: DatePickerValue) => {
    setMomentValue(moment(convertDatepickToDate(pickerValue)));
    setShowConfirmationModal(true);
  };

  const onChangeCancel = () => {
    setMomentValue(null);
    setShowConfirmationModal(false);
  };

  const onChangeConfirm = async () => {
    await partialUpdateCarePlan(selectedPatient.id, {
      activation_date: momentValue.format('YYYY-MM-DD'),
    });
    setShowConfirmationModal(false);
  };
  return (
    <div
      className="d-flex align-items-center w-100 justify-content-between"
      style={{ marginBottom: 10 }}
    >
      <div className="font-size-big" style={{ whiteSpace: 'nowrap' }}>
        Activation Date
      </div>
      <div className="font-size-big">
        {momentValue ? (
          momentValue.format('MM.DD.YYYY')
        ) : (
          <DatePicker
            placeHolder="Select Date"
            value={convertDateToDatePickValue(momentValue?.toDate())}
            hasFooter={false}
            onChange={onDateChange}
            format="MM.DD.YYYY"
            inputClassName="text-align-right"
            style={{ maxWidth: 100 }}
          />
        )}
      </div>
      <DateConfirmationModal
        dateValue={momentValue}
        show={showConfirmationModal}
        onClose={onChangeCancel}
        onCancel={onChangeCancel}
        onConfirm={onChangeConfirm}
      />
    </div>
  );
};
export default DateActivation;
