import addBubble from 'assets/icons/add_bubble.png';
import deleteBubble from 'assets/icons/delete_bubble.png';
import PopulationsModal from 'components/BulletedModals/PopulationsModal';
import { PatientManagementFormikKeys } from 'components/PatientManagementForm/types';
import { Tagitem } from 'components/Tagitem';
import { useFormikContext } from 'formik';
import { useCallback, useState } from 'react';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import './styles.css';
import styles from './styles.module.css';

interface PatientPopulationProps {
  patient: PatientGetSingle;
  populations: PopulationWithCount[];
}
const PatientPopulation = ({ patient, populations }: PatientPopulationProps) => {
  const { setFieldTouched, setFieldValue, values } = useFormikContext();
  const createIcon = (src) => <img className="cursor-pointer mr-1" src={src} alt="" />;
  const [showPopulationsModal, setShowPopulationsModal] = useState(false);

  const addIcon = createIcon(addBubble);

  const handleDeletePopulation = useCallback(
    (id: number) => {
      setFieldTouched(PatientManagementFormikKeys.POPULATION, true);
      setFieldValue(
        PatientManagementFormikKeys.POPULATION,
        values[PatientManagementFormikKeys.POPULATION].filter((popId: number) => popId !== id),
        true
      );
    },
    [setFieldTouched, setFieldValue, values]
  );

  const handleUpdatePopulations = useCallback(
    (currentlySelected: number[]) => {
      setFieldTouched(PatientManagementFormikKeys.POPULATION, true);
      setFieldValue(PatientManagementFormikKeys.POPULATION, currentlySelected, true);
      setShowPopulationsModal(false);
    },
    [setFieldValue, setFieldTouched]
  );

  return (
    <div className="dashboard-detail h-100">
      <div className="dashboard-item-header font-weight-md">Population</div>
      <div className={styles['tag-container']}>
        {populations
          ?.filter((population) =>
            values[PatientManagementFormikKeys.POPULATION].includes(population.id)
          )
          .map((population) => (
            <div key={`tag-item-container-${population.id}`}>
              <Tagitem
                className={`tag-item font-size-medium ${styles.tagItem}`}
                text={population.name}
                icon={
                  <img
                    className="cursor-pointer mr-1"
                    onClick={() => handleDeletePopulation(population.id)}
                    src={deleteBubble}
                    alt=""
                  />
                }
              />
            </div>
          ))}
        <Tagitem
          className={`tag-item font-size-medium cursor-pointer ${styles.tagItem}`}
          text="Add"
          icon={addIcon}
          onClick={() => setShowPopulationsModal(true)}
        />
      </div>
      {showPopulationsModal ? (
        <PopulationsModal
          assignedPopulationIds={values[PatientManagementFormikKeys.POPULATION]}
          handleClose={() => setShowPopulationsModal(false)}
          populations={populations}
          handleConfirm={handleUpdatePopulations}
          show={showPopulationsModal}
        />
      ) : null}
    </div>
  );
};

export default PatientPopulation;
