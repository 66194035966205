import { REQUIRED_MESSAGE } from 'util/formikUtils';
import { object, string } from 'yup';

const CreateFamilyHistoryValidationSchema = object().shape({
  name: string().required(REQUIRED_MESSAGE),
  date: object()
    .shape({
      day: string(),
      month: string(),
      year: string(),
    })
    .required(REQUIRED_MESSAGE),
  details: string().required(REQUIRED_MESSAGE),
});

export default CreateFamilyHistoryValidationSchema;
