import FormikBuilder from 'components/FormikBuilder';
import { FormikField } from 'components/FormikBuilder/FormikBuilder';
import styles from '../styles.module.css';

const formikFields: FormikField[] = [
  {
    label: 'email',
    name: 'personalInformation.contactInformation.email',
  },

  {
    label: 'phone number 1',
    name: 'personalInformation.contactInformation.phoneNumber1',
    type: 'phoneNumber',
  },
  {
    label: 'phone number 2',
    name: 'personalInformation.contactInformation.phoneNumber2',
    type: 'phoneNumber',
  },
];

const ContactInformation = () => {
  return (
    <div className="d-flex gap-md">
      <div className={` d-flex flex-column ${styles['personal-information-side']}`}>
        <div className={styles.title}>Contact information</div>
        <div className={styles.description}>Provider primary contact information</div>
      </div>
      <FormikBuilder formikFields={formikFields} />
    </div>
  );
};
export default ContactInformation;
