import FiltersButton from 'components/FiltersButton';
import { SearchBarAlt } from 'components/SearchBar';
import { ChangeEventHandler, FC } from 'react';

interface CardHeaderSearchFilterProps {
  search: string;
  handleSearch: ChangeEventHandler<HTMLInputElement>;
  handleFiltersChange: () => void;
  title: string;
}

const CardHeaderSearchFilter: FC<CardHeaderSearchFilterProps> = ({
  search,
  handleSearch,
  handleFiltersChange,
  title,
}: CardHeaderSearchFilterProps) => {
  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <p className="m-0 p-0">{title}</p>
      <div className="d-flex gap">
        <SearchBarAlt onChange={handleSearch} value={search} />
        <FiltersButton onFiltersClick={handleFiltersChange} />
      </div>
    </div>
  );
};
export default CardHeaderSearchFilter;
