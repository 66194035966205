import CardWithLinkButton from 'components/CardWithLinkButton';
import { useState } from 'react';

const PatientActivityPoints = () => {
  const [patientActivityPoints, setPatientActivityPoints] = useState([]);
  return (
    <CardWithLinkButton
      header={{
        linkLabel: 'Open',
        text: 'Patient activity points',
      }}
    >
      {patientActivityPoints?.length ? null : 'No data available'}
    </CardWithLinkButton>
  );
};
export default PatientActivityPoints;
