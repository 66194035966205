import { PHONE_NUMBER_VALIDATION_MESSAGE, REQUIRED_MESSAGE } from 'util/formikUtils';
import { phoneNumberRegex } from 'util/regexs';
import { object, ref, string } from 'yup';

export const CreatePatientAccountFormValidationSchema = object().shape({
  phoneNumber: string()
    .required(REQUIRED_MESSAGE)
    .matches(phoneNumberRegex, PHONE_NUMBER_VALIDATION_MESSAGE),
  password: string()
    .required(REQUIRED_MESSAGE)
    .min(8, 'Must contain at least 8 characters')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(
      /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g,
      'Password must contain at least one special character'
    ),
  confirmPassword: string()
    .required(REQUIRED_MESSAGE)
    .oneOf([ref('password'), null], 'Passwords must match'),
});
