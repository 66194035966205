import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import DropdownMenu from 'components/DropdownMenu';
import { PhoneNumberFormik, SelectFormik, TextInputFormik } from 'components/FormikComponents';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { useMemo } from 'react';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import { getStyles } from '../../../utils/getStyles';
import { AddRow } from '../../Steps';
import styles from '../../styles.module.css';

const EmergencyContacts = () => {
  const { touched, errors, values } = useFormikContext<InvitePatient>();
  const openError = useMemo(() => errors.emergencyContacts?.length > 0 || false, [errors]);

  const relationOptions = useMemo(() => {
    return [
      { label: 'Parent', value: 'parent' },
      { label: 'Partner', value: 'partner' },
      { label: 'Siblings', value: 'siblings' },
      { label: 'Cousin', value: 'cousin' },
      { label: 'Family', value: 'family' },
      { label: 'Friend', value: 'friend' },
    ];
  }, []);

  const relationValue = useMemo(() => {
    return values.emergencyContacts?.length > 0
      ? values.emergencyContacts.map((_, index) => {
          const relation = values.emergencyContacts[index].relationship;
          return {
            label: relationOptions.find((e) => e.value === relation)?.label,
            value: relation,
          };
        })
      : [];
  }, [values.emergencyContacts, relationOptions]);

  return (
    <DropdownMenu name="Emergency contacts" open={openError}>
      <FieldArray validateOnChange={false} name="emergencyContacts">
        {({ push, remove }) => (
          <>
            {values.emergencyContacts && values.emergencyContacts.length > 0 ? (
              values.emergencyContacts.map((_, index) => (
                <div className="d-flex flex-column gap" key={index}>
                  <div className={styles['row']}>
                    <div className="d-flex gap px-3 pt-3 mb-2">
                      <div
                        style={{ flexBasis: '13%' }}
                        className={`d-flex ${styles['form-label-short']} font-size-medium`}
                      >
                        First Name
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <TextInputFormik
                          className="w-100"
                          name={`emergencyContacts[${index}].firstName`}
                          style={getStyles(
                            errors,
                            touched,
                            `emergencyContacts[${index}].firstName`
                          )}
                        />
                        <ErrorMessage name={`emergencyContacts[${index}].firstName`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className={`d-flex ${styles['form-label-short']} font-size-medium`}>
                        Last Name
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <TextInputFormik
                          className="w-100"
                          name={`emergencyContacts[${index}].lastName`}
                          style={getStyles(errors, touched, `emergencyContacts[${index}].lastName`)}
                        />
                        <ErrorMessage name={`emergencyContacts[${index}].lastName`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className={`d-flex ${styles['form-label-short']} font-size-medium`}>
                        Relationship
                      </div>
                      <div
                        className={`flex-grow-1 d-flex flex-column gap`}
                        style={{ width: '30%' }}
                      >
                        <SelectFormik
                          options={relationOptions}
                          value={relationValue[index]}
                          name={`emergencyContacts[${index}].relationship`}
                          placeholder="Assign relationship..."
                          styles={getStyles(
                            errors,
                            touched,
                            `emergencyContacts[${index}].relationship`
                          )}
                        />
                        <ErrorMessage name={`emergencyContacts[${index}].relationship`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon fill="#8FAAC3" stroke="#8FAAC3" />
                      </div>
                    </div>
                  </div>
                  <div className={styles['row']}>
                    <div className="d-flex gap px-3 mb-2">
                      <div
                        className={`d-flex ${styles['form-label']} font-size-medium`}
                        style={{ flexBasis: '10%' }}
                      >
                        Contact email
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <TextInputFormik
                          className="w-100"
                          name={`emergencyContacts[${index}].contactEmail`}
                          style={getStyles(
                            errors,
                            touched,
                            `emergencyContacts[${index}].contactEmail`
                          )}
                        />
                        <ErrorMessage name={`emergencyContacts[${index}].contactEmail`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Primary phone
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <PhoneNumberFormik
                          className="w-100"
                          name={`emergencyContacts[${index}].contactPhone`}
                          style={getStyles(
                            errors,
                            touched,
                            `emergencyContacts[${index}].contactPhone`
                          )}
                        />
                        <ErrorMessage name={`emergencyContacts[${index}].contactPhone`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div
                        style={{ flexBasis: 0 }}
                        className={`d-flex ${styles['form-label']} font-size-medium`}
                      >
                        Secondary phone
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <PhoneNumberFormik
                          className="w-100"
                          name={`emergencyContacts[${index}].secondaryPhone`}
                          style={getStyles(
                            errors,
                            touched,
                            `emergencyContacts[${index}].secondaryPhone`
                          )}
                        />
                        <ErrorMessage name={`emergencyContacts[${index}].secondaryPhone`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  {index === values.emergencyContacts.length - 1 && (
                    <AddRow
                      name="emergency contact"
                      onClick={() =>
                        push({
                          firstName: '',
                          lastName: '',
                          relationship: '',
                          contactEmail: '',
                          contactPhone: '',
                          secondaryPhone: '',
                        })
                      }
                    />
                  )}
                </div>
              ))
            ) : (
              <AddRow
                name="emergency contact"
                onClick={() =>
                  push({
                    firstName: '',
                    lastName: '',
                    relationship: '',
                    contactEmail: '',
                    contactPhone: '',
                    secondaryPhone: '',
                  })
                }
              />
            )}
          </>
        )}
      </FieldArray>
    </DropdownMenu>
  );
};
export default EmergencyContacts;
