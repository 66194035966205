import rolesIcon from 'assets/icons/roles-icon.svg';
import CardWithLinkButton from 'components/CardWithLinkButton';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAdministrationContextV2 } from 'screens/Administration/store';
import { setRolesAdded } from 'screens/Administration/store/Dashboard/dashboardActionCreators';
import { getRolesSignups } from 'services/administrationService';
import styles from '../AdminDashboard.module.css';
import RolesSignups from './RolesSignups';

const NewRolesAdded = () => {
  const {
    administrationStateV2: {
      dashboard: { rolesAdded },
    },
    dispatch,
  } = useAdministrationContextV2();
  const history = useHistory();

  const fetchRolesSignups = useCallback(async () => {
    if (!rolesAdded?.length) {
      const res = await getRolesSignups();
      dispatch(setRolesAdded(res.results));
    }
  }, [rolesAdded, dispatch]);

  useEffect(() => {
    fetchRolesSignups();
  }, []);

  return (
    <CardWithLinkButton
      bodyClassName={`d-flex flex-column ${styles['dashboard-card']}`}
      header={{
        linkLabel: 'Open',
        text: 'New roles added',
        onClick: () => {
          history.push('/administration/roles');
        },
      }}
    >
      {!rolesAdded?.length ? (
        <div className="d-flex flex-column gap-md justify-content-center align-items-center w-100 flex-grow-1">
          <img src={rolesIcon} alt="no new provider sign-ups" />
          <div>There are no new roles created</div>
        </div>
      ) : (
        <RolesSignups roleSignups={rolesAdded} />
      )}
    </CardWithLinkButton>
  );
};
export default NewRolesAdded;
