import Button from 'components/Button';
import { LabeledField, SelectFormik, TextInputFormik } from 'components/FormikComponents';
import TextAreaFormik from 'components/FormikComponents/TextAreaFormik';
import VytracModal from 'components/Modal';
import { Formik, useFormikContext } from 'formik';
import { FC } from 'react';
import { FamilyRelationship } from 'types/ApiModels/Patients/PatientEnums';
import { familyHistoryRelationshipOptions } from 'util/option-utils';
import { PatientManagementFormikValues } from '../types';
import CreateFamilyHistoryValidationSchema from './utils/CreateFamilyHistoryValidationSchema';

interface FamilyHistoryFormFieldValues {
  name: string;
  details: string;
  id: number;
  patient: number;
  relationship: FamilyRelationship;
}

interface CreateFamilyHistoryModalProps {
  onClose: () => void;
  show: boolean;
  idCreator: () => number;
  patientId: number;
  currentId: number;
  queueEdit: (id: number) => void;
}

const CreateFamilyHistoryModal: FC<CreateFamilyHistoryModalProps> = ({
  onClose,
  show,
  idCreator,
  patientId,
  currentId,
  queueEdit,
}: CreateFamilyHistoryModalProps) => {
  const { setFormikState, values } = useFormikContext<PatientManagementFormikValues>();

  const handleCreate = (values: FamilyHistoryFormFieldValues) => {
    setFormikState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        FAMILY_HISTORY: [...prev.values.FAMILY_HISTORY, values],
      },
    }));
    onClose();
  };
  const handleEdit = (values: FamilyHistoryFormFieldValues) => {
    setFormikState((prev) => {
      const copy = [...prev.values.FAMILY_HISTORY];
      const updated = copy.findIndex((a) => a.id === values.id);
      copy[updated] = values;
      return {
        ...prev,
        values: {
          ...prev.values,
          FAMILY_HISTORY: copy,
        },
      };
    });
    values.id > 0 && queueEdit(values.id);
    onClose();
  };
  return (
    <VytracModal
      title="Create family history entry"
      body={
        <Formik<FamilyHistoryFormFieldValues>
          initialValues={
            currentId
              ? values.FAMILY_HISTORY.find((fh) => fh.id === currentId)
              : {
                  name: '',
                  details: '',
                  id: idCreator(),
                  patient: patientId,
                  relationship: null,
                }
          }
          onSubmit={currentId ? handleEdit : handleCreate}
          validationSchema={CreateFamilyHistoryValidationSchema}
        >
          {({ handleSubmit, setFieldValue, dirty, values }) => {
            const handleSelectChange = (selectValue: { value: string; label: string }) => {
              setFieldValue('relationship', selectValue.value);
            };
            return (
              <div>
                <div className="d-flex flex-column">
                  <LabeledField name="name" label="Family history name">
                    <TextInputFormik name="name" className="font-size-medium" />
                  </LabeledField>
                  <LabeledField name="relationship" label="Relationship">
                    <SelectFormik
                      name="relationship"
                      options={familyHistoryRelationshipOptions}
                      value={{
                        value: values.relationship,
                        label: FamilyRelationship[values.relationship],
                      }}
                      className="font-size-medium"
                      customOnChange={handleSelectChange}
                    />
                  </LabeledField>
                  <LabeledField name="details" label="Details">
                    <TextAreaFormik name="details" className="font-size-medium" />
                  </LabeledField>
                </div>
                <hr />
                <div className="d-flex justify-content-between w-100">
                  <Button label="cancel" variant="dull" onClick={onClose} />
                  <Button label="confirm" onClick={!dirty && currentId ? onClose : handleSubmit} />
                </div>
              </div>
            );
          }}
        </Formik>
      }
      onClose={onClose}
      show={show}
      centered
    />
  );
};
export default CreateFamilyHistoryModal;
