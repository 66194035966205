import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import BreadCrumbPath from 'types/Shared/BreadCrumbPath';
import './overrideStyles.css';
import styles from './styles.module.css';

interface IBreadcrumbs {
  items: BreadCrumbPath[];
}
const Breadcrumbs = ({ items }: IBreadcrumbs) => {
  const history = useHistory();
  return (
    <>
      <hr className={styles.horizontalLine} />
      <nav aria-label={`breadcrumb`} className={styles.nav}>
        <ol className="breadcrumb bg-transparent">
          {items.map(({ title, url }, index, array) => (
            <Fragment key={`${index}-${title}-${url}`}>
              {index !== array.length - 1 ? (
                <li className="breadcrumb-item">
                  <button className={styles.link} onClick={() => url && history.push(url)}>
                    {title}
                  </button>
                </li>
              ) : (
                <li className="breadcrumb-item active">{title}</li>
              )}
            </Fragment>
          ))}
        </ol>
      </nav>
      <hr className={styles.horizontalLine} />
    </>
  );
};

export default Breadcrumbs;
