import { Dispatch, useCallback, useContext, useEffect } from 'react';
import { getSpecialties } from 'services/providersService';
import AdministrationContextV2 from '../AdministrationContextV2';
import { AllAdminActions } from '../administrationReducer';
import { setSpecialties, SpecialtyState } from '../Specialties';

export const useAdminSpecialties = (): [SpecialtyState, Dispatch<AllAdminActions>] => {
  const {
    administrationStateV2: { specialties },
    fetchRegistry,
    dispatch,
  } = useContext(AdministrationContextV2);

  //make sure specialties is fetched whenever we want to use this
  const fetchSpecialties = useCallback(async () => {
    if (!specialties.list && !fetchRegistry.current.specialties.list) {
      fetchRegistry.current.specialties.list = true;
      const response = await getSpecialties();
      dispatch(setSpecialties(response.results));
      fetchRegistry.current.specialties.list = false;
    }
  }, [specialties.list, fetchRegistry, dispatch]);

  useEffect(() => {
    fetchSpecialties();
  }, [fetchSpecialties]);

  return [specialties, dispatch];
};
