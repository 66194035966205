import VerificationToken from 'screens/Common/VerificationToken';
import { RegisterViews } from 'types/Register';
import RegisterProviderForm from '../RegisterProviderForm';

//TODO: since some are classes I couldn't type this right. Research later
const RegisterComponentByEnum: Record<RegisterViews, any> = {
  [RegisterViews.REGISTER]: RegisterProviderForm,
  [RegisterViews.TOKEN]: VerificationToken,
};

export default RegisterComponentByEnum;
