import Surgeries from './Surgeries/Surgeries';
import Allergies from './Allergies';
import FamilyHistory from './FamilyHistory';

export const Step2 = () => {
  return (
    <>
      <Surgeries />
      <FamilyHistory />
      <Allergies />
    </>
  );
};
export default Step2;
