import { ErrorMessage, useFormikContext } from 'formik';
import { Fragment, useMemo } from 'react';
import './styles.css';
import styles from './styles.module.css';
import { PhoneNumberFormik } from 'components/FormikComponents';
import PatientManagementInput from 'components/PatientManagementInput';
import PatientManagementEliminateButton from 'components/PatientManagementEliminateButton';
import createNewCareChampion from '../util/createNewCareChampion';
import PatientManagementAddButton from 'components/PatientManagementAddButton';
import GenericPatientInfoTable from 'components/GenericPatientInfoTable';
import {
  CareChampionsFormikKeys,
  PatientManagementFormikKeys,
} from 'components/PatientManagementForm/types';
import PatientManagementSelect from 'components/PatientManagementSelect';
import { RelationShipEnum } from 'types/ApiModels/Patients/Relationship';
import TextSurroundedWithLines from 'components/TextSurroundedWithLines';

const CareChampions = ({ formData, handleChange, errors, touched }) => {
  // Context
  const { setFormikState, setFieldTouched } = useFormikContext();

  // Functions and subroutines
  const mapPatientInfo = (formData, handleChange, errors) => {
    const eliminateCareChampion = (eliminatedIndex) =>
      setFormikState(({ values, ...prevFormikState }) => ({
        values: {
          ...values,
          [PatientManagementFormikKeys.CARE_CHAMPIONS]: values[
            PatientManagementFormikKeys.CARE_CHAMPIONS
          ].filter((_, index) => index !== eliminatedIndex),
        },
        ...prevFormikState,
      }));

    return formData.map(({ fullName, email, relationship }, index) => {
      const handleChangeTouched = (name) => (e) => {
        setFieldTouched(name, true);
        handleChange(e);
      };
      return [
        <></>,
        <>
          <PatientManagementSelect
            name={`${PatientManagementFormikKeys.CARE_CHAMPIONS}[${index}].${CareChampionsFormikKeys.RELATIONSHIP}`}
            options={Object.entries(RelationShipEnum).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
            renderOption={(option, idx) => (
              <option
                value={option.value}
                key={`patient-management-select-option-${option}-${idx}`}
                className="text-capitalize"
              >
                {option.label}
              </option>
            )}
            error={
              errors && errors[index] && touched?.[index]?.[CareChampionsFormikKeys.RELATIONSHIP]
                ? errors[index][CareChampionsFormikKeys.RELATIONSHIP]
                : undefined
            }
            value={relationship}
            onChange={handleChangeTouched(
              `${PatientManagementFormikKeys.CARE_CHAMPIONS}[${index}].${CareChampionsFormikKeys.RELATIONSHIP}`
            )}
          />
        </>,
        <PatientManagementInput
          onChange={handleChangeTouched(
            `${PatientManagementFormikKeys.CARE_CHAMPIONS}[${index}].${CareChampionsFormikKeys.FULL_NAME}`
          )}
          value={fullName}
          name={`${PatientManagementFormikKeys.CARE_CHAMPIONS}[${index}].${CareChampionsFormikKeys.FULL_NAME}`}
          error={
            errors && errors[index] && touched?.[index]?.[CareChampionsFormikKeys.FULL_NAME]
              ? errors[index][CareChampionsFormikKeys.FULL_NAME]
              : undefined
          }
          key={`name-${index}`}
        />,
        <Fragment key={`phone-${index}`}>
          <PhoneNumberFormik
            name={`${PatientManagementFormikKeys.CARE_CHAMPIONS}[${index}].${CareChampionsFormikKeys.PERSONAL_PHONE_NUMBER}`}
            className={`w-100 form-control form-control-sm bg-light-gray ${
              touched?.[index]?.[CareChampionsFormikKeys.PERSONAL_PHONE_NUMBER] &&
              errors?.[index]?.[CareChampionsFormikKeys.PERSONAL_PHONE_NUMBER]
                ? 'border-red'
                : ''
            }`}
          />
          <ErrorMessage
            name={`${PatientManagementFormikKeys.CARE_CHAMPIONS}[${index}].${CareChampionsFormikKeys.PERSONAL_PHONE_NUMBER}`}
          >
            {(msg) => <div className="text-danger font-size-medium">{msg}</div>}
          </ErrorMessage>
        </Fragment>,
        <Fragment key={`secondaryPhone-${index}`}>
          <PhoneNumberFormik
            name={`${PatientManagementFormikKeys.CARE_CHAMPIONS}[${index}].${CareChampionsFormikKeys.SECONDARY_PHONE_NUMBER}`}
            className={`w-100 form-control form-control-sm bg-light-gray ${
              touched?.[index]?.[CareChampionsFormikKeys.SECONDARY_PHONE_NUMBER] &&
              errors?.[index]?.[CareChampionsFormikKeys.SECONDARY_PHONE_NUMBER]
                ? 'border-red'
                : ''
            }`}
          />
          <ErrorMessage
            name={`${PatientManagementFormikKeys.CARE_CHAMPIONS}[${index}].${CareChampionsFormikKeys.SECONDARY_PHONE_NUMBER}`}
          >
            {(msg) => <div className="text-danger font-size-medium">{msg}</div>}
          </ErrorMessage>
        </Fragment>,
        <PatientManagementInput
          onChange={handleChangeTouched(
            `${PatientManagementFormikKeys.CARE_CHAMPIONS}[${index}].${CareChampionsFormikKeys.EMAIL}`
          )}
          value={email}
          name={`${PatientManagementFormikKeys.CARE_CHAMPIONS}[${index}].${CareChampionsFormikKeys.EMAIL}`}
          error={
            errors && errors[index] && touched?.[index]?.[CareChampionsFormikKeys.EMAIL]
              ? errors[index][CareChampionsFormikKeys.EMAIL]
              : undefined
          }
          key={`email-${index}`}
        />,
        <PatientManagementEliminateButton
          key="delete-button"
          onClick={() => eliminateCareChampion(index)}
        />,
      ];
    });
  };

  const addNewCareChampion = () =>
    setFormikState(({ values, ...prevFormikState }) => ({
      ...prevFormikState,
      values: {
        ...values,
        [PatientManagementFormikKeys.CARE_CHAMPIONS]: [
          ...values[PatientManagementFormikKeys.CARE_CHAMPIONS],
          createNewCareChampion(),
        ],
      },
    }));

  // Variables and constants
  const title = (
    <>
      <span>care champions</span>
      <PatientManagementAddButton onClick={addNewCareChampion} />
    </>
  );
  const tableBody = mapPatientInfo(formData, handleChange, errors);
  const tableHeader = useMemo(
    () =>
      formData.length
        ? [
            'Relationship to the patient',
            'Full name',
            'Personal phone number',
            'Secondary phone number',
            'Email',
            '',
          ]
        : [],
    [formData.length]
  );

  return (
    <div className="dashboard-detail pb-0">
      <GenericPatientInfoTable
        tableName={title}
        tableHeader={tableHeader}
        tableBody={tableBody}
        containerClassNameList={[
          formData.length ? 'p-3' : 'pb-0',
          'care-champions-main-container',
          styles.table,
        ]}
      />
      {formData.length ? null : (
        <div className="px-4 pb-3">
          <TextSurroundedWithLines
            className="text-gray-low-risk font-size-medium"
            text="No care champions added"
          />
        </div>
      )}
    </div>
  );
};

export default CareChampions;
