import KeywordsTemplate from 'types/ApiModels/Administration/keywords-template';
import { PatientNotesKeywords } from 'types/ApiModels/Administration';
import { PaginatedResponse } from 'types/ApiModels/General';

export enum NotesKeywordsActionTypes {
  SET_BOOK_NK = 'SET_BOOK_NK',
  SET_TEMPLATES_NK = 'SET_TEMPLATES_NK',
  CLEAR_CHANGES_NK = 'CLEAR_CHANGES_NK',
  SET_SUBMIT_NK = 'SET_SUBMIT_NK',
  UPSERT_CREATED_NK = 'UPSERT_CREATED_NK',
  REMOVE_CREATED_NK = 'REMOVE_CREATED_NK',
  UPSERT_UPDATED_NK = 'UPSERT_UPDATED_NK',
  APPEND_DELETED_NK = 'APPEND_DELETED_NK',
  SET_CURRENT_DEFAULT_ID_NK = 'SET_CURRENT_DEFAULT_ID_NK',
  SET_RESET_CHANGES_NK = 'SET_RESET_CHANGES_NK',
  UPSERT_TEMPLATES_NK = 'UPSERT_TEMPLATES_NK',
  SYNC_REMOVE_TEMPLATES_NK = 'SYNC_REMOVE_TEMPLATES_NK',
}

export type NotesKeywordsAction =
  | {
      type: NotesKeywordsActionTypes.SET_BOOK_NK;
      payload: {
        book: Record<number, PaginatedResponse<PatientNotesKeywords>>;
      };
    }
  | {
      type: NotesKeywordsActionTypes.SET_TEMPLATES_NK;
      payload: {
        templates: KeywordsTemplate[];
      };
    }
  | {
      type: NotesKeywordsActionTypes.CLEAR_CHANGES_NK;
    }
  | {
      type: NotesKeywordsActionTypes.SET_SUBMIT_NK;
      payload: {
        submit: () => void;
      };
    }
  | {
      type: NotesKeywordsActionTypes.UPSERT_CREATED_NK;
      payload: {
        createdId: number;
      };
    }
  | {
      type: NotesKeywordsActionTypes.REMOVE_CREATED_NK;
      payload: {
        createdId: number;
      };
    }
  | {
      type: NotesKeywordsActionTypes.UPSERT_UPDATED_NK;
      payload: {
        updatedId: number;
      };
    }
  | {
      type: NotesKeywordsActionTypes.APPEND_DELETED_NK;
      payload: {
        deletedId: number;
      };
    }
  | {
      type: NotesKeywordsActionTypes.SET_CURRENT_DEFAULT_ID_NK;
      payload: {
        id: number;
      };
    }
  | {
      type: NotesKeywordsActionTypes.SET_RESET_CHANGES_NK;
      payload: {
        reset: () => void;
      };
    }
  | {
      type: NotesKeywordsActionTypes.UPSERT_TEMPLATES_NK;
      payload: {
        templates: KeywordsTemplate[];
      };
    }
  | {
      type: NotesKeywordsActionTypes.SYNC_REMOVE_TEMPLATES_NK;
      payload: {
        templateIds: number[];
      };
    };
