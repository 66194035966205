import Button from 'components/Button';
import { TextInputFormik } from 'components/FormikComponents';
import VyTracModal from 'components/Modal';
import { ErrorMessage, Form, Formik } from 'formik';
import { FC, useEffect, useRef } from 'react';
import Specialty from 'types/ApiModels/Providers/Specialty';
import { AddEditSpecialtyValidationSchema } from './utils/ValidationSchemas';

interface EditSpecialtyModalProps {
  show: boolean;
  onClose: () => void;
  specialtiesFromTable: Specialty[];
  currentSpecialtyId: number;
  handleSubmit: (values: { name: string }) => void;
}

const EditSpecialtyModal: FC<EditSpecialtyModalProps> = ({
  show,
  onClose,
  currentSpecialtyId,
  specialtiesFromTable,
  handleSubmit,
}: EditSpecialtyModalProps) => {
  return (
    <VyTracModal
      backdrop={false}
      title="Edit specialty"
      headerClassName="font-size-big font-weight-md m-0"
      body={
        <Formik
          initialValues={{
            name: specialtiesFromTable?.find((st) => st.id === currentSpecialtyId)?.name || '',
          }}
          validationSchema={AddEditSpecialtyValidationSchema(
            specialtiesFromTable?.flatMap((s) => {
              if (s.id === currentSpecialtyId) return [];
              return s.name;
            }) ?? []
          )}
          onSubmit={(values) => {
            handleSubmit(values);
            onClose();
          }}
        >
          {({ errors, touched, isValid }) => (
            <Form className="d-flex flex-column gap">
              <div className={`d-flex justify-content-between align-items-center gap`}>
                <div className={`d-flex font-size-medium`}>Specialty name</div>
                <div className="flex-grow-1">
                  <TextInputFormik
                    className="w-100 font-size-medium"
                    name="name"
                    autoFocus
                    selectOnFocus
                  />
                </div>
              </div>
              {errors.name && touched.name ? (
                <div className="d-flex">
                  <div className="d-flex font-size-medium"></div>
                  <ErrorMessage name="name">
                    {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                  </ErrorMessage>
                </div>
              ) : null}
              <div className="d-flex w-100 justify-content-between">
                <Button label="cancel" variant="cancel" onClick={onClose} />
                <Button label="apply" type="submit" disabled={!isValid} />
              </div>
            </Form>
          )}
        </Formik>
      }
      footer={undefined}
      onClose={onClose}
      show={show}
      centered
    />
  );
};
export default EditSpecialtyModal;
