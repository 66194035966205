import { getIn } from 'formik';

export function getStyles(errors, fieldName) {
  if (getIn(errors, fieldName)) {
    return {
      borderBottom: '1px solid red',
      color: 'red',
    };
  }
}
