import { vytracAxios } from 'ajax';
import { ITemplateCarePlan, ITemplateCarePlanAction } from 'types/ApiModels/Templates/CarePlan';

export async function getTemplateCarePlanList() {
  const response = await vytracAxios.get<ITemplateCarePlan[]>('rpm_template/');
  return response.data;
}

export const createCarePlanTemplate = async (carePlan: ITemplateCarePlan) => {
  const response = await vytracAxios.post<ITemplateCarePlan>(`rpm_template/`, carePlan);
  return response.data;
};

export async function getCarePlanTemplate(id: number) {
  const response = await vytracAxios.get<ITemplateCarePlan>(`rpm_template/${id}/`);
  return response.data;
}

export const partialUpdateCarePlanTemplate = async (carePlan: Partial<ITemplateCarePlan>) => {
  const response = await vytracAxios.patch<ITemplateCarePlan>(
    `rpm_template/${carePlan.id}/`,
    carePlan
  );
  return response.data;
};

export const deleteCarePlanTemplate = async (templateId: number) => {
  const response = await vytracAxios.delete(`rpm_template/${templateId}/`);
  return response.data;
};

export const createTemplateAction = async (templateId: number, action: ITemplateCarePlanAction) => {
  const response = await vytracAxios.post<ITemplateCarePlanAction>(
    `rpm_template/${templateId}/action/`,
    action
  );
  return response.data;
};

export const updateTemplateAction = async (
  templateId: number,
  action: Partial<ITemplateCarePlanAction>
) => {
  const response = await vytracAxios.patch<ITemplateCarePlanAction>(
    `/rpm_template/${templateId}/action/${action.id}/`,
    action
  );
  return response.data;
};

export const deleteTemplateAction = async (templateId: number, actionId: number) => {
  const response = await vytracAxios.delete(`rpm_template/${templateId}/action/${actionId}/`);
  return response.data;
};
