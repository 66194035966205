import React from 'react';
import Card from '../../../../../../../../../../../components/Card';
import GraphControls from '../../../../../../../../../../../components/VytracSurveyBuilder/components/Controls';
import Header from '../../../Header';
import TabButton from './components/TabButton';
import styles from './styles.module.css';

const Controls = ({
  questionnaire,
  onNameChange,
  tabType,
  setTabType,
  availableQuestionnaires,
  active = 0,
  setActive = () => {},
}) => {
  //content items for the graph DND
  const contentItems = [
    {
      name: 'Question',
      nodeType: 'question',
    },
  ];

  return (
    <div className="mr-4">
      <div className={styles.buttonContainer}>
        <TabButton
          type="create"
          label="Create a questionnaire"
          className={tabType === 'create' ? styles.active : ''}
          setTabType={setTabType}
        />
        <TabButton
          type="template"
          label="questionnaire templates"
          className={tabType === 'template' ? styles.active : ''}
          setTabType={setTabType}
        />
      </div>
      <div>
        {tabType === 'create' ? (
          <GraphControls
            style={{ width: 'auto' }}
            name={questionnaire.name}
            usedFor={questionnaire.usedFor}
            setName={onNameChange}
            items={contentItems}
            header="Questionnaire Options"
            label="Questionnaire name"
          />
        ) : (
          <Card headers={[<Header title="Available questionnaire templates" />]}>
            {availableQuestionnaires &&
              availableQuestionnaires.map((questionnaire, index) => (
                <div
                  key={index}
                  className={`${styles.item} ${index === active ? styles.active : ''}`}
                  onClick={() => setActive(index)}
                >
                  {questionnaire.name}
                </div>
              ))}
          </Card>
        )}
      </div>
    </div>
  );
};

export default Controls;
