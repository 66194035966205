import axios from 'axios';

export const getTimezone = async () => {
  const response = await axios.get('https://worldtimeapi.org/api/timezone', {
    transformRequest: (data, headers) => {
      delete headers.common['Authorization'];
      return data;
    },
  });
  return response.data;
};
