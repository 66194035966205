import Button from 'components/Button';
import React from 'react';
import { useHistory } from 'react-router-dom';
import TabEnum from 'screens/Templates/util/TabEnum';
import TabRouteMap from 'screens/Templates/util/TabRouteMap';
import styles from './styles.module.css';

const Actions = () => {
  const history = useHistory();
  return (
    <div className={styles.questionnaireTemplateActions}>
      <Button
        label="create new questionnaire"
        onClick={() => history.push(`${TabRouteMap[TabEnum.QUESTIONNAIRES]}/create`)}
      />
    </div>
  );
};
export default Actions;
