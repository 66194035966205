import DeleteIcon from 'assets/icons/delete.png';
import GenericButton from 'components/GenericButton';
import { FC } from 'react';

interface PopulationActionElementProps {
  id: number;
  handleDeletePopulation: (id: number) => void;
}

const PopulationActionElement: FC<PopulationActionElementProps> = ({
  id,
  handleDeletePopulation,
}: PopulationActionElementProps) => {
  return (
    <div className="d-flex">
      <GenericButton icon={DeleteIcon} alt="delete" onClick={() => handleDeletePopulation(id)} />
    </div>
  );
};
export default PopulationActionElement;
