import RecurrenceConfirmationModal from 'components/RPM/RecurrenceModals/RecurrenceConfirmationModal/RecurenceConfirmationModal';
import RecurrenceEditionModal from 'components/RPM/RecurrenceModals/RecurrenceEditionModal';
import TimePeriodSelect, { TimePeriodOptions } from 'components/TimePeriodSelect';
import React from 'react';
import {
  setPeriodSelected,
  setSelectedAction,
  setSelectedDate,
} from 'screens/Patients/store/PatientCarePlan/patientCarePlanActionCreators';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { usePatientFormikContext } from 'screens/Patients/store';
import AdherenceCount from './components/AdherenceCount';
import CreateAction from './components/CreateAction/CreateAction';
import DateActivation from './components/DateActivation';
import MiniatureCalendar from './components/MiniatureCalendar';
import PopulationManagement from './components/PopulationManagement';
import './styles.css';
import useCalendarModification from './util/useCalendarModification';

const timePeriodOptions = [
  TimePeriodOptions.DAY,
  TimePeriodOptions.WEEK,
  TimePeriodOptions.MONTH,
  TimePeriodOptions.CARE_PLAN_PERIOD,
];

const CalendarControl = () => {
  const {
    dispatch,
    patientState: {
      patientCarePlan: {
        selectedAction,
        periodSelected,
        selectedDate,
        carePlanState: { activation_date },
      },
    },
  } = usePatientContext();

  const { selectedPatient } = usePatientFormikContext();

  const {
    showRecurrenceEditionModal,
    showDeletionConfirmationModal,
    showEditionConfirmationModal,
    onCancel,
    onUpdateAllEvents,
    onUpdateOnlyThisEvent,
    onRecurrenceEditionSave,
    onDeleteAllEvents,
    onDeleteOnlyThisEvent,
    onRecurrenceDelete,
  } = useCalendarModification();

  const handleTimePeriodClick = (timePeriod: TimePeriodOptions) =>
    dispatch(setPeriodSelected(timePeriod));

  return (
    <div className="calendar-control">
      <div className="container-with-padding bg-light-gray font-size-medium">
        <TimePeriodSelect
          selectedTimePeriod={periodSelected}
          handleTimePeriodClick={handleTimePeriodClick}
          timeOptions={timePeriodOptions}
          disabledOptions={!activation_date ? [TimePeriodOptions.CARE_PLAN_PERIOD] : null}
        />
      </div>
      <PopulationManagement />
      <div className="container-with-padding">
        <AdherenceCount adherence={selectedPatient.adherence} />
        <DateActivation />
        <MiniatureCalendar
          adherence={selectedPatient.adherence}
          timePeriodSelected={periodSelected}
          carePlanActivationDate={activation_date}
          selectedDate={selectedDate}
          setSelectedDate={(newDate: Date) => dispatch(setSelectedDate(newDate))}
        />
      </div>
      <CreateAction onCancel={onCancel} />
      <RecurrenceEditionModal
        modalData={selectedAction}
        setModalData={(updatedSelectedAction) => dispatch(setSelectedAction(updatedSelectedAction))}
        onCancel={onCancel}
        onClose={onCancel}
        onDelete={onRecurrenceDelete}
        onSave={onRecurrenceEditionSave}
        show={showRecurrenceEditionModal}
        patientId={selectedPatient.id}
      />
      <RecurrenceConfirmationModal
        show={showEditionConfirmationModal}
        onClose={onCancel}
        onAllEvents={onUpdateAllEvents}
        onOnlyThisEvent={onUpdateOnlyThisEvent}
      />
      <RecurrenceConfirmationModal
        show={showDeletionConfirmationModal}
        onClose={onCancel}
        onAllEvents={onDeleteAllEvents}
        onOnlyThisEvent={onDeleteOnlyThisEvent}
      />
    </div>
  );
};

export default CalendarControl;
