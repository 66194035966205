import { Field, useFormikContext } from 'formik';
import { FC } from 'react';
import { parsePhoneNumber } from 'util/utils';
import styles from './styles.module.css';

interface PhoneNumberFormikProps {
  name: string;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
}

const PhoneNumberFormik: FC<PhoneNumberFormikProps> = ({
  name,
  className,
  style,
  placeholder,
}: PhoneNumberFormikProps) => {
  const { setFieldValue, handleChange } = useFormikContext();

  const handlePhoneNumberPaste = (setPhoneNumber) => (e: ClipboardEvent) => {
    e.preventDefault();
    const data = e.clipboardData.getData('text');
    const [parsed, isValid] = parsePhoneNumber(data);
    isValid && setPhoneNumber(parsed);
  };

  const handlePhoneNumberKeyDown = (e: KeyboardEvent) => {
    const allowedKeys = ['Backspace', 'Delete', '-', 'ArrowRight', 'ArrowLeft', 'Tab'];
    if (isNaN(parseInt(e.key)) && !allowedKeys.includes(e.key) && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault();
      return;
    }
  };

  return (
    <Field
      onChange={handleChange}
      onKeyDown={handlePhoneNumberKeyDown}
      onPaste={handlePhoneNumberPaste((val) => setFieldValue(name, val))}
      type="text"
      name={name}
      className={`vytrac-input rounded px-2 ${className ?? ''}`}
      style={style}
      placeholder={placeholder}
    />
  );
};
export default PhoneNumberFormik;
