import { Client, Conversation } from '@twilio/conversations';
import { useAuthContext } from 'auth';
import Button from 'components/Button';
import { LabeledField, SelectFormik, TextInputFormik } from 'components/FormikComponents';
import TextAreaFormik from 'components/FormikComponents/TextAreaFormik';
import VytracModal from 'components/Modal';
import { Formik } from 'formik';
import { useMemo, useState } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Chat from 'screens/messages/messages-content/patient-messages/chat';
import { setSelectedChat as setSelectedChatActionCreator } from 'screens/messages/store/messages-action-creators';
import { useMessagesContext } from 'screens/messages/store/messages-context';
import MessagesProvider from 'screens/messages/store/messages-provider';
import { usePatientFormikContext } from 'screens/Patients/store';
import { createEncounter } from 'services/encounterService';
import { useTwilioClient } from 'store/twilio-client';
import { useAppQuery } from 'store/use-app-query';
import { EncounterCategory } from 'types/ApiModels/Patients/encounter';
import type ConversationAttributes from 'types/Shared/conversation-attributes';
import ConversationType from 'types/Shared/conversation-type';
import { REQUIRED_MESSAGE } from 'util/formikUtils';
import { object, string } from 'yup';
import EncounterContainer from './components/EncounterContainer';
import { PatientMessagesList } from './patient-messages-list';
import styles from './styles.module.css';

const SendMessageValidationSchema = object().shape({
  category: string().required(REQUIRED_MESSAGE),
  subject: string().required(REQUIRED_MESSAGE),
  message: string().required(REQUIRED_MESSAGE),
});

interface SendMessageModalBodyProps {
  handleClose: () => void;
  patientId: number;
  patientIdentity: string;
  providerIdentity: string;
  providerUserId: number;
  setSelectedChat: (sid: string) => void;
}

const createConversation = async ({
  values,
  patientIdentity,
  providerIdentity,
  patientId,
  twilioClient,
}: {
  values;
  patientIdentity: string;
  providerIdentity: string;
  patientId: number;
  twilioClient: Client;
}) => {
  try {
    const attributes: ConversationAttributes = {
      subject: values.subject,
      subject_type: values.category,
      conversation_type: ConversationType.CareTeamRequestFollowUp,
      identityMembers: [patientIdentity, providerIdentity],
      patient_id: patientId,
      patient_identity: patientIdentity,
    };
    const conversation = await twilioClient.createConversation({
      attributes: { ...attributes },
    });
    return conversation;
  } catch (error) {
    console.error('Error while creating conversation', error);
    return null;
  }
};

const addParticipants = async (conversation: Conversation, participantIdentities: string[]) => {
  const settledResult = await Promise.allSettled(
    participantIdentities.map((pid) => conversation.add(pid))
  );
  settledResult.forEach((sr) => {
    if (sr.status === 'rejected') {
      console.error('Error adding participant', sr.reason);
    }
  });
};

export const SendMessageModalBody = ({
  handleClose,
  patientId,
  patientIdentity,
  providerIdentity,
  providerUserId,
  setSelectedChat,
}: SendMessageModalBodyProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { client } = useTwilioClient();
  const { data: encounterCategories } = useAppQuery<EncounterCategory[]>('encounterCategories');
  const [serverError, setServerError] = useState(null);

  const options = useMemo(() => {
    return encounterCategories?.map((ec) => ({ value: ec.value, label: ec.name })) ?? [];
  }, [encounterCategories]);

  const handleSubmit = async (values) => {
    setServerError(null);
    setIsSubmitting(true);

    const newConversation = await createConversation({
      patientId,
      patientIdentity,
      providerIdentity,
      twilioClient: client,
      values,
    });
    if (!newConversation) {
      return;
    }
    const unknownAttributes: unknown = newConversation.attributes;
    const attributes = unknownAttributes as ConversationAttributes;

    const [encounter, error] = await createEncounter({
      category: values.category,
      patient: patientId,
      subject: values.subject,
      twilio_conversation_sid: newConversation.sid,
      owner: providerUserId,
    });
    if (error) {
      //roll back conversation
      setServerError(error);
      await newConversation.delete();
      return;
    }
    await addParticipants(newConversation, [providerIdentity, patientIdentity]);
    try {
      await newConversation.updateAttributes({ ...attributes, encounter_id: encounter.id });
    } catch (error) {
      console.error(
        `Error while updating attributes`,
        { ...attributes, encounter_id: encounter.id },
        error
      );
    }
    const messageIdx = await newConversation
      .prepareMessage()
      .setBody(values.message)
      .build()
      .send();
    try {
      await newConversation.updateLastReadMessageIndex(messageIdx);
    } catch (error) {
      console.error('ERror while updating last message index', error);
    }
    setSelectedChat(newConversation.sid);
    setIsSubmitting(false);
    handleClose();
  };

  return (
    <Formik
      initialValues={{
        category: '',
        subject: '',
        message: '',
      }}
      onSubmit={handleSubmit}
      validationSchema={SendMessageValidationSchema}
    >
      {({ values, handleSubmit: handleFormikSubmit }) => {
        return (
          <div className="d-flex flex-column gap-md">
            <section aria-label="category">
              <LabeledField label="Category" name="category">
                <SelectFormik
                  name="category"
                  options={options}
                  className="font-size-medium"
                  value={options.find((eco) => eco.value === values.category)}
                />
              </LabeledField>
            </section>
            <section aria-label="subject">
              <LabeledField name="subject" label="Subject">
                <TextInputFormik
                  name="subject"
                  placeholder="What is this about?"
                  className="font-size-medium w-100 px-0"
                />
              </LabeledField>
            </section>
            <section aria-label="message">
              <LabeledField name="message" label="message">
                <TextAreaFormik
                  name="message"
                  placeholder="Write your message here"
                  className="font-size-medium"
                />
              </LabeledField>
            </section>
            {isSubmitting ? (
              <section aria-label="status">
                <ProgressBar label="Sending message..." now={100} striped animated />
              </section>
            ) : null}
            <section aria-label="buttons" className="d-flex justify-content-between">
              <Button label="Cancel" variant="dull" type="button" onClick={handleClose} />
              <Button label="Send" type="button" onClick={handleFormikSubmit} />
            </section>
            {serverError ? (
              <section aria-label="error messge" className="text-danger">
                There was an error creating the conversation
              </section>
            ) : null}
          </div>
        );
      }}
    </Formik>
  );
};

interface SendMessageModalProps {
  patientInfo: {
    id: number;
    firstName: string;
    lastName: string;
    twilio_identity: string;
  };
  providerIdentity: string;
  providerUserId: number;
  show: boolean;
  onClose: () => void;
  setSelectedChat: (sid: string) => void;
}

const SendMessageModal = ({
  patientInfo,
  providerIdentity,
  providerUserId,
  show,
  onClose,
  setSelectedChat,
}: SendMessageModalProps) => {
  return (
    <VytracModal
      title={`Send new message to ${patientInfo.firstName} ${patientInfo.lastName}`}
      show={show}
      body={
        <SendMessageModalBody
          handleClose={onClose}
          patientId={patientInfo.id}
          patientIdentity={patientInfo.twilio_identity}
          providerIdentity={providerIdentity}
          providerUserId={providerUserId}
          setSelectedChat={setSelectedChat}
        />
      }
      onClose={onClose}
      centered
    />
  );
};

const PatientMessagesContent = () => {
  const { currentUser } = useAuthContext();
  const { selectedPatient } = usePatientFormikContext();
  const [showSendMessageModal, setShowSendMessageModal] = useState(false);
  const { dispatch } = useMessagesContext();

  const handleSendMessage = () => {
    setShowSendMessageModal(true);
  };

  const handleClose = () => setShowSendMessageModal(false);
  return (
    <>
      <Container className={styles.main}>
        <Row className="h-100" noGutters>
          <Col lg={3} style={{ paddingRight: '10px' }} className="d-flex flex-column gap-md">
            <Row className="d-flex justify-content-end">
              <Button label="Send new message" onClick={handleSendMessage} />
            </Row>
            <PatientMessagesList />
          </Col>

          <Col lg={6} style={{ padding: '0px 10px' }}>
            <Chat containerClass={styles['message-container']} />
          </Col>

          <Col lg={3} style={{ paddingLeft: '10px' }}>
            <EncounterContainer patient={selectedPatient} />
          </Col>
        </Row>
      </Container>
      <SendMessageModal
        providerUserId={currentUser?.id}
        patientInfo={{
          id: selectedPatient?.id,
          firstName: selectedPatient?.user?.first_name,
          lastName: selectedPatient?.user?.last_name,
          twilio_identity: selectedPatient?.twilio_identity,
        }}
        onClose={handleClose}
        show={showSendMessageModal}
        providerIdentity={currentUser?.twilio_identity}
        setSelectedChat={(sid: string) => dispatch(setSelectedChatActionCreator(sid))}
      />
    </>
  );
};

const PatientMessages = () => {
  return (
    <MessagesProvider>
      <PatientMessagesContent />
    </MessagesProvider>
  );
};

export default PatientMessages;
