import { SET_STICKY_NOTES } from './sticky-notes-action-types';

export const stickyNotesInitialState = { stickyNotes: [] };

const stickyNotesReducer = (state = stickyNotesInitialState, action) => {
  switch (action.type) {
    case SET_STICKY_NOTES:
      return { ...state, stickyNotes: action.payload };
    default:
      return state;
  }
};

export default stickyNotesReducer;
