import Button from 'components/Button';
import { useCallback, useMemo } from 'react';
import {
  resetDirtyForms,
  setOpenAddFormModal,
  updateDirtyForms,
} from 'screens/Administration/store/Forms';
import { useAdminForms } from 'screens/Administration/store/hooks';
import { updateAdminForm } from 'services/administrationService';

export const FormsActions = () => {
  const [{ currentForm, dirtyForms }, dispatch] = useAdminForms();

  const isDirty = useMemo(() => Object.keys(dirtyForms).length > 0, [dirtyForms]);
  const handleUpdateDirtyForms = useCallback(async () => {
    Object.keys(dirtyForms).forEach(async (key) => {
      const parsedForm = {
        ...dirtyForms[key],
        population: dirtyForms[key].population.map((pop) => pop.id),
      };
      await updateAdminForm(parsedForm, parseInt(key));
      dispatch(updateDirtyForms());
    });
  }, [dirtyForms, dispatch]);

  return (
    <div className="d-flex gap">
      <Button
        label="Cancel"
        variant="cancel"
        disabled={!isDirty}
        onClick={() => dispatch(resetDirtyForms())}
      />
      <Button
        label="Save changes"
        variant="confirm"
        disabled={!isDirty}
        onClick={handleUpdateDirtyForms}
      />
      {!currentForm && (
        <Button label="Add form" onClick={() => dispatch(setOpenAddFormModal(true))} />
      )}
    </div>
  );
};
