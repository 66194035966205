import addTagIcon from 'assets/icons/add_tag_icon.svg';
import deleteTagIcon from 'assets/icons/remove_tag_icon.svg';
import { Tag } from 'components/Tagitem';
import TagList from 'components/TagList';
import { ErrorMessage, getIn, useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { Language } from 'types/ApiModels/Users';

interface LanugagesFieldProps {
  name: string;
  languages: Language[];
  handleAdd: () => void;
}

const LanguagesField: FC<LanugagesFieldProps> = ({
  name,
  languages,
  handleAdd,
}: LanugagesFieldProps) => {
  const { values, setFieldTouched, setFieldValue } = useFormikContext();

  const currentSelectedLanguages = useMemo(
    () => languages?.filter((l) => getIn(values, name).includes(l.id)) ?? [],
    [languages, name, values]
  );

  const handleRemoveLanguage = (languageId: number) => {
    setFieldTouched(name, true);
    setFieldValue(
      name,
      getIn(values, name).filter((lid) => lid !== languageId),
      true
    );
  };
  return (
    <>
      <TagList
        className="w-100 py-1 rounded"
        tagList={[
          ...currentSelectedLanguages.map<Tag>((s) => ({
            text: s.name,
            icon: (
              <img
                src={deleteTagIcon}
                onClick={() => handleRemoveLanguage(s.id)}
                height={16}
                width={16}
                className="cursor-pointer"
              />
            ),
          })),
          {
            text: 'Add',
            icon: <img src={addTagIcon} height={16} width={16} />,
            className: 'cursor-pointer',
            onClick: handleAdd,
          },
        ]}
      />
      <ErrorMessage name={name}>
        {(msg) => <div className="text-danger font-size-medium">{msg}</div>}
      </ErrorMessage>
    </>
  );
};
export default LanguagesField;
