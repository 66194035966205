import { PhoneNumberFormik } from 'components/FormikComponents';
import GenericPatientInfoTable from 'components/GenericPatientInfoTable';
import {
  PatientManagementFormikKeys,
  PersonalInformationFormikKeys,
} from 'components/PatientManagementForm/types';
import PatientManagementInput from 'components/PatientManagementInput';
import { ErrorMessage } from 'formik';
import { Fragment } from 'react';
import './styles.css';

const ContactInformation = ({ formData, handleChange, errors, touched }) => {
  // Functions and subroutines
  const createContactInformationBodyList = ({ email }, handleChange, errors) => {
    const firstItem = 'Patient primary contact information';

    return [
      [
        firstItem,
        <PatientManagementInput
          value={email}
          onChange={handleChange}
          name={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.CONTACT_INFORMATION}.email`}
          error={errors ? errors['email'] : undefined}
          key={`contact-information-email`}
        />,
        <Fragment key={`contact-information-phone-primary`}>
          <PhoneNumberFormik
            name={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.CONTACT_INFORMATION}.primaryPhoneNumber`}
            className={`w-100 form-control form-control-sm bg-light-gray ${
              errors?.primaryPhoneNumber && touched?.primaryPhoneNumber ? 'border-red' : ''
            }`}
          />
          <ErrorMessage
            name={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.CONTACT_INFORMATION}.primaryPhoneNumber`}
          >
            {(msg) => <div className="text-danger font-size-medium">{msg}</div>}
          </ErrorMessage>
        </Fragment>,
        <Fragment key={`contact-information-phone-secondary`}>
          <PhoneNumberFormik
            name={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.CONTACT_INFORMATION}.secondaryPhoneNumber`}
            className={`w-100 form-control form-control-sm bg-light-gray ${
              errors?.secondaryPhoneNumber && touched?.secondaryPhoneNumber ? 'border-red' : ''
            }`}
          />
          <ErrorMessage
            name={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.CONTACT_INFORMATION}.secondaryPhoneNumber`}
          >
            {(msg) => <div className="text-danger font-size-medium">{msg}</div>}
          </ErrorMessage>
        </Fragment>,
      ],
    ];
  };

  // Variables and constants
  const title = 'contact information';
  const header = ['Preferred email', 'Primary phone', 'Secondary phone'];
  const body = createContactInformationBodyList(formData, handleChange, errors);

  return (
    <GenericPatientInfoTable
      tableName={title}
      tableHeader={header}
      tableBody={body}
      containerClassNameList={['contact-information-main-container']}
    />
  );
};

export default ContactInformation;
