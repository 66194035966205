import { ITrackingLog, TrackingAction, TrackingType } from 'types/ApiModels/Tracking/TrackingLog';

export const addTrackingLog: ITrackingLog = {
  tab: 'care_plan',
  duration: null,
  action: TrackingAction.ADD,
  description: 'Create action for patient care plan',
  object_type: TrackingType.CARE_PLAN_ACTION,
  object_id: null,
  activity: null,
  patient: null,
};

export const deleteTrackingLog: ITrackingLog = {
  tab: 'care_plan',
  duration: null,
  action: TrackingAction.DELETE,
  description: 'Delete action from patient care plan',
  object_type: TrackingType.CARE_PLAN_ACTION,
  object_id: null,
  activity: null,
  patient: null,
};

export const editTrackingLog: ITrackingLog = {
  tab: 'care_plan',
  duration: null,
  action: TrackingAction.UPDATE,
  description: 'Update action from patient care plan',
  object_type: TrackingType.CARE_PLAN_ACTION,
  object_id: null,
  activity: null,
  patient: null,
};
