import ProviderProfileForm from 'components/Provider/Account/ProviderProfileForm';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import { Form, Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useAdministrationContext,
  useAdministrationContextV2,
  useAdminRoles,
  useAdminSpecialties,
} from 'screens/Administration/store';
import {
  removeProviderFromBook,
  setRemoveSingleProvider,
  setResetSingleProviderForm,
  setSelectedProvider,
  setSingleProviderFormDirty,
  setSubmitSingleProviderForm,
} from 'screens/Administration/store/Providers';
import {
  deleteProvider,
  getProvidersTitles,
  getSingleProvider,
  updateProvider,
} from 'services/providersService';
import { getLanguages, getSexOptions } from 'services/userService';
import { useAppQuery } from 'store/use-app-query';
import { Title } from 'types/ApiModels/Providers/Provider';
import { Language } from 'types/ApiModels/Users';
import Sex, { SexLabelId } from 'types/ApiModels/Users/Sex';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDateToDatePickValue } from 'util/dateUtils';
import { localIdCreator } from 'util/utils';
import ProviderDetailsActions from './ProviderDetailsActions';
import { mapProviderFormToProvider } from './utils';
import ProviderDetailsValidationSchema from './utils/ProviderDetailsValidationSchema';

//need some way to identify addresses
export const createLocalAddressId = localIdCreator();

export interface ProviderDetailsFormValues {
  identification: {
    firstName: string;
    lastName: string;
    middleName: string;
    dateOfBirth: DatePickerValue;
    gender: SexLabelId;
    title: number;
    language: number[];
    accessRoles: number[];
  };
  personalInformation: {
    contactInformation: {
      email: string;
      phoneNumber1: string;
      phoneNumber2: string;
    };
    addressInformation: {
      id: number;
      home: string;
      apt: string;
      zipCode: string;
      state: string;
      city: string;
    }[];
  };
  specialties: number[];
}

const ProviderDetails = () => {
  const { breadcrumbPathList, setBreadcrumbPathList, setActions } = useAdministrationContext();
  const history = useHistory();
  const { roles } = useAdminRoles();
  const { providerId } = useParams<{ providerId: string }>();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmRemoveProviderModal, setShowConfirmRemoveProviderModal] = useState(false);
  const [commitingValues, setCommitingValues] = useState<ProviderDetailsFormValues>();
  const {
    administrationStateV2: {
      providers: { selectedProvider },
    },
    dispatch,
  } = useAdministrationContextV2();

  const [{ list: specialties }] = useAdminSpecialties();

  const { data: languages } = useAppQuery<Language[]>('languages');
  const { data: genders } = useAppQuery<Sex[]>('sex');
  const [titles, setTitles] = useState<Title[]>([]);

  const fetchTitles = async () => {
    const titles = await getProvidersTitles();
    titles && setTitles(titles.results);
  };

  useEffect(() => {
    fetchTitles();
  }, []);

  const fetchProvider = useCallback(
    async (providerId: number) => {
      const res = await getSingleProvider(providerId);
      dispatch(setSelectedProvider(res));
    },
    [dispatch]
  );

  useEffect(() => {
    const providerIdNumber = parseInt(providerId);
    if (isNaN(providerIdNumber)) return;
    if (!selectedProvider) {
      fetchProvider(providerIdNumber);
    }
  }, [fetchProvider, providerId, selectedProvider]);

  useEffect(() => {
    if (selectedProvider) {
      setActions(ProviderDetailsActions);
      if (
        breadcrumbPathList[breadcrumbPathList.length - 1].title !==
        `${selectedProvider?.first_name} ${selectedProvider?.last_name}`
      )
        setBreadcrumbPathList([
          ...breadcrumbPathList,
          { title: `${selectedProvider?.first_name} ${selectedProvider?.last_name}`, url: '' },
        ]);
    }
  }, [selectedProvider]);

  useEffect(() => {
    dispatch(
      setRemoveSingleProvider(() => {
        setShowConfirmRemoveProviderModal(true);
      })
    );
  }, [dispatch, providerId]);

  if (!selectedProvider) return <></>;

  const handleCommitProviderChanges = async () => {
    const newProviderValues = mapProviderFormToProvider(
      commitingValues,
      selectedProvider.is_active
    );
    const res = await updateProvider(selectedProvider.id, newProviderValues);
  };

  const handleRemoveCurrentProvider = async () => {
    const res = await deleteProvider(selectedProvider.id);
    dispatch(removeProviderFromBook(selectedProvider.id));
    dispatch(setSelectedProvider());
    history.push('/administration/providers/all');
  };
  return (
    <Formik<ProviderDetailsFormValues>
      initialValues={{
        identification: {
          firstName: selectedProvider.first_name ?? '',
          lastName: selectedProvider.last_name ?? '',
          middleName: selectedProvider.middle_name ?? '',
          dateOfBirth: selectedProvider.date_birth
            ? convertDateToDatePickValue(new Date(selectedProvider.date_birth))
            : null,
          gender: selectedProvider?.sex ?? null,
          title: selectedProvider.title?.id ?? 0,
          language: selectedProvider?.languages.map((l) => l.id),
          accessRoles: selectedProvider.roles.map((r) => r.id),
        },
        personalInformation: {
          contactInformation: {
            email: selectedProvider.email,
            phoneNumber1: selectedProvider.phone_number1 ?? '',
            phoneNumber2: selectedProvider.phone_number2 ?? '',
          },
          addressInformation: selectedProvider.address.length
            ? selectedProvider.address.map((a) => ({
                home: a.home,
                apt: a.apt,
                state: a.state,
                zipCode: a.zip_code,
                city: a.city,
                id: createLocalAddressId(),
              }))
            : [{ home: '', apt: '', state: '', city: '', zipCode: '', id: createLocalAddressId() }],
        },
        specialties: selectedProvider.speciality.map((s) => s.id),
      }}
      validationSchema={ProviderDetailsValidationSchema}
      onSubmit={(values) => {
        setCommitingValues(values);
        setShowConfirmModal(true);
      }}
    >
      {({ submitForm, resetForm, dirty }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setSubmitSingleProviderForm(submitForm));
          dispatch(setResetSingleProviderForm(resetForm));
        }, [resetForm, submitForm]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setSingleProviderFormDirty(dirty));
        }, [dirty]);
        return (
          <Form>
            <ProviderProfileForm
              selectedProvider={selectedProvider}
              genderOptions={genders ?? []}
              languages={languages ?? []}
              roles={roles}
              titles={titles}
              specialties={specialties}
            />
            <SaveChangesConfirmationModal
              onCancel={() => setShowConfirmModal(false)}
              onConfirm={handleCommitProviderChanges}
              show={showConfirmModal}
            />
            <SaveChangesConfirmationModal
              onCancel={() => setShowConfirmRemoveProviderModal(false)}
              show={showConfirmRemoveProviderModal}
              onConfirm={handleRemoveCurrentProvider}
              message={`Are you sure you want to delete provider: ${selectedProvider.username}`}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default ProviderDetails;
