import { IAccount } from "types/Administration/Account/Account";

export const emptyAccountSettings: IAccount = {
    generalEmail: '',
    generalPhone: '',
    techSupportEmail: '',
    address: '',
    secondAddress: '',
    iosPatientApp: '',
    iosProviderApp: '',
    androidPatientApp: '',
    androidProviderApp: '',
    patientWebPortal: '',
    countryCode: '',
    timezone: '',
    dateFormat: '',
    nationalProviderIdentifier: '',
    signature: '',
    updatedAt: '',
    ipaddress: '',
    brandName: '',
    brandLogo: '',
    brandPracticeIcon: '',
    brandColorScheme: '',
    brandRewardsStoreFeed: '',
    brandRewardsRedeemLink: '',
    updatedBy: '',
}