import styles from '../../styles.module.css';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { AddRow } from '../../Steps';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import DropdownMenu from 'components/DropdownMenu';
import { PhoneNumberFormik, SelectFormik, TextInputFormik } from 'components/FormikComponents';
import { getStyles } from '../../../utils/getStyles';
import { useMemo } from 'react';
const CareChampions = () => {
  const { values, touched, errors } = useFormikContext<InvitePatient>();
  const openError = useMemo(() => errors.careChampions?.length > 0 || false, [errors]);

  const relationOptions = useMemo(() => {
    return [
      { label: 'Parent', value: 'parent' },
      { label: 'Partner', value: 'partner' },
      { label: 'Siblings', value: 'siblings' },
      { label: 'Cousin', value: 'cousin' },
      { label: 'Family', value: 'family' },
      { label: 'Friend', value: 'friend' },
    ];
  }, []);

  const relationValue = useMemo(() => {
    return values.careChampions?.length > 0
      ? values.careChampions.map((_, index) => {
          const relation = values.careChampions[index].relationship;
          return {
            label: relationOptions.find((e) => e.value === relation)?.label,
            value: relation,
          };
        })
      : [];
  }, [values.careChampions, relationOptions]);

  return (
    <DropdownMenu name="Care champions" open={openError}>
      <FieldArray validateOnChange={false} name="careChampions">
        {({ push, remove }) => (
          <>
            {values.careChampions && values.careChampions.length > 0 ? (
              values.careChampions.map((_, index) => (
                <div className="d-flex flex-column gap" key={index}>
                  <div className={styles['row']}>
                    <div className="d-flex gap px-3 pt-3 mb-2">
                      <div
                        style={{ flexBasis: '17%' }}
                        className={`d-flex ${styles['form-label']} font-size-medium`}
                      >
                        Full Name
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <TextInputFormik
                          className="w-100"
                          name={`careChampions[${index}].fullName`}
                          style={getStyles(errors, touched, `careChampions[${index}].fullName`)}
                        />
                        <ErrorMessage name={`careChampions[${index}].fullName`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Personal phone number
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <PhoneNumberFormik
                          className="w-100"
                          name={`careChampions[${index}].personalPhoneNumber`}
                          style={getStyles(
                            errors,
                            touched,
                            `careChampions[${index}].personalPhoneNumber`
                          )}
                        />
                        <ErrorMessage name={`careChampions[${index}].personalPhoneNumber`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Relationship
                      </div>
                      <div
                        className={`flex-grow-1 d-flex flex-column gap`}
                        style={{ width: '20%' }}
                      >
                        <SelectFormik
                          options={relationOptions}
                          value={relationValue[index]}
                          name={`careChampions[${index}].relationship`}
                          placeholder="Assign relationship..."
                          styles={getStyles(
                            errors,
                            touched,
                            `careChampions[${index}].relationship`
                          )}
                        />
                        <ErrorMessage name={`careChampions[${index}].relationship`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon fill="#8FAAC3" stroke="#8FAAC3" />
                      </div>
                    </div>
                  </div>
                  <div className={styles['row']}>
                    <div className="d-flex gap px-3 mb-2">
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Secondary phone number
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <PhoneNumberFormik
                          className="w-100"
                          name={`careChampions[${index}].secondaryPhoneNumber`}
                          style={getStyles(
                            errors,
                            touched,
                            `careChampions[${index}].secondaryPhoneNumber`
                          )}
                        />
                        <ErrorMessage name={`careChampions[${index}].secondaryPhoneNumber`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>Email</div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <TextInputFormik
                          className="w-100"
                          name={`careChampions[${index}].email`}
                          style={getStyles(errors, touched, `careChampions[${index}].email`)}
                        />
                        <ErrorMessage name={`careChampions[${index}].email`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  {index === values.careChampions.length - 1 && (
                    <AddRow
                      name="care champion"
                      onClick={() =>
                        push({
                          fullName: '',
                          personalPhoneNumber: '',
                          secondaryPhoneNumber: '',
                          email: '',
                          relationship: '',
                        })
                      }
                    />
                  )}
                </div>
              ))
            ) : (
              <AddRow
                name="care champion"
                onClick={() =>
                  push({
                    fullName: '',
                    personalPhoneNumber: '',
                    secondaryPhoneNumber: '',
                    email: '',
                    relationship: '',
                  })
                }
              />
            )}
          </>
        )}
      </FieldArray>
    </DropdownMenu>
  );
};
export default CareChampions;
