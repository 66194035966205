type Relationship = 'family' | 'friend' | 'cousin' | 'siblings' | 'parent' | 'partner';

export enum RelationShipEnum {
  family = 'Family',
  friend = 'Friend',
  cousin = 'Cousin',
  siblings = 'Siblings',
  parent = 'Parent',
  partner = 'Partner',
}

export default Relationship;
