import PatientProfileRing from 'components/patient/PatientProfileRing';
import {
  getOtherPhysicians,
  getPrimaryPhysician,
} from 'components/PatientPreviewTable/components/Row/PatientRow';
import UserBubble from 'components/UserBubble';
import UserBubbleGroup from 'components/UserBubbleGroup';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import { formatDottedDate } from 'util/dateUtils';
import { ReactComponent as NoMobileAppIcon } from 'assets/icons/no_mobile_app.svg';
import styles from './styles.module.css';
interface PatientSearchResultProps {
  patient: PatientGet;
}
const PatientSearchResult = ({ patient }: PatientSearchResultProps) => {
  const { id, user, score, care_team } = patient;
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.patient}>
          <span>{`${score ?? 0}`}</span>
          <PatientProfileRing currentPatient={patient} size={36} />
          {!patient.has_mobile_app && (
            <NoMobileAppIcon style={{ marginRight: '5px', width: '20px', height: '20px' }} />
          )}
          <span>{`${user.first_name} ${user.last_name}`}</span>
        </div>
        <div className={styles.text}>#{id}</div>
        <div className={styles.text}>{formatDottedDate(new Date(user.birth_date))}</div>
      </div>
      <div className="d-flex align-items-center">
        <UserBubble user={getPrimaryPhysician(care_team)} />
        <UserBubbleGroup users={getOtherPhysicians(care_team)} maxCount={3} />
      </div>
    </div>
  );
};
export default PatientSearchResult;
