import moment from 'moment';
import React from 'react';
import { ReactComponent as NoMobileAppIcon } from 'assets/icons/no_mobile_app.svg';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';

interface IPatientDetail {
  currentPatient: RecursivePartial<PatientGetSingle>;
  showActive: boolean;
}

const PatientDetail = ({ currentPatient, showActive = true }: IPatientDetail) => {
  return (
    <div>
      {currentPatient?.user && (
        <>
          <div className="align-items-center dashboard-patient-detail-top">
            {!currentPatient.has_mobile_app && <NoMobileAppIcon style={{ marginRight: '15px' }} />}
            <span
              style={{
                fontSize: 16,
                textTransform: 'uppercase',
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
              }}
            >
              {currentPatient?.user.first_name} {currentPatient?.user.last_name}
            </span>
            <span style={{ fontSize: 12 }}>
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
              {showActive && (
                <span style={{ fontWeight: 'bold' }}>
                  {currentPatient.is_active ? 'Active' : 'Inactive'}
                  &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              )}
              #{currentPatient.id}
            </span>
          </div>
          <div className="dashboard-patient-detail-bottom">
            <span style={{ display: 'inline', whiteSpace: 'nowrap' }}>
              {moment(currentPatient?.user.birth_date).format('MM.DD.YYYY')}
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
              {currentPatient?.user.email}
              &nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;
              {currentPatient?.user.phone_number}
            </span>
          </div>
        </>
      )}
    </div>
  );
};
export default PatientDetail;
