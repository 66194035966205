import Button from 'components/Button';
import { resetCareTeamForm } from 'screens/Administration/store/CareTeam';
import { useAdminCareTeams } from 'screens/Administration/store/hooks';

const AdminCareTeamActions = () => {
  const [
    { deletedCareTeams, updatedCareTeams, createdCareTeams, submitCareTeamsForm, createCareTeam },
    ,
    dispatch,
  ] = useAdminCareTeams();

  const isTableDirty =
    deletedCareTeams.length || updatedCareTeams.length || createdCareTeams.length;
  return (
    <div className="d-flex gap-sm">
      <Button
        label="cancel"
        variant="cancel"
        disabled={!isTableDirty}
        onClick={() => {
          dispatch(resetCareTeamForm());
        }}
      />
      <Button
        label="save changes"
        variant="confirm"
        disabled={!isTableDirty}
        onClick={submitCareTeamsForm}
      />
      <Button
        label="add new care team"
        onClick={() => {
          createCareTeam();
        }}
      />
    </div>
  );
};
export default AdminCareTeamActions;
