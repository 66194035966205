import { ReactNode, useEffect, useState } from 'react';
import { getSingleProvider } from 'services/providersService';
import { Provider } from 'types/ApiModels/Providers/Provider';
import { MinifiedUser } from 'types/ApiModels/Users';
import { AuthContext } from './store';

interface AuthProvider {
  children: JSX.Element | JSX.Element[];
}
const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<MinifiedUser>();
  const [providerInfo, setProviderInfo] = useState<Provider>();

  useEffect(() => {
    if (currentUser && !providerInfo) {
      getSingleProvider(currentUser.provider_id).then(setProviderInfo);
    }
  }, [currentUser, providerInfo]);

  useEffect(() => {
    console.log('hey from auth provider', { currentUser });
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, providerInfo, setCurrentUser, setProviderInfo }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
