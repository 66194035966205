import InvitePatient from 'types/ApiModels/Administration/InvitePatient';

const initialValues: InvitePatient = {
  email: '',
  careTeam: -1,
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: null,
  sexAssignedAtBirth: null,
  populations: [],
  populationsData: [],
  surgeries: [],
  allergies: [],
  familyHistory: [],
  contactInformation: {
    primaryPhone: '',
    secondaryPhone: '',
  },
  address: [],
  insurance: [],
  demographicInfo: {
    genderIdentity: '',
    maritalStatus: -1,
    race: -1,
    ethnicity: -1,
    language: -1,
    religion: -1,
  },
  healthScoring: {
    yellowAlert: 0,
    redAlert: 0,
  },
  emergencyContacts: [],
  careChampions: [],
  isAppUser: true,
};
export default initialValues;
