import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { FC } from 'react';
import AsyncSelect from 'react-select/async';
import { searchCareTeams } from 'services/administrationService';
import { CareTeam } from 'types/ApiModels/CareTeam';
import { debouncePromiseValue } from 'util/utils';
import CareTeamOption from './CareTeamOption';

interface CareTeamAsyncSelectProps {
  onChange: (selectOption) => void;
  value?;
  defaultValue?;
  styles?;
  placeholder?: string;
  className?: string;
}

export interface CareTeamSelectOption {
  label: string;
  value: number;
  careTeam: CareTeam;
}

const mapCareTeamToSelect = (p): CareTeamSelectOption => {
  const label = p.name;
  return { label, value: p.id, careTeam: p };
};

const CareTeamAsyncSelect: FC<CareTeamAsyncSelectProps> = ({
  onChange,
  defaultValue,
  value,
  styles = {},
  placeholder = 'Assign care team...',
  className = '',
}: CareTeamAsyncSelectProps) => {
  const loadCareTeamOptions = debouncePromiseValue(async (inputValue: string) => {
    const result = (await searchCareTeams(inputValue)).results;
    return result.map(mapCareTeamToSelect);
  }, 1000);

  return (
    <AsyncSelect<CareTeamSelectOption>
      className={className}
      name="careTeam"
      cacheOptions
      value={value}
      defaultValue={defaultValue}
      formatOptionLabel={({ label, careTeam }) => (
        <CareTeamOption careTeam={careTeam} label={label} />
      )}
      loadOptions={loadCareTeamOptions}
      defaultOptions
      onChange={onChange}
      styles={{
        ...vytracSelectStyle,
        ...styles,
      }}
      placeholder={placeholder}
    />
  );
};
export default CareTeamAsyncSelect;
