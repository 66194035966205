import React, { FC } from 'react';
import AlertSummary from 'types/ApiModels/Patients/AlertSummary';
import AlertGroup from './AlertGroup';

interface IAlerts {
  alertsSummary: AlertSummary[];
}

const Alerts: FC<IAlerts> = ({ alertsSummary }) => {
  return (
    <div className="d-flex">
      <AlertGroup
        alertList={alertsSummary.filter((alert_) => alert_.alert_type === 'red')}
        color="var(--red-high-risk)"
      />
      <AlertGroup
        alertList={alertsSummary.filter((alert_) => alert_.alert_type === 'yellow')}
        color="var(--yellow-medium-risk)"
      />
    </div>
  );
};

export default Alerts;
