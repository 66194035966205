import { DialogsContextProvider } from 'components/VyTracLayout/store';
import { useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import RegisterLoginLayout from 'screens/Common/RegisterLoginLayout';
import { useRegisterContext } from 'screens/Register/store';
import { RegisterViews } from 'types/Register';
import { RegisterProviderContext } from './store/RegisterProviderContext';
import RegisterComponentByEnum from './utils/RegisterComponentByEnum';

const RegisterProvider = () => {
  const history = useHistory();
  const [currentView, setCurrentView] = useState<RegisterViews>(RegisterViews.REGISTER);
  const [registerValues, setRegisterValues] = useState({
    hash: '',
    username: '',
    password: '',
    email: '',
    phone_number: '',
    confirm_password: '',
    code: '',
  });
  const { email, hash } = useRegisterContext();

  useEffect(() => {
    email && hash && setRegisterValues((rg) => ({ ...rg, email, hash }));
  }, [email, hash]);

  const handleChangeView = (view: RegisterViews) => {
    setCurrentView(view);
  };
  const handleRegisterValues = (values: any) => {
    setRegisterValues(values);
  };
  const RegisterComponent = useMemo(() => RegisterComponentByEnum[currentView], [currentView]);

  return (
    <>
      <RegisterProviderContext.Provider
        value={{
          changeView: handleChangeView,
          setRegisterValues: handleRegisterValues,
          registerValues,
        }}
      >
        <DialogsContextProvider>
          <RegisterLoginLayout>
            <RegisterComponent
              previousValues={registerValues}
              setPreviousValues={handleRegisterValues}
              changeView={handleChangeView}
              userEmail={email}
              onCancel={() => history.push('/')}
            />
          </RegisterLoginLayout>
        </DialogsContextProvider>
      </RegisterProviderContext.Provider>
    </>
  );
};

export default RegisterProvider;
