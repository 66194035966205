import Button from 'components/Button';
import { LabeledField, TextInputFormik } from 'components/FormikComponents';
import TextAreaFormik from 'components/FormikComponents/TextAreaFormik';
import VytracModal from 'components/Modal';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { FC } from 'react';
import { PatientManagementFormikValues } from '../types';
import CreateAllergyValidationSchema from './utils/CreateAllergyValidationSchema';

interface AllergyFormFieldValues {
  name: string;
  details: string;
  id: number;
  patient: number;
}

interface CreateAllergyModalProps {
  onClose: () => void;
  show: boolean;
  idCreator: () => number;
  patientId: number;
  currentId: number;
  queueEdit: (id: number) => void;
}

const CreateAllergyModal: FC<CreateAllergyModalProps> = ({
  onClose,
  show,
  idCreator,
  patientId,
  currentId,
  queueEdit,
}: CreateAllergyModalProps) => {
  const { setFormikState, values } = useFormikContext<PatientManagementFormikValues>();

  const handleCreate = (values: AllergyFormFieldValues) => {
    setFormikState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        ALLERGIES: [...prev.values.ALLERGIES, values],
      },
    }));
    onClose();
  };
  const handleEdit = (values: AllergyFormFieldValues) => {
    setFormikState((prev) => {
      const allergiesCopy = [...prev.values.ALLERGIES];
      const updateAllergyIdx = allergiesCopy.findIndex((a) => a.id === values.id);
      allergiesCopy[updateAllergyIdx] = values;
      return {
        ...prev,
        values: {
          ...prev.values,
          ALLERGIES: allergiesCopy,
        },
      };
    });
    values.id > 0 && queueEdit(values.id);
    onClose();
  };
  return (
    <VytracModal
      title="Add allergy"
      body={
        <Formik<AllergyFormFieldValues>
          initialValues={
            currentId
              ? values.ALLERGIES.find((a) => a.id === currentId)
              : {
                  name: '',
                  details: '',
                  id: idCreator(),
                  patient: patientId,
                }
          }
          onSubmit={currentId ? handleEdit : handleCreate}
          validationSchema={CreateAllergyValidationSchema}
        >
          {({ handleSubmit, dirty }) => (
            <div>
              <div className="d-flex flex-column">
                <LabeledField name="name" label="Allergy name">
                  <TextInputFormik name="name" className="font-size-medium" />
                </LabeledField>
                <LabeledField name="details" label="Details">
                  <TextAreaFormik name="details" className="font-size-medium" />
                </LabeledField>
              </div>
              <hr />
              <div className="d-flex justify-content-between w-100">
                <Button label="cancel" variant="dull" onClick={onClose} />
                <Button label="confirm" onClick={!dirty && currentId ? onClose : handleSubmit} />
              </div>
            </div>
          )}
        </Formik>
      }
      onClose={onClose}
      show={show}
      centered
    />
  );
};
export default CreateAllergyModal;
