import { Conversation } from '@twilio/conversations';
import { useConversationMetadata } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getConversationsMetadata } from 'services/twilio';
import { useTwilioClient } from 'store/twilio-client';
import { TwilioConversation } from 'store/twilio-conversations/conversations-reducer';
import { ConversationMetadata } from 'types/ApiModels/conversations';
import { mapConversationMetadataAllSettledToTwilioConversation } from '../utils';

/**
 *  used mainly for search
 * @param feed either the list of conversations or the list of sids
 * @returns
 */
const useTwilioConversations = (
  feed: Conversation[] | string[],
  metadataFeed?: Record<string, ConversationMetadata>
) => {
  const [conversations, setConversations] = useState<TwilioConversation[]>([]);
  const [areConversationsLoading, setAreConversationsLoading] = useState(false);

  const upsertConversation = useCallback(async (conversation: Conversation) => {
    const metadataAllSettled = await getConversationsMetadata([conversation]);
    const conversationsMapped = mapConversationMetadataAllSettledToTwilioConversation(
      metadataAllSettled
    )(conversation, 0);

    setConversations((cs) => {
      const foundIdx = cs.findIndex((c) => c.ref.sid === conversation.sid);
      if (foundIdx === -1) {
        return [...cs, conversationsMapped];
      }
      const copy = [...cs];
      copy[foundIdx] = conversationsMapped;
      return copy;
    });
  }, []);

  const sortedConversations = useMemo(() => {
    const conversationsCopy = [...conversations];
    return conversationsCopy.sort((a, b) =>
      a.lastMessage?.dateCreated > b.lastMessage?.dateCreated ? -1 : 1
    );
  }, [conversations]);

  const { client } = useTwilioClient();

  const getConversations = useCallback(
    async (conversationSids: string[]) => {
      if (!client) return;
      //this is a bit dangerous because we're swalling exceptions here
      const conversationsSettled = await Promise.allSettled(
        (conversationSids as string[]).map((sid) => client.getConversationBySid(sid))
      );
      return conversationsSettled.flatMap((cf) => {
        if (cf.status === 'rejected') return [];
        return [cf.value];
      });
    },
    [client]
  );

  const setupConversations = useCallback(
    async (feed: Conversation[] | string[]) => {
      setAreConversationsLoading(true);
      let conversations: Conversation[];
      if (!feed?.length) {
        conversations = [];
      }
      if (typeof feed?.[0] === 'string') {
        conversations = await getConversations(feed as string[]);
      } else {
        conversations = feed as Conversation[];
      }
      if (!conversations) return;
      const metadataAllSettled = await getConversationsMetadata(conversations);
      const conversationsMapped = conversations.map(
        mapConversationMetadataAllSettledToTwilioConversation(metadataAllSettled)
      );
      setConversations(conversationsMapped);
      setAreConversationsLoading(false);
    },
    [getConversations]
  );

  const conversationParticipantIdentities = useMemo(() => {
    return conversations.reduce((res, c) => {
      const participantIds = c.participants.flatMap((p) => {
        if (res.includes(p.identity)) return [];
        return [p.identity];
      });
      return [...res, ...participantIds];
    }, []);
  }, [conversations]);

  const metadataCache = useConversationMetadata(
    conversationParticipantIdentities,
    metadataFeed,
    'use-twilio-conversations'
  );

  useEffect(() => {
    if (!feed) return;
    setupConversations(feed);
  }, [feed, setupConversations]);

  return useMemo(
    () => ({
      conversations: sortedConversations,
      metadataCache,
      areConversationsLoading,
      upsertConversation,
    }),
    [areConversationsLoading, metadataCache, sortedConversations, upsertConversation]
  );
};

export default useTwilioConversations;
