import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { updateThreshold } from 'screens/Patients/store/PatientGoalsThresholds';
import { IGoalThreshold } from 'types/ApiModels/Patients/Goal';
import { updateThresholdValues } from '../../GoalChartCard/components/GoalLineChart/useGoalChart';
import goalDetailAlertsValidationSchema from './goalDetailAlertsValidationSchema';
import { ThresholdFormikKeys, ThresholdFormikValues } from './thresholdFormikUtils';

const useThresholds = () => {
  const { values } = useFormikContext<ThresholdFormikValues>();
  const {
    dispatch,
    patientState: {
      patientGoalsThresholds: { thresholds, selectedGoal },
    },
  } = usePatientContext();

  const thresholdDispatcher = useCallback(
    (updatedThreshold: IGoalThreshold) => dispatch(updateThreshold(selectedGoal, updatedThreshold)),
    [dispatch, selectedGoal]
  );

  const onThresholdChange = useCallback(
    (formikKey: ThresholdFormikKeys) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = parseInt(e.target.value);
      if (!inputValue) return;

      const nextUpdatedValues = {
        ...values,
        [formikKey]: inputValue,
      };

      if (goalDetailAlertsValidationSchema.isValidSync(nextUpdatedValues)) {
        updateThresholdValues(thresholds, selectedGoal, thresholdDispatcher, formikKey, inputValue);
      }
    },
    [thresholds, values, selectedGoal, thresholdDispatcher]
  );

  return {
    onThresholdChange,
  };
};
export default useThresholds;
