import { FC } from 'react';
import { Form as BootstrapForm } from 'react-bootstrap';

interface PopulationStatusElementProps {
  id: number;
  handleUpdateIsActivePopulation: ({ id: number, value: boolean }) => void;
  status: boolean;
}

const PopulationStatusElement: FC<PopulationStatusElementProps> = ({
  handleUpdateIsActivePopulation,
  id,
  status,
}: PopulationStatusElementProps) => {
  return (
    <div onClick={() => handleUpdateIsActivePopulation({ id, value: !status })}>
      <BootstrapForm.Check type="switch" checked={status} readOnly />
    </div>
  );
};
export default PopulationStatusElement;
