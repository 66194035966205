import { useFormikContext } from 'formik';
import GenericPatientInfoTable from 'components/GenericPatientInfoTable';
import PatientManagementEliminateButton from 'components/PatientManagementEliminateButton';
import PatientManagementInput from 'components/PatientManagementInput';
import createNewInsuranceProvider from '../../util/createNewInsuranceProvider';
import './styles.css';
import PatientManagementAddButton from 'components/PatientManagementAddButton';
import {
  InsuranceFormikValues,
  PatientManagementFormikKeys,
  PatientManagementFormikValues,
  PersonalInformationFormikKeys,
} from 'components/PatientManagementForm/types';
import TextSurroundedWithLines from 'components/TextSurroundedWithLines';
import PatientManagementSelect from 'components/PatientManagementSelect';
import Insurance from 'types/ApiModels/Patients/Insurance';
import InsuranceFormikKeys from 'components/PatientManagementForm/types/InsuranceFormikKeys';

interface InsuranceInformationProps {
  formData: InsuranceFormikValues[];
  handleChange;
  errors;
  touched;
  insurances: Insurance[];
}

const InsuranceInformation = ({
  formData,
  handleChange,
  errors,
  touched,
  insurances,
}: InsuranceInformationProps) => {
  const { setFormikState } = useFormikContext<PatientManagementFormikValues>();

  // Functions and subroutines
  const createInsuranceBodyList = (formData, handleChange, errors) => {
    // Functions and subroutines
    const transformInsuranceProviderToArray = (insuranceProvider, index) => {
      if (!insuranceProvider) return [];
      const deleteInsuranceProvider = (deletedIndex) =>
        setFormikState((prevState) => ({
          ...prevState,
          values: {
            ...prevState.values,
            [PatientManagementFormikKeys.PERSONAL_INFORMATION]: {
              ...prevState.values[PatientManagementFormikKeys.PERSONAL_INFORMATION],
              [PersonalInformationFormikKeys.INSURANCE]: prevState.values[
                PatientManagementFormikKeys.PERSONAL_INFORMATION
              ][PersonalInformationFormikKeys.INSURANCE].filter(
                (_, index) => index !== deletedIndex
              ),
            },
          },
        }));

      return [
        <PatientManagementSelect
          key={`insurance-field-${InsuranceFormikKeys.INSURANCE_ID}`}
          value={insuranceProvider[InsuranceFormikKeys.INSURANCE_ID]}
          onChange={handleChange}
          name={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.INSURANCE}[${index}].${InsuranceFormikKeys.INSURANCE_ID}`}
          error={
            errors && errors[index] && touched?.[index]?.[InsuranceFormikKeys.INSURANCE_ID]
              ? errors[index][InsuranceFormikKeys.INSURANCE_ID]
              : undefined
          }
          options={insurances}
          renderOption={(i: Insurance) => (
            <option value={i.id} key={`insurance-${i.id}`}>
              {i.name}
            </option>
          )}
        />,
        ...[InsuranceFormikKeys.SUBSCRIBER_NUMBER, InsuranceFormikKeys.GROUP_NUMBER].map((key) => (
          <PatientManagementInput
            key={`insurance-field-${key}`}
            value={insuranceProvider[key]}
            onChange={handleChange}
            name={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.INSURANCE}[${index}].${key}`}
            error={
              errors && errors[index] && touched?.[index]?.[key] ? errors[index][key] : undefined
            }
          />
        )),
        <PatientManagementEliminateButton
          onClick={() => deleteInsuranceProvider(index)}
          key="delete-button"
        />,
      ];
    };

    const transformSecondaryInsuranceProviderListToArray = (insuranceProviderList) =>
      insuranceProviderList.map((insuranceProvider, index) => [
        'Secondary insurance provider',
        ...transformInsuranceProviderToArray(insuranceProvider, index + 1),
      ]);

    // Variables and constants
    const insuranceProviders = {
      main: formData.find((_, index) => index === 0),
      secondary: formData.filter((_, index) => index !== 0),
    };
    const { main, secondary } = insuranceProviders;

    return formData.length > 0
      ? [
          ['Main insurance provider', ...transformInsuranceProviderToArray(main, 0)],
          ...transformSecondaryInsuranceProviderListToArray(secondary),
        ]
      : [];
  };

  const addNewInsuranceProvider = () =>
    setFormikState(({ values, ...prevFormikState }) => ({
      ...prevFormikState,
      values: {
        ...values,
        [PatientManagementFormikKeys.PERSONAL_INFORMATION]: {
          ...values[PatientManagementFormikKeys.PERSONAL_INFORMATION],
          [PersonalInformationFormikKeys.INSURANCE]: [
            ...values[PatientManagementFormikKeys.PERSONAL_INFORMATION][
              PersonalInformationFormikKeys.INSURANCE
            ],
            createNewInsuranceProvider(),
          ],
        },
      },
    }));

  // Variables and constants
  const title = (
    <>
      <span>insurance</span>
      <PatientManagementAddButton onClick={addNewInsuranceProvider} />
    </>
  );
  const header = formData.length > 0 ? ['Name', 'Subscriber number', 'Group number', ''] : [];
  const body = createInsuranceBodyList(formData, handleChange, errors);

  return (
    <>
      <GenericPatientInfoTable
        tableName={title}
        tableHeader={header}
        tableBody={body}
        containerClassNameList={['insurance-information-main-container']}
      />
      {formData.length === 0 ? (
        <div className="p-3">
          <TextSurroundedWithLines
            text="No insurances added"
            className="text-gray-low-risk font-size-medium"
          />
        </div>
      ) : null}
    </>
  );
};

export default InsuranceInformation;
