import { useAuthContext } from 'auth';
import { useCallback, useEffect } from 'react';
import { useProviderProfileContext } from 'screens/provider-profile/store';
import { setProviderProfileOptionsInfo } from 'screens/provider-profile/store/providerProfileActionCreators';
import { getRoles } from 'services/administrationService';
import { getProvidersTitles, getSingleProvider, getSpecialties } from 'services/providersService';
import { getLanguages, getSexOptions } from 'services/userService';

export const useProfileMyAccountLists = () => {
  const { currentUser } = useAuthContext();
  const {
    providerProfileState: { optionsInfo },
    dispatch,
  } = useProviderProfileContext();

  const fetchAllOptions = useCallback(async () => {
    const [currentProvider, genderOptions, languages, titles, specialties, roles] =
      await Promise.all([
        getSingleProvider(currentUser.provider_id),
        getSexOptions(),
        getLanguages(),
        getProvidersTitles(),
        getSpecialties(),
        getRoles(),
      ]);

    dispatch(
      setProviderProfileOptionsInfo({
        currentProvider,
        genderOptions,
        languages,
        roles,
        specialties: specialties.results,
        titles: titles.results,
      })
    );
  }, [currentUser?.provider_id, dispatch]);

  useEffect(() => {
    if (!optionsInfo.currentProvider && currentUser) {
      fetchAllOptions();
    }
  }, [currentUser, fetchAllOptions, optionsInfo.currentProvider]);
};
