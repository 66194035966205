import moment from 'moment';

const calculateAndFormatPeriod = (adherenceStartDate) => {
  const startDate = adherenceStartDate ? moment(adherenceStartDate, 'YYYY-MM-DD') : moment();
  const endDate = startDate.clone().add(30, 'days');

  return startDate.month() !== endDate.month()
    ? `${startDate.format('MMM')} - ${endDate.format('MMM')}`
    : endDate.format('MMM');
};

export default calculateAndFormatPeriod;
