import { phoneNumberRegex, zipCodeRegex } from 'util/regexs';
import * as yup from 'yup';

const REQUIRED_MESSAGE = 'Required';
const AT_LEAST_ONE_MESSAGE = 'Pick at least one';

const ProviderDetailsValidationSchema = yup.object().shape({
  identification: yup.object().shape({
    firstName: yup.string().required(REQUIRED_MESSAGE),
    lastName: yup.string().required(REQUIRED_MESSAGE),
    middleName: yup.string(),
    dateOfBirth: yup
      .object()
      .nullable()
      .shape({
        day: yup.number(),
        month: yup.number(),
        year: yup.number(),
      })
      .required(REQUIRED_MESSAGE),
    gender: yup.string().nullable().required(REQUIRED_MESSAGE),
    title: yup.number(),
    accessRoles: yup.array().required().min(1, AT_LEAST_ONE_MESSAGE),
    language: yup.array().required().min(1, AT_LEAST_ONE_MESSAGE),
  }),
  personalInformation: yup.object().shape({
    contactInformation: yup.object().shape({
      email: yup.string().email('Invalid email').required(REQUIRED_MESSAGE),
      phoneNumber1: yup
        .string()
        .matches(phoneNumberRegex, 'Phone number must be in the format XXX-XXX-XXXX')
        .required(REQUIRED_MESSAGE),
      phoneNumber2: yup
        .string()
        .matches(phoneNumberRegex, 'Phone number must be in the format XXX-XXX-XXXX'),
    }),
    addressInformation: yup.array().of(
      yup.object().shape({
        home: yup.string().required(REQUIRED_MESSAGE),
        apt: yup.string(),
        city: yup.string().required(REQUIRED_MESSAGE),
        state: yup.string().required(REQUIRED_MESSAGE),
        zipCode: yup.string().matches(zipCodeRegex, 'Invalid zip code').required(REQUIRED_MESSAGE),
        id: yup.number(),
      })
    ),
  }),
  specialties: yup.array().of(yup.number()),
  // .min(1, AT_LEAST_ONE_MESSAGE)
  // .required(),
});

export default ProviderDetailsValidationSchema;
