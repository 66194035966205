import { LabeledField, PhoneNumberFormik, TextInputFormik } from 'components/FormikComponents';
import { Formik } from 'formik';
import { FC, useState } from 'react';
import {
  CreateAccountFormikKeys,
  CreateAccountFormikValues,
  RegisterPatientCards,
} from 'screens/RegisterPatient/types';
import { CreatePatientAccountFormValidationSchema } from './utils';
import styles from '../../styles.module.css';
import { useRegisterPatientContext } from 'screens/RegisterPatient/store';
import { registerUser } from 'services/userService';
import { useDialog } from 'components/VyTracLayout/store/hooks';
import { useRegisterContext } from 'screens/Register/store';
import { ReactComponent as EyeIcon } from 'assets/icons/eye_opened.svg';
import { ReactComponent as EyeClosed } from 'assets/icons/eye_closed.svg';

const { CONFIRM_PASSWORD, PASSWORD, PHONE_NUMBER } = CreateAccountFormikKeys;

const EYE_ICON_SIZE = 16;

const CreatePatientAccountForm: FC = () => {
  const { setCurrentCard, setRegisterValues } = useRegisterPatientContext();
  const { email, hash } = useRegisterContext();
  const { errorDialog } = useDialog();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleCreateAccountSubmit = async (values: CreateAccountFormikValues) => {
    try {
      const res = await registerUser({
        password: values.password,
        phone_number: values.phoneNumber,
        email,
        hash,
      });
      if (res.status === 400) {
        const json = await res.json();
        const message = Object.entries(json).reduce((result, [key, value]) => {
          if (value?.[0] === 'This field is required.') {
            result += `${result ? ', ' : ''}${key} is required `;
          }
          return result;
        }, '');
        errorDialog('Error', message);
        return;
      }
      if (res.status > 400) {
        //TODO: check for existing username error
        errorDialog('Error', 'An error occurred when trying to verify your data: <error>');
        return;
      }
      setRegisterValues({
        password: values.password,
        phoneNumber: values.phoneNumber,
      });
      setCurrentCard(RegisterPatientCards.REGISTER_COMPLETE);
    } catch (error) {
      console.error('error?', error);
      //TODO:check for existing username and display error to resend the form
    }
  };
  return (
    <Formik<CreateAccountFormikValues>
      initialValues={{
        phoneNumber: '',
        password: '',
        confirmPassword: '',
      }}
      onSubmit={handleCreateAccountSubmit}
      validationSchema={CreatePatientAccountFormValidationSchema}
      validateOnChange
    >
      {({ handleSubmit }) => {
        return (
          <div className="p-3">
            <div className="d-flex flex-column gap">
              <div>
                <LabeledField label="Phone number" name={PHONE_NUMBER}>
                  <PhoneNumberFormik name={PHONE_NUMBER} placeholder="Phone number" />
                </LabeledField>
              </div>
              <div>
                <LabeledField label="Create a password" name={PASSWORD}>
                  <TextInputFormik
                    type={showPassword ? 'text' : 'password'}
                    name={PASSWORD}
                    placeholder="Password"
                    inputContainerClassName="d-flex justify-content-between vytrac-input rounded px-2"
                  >
                    <button
                      className="unstyled-button cursor-pointer d-flex align-items-center"
                      type="button"
                      onClick={() => setShowPassword((sp) => !sp)}
                    >
                      {showPassword ? (
                        <EyeClosed
                          width={EYE_ICON_SIZE.toString()}
                          height={EYE_ICON_SIZE.toString()}
                          className="mt-1"
                        />
                      ) : (
                        <EyeIcon
                          width={EYE_ICON_SIZE.toString()}
                          height={EYE_ICON_SIZE.toString()}
                        />
                      )}
                    </button>
                  </TextInputFormik>
                </LabeledField>
              </div>
              <div>
                <LabeledField label="Confirm your password" name={CONFIRM_PASSWORD}>
                  <TextInputFormik
                    name={CONFIRM_PASSWORD}
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm password"
                    inputContainerClassName="d-flex justify-content-between vytrac-input rounded px-2"
                  >
                    <button
                      className="unstyled-button cursor-pointer d-flex align-items-center"
                      type="button"
                      onClick={() => setShowConfirmPassword((sp) => !sp)}
                    >
                      {showConfirmPassword ? (
                        <EyeClosed
                          width={EYE_ICON_SIZE.toString()}
                          height={EYE_ICON_SIZE.toString()}
                          className="mt-1"
                        />
                      ) : (
                        <EyeIcon
                          width={EYE_ICON_SIZE.toString()}
                          height={EYE_ICON_SIZE.toString()}
                        />
                      )}
                    </button>
                  </TextInputFormik>
                </LabeledField>
              </div>
            </div>
            <div className="d-flex w-100 justify-content-center mt-2">
              <button
                className={`${styles['create-account-button']} rounded p-2 w-100 font-size-large font-weight-lg cursor-pointer`}
                type="button"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
export default CreatePatientAccountForm;
