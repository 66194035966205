import CarePlanActionModal from 'components/RPM/ActionModal';
import RpmActionButton from 'components/RPM/RpmActionButton';
import { ActionEventTypeLabel } from 'components/RPM/utils/eventTypeMapper';
import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { usePatientFormikContext } from 'screens/Patients/store';
import {
  setCarePlanModalEventType,
  setPatientActions,
  setPreviousPatientActions,
  setSelectedAction,
} from 'screens/Patients/store/PatientCarePlan/patientCarePlanActionCreators';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { createPatientAction } from 'services/patientService';
import { createTrackingLog } from 'services/trackingService';
import { IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import NullActivationDateModal from '../../../../../../../NullActivationDateModal';
import { initialAction } from '../../../../../../util/initialAction';
import { addTrackingLog } from '../../util/TrackingLogs';

interface ICreateAction {
  onCancel: () => void;
}
const CreateAction = ({ onCancel }: ICreateAction) => {
  const { selectedPatient } = usePatientFormikContext();
  const {
    dispatch,
    patientState: {
      patientCarePlan: {
        selectedDate,
        selectedAction,
        patientActions,
        carePlanModalEventType,
        carePlanState,
      },
      patientTracking: { selectedActivity },
    },
  } = usePatientContext();
  const [showNullActivationDateModal, setShowNullActivationDateModal] = useState<boolean>(false);

  const onAddCustomAction = () => {
    if (carePlanState.activation_date === null) {
      setShowNullActivationDateModal(true);
      return;
    }

    dispatch(setPreviousPatientActions());
    dispatch(
      setSelectedAction({
        ...initialAction,
        start_date: selectedDate.toISOString(),
        end_date: selectedDate.toISOString(),
      } as IPatientTimelineAction)
    );
    dispatch(setCarePlanModalEventType(null));
  };

  const onCreateActionConfirm = async () => {
    const newAction = await createPatientAction(selectedPatient.id, { ...selectedAction });

    let updatedPatientActions = [...patientActions];
    const actionIdx = updatedPatientActions.findIndex((action) => action.id === selectedAction.id);
    if (actionIdx === -1) {
      updatedPatientActions = [...updatedPatientActions, newAction];
    } else {
      updatedPatientActions[actionIdx] = { ...newAction };
    }

    dispatch(setPatientActions(updatedPatientActions));
    dispatch(setSelectedAction(null));
    createTrackingLog({
      ...addTrackingLog,
      object_id: newAction.id,
      patient: selectedPatient.id,
      activity: selectedActivity.id,
    });
  };

  return (
    <div className="container-with-padding">
      <div className="font-size-big mb-3">Commonly used care plan actions</div>
      <Droppable droppableId="calendar-control">
        {(provided) => (
          <div
            className="d-flex flex-wrap mb-4 justify-content-between"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {Object.values(ActionEventTypeLabel).map((carePlanAction, index) => (
              <RpmActionButton
                text={carePlanAction}
                id={carePlanAction}
                key={carePlanAction}
                index={index}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <button id="add-rpm-action-btn" onClick={onAddCustomAction}>
        add custom action
      </button>
      <NullActivationDateModal
        show={showNullActivationDateModal}
        onConfirm={() => setShowNullActivationDateModal(false)}
        message="To create an action there needs to be a valid activation date"
      />
      <CarePlanActionModal
        show={typeof selectedAction?.id !== 'number'}
        onClose={onCancel}
        onCancel={onCancel}
        onConfirm={onCreateActionConfirm}
        eventType={carePlanModalEventType}
        modalData={selectedAction}
        setModalData={(updatedSelectedAction) => dispatch(setSelectedAction(updatedSelectedAction))}
        patientId={selectedPatient.id}
      />
    </div>
  );
};
export default CreateAction;
