import { getIn } from 'formik';
import { setToken } from 'services/tokenService';
import { login } from 'services/userService';
import { LoginFormValues, loginViews } from 'types/Login';
import { emailRegex, phoneNumberRegex, usernameRegex } from 'util/regexs';
import routes from 'util/routes';

export const predefinedOption = 'User predefined';

export function getStyles(errors, fieldName) {
  if (getIn(errors, fieldName)) {
    return {
      borderBottom: '1px solid red',
      color: 'red',
    };
  }
}

export async function onSubmit(
  values: LoginFormValues,
  setView,
  history,
  setLoginValues,
  setRequestErrorMsg?: (msg: string) => void
) {
  const email = values.email;
  const password = values['password'];
  const code = values['code'];
  const timezone = values.timezone === predefinedOption ? undefined : values.timezone;
  let data;
  try {
    data = await login({ email, password, code, timezone });
    if (!data.error) {
      if (data.access) {
        setToken(data, values.rememberMe);
        history.push(routes[0].path);
      } else {
        setLoginValues({ ...values, emailToMessage: data.message });
        setView(loginViews.token);
      }
    } else {
      setRequestErrorMsg?.(data.message);
    }
  } catch (e) {
    console.error(e);
    return null;
  }
}
