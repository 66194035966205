import { FC, MouseEventHandler } from 'react';
import { CarePlanActionType } from 'types/ApiModels/CarePlan/ActionType';
import DeviceMeasureIconLabel from './device-measure-icon-label';
import styles from './device-table-cells.module.css';

interface DeviceMeasuresCellProps {
  types: CarePlanActionType[];
  handleHover: MouseEventHandler<HTMLParagraphElement>;
  handleHoverOut: MouseEventHandler<HTMLParagraphElement>;
  showDetail: boolean;
}

const DeviceMeasuresCell: FC<DeviceMeasuresCellProps> = ({
  types,
  handleHover,
  handleHoverOut,
  showDetail,
}: DeviceMeasuresCellProps) => {
  return types?.length > 1 ? (
    <div className="d-flex">
      <div className="position-relative">
        <p className="m-0 p-0 link" onMouseOver={handleHover} onMouseOut={handleHoverOut}>
          Multiple
        </p>
        {showDetail ? (
          <div
            className={`position-absolute d=flex gap-sm rounded p-3 card-shadow ${styles['detail-container']}`}
          >
            {types.map((t) => (
              <DeviceMeasureIconLabel type={t} key={t} />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <DeviceMeasureIconLabel type={types?.[0]} />
  );
};
export default DeviceMeasuresCell;
