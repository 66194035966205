import { PhoneNumberFormik } from 'components/FormikComponents';
import { ErrorMessage, useFormikContext } from 'formik';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import { getStyles } from '../../../utils/getStyles';
import DropdownMenu from 'components/DropdownMenu';
import styles from '../../styles.module.css';
import { useMemo } from 'react';

const ContactInformation = () => {
  const { errors, touched } = useFormikContext<InvitePatient>();
  const openError = useMemo(() => !!errors.contactInformation, [errors]);

  return (
    <DropdownMenu name="Contact information" open={openError}>
      <div className="d-flex flex-column gap">
        <div className={styles['row']}>
          <div className="d-flex gap px-3 pt-3 mb-2">
            <div className={`d-flex ${styles['form-label']} font-size-medium`}>Primary phone</div>
            <div className={`flex-grow-1 d-flex flex-column gap`}>
              <PhoneNumberFormik
                className="w-100"
                name={`contactInformation.primaryPhone`}
                style={getStyles(errors, touched, `contactInformation.primaryPhone`)}
              />
              <ErrorMessage name={`contactInformation.primaryPhone`}>
                {(msg) => <div className="text-danger font-size-small">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="d-flex gap px-3 mb-2 text-nowrap">
              <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                Secondary phone
              </div>
              <div className={`flex-grow-1 d-flex flex-column gap`}>
                <PhoneNumberFormik
                  className="w-100"
                  name={`contactInformation.secondaryPhone`}
                  style={getStyles(errors, touched, `contactInformation.secondaryPhone`)}
                />
                <ErrorMessage name={`contactInformation.secondaryPhone`}>
                  {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DropdownMenu>
  );
};
export default ContactInformation;
