enum DemographicFormikKeys {
  ETHNICITY = 'ethnicity',
  GENDER = 'gender',
  LANGUAGE = 'language',
  MARITAL_STATUS = 'maritalStatus',
  RACE = 'race',
  RELIGION = 'religion',
}

export default DemographicFormikKeys;
