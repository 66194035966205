import PopulationsModal from 'components/PopulationsModal/PopulationsModal';
import { Tagitem } from 'components/Tagitem';
import React, { useState } from 'react';
import { usePatientFormikContext } from 'screens/Patients/store';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import PopulationPatient from 'types/ApiModels/Patients/PopulationPatient';
import { ITemplateCarePlanAction } from 'types/ApiModels/Templates/CarePlan';
import DeleteConfirmationModal from './components/DeleteConfirmationModal';
import usePopulationManagement from './usePopulationManagement';
import { AddIcon, DeleteIcon } from './utils/tagIcons';

const PopulationManagement = () => {
  const { selectedPatient } = usePatientFormikContext();

  const [showPopulationsModal, setShowPopulationsModal] = useState<boolean>(false);
  const [selectedPopulation, setSelectedPopulation] = useState<PopulationPatient>(null);

  const {
    patientState: {
      patientCarePlan: { patientActions },
    },
  } = usePatientContext();

  const { importActions, addPopulations, deletePopulation } = usePopulationManagement();

  const onPopulationModalConfirm = (actions: ITemplateCarePlanAction[]) => {
    console.log(actions);
    importActions(actions);
    addPopulations(actions);
    setShowPopulationsModal(false);
  };

  const onPopulationDelete = (populationId: number) => {
    deletePopulation(populationId);
  };

  return (
    <div className="container-with-padding">
      <p className="font-size-big mb-3">Care plan template used</p>
      <div className="d-flex flex-wrap">
        {selectedPatient.populations?.map((population) => (
          <Tagitem
            text={population.name}
            className="tag-item font-size-medium cursor-pointer"
            icon={<DeleteIcon onClick={() => setSelectedPopulation(population)} />}
            key={`tag-item-${population.id}`}
          />
        ))}
        <Tagitem
          text="Add"
          className="tag-item font-size-medium cursor-pointer"
          icon={<AddIcon />}
          onClick={() => setShowPopulationsModal(true)}
        />
      </div>
      <PopulationsModal
        onClose={() => setShowPopulationsModal(false)}
        onConfirm={onPopulationModalConfirm}
        show={showPopulationsModal}
        patientPopulations={selectedPatient.populations.map((population) => population.id)}
        patientActions={patientActions}
      />
      <DeleteConfirmationModal
        selectedPopulation={selectedPopulation}
        onClose={() => setSelectedPopulation(null)}
        onDelete={onPopulationDelete}
      />
    </div>
  );
};

export default PopulationManagement;
