import React from 'react';
import { Table } from 'react-bootstrap';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import PatientRow from './components/Row';
import styles from './styles.module.css';

interface IPatientPreviewTable {
  patientList?: PatientGet[];
  hideTHead?: boolean;
  onPatientClick?: (patient: PatientGet) => void;
  isLoading?: boolean;
}

const PatientPreviewTable = ({
  patientList = [],
  hideTHead = false,
  onPatientClick = () => null,
  isLoading = false,
}: IPatientPreviewTable) => {
  return (
    <Table className={`mb-0 ${styles.main}`} responsive borderless>
      {!hideTHead && (
        <thead>
          <tr>
            <th>score</th>
            <th>patient</th>
            <th>patient id</th>
            <th>date of birth</th>
            <th>alerts</th>
            <th>adherence</th>
            <th>population</th>
            <th>time spent</th>
            <th>notes</th>
            <th>satisfaction</th>
            <th>primary</th>
            <th>others</th>
          </tr>
        </thead>
      )}
      <tbody style={{ opacity: isLoading && '50%' }}>
        {patientList.map((patient, index) => (
          <PatientRow key={index} patient={patient} onPatientClick={onPatientClick} />
        ))}
      </tbody>
    </Table>
  );
};

export default PatientPreviewTable;
