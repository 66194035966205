import DeleteIcon from 'assets/icons/delete.png';
import EditIcon from 'assets/icons/edit.png';
import Card from 'components/Card';
import GenericButton from 'components/GenericButton';
import GenericTable from 'components/GenericTable';
import ListItem from 'components/ListItem';
import { PaginationControlsAlt } from 'components/Pagination/PaginationControls';
import SatisfactionBar from 'components/SatisfactionBar';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import { RoundTagitem } from 'components/Tagitem';
import VytracSpinner from 'components/vytrac-spinner';
import { useDebouncedSearch, usePaginatedRequest } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form as BootstrapForm } from 'react-bootstrap';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useAdministrationContext, useAdministrationContextV2 } from 'screens/Administration/store';
import {
  matchProviderBooks,
  resetDeletedProviders,
  resetUpdatedProviders,
  setProvidersBook,
  setProvidersBookAndMatchTable,
  setResetProviderForm,
  setSelectedProvider,
  setSubmitProviderForm,
  toggleShowInviteProvider,
  updateProviderTableDelete,
  updateProviderTableIsActive,
} from 'screens/Administration/store/Providers';
import { deleteProvider, searchProviders, updateProvider } from 'services/providersService';
import { PaginatedResponse } from 'types/ApiModels/General';
import { Provider } from 'types/ApiModels/Providers/Provider';
import GenericColumn from 'types/Shared/GenericColumn';
import { AdminProvidersActions } from '../AdminProviders';
import InviteProviderModal from './InviteProviderModal';
import styles from './styles.module.css';
import TableHeader from './TableHeader';
import { mapProviderToProviderRow, mapProviderToUpdateProvider, ProviderRow } from './utils';

const ProvidersTable = () => {
  const { setActions } = useAdministrationContext();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    administrationStateV2: {
      providers: {
        providersBook,
        //need to keep a copy of providers in the table since we want to allow the user to roll back in case they don't want to apply the changes
        providersBookFromTable,
        updatedIsActiveProviders,
        deletedProviders,
        showInviteProvider,
      },
    },
    dispatch,
  } = useAdministrationContextV2();

  const history = useHistory();

  const { currentPage, book, first, last, next, pages, previous } = usePaginatedRequest<Provider>(
    'providers/',
    10,
    providersBook
  );

  const resetProvidersForm = useCallback(() => {
    //save reset book (local book will be synced by useEffect)
    dispatch(matchProviderBooks());
    dispatch(resetUpdatedProviders());
    dispatch(resetDeletedProviders());
  }, [dispatch]);

  useEffect(() => {
    setActions(AdminProvidersActions);
  }, []);

  useEffect(() => {
    dispatch(
      setSubmitProviderForm(() => {
        setShowConfirmModal(true);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(setResetProviderForm(resetProvidersForm));
  }, [dispatch, resetProvidersForm]);

  useEffect(() => {
    const bookFromTableValues = Object.values(providersBookFromTable);
    const bookValues = Object.values(providersBook ?? {});
    if (providersBook && !bookFromTableValues.length && bookValues.length) {
      dispatch(matchProviderBooks());
    }
  }, [dispatch, providersBook, providersBookFromTable]);

  const onCloseConfirm = () => {
    dispatch(toggleShowInviteProvider());
  };

  useEffect(() => {
    dispatch(setProvidersBookAndMatchTable(book));
  }, [book, dispatch]);

  const handleActiveChange = (providerId) => () => {
    dispatch(updateProviderTableIsActive(providerId, currentPage));
  };

  const providerBookCurrentPage = useMemo(() => {
    return providersBookFromTable?.[currentPage];
  }, [currentPage, providersBookFromTable]);

  const providerRows = useMemo(
    () => providerBookCurrentPage?.results?.map(mapProviderToProviderRow) ?? [],
    [providerBookCurrentPage?.results]
  );

  const { url } = useRouteMatch();

  const handleDelete = (providerId: number) => () => {
    dispatch(updateProviderTableDelete(providerId, currentPage));
  };

  const findProviderInBook = (
    providerId: number,
    book: Record<number, PaginatedResponse<Provider>>
  ) => {
    for (const page of Object.values(book)) {
      const found = page.results.find((p) => p.id === providerId);
      if (found) return { ...found };
    }
    return null;
  };

  const updateProviders = async () => {
    const updatedProviders = updatedIsActiveProviders.flatMap((up) => {
      const providerEntry = findProviderInBook(up.id, book);
      if (providerEntry.is_active === up.value) return [];
      return [{ ...providerEntry, is_active: up.value }];
    });
    try {
      const promises = updatedProviders.map((up) =>
        updateProvider(up.id, mapProviderToUpdateProvider(up))
      );
      await Promise.allSettled(promises);
      dispatch(resetUpdatedProviders());
    } catch (error) {
      //TODO: display global error to tell what happened
      console.error(error);
    }
  };

  const deleteProviders = async () => {
    try {
      const promises = deletedProviders.map((dp) => deleteProvider(dp));
      await Promise.allSettled(promises);
      dispatch(resetDeletedProviders());
    } catch (error) {
      //TODO: display global error to tell what happened
      console.error(error);
    }
  };

  const handleSubmitProvidersForm = async () => {
    updatedIsActiveProviders.length && (await updateProviders());
    deletedProviders.length && (await deleteProviders());
    dispatch(setProvidersBook(providersBookFromTable));
    setShowConfirmModal(false);
  };

  //search
  const {
    search,
    handleSearch,
    mappedSearchResult: providerRowsSearchResult,
    bareSearchResult,
    isSearching,
  } = useDebouncedSearch<Provider, ProviderRow>(searchProviders, mapProviderToProviderRow);

  const columns: GenericColumn[] = [
    {
      title: 'ID',
      key: 'id',
      hidden: true,
    },
    {
      title: 'Provider',
      key: 'provider',
      render: ({ provider, id }) => (
        <Link to={`${url}/${id}`}>
          <ListItem
            className={styles.provider}
            user={provider}
            onClick={() => {
              const clickedProvider = search
                ? bareSearchResult.find((p) => p.id === id)
                : providersBookFromTable[currentPage].results.find((p) => p.id === id);
              dispatch(setSelectedProvider({ ...clickedProvider }));
            }}
          />
        </Link>
      ),
    },
    {
      title: 'Patients',
      key: 'patients',
    },
    {
      title: 'Follow-ups',
      key: 'follow-ups',
    },
    {
      title: 'Open cases',
      key: 'open-cases',
    },
    {
      title: 'Completed',
      key: 'completed',
    },
    {
      title: 'Satisfaction',
      key: 'satisfaction',
      render: (row: any) => <SatisfactionBar value={row.satisfaction} />,
    },
    {
      title: 'Title',
      key: 'title',
    },
    {
      title: 'Roles',
      key: 'roles',
    },
    {
      title: 'Speciality',
      key: 'speciality',
      render: (row) => (
        <div className="d-flex">
          {row.speciality?.map((tag, index) => (
            <div key={index} className="d-flex">
              <RoundTagitem text={tag} containerClassNameList={['border', 'px-3 py-0']} />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Status',
      key: 'status',
    },
    {
      title: 'Action',
      key: 'action',
      render: (row) => (
        <div className="d-flex">
          <GenericButton
            onClick={() => history.push(`/administration/providers/all/${row.id}`)}
            icon={EditIcon}
            alt="edit"
            className="mr-3"
          />
          <GenericButton icon={DeleteIcon} alt="delete" onClick={handleDelete(row.id)} />
        </div>
      ),
    },
    {
      title: 'Active',
      key: 'active',
      render: (row: ProviderRow) => {
        return (
          <div onClick={handleActiveChange(row.id)}>
            <BootstrapForm.Check type="switch" checked={row.active} readOnly />
          </div>
        );
      },
    },
  ];

  return (
    <Card
      className="card-bg-border"
      headers={[
        <TableHeader
          providerCount={providerBookCurrentPage?.count}
          key={'header'}
          search={search}
          handleSearch={handleSearch}
          onFiltersClick={() => undefined}
        />,
      ]}
    >
      {!search ? (
        <>
          <GenericTable columns={columns} data={providerRows} />
          <div className="d-flex justify-content-end">
            <PaginationControlsAlt
              currentPage={currentPage}
              handleFirst={first}
              handleLast={last}
              handleNext={next}
              handlePrevious={previous}
              maxPage={pages}
            />
          </div>
        </>
      ) : isSearching ? (
        <div className="d-flex justify-content-center align-items-center">
          <VytracSpinner />
        </div>
      ) : (
        <GenericTable columns={columns} data={providerRowsSearchResult} />
      )}

      <SaveChangesConfirmationModal
        onCancel={() => setShowConfirmModal(false)}
        show={showConfirmModal}
        onConfirm={handleSubmitProvidersForm}
      />
      <InviteProviderModal
        show={showInviteProvider}
        onClose={onCloseConfirm}
        onConfirm={onCloseConfirm}
      />
    </Card>
  );
};
export default ProvidersTable;
