import PopulationsModal from 'components/PopulationsModal/PopulationsModal';
import { Form, Formik, FormikContextType } from 'formik';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import StepHeader from './StepHeader';
import { Step1, Step2, Step3, StepFooter } from './Steps';
import initialValues from './utils/initialValues';
import { onSubmit } from './utils/submitForm';
import { InvitePatientValidationSchema } from './utils/validationSchema';

interface InvitePatientFormProps {
  onConfirm: () => void;
  onCancel: () => void;
  setShowPopulationsModal: Dispatch<SetStateAction<boolean>>;
  setPreviousPopulations: Dispatch<SetStateAction<number[]>>;
  previousPopulations: number[];
  showPopulationsModal: boolean;
  formRef: React.MutableRefObject<FormikContextType<InvitePatient>>;
}

export const InvitePatientForm = ({
  onCancel,
  onConfirm,
  setShowPopulationsModal,
  formRef,
}: InvitePatientFormProps) => {
  const [step, setStep] = useState(0);

  const [formError, setFormError] = useState<string>(null);

  const nextStep = useCallback(() => setStep(step + 1), [setStep, step]);
  const prevStep = useCallback(() => setStep(step - 1), [setStep, step]);

  const setModalFormErrors = useCallback((errors: string) => setFormError(errors), []);
  const clearModalFormErrors = useCallback(() => setFormError(null), []);

  useEffect(() => {
    return () => clearModalFormErrors();
  }, [formRef.current?.values]);

  const handleSubmit = useCallback(
    async (values: InvitePatient) => {
      if (step === 2) {
        try {
          await onSubmit(values);
          onConfirm();
        } catch (e) {
          let stringErrors = '';
          Object.keys(e.response.data).forEach((key) => {
            if (e.response.headers['content-type'] === 'application/json') {
              if (Array.isArray(e.response.data[key])) {
                stringErrors += e.response.data[key][0].toLowerCase() + ', ';
              } else {
              }
            }
          });
          stringErrors = stringErrors.substring(0, stringErrors.length - 2) + '.';
          setModalFormErrors('There were errors inviting the patient: ' + stringErrors);
          return;
        }
        return;
      }
      nextStep();
    },
    [step, nextStep]
  );

  const FormStep = useCallback(() => {
    switch (step) {
      case 0:
        return <Step1 />;
      case 1:
        return <Step2 />;
      case 2:
        return <Step3 />;
      default:
        return null;
    }
  }, [step, setShowPopulationsModal]);

  const FormStepFooter = useCallback(() => {
    switch (step) {
      case 0:
        return <StepFooter onCancel={onCancel} confirmLabel="next" cancelLabel="cancel" />;
      case 1:
        return <StepFooter onCancel={prevStep} confirmLabel="next" cancelLabel="back" />;
      case 2:
        return <StepFooter onCancel={prevStep} confirmLabel="Send invitation" cancelLabel="back" />;
      default:
        return null;
    }
  }, [step, nextStep, prevStep, onCancel]);

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      validationSchema={InvitePatientValidationSchema[step]}
      onSubmit={handleSubmit}
    >
      <Form>
        <StepHeader step={step} />
        <FormStep />
        <div className="d-flex pt-2 pl-2">
          {formError ? <div className="text-danger font-size-small">{formError}</div> : null}
        </div>
        <FormStepFooter />
      </Form>
    </Formik>
  );
};
