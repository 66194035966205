import axios from 'axios';
import { clearTokens, getAccessToken, getRefreshToken, setToken } from 'services/tokenService';
import { host, usersPath } from 'util/apiUtils';

export const vytracAxios = axios.create({
  baseURL: `${host}/api/`,
});

export const authUserAxios = axios.create({
  baseURL: `${host}/api/users/`,
});

const requestInterceptorConfig = (config) => {
  const token = getAccessToken().token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};
const requestInterceptorReject = (error) => {
  return Promise.reject(error);
};
const responseInterceptorConfig = (response) => {
  return response;
};
const responseInterceptorReject = (error) => {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const { rememberMe } = getAccessToken();
    axios
      .post(`${host}${usersPath}/token/refresh/`, {
        refresh: getRefreshToken(),
      })
      .then((res) => {
        if (res.status === 200) {
          setToken(res.data, rememberMe);
          originalRequest.headers.Authorization = `Bearer ${res.data.access_token}`;
          return axios(originalRequest);
        }
      })
      .catch(() => {
        clearTokens();
        return Promise.reject(error);
      });
  } else {
    return Promise.reject(error);
  }
};

vytracAxios.interceptors.request.use(requestInterceptorConfig, requestInterceptorReject);
vytracAxios.interceptors.response.use(responseInterceptorConfig, responseInterceptorReject);

authUserAxios.interceptors.request.use(requestInterceptorConfig, requestInterceptorReject);
authUserAxios.interceptors.response.use(responseInterceptorConfig, responseInterceptorReject);
