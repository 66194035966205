import { checkEmail } from 'services/userService';
import { PHONE_NUMBER_VALIDATION_MESSAGE, REQUIRED_MESSAGE } from 'util/formikUtils';
import { phoneNumberRegex } from 'util/regexs';
import { debouncePromiseValue } from 'util/utils';
import * as yup from 'yup';

const fetchedEmailsCache: Record<string, boolean> = {};

const testUserEmail = async (email) => {
  if (fetchedEmailsCache[email] !== undefined) {
    return fetchedEmailsCache[email];
  }
  const res = await checkEmail(email);
  fetchedEmailsCache[email] = res;
  return res;
};
const debouncedTestUserEmail = debouncePromiseValue<boolean>(testUserEmail, 500);

export const InvitePatientValidationSchema = [
  yup.object().shape({
    email: yup
      .string()
      .email('Enter a valid email')
      .test('duplicateEmail', 'Email already exists', async (value) => {
        if (!value) return true;
        const isValidEmail = yup.string().email().isValidSync(value);
        if (!isValidEmail) return true;
        const res = await debouncedTestUserEmail(value);
        return res;
      })
      .when('isAppUser', {
        is: (isAppUser) => isAppUser,
        then: yup.string().required(REQUIRED_MESSAGE),
        otherwise: yup.string(),
      }),
    careTeam: yup.number().min(1, REQUIRED_MESSAGE),
    firstName: yup.string().required(REQUIRED_MESSAGE),
    middleName: yup.string(),
    lastName: yup.string().required(REQUIRED_MESSAGE),
    dateOfBirth: yup.object().shape({
      day: yup.number().required(REQUIRED_MESSAGE),
      month: yup.number().required(REQUIRED_MESSAGE),
      year: yup.number().required(REQUIRED_MESSAGE),
    }),
    sexAssignedAtBirth: yup.string().required(REQUIRED_MESSAGE),
    populations: yup
      .array()
      .of(yup.number().required(REQUIRED_MESSAGE))
      .min(1, 'At least one population is required'),
    isAppUser: yup.boolean(),
  }),
  yup.object().shape({
    surgeries: yup.array().of(
      yup.object().shape({
        date: yup.object().shape({
          day: yup.number().required(REQUIRED_MESSAGE),
          month: yup.number().required(REQUIRED_MESSAGE),
          year: yup.number().required(REQUIRED_MESSAGE),
        }),
        name: yup.string().required(REQUIRED_MESSAGE),
        details: yup.string().required(REQUIRED_MESSAGE),
      })
    ),
    allergies: yup.array().of(
      yup.object().shape({
        name: yup.string().required(REQUIRED_MESSAGE),
        details: yup.string().required(REQUIRED_MESSAGE),
      })
    ),
    familyHistory: yup.array().of(
      yup.object().shape({
        relationToPatient: yup.string().required(REQUIRED_MESSAGE),
        medicalCondition: yup.string().required(REQUIRED_MESSAGE),
        details: yup.string().required(REQUIRED_MESSAGE),
      })
    ),
  }),
  yup.object().shape({
    contactInformation: yup.object().shape({
      primaryPhone: yup.string().matches(phoneNumberRegex, PHONE_NUMBER_VALIDATION_MESSAGE),
      secondaryPhone: yup.string().matches(phoneNumberRegex, PHONE_NUMBER_VALIDATION_MESSAGE),
    }),
    address: yup.array().of(
      yup.object().shape({
        home: yup.string().required(REQUIRED_MESSAGE),
        apt: yup.string(),
        city: yup.string().required(REQUIRED_MESSAGE),
        state: yup.string().required(REQUIRED_MESSAGE),
        zip_code: yup.string().required(REQUIRED_MESSAGE),
      })
    ),
    demographicInfo: yup.object().shape({
      genderIdentity: yup.string(),
      maritalStatus: yup.string(),
      race: yup.string(),
      ethnicity: yup.string(),
      language: yup.string(),
      religion: yup.string(),
    }),
    emergencyContacts: yup.array().of(
      yup.object().shape({
        firstName: yup.string().required(REQUIRED_MESSAGE),
        lastName: yup.string().required(REQUIRED_MESSAGE),
        relationship: yup.string().required(REQUIRED_MESSAGE),
        contactEmail: yup.string().email('Enter a valid email').required(REQUIRED_MESSAGE),
        contactPhone: yup
          .string()
          .matches(phoneNumberRegex, PHONE_NUMBER_VALIDATION_MESSAGE)
          .required(REQUIRED_MESSAGE),
        secondaryPhone: yup.string().matches(phoneNumberRegex, PHONE_NUMBER_VALIDATION_MESSAGE),
      })
    ),
    careChampions: yup.array().of(
      yup.object().shape({
        fullName: yup.string().required(REQUIRED_MESSAGE),
        personalPhoneNumber: yup
          .string()
          .matches(phoneNumberRegex, PHONE_NUMBER_VALIDATION_MESSAGE)
          .required(REQUIRED_MESSAGE),
        secondaryPhoneNumber: yup
          .string()
          .matches(phoneNumberRegex, PHONE_NUMBER_VALIDATION_MESSAGE)
          .required(REQUIRED_MESSAGE),
        email: yup.string().email('Enter a valid email').required(REQUIRED_MESSAGE),
        relationship: yup.string().required(REQUIRED_MESSAGE),
      })
    ),
    insurance: yup.array().of(
      yup.object().shape({
        insuranceId: yup.number().required(REQUIRED_MESSAGE),
        subscriberNumber: yup.string().required(REQUIRED_MESSAGE),
        groupNumber: yup.string().required(REQUIRED_MESSAGE),
      })
    ),
    healthScoring: yup.object().shape({
      yellowAlert: yup.number(),
      redAlert: yup.number(),
    }),
  }),
];
