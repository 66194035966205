import Button from 'components/Button';
import VyTracModal from 'components/Modal/VytracModal';
import React, { useState } from 'react';
import { usePatientFormikContext } from 'screens/Patients/store';
import { enrollPatient } from 'services/patientService';
import styles from './styles.module.css';
import EmergencyButton from '../../../EmergencyButton';

const Actions = () => {
  // Context
  const { selectedPatient, setSelectedPatient } = usePatientFormikContext();
  const [showEnrollModal, setShowEnrollModal] = useState<boolean>(false);

  const AbstractButton = (props) => <Button {...props} className="mx-1" />;

  const onEnrollPatient = async () => {
    await enrollPatient(selectedPatient.id);
    setSelectedPatient({ ...selectedPatient, is_active: true });
    setShowEnrollModal(false);
  };

  return (
    <div className="d-flex">
      {!selectedPatient.has_mobile_app && (
        <AbstractButton
          label="enroll patient"
          type="submit"
          disabled={selectedPatient.is_active}
          onClick={() => setShowEnrollModal(true)}
        />
      )}
      <EmergencyButton />

      <VyTracModal
        title="Enroll Patient"
        body={`Are you sure you wish to enroll patient ${selectedPatient.user.first_name} ${selectedPatient.user.last_name}?`}
        footer={
          <div className="d-flex w-100 justify-content-between">
            <AbstractButton
              label="cancel"
              variant="delete"
              onClick={() => setShowEnrollModal(false)}
            />
            <AbstractButton label="enroll" variant="confirm" onClick={onEnrollPatient} />
          </div>
        }
        bodyClassName={styles.modalBody}
        onClose={() => setShowEnrollModal(false)}
        show={showEnrollModal}
        centered
      />
    </div>
  );
};

export default Actions;
