import React from 'react';
import Adherence from 'types/ApiModels/Patients/Adherence';
import calculateAndFormatPeriod from '../../util/calculateAndFormatPeriod';

interface IAdherenceCount {
  adherence: Adherence;
}
const AdherenceCount = ({ adherence }: IAdherenceCount) => {
  const color = adherence.adherence_days?.length > 15 ? 'green' : 'red';
  return (
    <div className="font-size-big d-flex justify-content-between mb-3">
      <span>
        30 day adherence (
        <span style={{ color: color }}>{adherence.adherence_days.length} of 30</span>)
      </span>
      <span>{calculateAndFormatPeriod(adherence.care_plan_from)}</span>
    </div>
  );
};
export default AdherenceCount;
