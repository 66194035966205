import CardWithLinkButton from 'components/CardWithLinkButton';
import { useState } from 'react';
import rulesIcon from 'assets/icons/rules-icon.svg';
import styles from '../AdminDashboard.module.css';

const NewRulesAdded = () => {
  const [newRules, setNewRules] = useState([]);
  return (
    <CardWithLinkButton
      bodyClassName={`d-flex flex-column ${styles['dashboard-card']}`}
      header={{
        linkLabel: 'Open',
        text: 'New rules added',
      }}
    >
      {!newRules?.length ? (
        <div className="d-flex flex-column gap-md justify-content-center align-items-center w-100 flex-grow-1">
          <img src={rulesIcon} alt="no new provider sign-ups" />
          <div>There are no new rules created</div>
        </div>
      ) : null}
    </CardWithLinkButton>
  );
};
export default NewRulesAdded;
