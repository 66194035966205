import Button from 'components/Button';
import VyTracModal from 'components/Modal';
import { SearchBarAlt } from 'components/SearchBar';
import { FC, useState } from 'react';
import Specialty from 'types/ApiModels/Providers/Specialty';
import styles from './styles.module.css';

interface SpecialtiesModalProps {
  specialties: Specialty[];
  assignedSpecialtiesIds: number[];
  handleClose: () => void;
  handleConfirm: (specialtiesIds: number[]) => void;
  show: boolean;
}

const SpecialtiesModal: FC<SpecialtiesModalProps> = ({
  specialties,
  assignedSpecialtiesIds,
  handleClose,
  handleConfirm,
  show,
}: SpecialtiesModalProps) => {
  const [currentlySelected, setCurrentlySelected] = useState(assignedSpecialtiesIds);

  const handleSpecialtySelect = (specialtyId: number) => {
    setCurrentlySelected((sids) => {
      const existingIdx = sids.findIndex((id) => id === specialtyId);
      return existingIdx === -1 ? [...sids, specialtyId] : sids.filter((id) => id !== specialtyId);
    });
  };

  const [search, setSearch] = useState('');

  return (
    <VyTracModal
      show={show}
      title="Assign a specialty"
      onClose={handleClose}
      body={
        <div className="d-flex flex-column gap">
          <SearchBarAlt onChange={(e) => setSearch(e.target.value)} value={search} />
          <div>
            {specialties?.flatMap((s) => {
              if (!s.name.toLowerCase().includes(search.toLowerCase())) return [];

              const isSelected = currentlySelected?.some((asId) => s.id === asId);
              return [
                <div
                  key={s.id}
                  className={`d-flex gap justify-content-between ${styles['specialty-container']} cursor-pointer`}
                  onClick={() => handleSpecialtySelect(s.id)}
                >
                  <div className="d-flex align-items-center">
                    <div
                      className={`${styles['custom-input']} ${isSelected ? styles.selected : ''}`}
                    ></div>
                    <input type="checkbox" hidden value={s.id} checked={isSelected} />
                  </div>
                  <div className="flex-grow-1 ">{s.name}</div>
                </div>,
              ];
            })}
          </div>
        </div>
      }
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="CANCEL" variant="cancel" onClick={handleClose} />
          <Button label="CONFIRM" onClick={() => handleConfirm(currentlySelected)} />
        </div>
      }
    />
  );
};
export default SpecialtiesModal;
