import Button from 'components/Button';
import GenericTable from 'components/GenericTable';
import VytracModal from 'components/Modal';
import { FC } from 'react';
import { PatientNotesKeywords } from 'types/ApiModels/Administration';
import GenericColumn from 'types/Shared/GenericColumn';

interface KeywordsCountModalProps {
  onClose: () => void;
  currentPatient: PatientNotesKeywords;
  show: boolean;
}

const columns: GenericColumn[] = [
  {
    key: 'keyword',
    title: 'keyword',
  },
  { key: 'count', title: 'uses' },
];

const KeywordsCountModal: FC<KeywordsCountModalProps> = ({
  onClose,
  currentPatient,
  show,
}: KeywordsCountModalProps) => {
  if (!currentPatient) return null;
  return (
    <VytracModal
      title={`${currentPatient.full_name} - Keywords used`}
      body={<GenericTable columns={columns} data={currentPatient.keywords_templates} />}
      footer={
        <footer className="w-100 d-flex justify-content-center align-items-center">
          <Button variant="confirm" label="Okay" onClick={onClose} />
        </footer>
      }
      centered
      onClose={onClose}
      show={show}
    />
  );
};
export default KeywordsCountModal;
