import { SelectFormik, TextInputFormik } from 'components/FormikComponents';
import { ErrorMessage, useFormikContext } from 'formik';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import { getStyles } from '../../../utils/getStyles';
import DropdownMenu from 'components/DropdownMenu';
import styles from '../../styles.module.css';
import { useMemo } from 'react';

const HealthScoring = () => {
  const { errors, touched } = useFormikContext<InvitePatient>();
  const openError = useMemo(() => !!errors.healthScoring, [errors]);
  const healthScoringOptions = useMemo(() => {
    return [
      { label: '1 missed call', value: 1 },
      { label: '2 missed calls', value: 2 },
      { label: '3 missed calls', value: 3 },
      { label: '4 missed calls', value: 4 },
      { label: '5 missed calls', value: 5 },
      { label: '6 missed calls', value: 6 },
      { label: '7 missed calls', value: 7 },
      { label: '8 missed calls', value: 8 },
      { label: '9 missed calls', value: 9 },
      { label: '10 missed calls', value: 10 },
    ];
  }, []);
  return (
    <DropdownMenu name="Health scoring" open={openError}>
      <div className="d-flex flex-column gap">
        <div className={styles['row']}>
          <div className="d-flex gap px-3 pt-3 mb-2">
            <div className={`d-flex ${styles['form-label']} font-size-medium`}>Yellow alert</div>
            <div className={`flex-grow-1 d-flex flex-column gap`}>
              <SelectFormik
                options={healthScoringOptions}
                name={`healthScoring.yellowAlert`}
                placeholder="Select health scoring..."
                styles={getStyles(errors, touched, `healthScoring.yellowAlert`)}
                maxMenuHeight={120}
              />
              <ErrorMessage name={`healthScoring.yellowAlert`}>
                {(msg) => <div className="text-danger font-size-small">{msg}</div>}
              </ErrorMessage>
            </div>
            <div className="d-flex gap px-3 mb-2 text-nowrap">
              <div className={`d-flex ${styles['form-label']} font-size-medium`}>Red alert</div>
              <div className={`flex-grow-1 d-flex flex-column gap`}>
                <SelectFormik
                  options={healthScoringOptions}
                  name={`healthScoring.redAlert`}
                  placeholder="Select health scoring..."
                  styles={getStyles(errors, touched, `healthScoring.redAlert`)}
                  maxMenuHeight={120}
                />
                <ErrorMessage name={`healthScoring.redAlert`}>
                  {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                </ErrorMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DropdownMenu>
  );
};
export default HealthScoring;
