export const setToken = (token: { access: string; refresh: string }, rememberMe = false) => {
  if (rememberMe) {
    localStorage.setItem('access', token.access);
    localStorage.setItem('refresh', token.refresh);
  } else {
    sessionStorage.setItem('access', token.access);
    sessionStorage.setItem('refresh', token.refresh);
  }
};

export const getAccessToken = () => {
  const localToken = localStorage.getItem('access');
  const sessionToken = sessionStorage.getItem('access');
  return { token: localToken || sessionToken, rememberMe: !!localToken };
};
export const getRefreshToken = () => {
  return localStorage.getItem('refresh') || sessionStorage.getItem('refresh');
};

export const clearTokens = () => {
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  sessionStorage.removeItem('access');
  sessionStorage.removeItem('refresh');
};
