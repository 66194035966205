import { FC, useEffect, useMemo, useState } from 'react';
import { checkInvitation } from 'services/userService';
import Invitation from 'types/ApiModels/Users/Invitation';
import { useQueryParams } from 'hooks';
import CheckingInvitation from './CheckingInvitation';
import InvalidInvitationFallback from './InvalidInvitationFallback';
import { RegisterContext } from './store';
import { InvitationStatus } from './types';
import { mapInvitationTypeToComponent } from './utils';

const Register: FC = () => {
  const [email, setEmail] = useState<string>();
  const [hash, setHash] = useState<string>();
  const [invitationStatus, setInvitationStatus] = useState(InvitationStatus.PENDING);
  const [invitation, setInvitation] = useState<Invitation>();

  const query = useQueryParams();

  useEffect(() => {
    const fetchCheckInvitation = async () => {
      //edge case: email has + symbol
      const email = query.get('email').replace(/ /g, '+');
      const hash = query.get('hash');
      if (email && hash) {
        try {
          const invitation: Invitation = await checkInvitation(email, hash);
          setEmail(email);
          setHash(hash);
          setInvitation(invitation);
          setInvitationStatus(InvitationStatus.VALID);
        } catch (e) {
          console.error(e);
          setInvitationStatus(InvitationStatus.INVALID);
        }
      } else {
        setInvitationStatus(InvitationStatus.INVALID);
      }
    };
    fetchCheckInvitation();
  }, [query]);

  const CurrentRegisterPage = useMemo(() => {
    switch (invitationStatus) {
      case InvitationStatus.PENDING:
        return CheckingInvitation;
      case InvitationStatus.INVALID:
        return InvalidInvitationFallback;
      case InvitationStatus.VALID:
        return mapInvitationTypeToComponent[invitation.type];
      default:
        break;
    }
  }, [invitation, invitationStatus]);

  return (
    <RegisterContext.Provider value={{ email, hash }}>
      {CurrentRegisterPage && <CurrentRegisterPage />}
    </RegisterContext.Provider>
  );
};
export default Register;
