import { REQUIRED_MESSAGE } from 'util/formikUtils';
import { object, string } from 'yup';

const CreateFamilyHistoryValidationSchema = object().shape({
  name: string().required(REQUIRED_MESSAGE),
  details: string().required(REQUIRED_MESSAGE),
  relationship: string().nullable().required(REQUIRED_MESSAGE),
});

export default CreateFamilyHistoryValidationSchema;
