import Button from 'components/Button';
import VytracModal from 'components/Modal';
import React from 'react';

interface INullActivationDate {
  message: string;
  show: boolean;
  onConfirm: () => void;
}
const NullActivationDateModal = ({ message, show, onConfirm }: INullActivationDate) => {
  return (
    <VytracModal
      show={show}
      centered
      title={'Care plan activation date'}
      body={<div className="font-size-big">{message}</div>}
      footer={<Button label="confirm" variant="confirm" onClick={onConfirm} />}
      onClose={onConfirm}
    />
  );
};
export default NullActivationDateModal;
