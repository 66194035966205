import { FC } from 'react';
import { ReactComponent as PlusCircleIcon } from 'assets/icons/plus_button_icon.svg';
import FiltersButton from 'components/FiltersButton';

interface DevicesCardHeaderProps {
  handleAddDevice: () => void;
  devicesCount: number;
}

const DevicesCardHeader: FC<DevicesCardHeaderProps> = ({
  handleAddDevice,
  devicesCount,
}: DevicesCardHeaderProps) => {
  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <div className="font-weight-md">Patient devices</div>
      <div className="d-flex gap align-items-center">
        <button
          className="unstyled-button d-flex align-items-center cursor-pointer"
          type="button"
          onClick={handleAddDevice}
        >
          <PlusCircleIcon width="16" height="16" />
        </button>
        <p className="m-0 p-0">{devicesCount}</p>
        <FiltersButton />
      </div>
    </div>
  );
};
export default DevicesCardHeader;
