import Button from 'components/Button';
import { LabeledField, TextInputFormik } from 'components/FormikComponents';
import DatePickerFormik from 'components/FormikComponents/date-picker-formik';
import TextAreaFormik from 'components/FormikComponents/TextAreaFormik';
import VytracModal from 'components/Modal';
import { Formik, useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import { PatientManagementFormikValues } from '../types';
import CreateSurgeryHistoryValidationSchema from './utils/CreateSurgeryHistoryValidationSchema';

interface SurgeryHistoryFormFieldValues {
  name: string;
  details: string;
  id: number;
  patient: number;
  date: DatePickerValue;
}

interface CreateSurgeryHistoryModalProps {
  onClose: () => void;
  show: boolean;
  idCreator: () => number;
  patientId: number;
  currentId: number;
  queueEdit: (id: number) => void;
}

const CreateSurgeryHistoryModal: FC<CreateSurgeryHistoryModalProps> = ({
  onClose,
  show,
  idCreator,
  patientId,
  currentId,
  queueEdit,
}: CreateSurgeryHistoryModalProps) => {
  const { setFormikState, values } = useFormikContext<PatientManagementFormikValues>();

  const handleCreate = (values: SurgeryHistoryFormFieldValues) => {
    setFormikState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        SURGERIES: [
          ...prev.values.SURGERIES,
          { ...values, date: convertDatepickToDate(values.date).toISOString() },
        ],
      },
    }));
    onClose();
  };

  const handleEdit = (values: SurgeryHistoryFormFieldValues) => {
    setFormikState((prev) => {
      const copy = [...prev.values.SURGERIES];
      const updated = copy.findIndex((a) => a.id === values.id);
      copy[updated] = { ...values, date: convertDatepickToDate(values.date).toISOString() };
      return {
        ...prev,
        values: {
          ...prev.values,
          SURGERIES: copy,
        },
      };
    });
    values.id > 0 && queueEdit(values.id);
    onClose();
  };

  const initialValues = useMemo(() => {
    const foundSurgery = currentId ? values.SURGERIES.find((s) => s.id === currentId) : null;
    return foundSurgery
      ? { ...foundSurgery, date: convertDateToDatePickValue(new Date(foundSurgery.date)) }
      : {
          name: '',
          details: '',
          id: idCreator(),
          patient: patientId,
          date: null,
        };
  }, [currentId, idCreator, patientId, values.SURGERIES]);

  return (
    <VytracModal
      title="Create surgery history entry"
      body={
        <Formik<SurgeryHistoryFormFieldValues>
          initialValues={initialValues}
          onSubmit={currentId ? handleEdit : handleCreate}
          validationSchema={CreateSurgeryHistoryValidationSchema}
        >
          {({ handleSubmit, dirty }) => {
            return (
              <div>
                <div className="d-flex flex-column">
                  <LabeledField name="name" label="Surgery name">
                    <TextInputFormik name="name" className="font-size-medium" />
                  </LabeledField>
                  <LabeledField name="date" label="Date">
                    <DatePickerFormik name="date" inputClassName="font-size-medium w-100" />
                  </LabeledField>
                  <LabeledField name="details" label="Details">
                    <TextAreaFormik name="details" className="font-size-medium" />
                  </LabeledField>
                </div>
                <hr />
                <div className="d-flex justify-content-between w-100">
                  <Button label="cancel" variant="dull" onClick={onClose} />
                  <Button label="confirm" onClick={!dirty && currentId ? onClose : handleSubmit} />
                </div>
              </div>
            );
          }}
        </Formik>
      }
      onClose={onClose}
      show={show}
      centered
    />
  );
};
export default CreateSurgeryHistoryModal;
