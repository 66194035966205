import Button from 'components/Button';
import { ControlButtonLink } from 'components/ControlButton';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAdministrationContextV2 } from 'screens/Administration/store';
import { toggleShowInviteProvider } from 'screens/Administration/store/Providers';
import AdminCareTeam from './AdminCareTeam';
import AdminSpecialty from './AdminSpecialty';
import ProviderDetails from './ProviderDetails';
import ProvidersTable from './ProvidersTable';

export const AdminProvidersActions = () => {
  const {
    administrationStateV2: {
      providers: {
        resetProvidersForm,
        submitProvidersForm,
        updatedIsActiveProviders,
        deletedProviders,
      },
    },
    dispatch,
  } = useAdministrationContextV2();

  return (
    <div className="d-flex gap-sm">
      <Button
        label="cancel"
        variant="cancel"
        disabled={updatedIsActiveProviders.length === 0 && deletedProviders.length === 0}
        onClick={() => {
          resetProvidersForm();
        }}
      />
      <Button
        label="save changes"
        variant="confirm"
        disabled={updatedIsActiveProviders.length === 0 && deletedProviders.length === 0}
        onClick={submitProvidersForm}
      />
      <Button
        label="add new provider"
        onClick={() => {
          dispatch(toggleShowInviteProvider());
        }}
      />
    </div>
  );
};

export const adminProvidersControlsList = [
  {
    label: 'Provider list',
    enumValue: 'providerList',
    route: 'all',
  },
  {
    label: 'provider specialties',
    enumValue: 'providerSpecialties',
    route: 'specialties',
  },
  {
    label: 'care team',
    enumValue: 'careTeam',
    route: 'careteam',
  },
];

const AdminProviders = () => {
  const { path, url } = useRouteMatch();

  return (
    <div className="container-with-padding d-flex gap flex-column">
      <div className="d-flex gap">
        {adminProvidersControlsList.map((apcl) => (
          <ControlButtonLink
            key={apcl.enumValue}
            to={`${url}${apcl.route ? '/' + apcl.route : ''}`}
          >
            {apcl.label}
          </ControlButtonLink>
        ))}
      </div>
      <Switch>
        <Route path={`${path}/all`} exact>
          <ProvidersTable />
        </Route>
        <Route path={`${path}/all/:providerId`}>
          <ProviderDetails />
        </Route>
        <Route path={`${path}/specialties`}>
          <AdminSpecialty />
        </Route>
        <Route path={`${path}/careteam`}>
          <AdminCareTeam />
        </Route>
      </Switch>
    </div>
  );
};

export default AdminProviders;
