import MentionItemCustom from 'types/Shared/MentionItemCustom';
import styles from './styles.module.css';

export const MessageWithMention = ({
  body,
  senderId,
}: {
  body: (MentionItemCustom | string)[];
  senderId: number;
}) => {
  return (
    <>
      {body.map((sb, idx) => {
        if (typeof sb === 'string') return <span key={idx}>{sb}</span>;
        const [type, id] = sb.id.split('-');
        return (
          <span
            className={`${styles[type]} ${
              senderId === parseInt(id) && type === 'mention' ? styles['mention-self'] : ''
            }`}
            key={sb.id}
          >
            {sb.display}
          </span>
        );
      })}
    </>
  );
};
