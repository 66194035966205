import { useAuthContext } from 'auth';
import AuthGuard from 'auth/AuthGuard';
import AuthProvider from 'auth/AuthProvider';
import { canAccess } from 'auth/can';
import { PaginationProvider } from 'components/Pagination';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Switch, withRouter } from 'react-router-dom';
import Login from 'screens/Login';
import { NotFound } from 'screens/not-found';
import Register from 'screens/Register';
import './App.scss';
import routes from './util/routes';
import WebSocketProvider from './websockets/WebSocketProvider';

const client = new QueryClient();

const RouteList = () => {
  const { currentUser } = useAuthContext();
  if (!currentUser) return <></>;
  return (
    <>
      {routes.flatMap(({ path, exact, component: Component, relatedPermission }) => {
        if (
          relatedPermission &&
          !canAccess(currentUser.permissions, { access: relatedPermission, do: 'view' })
        )
          return [];
        return [
          <Route key={path} exact={exact} path={path}>
            <Component />
          </Route>,
        ];
      })}
      <Route path="*" component={NotFound} />
    </>
  );
};

const App = () => {
  return (
    <QueryClientProvider client={client}>
      <AuthProvider>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/register" component={Register} />
          <WebSocketProvider>
            <PaginationProvider>
              <AuthGuard>
                <RouteList />
              </AuthGuard>
            </PaginationProvider>
          </WebSocketProvider>
        </Switch>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default withRouter(App);
