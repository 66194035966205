import { AxiosError } from 'axios';
import { PatientManagementFormikValues } from 'components/PatientManagementForm/types';
import { PersonalInformationAddress } from 'components/PatientManagementForm/types/PatientManagementFormikValues';
import { hasNotAllEmptyFields } from 'components/PatientManagementForm/utils';
import {
  createAllergyBulk,
  createFamilyHistoryBulk,
  createSurgeryBulk,
  deleteAllergyBulk,
  deleteFamilyHistoryBulk,
  deleteSurgeryBulk,
  updateAllergyBulk,
  updateFamilyHistoryBulk,
  updatePatient,
  updateSurgeryBulk,
} from 'services/patientService';
import Patient, { Allergy, FamilyHistory, Surgery } from 'types/ApiModels/Patients/Patient';
import PatientCreateUpdate from 'types/ApiModels/Patients/PatientCreateUpdate';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import Address from 'types/ApiModels/Users/Address';
import PatientInsurance from 'types/ApiModels/Users/PatientInsurance';
import { formatDate } from 'util/dateUtils';

const updatePatientInformation = async (
  body,
  patientId
): Promise<[patient: Patient, error: any]> => {
  try {
    const res = await updatePatient(body, patientId);
    return [res, null];
  } catch (error) {
    return [null, (error as AxiosError).response.data];
  }
};

const mapAddressFormToRequest = (a: PersonalInformationAddress): Address => ({
  apt: a.apt,
  city: a.city,
  home: a.address,
  state: a.state,
  zip_code: a.zipCode,
});

const mapValuesToPatientPut = (
  values: PatientManagementFormikValues,
  username: string
): PatientCreateUpdate => {
  const care_champions = values.CARE_CHAMPIONS?.map((cc) => ({
    id: cc.id > 0 ? cc.id : undefined,
    full_name: cc.fullName,
    phone_number: cc.personalPhoneNumber,
    secondary_phone: cc.secondaryPhoneNumber,
    email: cc.email,
    relationship: cc.relationship,
  }));
  const emergency_contact = values.EMERGENCY_CONTACTS?.flatMap((ec) => {
    if (!hasNotAllEmptyFields(ec)) return [];
    return [
      {
        id: ec.id > 0 ? ec.id : undefined,
        first_name: ec.firstName,
        last_name: ec.lastName,
        phone_number: ec.contactPhone,
        relationship: ec.relationship?.toLowerCase(),
        second_phone_number: undefined,
        email: ec.contactEmail,
      },
    ];
  });
  return {
    first_name: values.IDENTIFICATION.firstName,
    middle_name: values.IDENTIFICATION.middleName,
    care_team: undefined,
    preferred_contact_method: undefined,
    last_name: values.IDENTIFICATION.lastName,
    date_birth: new Date(values.IDENTIFICATION.dateOfBirth).toISOString(),
    sex: values.IDENTIFICATION.sex,
    phone_number1: values.PERSONAL_INFORMATION.contactInformation.primaryPhoneNumber,
    phone_number2: values.PERSONAL_INFORMATION.contactInformation.secondaryPhoneNumber,
    username,
    email: values.PERSONAL_INFORMATION.contactInformation.email,
    address: values.PERSONAL_INFORMATION.address.flatMap((a) => {
      if (!hasNotAllEmptyFields(a)) return [];
      return [mapAddressFormToRequest(a)];
    }),
    insurances: values.PERSONAL_INFORMATION.insurance.flatMap<PatientInsurance>((i, idx) => {
      if (!hasNotAllEmptyFields(i)) return [];
      return [
        {
          group_number: i.groupNumber,
          suscriber_number: i.subscriberNumber,
          is_main: idx === 0,
          id: i.id > 0 ? i.id : undefined,
          deleted: undefined,
          insurance: i.insuranceId > 0 ? i.insuranceId : undefined,
          patient: undefined,
        },
      ];
    }),
    populations: values.POPULATION,
    marital_status: values.PERSONAL_INFORMATION.demographicInfo.maritalStatus,
    race: values.PERSONAL_INFORMATION.demographicInfo.race,
    care_champions: care_champions.filter(hasNotAllEmptyFields),
    emergency_contact,
    gender_identity: values.PERSONAL_INFORMATION.demographicInfo.gender,
    ethnicity: values.PERSONAL_INFORMATION.demographicInfo.ethnicity,
    religion: values.PERSONAL_INFORMATION.demographicInfo.religion,
    languages_id: values.PERSONAL_INFORMATION.demographicInfo.language,
    missed_calls_yellow_alert: values.HEALTH_SCORING.missed_calls_yellow_alert ?? undefined,
    missed_calls_red_alert: values.HEALTH_SCORING.missed_calls_red_alert ?? undefined,
  };
};

interface SurgeryFamilyAllergyIds {
  familyHistory: number[];
  surgery: number[];
  allergy: number[];
}

const filterAndParseCreatables = (item) => {
  if (item.id > 0) return [];
  return { ...item, id: undefined, deleted: undefined };
};

const onPatientManagementSubmit = async (
  values: PatientManagementFormikValues,
  patient: PatientGetSingle,
  toDelete: SurgeryFamilyAllergyIds,
  toUpdate: SurgeryFamilyAllergyIds
): Promise<
  [
    results: { familyHistory: FamilyHistory[]; surgeries: Surgery[]; allergies: Allergy[] },
    errors: any[]
  ]
> => {
  //delete
  const familyDeleteResultPromise = deleteFamilyHistoryBulk(patient.id, toDelete.familyHistory);
  const surgeryDeleteResultPromise = deleteSurgeryBulk(patient.id, toDelete.surgery);
  const allergyDeleteResultPromise = deleteAllergyBulk(patient.id, toDelete.allergy);

  //create
  const familyHistoryCreateResultPromise = createFamilyHistoryBulk(
    patient.id,
    values.FAMILY_HISTORY.flatMap(filterAndParseCreatables)
  );
  const surgeriesCreateResultPromise = createSurgeryBulk(
    patient.id,
    values.SURGERIES.flatMap<Surgery>((s) => {
      if (s.id > 0) return [];
      return [
        {
          ...s,
          date: formatDate(new Date(s.date), 'yyyy-mm-dd'),
          id: undefined,
          deleted: undefined,
        },
      ];
    })
  );
  const allergiesCreateResultPromise = createAllergyBulk(
    patient.id,
    values.ALLERGIES.flatMap(filterAndParseCreatables)
  );
  //update
  const familyUpdateResultPromise = updateFamilyHistoryBulk(
    patient.id,
    values.FAMILY_HISTORY.filter((fh) => toUpdate.familyHistory.includes(fh.id))
  );
  const surgeryUpdateResultPromise = updateSurgeryBulk(
    patient.id,
    values.SURGERIES.flatMap((s) => {
      if (!toUpdate.surgery.includes(s.id)) return [];
      return [
        {
          ...s,
          date: formatDate(new Date(s.date), 'yyyy-mm-dd'),
        },
      ];
    })
  );
  const allergyUpdateResultPromise = updateAllergyBulk(
    patient.id,
    values.ALLERGIES.filter((a) => toUpdate.allergy.includes(a.id))
  );

  //error handling
  const mapped = mapValuesToPatientPut(values, patient.user.username);
  const [
    [, patientError],
    [, familyDeleteError],
    [, surgeryDeleteError],
    [, allergyDeleteError],
    [familyCreateResult, familyCreateError],
    [surgeryCreateResult, surgeryCreateError],
    [allergyCreateResult, allergyCreateError],
    [, familyUpdateError],
    [, surgeryUpdateError],
    [, allergyUpdateError],
  ] = await Promise.all([
    updatePatientInformation(mapped, patient.id),
    familyDeleteResultPromise,
    surgeryDeleteResultPromise,
    allergyDeleteResultPromise,
    familyHistoryCreateResultPromise,
    surgeriesCreateResultPromise,
    allergiesCreateResultPromise,
    familyUpdateResultPromise,
    surgeryUpdateResultPromise,
    allergyUpdateResultPromise,
  ]);

  return [
    {
      familyHistory: familyCreateResult,
      surgeries: surgeryCreateResult,
      allergies: allergyCreateResult,
    },
    [
      patientError,
      familyDeleteError,
      surgeryDeleteError,
      allergyDeleteError,
      familyCreateError,
      surgeryCreateError,
      allergyCreateError,
      familyUpdateError,
      surgeryUpdateError,
      allergyUpdateError,
    ],
  ];
};

export default onPatientManagementSubmit;
