import arrowIcon from 'assets/icons/arrow_down.png';
import eyeCloseIcon from 'assets/icons/eye_closed.svg';
import eyeIcon from 'assets/icons/eye_opened.svg';
import DismissableDialog from 'components/DismissableDialog';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import { getTimezone } from 'services/timezoneService';
import colors from 'styles/colors';
import { LoginFormValues, loginViews } from 'types/Login';
import { setBreadcrumTitle } from 'util/themeUtils';
import { useLoginContext } from '../store';
import { LoginValidationSchema } from '../ValidationSchemas';
import styles from './styles.module.css';
import { getStyles, onSubmit, predefinedOption } from './util';

const LoginForm = () => {
  const { changeView, setLoginValues, isResettingPassword, setIsResettingPassword } =
    useLoginContext();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [timezones, setTimezones] = useState([]);
  const [requestErrorMsg, setRequestErrorMsg] = useState('');

  useEffect(() => {
    const fetchTimezones = async () => {
      try {
        const timezonesResponse = await getTimezone();
        setTimezones([predefinedOption, ...timezonesResponse]);
      } catch (e) {
        console.error(e);
      }
    };
    fetchTimezones();
  }, []);

  function filterBy(option, state) {
    if (state.selected.length) {
      return true;
    }
    return option.toLowerCase().indexOf(state.text.toLowerCase()) > -1;
  }
  return (
    <Formik
      onSubmit={async (values: LoginFormValues) => {
        isResettingPassword && setIsResettingPassword(false);
        await onSubmit(values, changeView, history, setLoginValues, setRequestErrorMsg);
      }}
      initialValues={{
        email: '',
        password: '',
        timezone: predefinedOption,
        rememberMe: false,
      }}
      validationSchema={LoginValidationSchema}
    >
      {({ errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
        <Form
          className="h-100 w-100 m-0 d-flex flex-column justify-content-between"
          onChange={() => {
            if (requestErrorMsg) setRequestErrorMsg('');
          }}
        >
          <div>
            <div className="d-flex w-100 mb-5">
              <div className="flex-grow-1">
                <div className="green-line"></div>
              </div>
              <div className="flex-grow-1">
                <div className="grey-line"></div>
              </div>
            </div>
            <p className="text-left dynamic-font-heading mb-5">
              Welcome to <span className="bold-blue text-bolder">{setBreadcrumTitle()}</span>
            </p>
            {isResettingPassword ? (
              <DismissableDialog
                textColor={colors.greenConfirmationText}
                bgColor={colors.greenConfirmationBg}
                label="Confirmation"
                text="Password successfully reset, login with your new credentials"
              />
            ) : null}
            <p className={`grey-text dynamic-font-normal`}>Email</p>
            <Field
              onChange={handleChange}
              onBlur={handleBlur}
              className="text-field dynamic-font-normal"
              type="text"
              style={touched['email'] && getStyles(errors, 'email')}
              name="email"
            />
            <ErrorMessage name="email">
              {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
            </ErrorMessage>
            <p className={` grey-text dynamic-font-normal mt-4 mb-0`}>Password</p>
            <div>
              <Field
                onChange={handleChange}
                onBlur={handleBlur}
                style={touched['password'] && getStyles(errors, 'password')}
                className="text-field dynamic-font-normal"
                type={!showPassword ? 'password' : 'text'}
                name="password"
              />
              <img
                className="textfield-button"
                onClick={() => setShowPassword((prev) => !prev)}
                src={!showPassword ? eyeIcon : eyeCloseIcon}
                style={{ transform: 'scale(2)' }}
                alt=""
              />
              <ErrorMessage name="password">
                {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
              </ErrorMessage>
            </div>

            <div className="d-flex flex-column gap-md">
              <p className={`grey-text dynamic-font-normal mt-4 mb-0`}>Your time zone</p>
              <Typeahead
                filterBy={filterBy}
                options={timezones}
                id="timezone-field"
                onInputChange={(text) => setFieldValue('timezone', text)}
                onChange={(selected) => {
                  const value = selected.length > 0 ? selected[0] : undefined;
                  setFieldValue('timezone', value);
                }}
                defaultSelected={timezones.length > 0 ? [timezones[0]] : [predefinedOption]}
                onBlur={handleBlur}
                inputProps={{
                  style: {
                    ...(touched['timezone'] && getStyles(errors, 'timezone')),
                    boxShadow: 'none',
                    borderWidth: '0px 0px 1px 0px',
                    borderColor: '#d4e7fa',
                    borderRadius: 0,
                  },
                  className: 'text-field dynamic-font-normal',
                  name: 'timezone',
                }}
              >
                {({ isMenuShown, toggleMenu }) => (
                  <img
                    className={styles['toggle-button']}
                    src={arrowIcon}
                    style={{ transform: isMenuShown ? 'rotate(180deg)' : 'inherit' }}
                    alt=""
                    onClick={(e) => toggleMenu()}
                    onMouseDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                )}
              </Typeahead>
              <ErrorMessage name="timezone">
                {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            {requestErrorMsg ? (
              <div className="dynamic-font-normal text-danger pt-4">{requestErrorMsg}</div>
            ) : null}
          </div>

          <div>
            <div className="pl-2 m-0">
              <input
                type="checkbox"
                name="rememberMe"
                className="greycheck dynamic-font-normal"
                onChange={handleChange}
              />
              <span className="grey-text dynamic-font-normal">
                &nbsp;&nbsp;&nbsp;&nbsp;Remember me
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <Button
                className="dynamic-font-normal text-bold"
                onClick={() => changeView(loginViews.forgotPassword)}
                variant="light"
              >
                FORGOT PASSWORD
              </Button>
              <Button
                className="dynamic-font-normal text-bold"
                type="submit"
                disabled={isSubmitting}
              >
                LOGIN
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
