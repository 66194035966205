import { useFormikContext } from 'formik';
import styles from './styles.module.css';
import PatientManagementInput from 'components/PatientManagementInput';
import PatientManagementEliminateButton from 'components/PatientManagementEliminateButton';
import PatientManagementAddButton from 'components/PatientManagementAddButton';
import GenericPatientInfoTable from 'components/GenericPatientInfoTable';
import createNewAddress from '../../util/createNewAddress';
import {
  PatientManagementFormikKeys,
  PersonalInformationFormikKeys,
} from 'components/PatientManagementForm/types';

const AddressInformation = ({ formData, handleChange, errors, touched }) => {
  // Context
  const { setFormikState, setFieldTouched } = useFormikContext();

  // Functions and subroutines
  const createAddressBodyList = (formData, handleChange, errors) => {
    const handleChangeTouched = (name) => (e) => {
      setFieldTouched(name, true);
      handleChange(e);
    };
    // Functions and subroutines
    const transformAddressToArray = ({ address, apt, city, state, zipCode }, index) => {
      const deleteAddress = (deletedIndex) =>
        setFormikState(({ values, ...prevFormikState }) => ({
          values: {
            ...values,
            [PatientManagementFormikKeys.PERSONAL_INFORMATION]: {
              ...values[PatientManagementFormikKeys.PERSONAL_INFORMATION],
              [PersonalInformationFormikKeys.ADDRESS]: values[
                PatientManagementFormikKeys.PERSONAL_INFORMATION
              ][PersonalInformationFormikKeys.ADDRESS].filter((_, index) => index !== deletedIndex),
            },
          },
          ...prevFormikState,
        }));

      const addressInfoMap = { address, apt, city, state, zipCode };

      return [
        ...Object.keys(addressInfoMap).map((key) => (
          <PatientManagementInput
            value={addressInfoMap[key]}
            onChange={handleChangeTouched(
              `${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.ADDRESS}[${index}].${key}`
            )}
            name={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.ADDRESS}[${index}].${key}`}
            error={
              errors && errors[index] && touched?.[index]?.[key] ? errors[index][key] : undefined
            }
            key={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.ADDRESS}[${index}].${key}`}
          />
        )),
        index !== 0 ? (
          <PatientManagementEliminateButton onClick={() => deleteAddress(index)} />
        ) : (
          <></>
        ),
      ];
    };

    const transformSecondaryAddressListToArray = (adressList) =>
      adressList.map((adress, index) => [
        'Other address',
        ...transformAddressToArray(adress, index + 1),
      ]);

    // Variables and constants
    const addresses = {
      main: formData.find((_, index) => index === 0),
      secondary: formData.filter((_, index) => index !== 0),
    };
    const { main, secondary } = addresses;

    return [
      ['Home address', ...transformAddressToArray(main, 0)],
      ...transformSecondaryAddressListToArray(secondary),
    ];
  };

  const addNewAddress = () =>
    setFormikState(({ values, ...prevFormikState }) => ({
      values: {
        ...values,
        [PatientManagementFormikKeys.PERSONAL_INFORMATION]: {
          ...values[PatientManagementFormikKeys.PERSONAL_INFORMATION],
          [PersonalInformationFormikKeys.ADDRESS]: [
            ...values[PatientManagementFormikKeys.PERSONAL_INFORMATION][
              PersonalInformationFormikKeys.ADDRESS
            ],
            createNewAddress(),
          ],
        },
      },
      ...prevFormikState,
    }));

  // Variables and constants
  const title = (
    <>
      <span>address</span>
      <PatientManagementAddButton onClick={addNewAddress} />
    </>
  );
  const header = ['Address', 'Unit #', 'City', 'State', 'Zip Code', ''];
  const body = createAddressBodyList(formData, handleChange, errors);

  return (
    <GenericPatientInfoTable
      tableName={title}
      tableHeader={header}
      tableBody={body}
      containerClassNameList={[styles.main]}
    />
  );
};

export default AddressInformation;
