import {
  CareChampionsFormikKeys,
  CareChampionsFormikValues,
} from 'components/PatientManagementForm/types';
import { RelationShipEnum } from 'types/ApiModels/Patients/Relationship';

const createNewCareChampion = (
  fullName = '',
  primaryPhoneNumber = '',
  secondaryPhoneNumber = '',
  email = '',
  relationship?: RelationShipEnum
): CareChampionsFormikValues => {
  return {
    [CareChampionsFormikKeys.FULL_NAME]: fullName,
    [CareChampionsFormikKeys.PERSONAL_PHONE_NUMBER]: primaryPhoneNumber,
    [CareChampionsFormikKeys.SECONDARY_PHONE_NUMBER]: secondaryPhoneNumber,
    [CareChampionsFormikKeys.EMAIL]: email,
    [CareChampionsFormikKeys.RELATIONSHIP]: relationship ? relationship : null,
  };
};

export default createNewCareChampion;
