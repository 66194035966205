import {
  CareChampionsFormikValues,
  EmergencyContactFormikValues,
  IdentificationFormikKeys,
  IdentificationFormikValues,
  PatientManagementFormikKeys,
  PatientManagementFormikValues,
  PersonalInformationFormikKeys,
  PersonalInformationFormikValues,
  DemographicInfoFormikValues,
} from 'components/PatientManagementForm/types';
import { InsuranceFormikValues } from 'components/PatientManagementForm/types/PatientManagementFormikValues';
import moment from 'moment';
import EmergencyContact from 'types/ApiModels/Patients/EmergencyContact';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { RelationShipEnum } from 'types/ApiModels/Patients/Relationship';
import { User } from 'types/ApiModels/Users';
import { negativeLocalIdCreator } from 'util/utils';
import createNewAddress from './createNewAddress';

export const createLocalEmergencyId = negativeLocalIdCreator();

const createPatientManagementFormikInitialValues = ({
  selectedPatient,
}: {
  selectedPatient: PatientGetSingle;
}): PatientManagementFormikValues => {
  // Functions and subroutines
  const validateString = (str: string): string => (str ? str : '');

  const createPatientIdentification = ({
    first_name,
    last_name,
    middle_name,
    birth_date,
    sex,
  }: Partial<User>): IdentificationFormikValues => {
    return {
      [IdentificationFormikKeys.FIRST_NAME]: validateString(first_name),
      [IdentificationFormikKeys.LAST_NAME]: validateString(last_name),
      [IdentificationFormikKeys.MIDDLE_NAME]: validateString(middle_name),
      [IdentificationFormikKeys.BIRTHDATE]: birth_date
        ? moment(birth_date).format('YYYY-MM-DD')
        : '',
      [IdentificationFormikKeys.SEX]: sex,
    };
  };

  const createCareChampionList = (): CareChampionsFormikValues[] => {
    return selectedPatient.care_champions?.length
      ? selectedPatient.care_champions.map<CareChampionsFormikValues>((cc) => ({
          email: cc.email,
          fullName: cc.full_name,
          personalPhoneNumber: cc.phone_number,
          relationship: cc.relationship as RelationShipEnum,
          secondaryPhoneNumber: cc.secondary_phone,
        }))
      : [];
  };

  const createPersonalInformation = (
    patient: PatientGetSingle
  ): PersonalInformationFormikValues => {
    const createContactInformation = ({
      email,
      phone_number,
      second_phone_number,
    }: Pick<User, 'email' | 'phone_number' | 'second_phone_number'>) => ({
      email: validateString(email),
      primaryPhoneNumber: validateString(phone_number),
      secondaryPhoneNumber: validateString(second_phone_number),
    });

    const createAddressList = () =>
      patient.address?.length
        ? patient.address.map((a) => ({
            address: a.home,
            apt: a.apt,
            city: a.city,
            state: a.state,
            zipCode: a.zip_code,
          }))
        : [createNewAddress()];

    const createInsuranceProviderList = () =>
      patient.insurances?.length
        ? patient.insurances.map<InsuranceFormikValues>((i) => ({
            groupNumber: i.group_number,
            id: i.id,
            subscriberNumber: i.suscriber_number,
            insuranceId: i.insurance,
          }))
        : [];

    const createDemographicInfo = (): DemographicInfoFormikValues => ({
      gender: patient.gender_identity || '',
      maritalStatus: patient.marital_status || '',
      race: patient.race?.id,
      ethnicity: patient.ethnicity?.id,
      language: selectedPatient?.user?.languages ?? [],
      religion: patient.religion?.id,
    });

    return {
      [PersonalInformationFormikKeys.CONTACT_INFORMATION]: createContactInformation(patient.user),
      [PersonalInformationFormikKeys.ADDRESS]: createAddressList(),
      [PersonalInformationFormikKeys.INSURANCE]: createInsuranceProviderList(),
      [PersonalInformationFormikKeys.DEMOGRAPHIC_INFO]: createDemographicInfo(),
    };
  };

  const createEmergencyContactList = (
    emergencyContacts: EmergencyContact[]
  ): EmergencyContactFormikValues[] => {
    return emergencyContacts?.length
      ? emergencyContacts.map<EmergencyContactFormikValues>((ec) => ({
          //TODO: should come from backend
          contactEmail: ec.email,
          contactPhone: ec.phone_number.toString(),
          firstName: ec.first_name,
          id: ec.id,
          lastName: ec.last_name,
          relationship: RelationShipEnum[ec.relationship],
        }))
      : [];
  };

  // Variables and constants
  return {
    [PatientManagementFormikKeys.IDENTIFICATION]: createPatientIdentification(selectedPatient.user),
    [PatientManagementFormikKeys.POPULATION]: selectedPatient.populations.map((pop) => pop.id),
    [PatientManagementFormikKeys.CARE_CHAMPIONS]: createCareChampionList(),
    [PatientManagementFormikKeys.PERSONAL_INFORMATION]: createPersonalInformation(selectedPatient),
    [PatientManagementFormikKeys.EMERGENCY_CONTACTS]: createEmergencyContactList(
      selectedPatient.emergency_contact
    ),
    [PatientManagementFormikKeys.SURGERIES]: selectedPatient.surgeries,
    [PatientManagementFormikKeys.FAMILY_HISTORY]: selectedPatient.familyhistory,
    [PatientManagementFormikKeys.ALLERGIES]: selectedPatient.allergies,
    [PatientManagementFormikKeys.HEALTH_SCORING]: {
      missed_calls_red_alert: selectedPatient.missed_calls_red_alert,
      missed_calls_yellow_alert: selectedPatient.missed_calls_yellow_alert,
    },
  };
};

export default createPatientManagementFormikInitialValues;
