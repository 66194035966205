import PasswordInput from 'components/PasswordInput';
import { useDialogContext } from 'components/VyTracLayout/store';
import { useDialog } from 'components/VyTracLayout/store/hooks';
import { ErrorMessage, Field, Form, Formik, getIn } from 'formik';
import { KeyboardEvent } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { RegisterViews } from 'types/Register';
import { parsePhoneNumber } from 'util/utils';
import { useRegisterProviderContext } from '../store/RegisterProviderContext';
import { onSubmit } from '../utils';
import { RegisterValidationSchema } from '../ValidationSchemas';
import { getStyles } from './util';
import { useRegisterContext } from '../../Register/store';

const RegisterProviderForm = () => {
  const { registerValues, changeView, setRegisterValues } = useRegisterProviderContext();
  const history = useHistory();
  const { hash, email } = useRegisterContext();

  const handlePhoneNumberPaste = (setPhoneNumber) => (e: ClipboardEvent) => {
    e.preventDefault();
    const data = e.clipboardData.getData('text');
    const [parsed, isValid] = parsePhoneNumber(data);
    isValid && setPhoneNumber(parsed);
  };

  const handlePhoneNumberKeyDown = (e: KeyboardEvent) => {
    const allowedKeys = ['Backspace', 'Delete', '-', 'ArrowRight', 'ArrowLeft'];
    if (isNaN(parseInt(e.key)) && !allowedKeys.includes(e.key) && !e.ctrlKey && !e.shiftKey) {
      e.preventDefault();
      return;
    }
  };

  const { dialog: DialogComponent } = useDialogContext();
  const { errorDialog } = useDialog();

  return (
    <Formik
      validationSchema={RegisterValidationSchema}
      initialValues={registerValues}
      onSubmit={async (values, { setFieldError }) => {
        const newValues = { ...values };
        delete newValues['confirm_password'];
        try {
          const data = await onSubmit({
            ...newValues,
            hash,
            email,
          });
          if (!data.error) {
            changeView(RegisterViews.TOKEN);
            setRegisterValues(values);
          } else {
            Object.keys(data.message).forEach((key) => {
              if (key === 'detail') {
                errorDialog(
                  'Error',
                  data.message[key]?.non_field_errors?.join(', ') ?? 'An error occurred'
                );
              } else setFieldError(key, data.message[key]);
            });
          }
        } catch (e) {
          console.error('Unexpected error', e);
        }
      }}
    >
      {({ touched, errors, handleBlur, handleChange, isSubmitting, setFieldValue }) => (
        <Form className="h-100 w-100 m-0 d-flex flex-column justify-content-between">
          <div>
            <div className="d-flex w-100 mb-5">
              <div className="flex-grow-1">
                <div className="green-line"></div>
              </div>
              <div className="flex-grow-1">
                <div className="grey-line"></div>
              </div>
            </div>
            <p className="text-left dynamic-font-heading mb-5">
              Welcome to <span className="bold-blue text-bolder">VyTrac</span>
            </p>
            <p className="text-left dynamic-font-normal">
              A registration invitation has been sent to{' '}
              <span className="bold-blue text-bolder">{registerValues.email}</span>. Create your
              login credentials to access the Vytrac platform.
            </p>
            {DialogComponent ? <DialogComponent /> : null}
            <p
              className={`${
                getIn(touched, 'password') && getIn(errors, 'password') ? 'text-danger' : ''
              } grey-text dynamic-font-normal mt-4 mb-0`}
            >
              Password
            </p>
            <div>
              <PasswordInput
                name="password"
                invalidStyle={touched['password'] && getStyles(errors, 'password')}
              />
              <ErrorMessage name="password">
                {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            <p
              className={`${
                getIn(touched, 'confirm_password') && getIn(errors, 'confirm_password')
                  ? 'text-danger'
                  : ''
              } grey-text dynamic-font-normal mt-4 mb-0`}
            >
              Confirm password
            </p>
            <div>
              <PasswordInput
                name="confirm_password"
                invalidStyle={touched['confirm_password'] && getStyles(errors, 'confirm_password')}
              />

              <ErrorMessage name="confirm_password">
                {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
              </ErrorMessage>
            </div>
            <p
              className={`${
                getIn(touched, 'phone_number') && getIn(errors, 'phone_number') ? 'text-danger' : ''
              } grey-text dynamic-font-normal mt-4 mb-0`}
            >
              Add a phone number
            </p>
            <Field
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handlePhoneNumberKeyDown}
              onPaste={handlePhoneNumberPaste((val) => setFieldValue('phone_number', val))}
              className="text-field dynamic-font-normal"
              type="text"
              style={getIn(touched, 'phone_number') && getStyles(errors, 'phone_number')}
              name="phone_number"
            />
            <ErrorMessage name="phone_number">
              {(msg) => <div className="dynamic-font-normal text-danger">{msg}</div>}
            </ErrorMessage>
          </div>
          <div>
            <div className="d-flex justify-content-between">
              <Button
                className="dynamic-font-normal text-bold"
                onClick={() => history.push('/')}
                variant="light"
              >
                CANCEL
              </Button>
              <Button
                className="dynamic-font-normal text-bold"
                type="submit"
                disabled={isSubmitting}
              >
                REGISTER ACCOUNT
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default RegisterProviderForm;
