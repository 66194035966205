import { Moment } from 'moment';
import { createPatientAction, updatePatientAction } from 'services/patientService';
import { createTrackingLog } from 'services/trackingService';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { addExceptionToRecurrence } from 'util/calendarUtils/actionRecurrence/absolute/addExceptionToRecurrence';
import { generateFutureRecurrence } from 'util/calendarUtils/actionRecurrence/absolute/generateFutureRecurrence';
import { editTrackingLog } from './TrackingLogs';

export const updateOnlyThisEvent = async (
  selectedPatientId: number,
  trackingActivityId: number,
  patientActions: IPatientCarePlanAction[],
  selectedTimelineAction: IPatientTimelineAction,
  blockStartDate: Moment
): Promise<IPatientCarePlanAction[]> => {
  let updatedPatientActions = [...patientActions];
  let originalActionIndex: number;
  let originalAction: IPatientCarePlanAction;

  if (selectedTimelineAction.id >= 0) {
    // means selected action is original
    originalActionIndex = updatedPatientActions.findIndex(
      (action) => action.id === selectedTimelineAction.id
    );
    originalAction = { ...updatedPatientActions[originalActionIndex] };

    /// generate recurrent action starting from next action
    const nextRecurrentAction = generateFutureRecurrence(blockStartDate, originalAction);
    const newCarePlanAction = await createPatientAction(selectedPatientId, nextRecurrentAction);
    updatedPatientActions = [...updatedPatientActions, newCarePlanAction];

    // remove recurrence from selected action
    const updatedAction = await updatePatientAction(selectedPatientId, {
      ...selectedTimelineAction,
      recurrence: null,
    });
    updatedPatientActions[originalActionIndex] = updatedAction;
  } else {
    // means action is recurrent generated
    const updatedOriginalAction = addExceptionToRecurrence(
      originalActionIndex,
      updatedPatientActions,
      selectedTimelineAction
    );
    await updatePatientAction(selectedPatientId, updatedOriginalAction);

    // create new action
    const newCarePlanAction = await createPatientAction(selectedPatientId, {
      ...selectedTimelineAction,
      recurrence: null,
    });
    updatedPatientActions = [...updatedPatientActions, newCarePlanAction];
  }

  if (trackingActivityId)
    createTrackingLog({
      ...editTrackingLog,
      description: 'update only one action from recurrence',
      object_id:
        selectedTimelineAction.id >= 0
          ? selectedTimelineAction.id
          : selectedTimelineAction.originalActivityId,
      patient: selectedPatientId,
      activity: trackingActivityId,
    });

  return updatedPatientActions;
};
