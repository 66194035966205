import { registerUser } from 'services/userService';
import RegisterUserRequest from 'types/ApiModels/Login/RegisterUserRequest';

export const onSubmit = async (values: RegisterUserRequest) => {
  try {
    const response = await registerUser(values);
    if (response.status === 200 || response.status === 201) return await response.json();
    else return { error: response.status, message: await response.json() };
  } catch (error) {
    throw error;
  }
};
