import { PatientQuickFilter } from 'screens/Patients/Content/components/PatientListWithFilters/QuickFilters';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import { PatientListAction, PatientListActionTypes } from './patientListActionType';

export interface IPatientListState {
  complete: PatientGet[];
  filter: PatientQuickFilter;
  searchValue: string;
  page: PatientGet[][];
}

export const patientListInitialState: IPatientListState = {
  complete: [],
  filter: null,
  searchValue: '',
  page: [],
};

const patientListReducer = (state = patientListInitialState, action: PatientListAction) => {
  switch (action.type) {
    case PatientListActionTypes.SET_COMPLETE: {
      const stateCopy = { ...state };
      stateCopy.complete = [...action.payload.completeList];
      return stateCopy;
    }

    case PatientListActionTypes.SET_FILTER: {
      return { ...state, filter: action.payload.filter };
    }

    case PatientListActionTypes.SET_SEARCH_VALUE: {
      const stateCopy = { ...state };
      stateCopy.searchValue = action.payload.searchValue;
      return stateCopy;
    }

    case PatientListActionTypes.SET_PAGE: {
      const stateCopy = { ...state };
      stateCopy.page = [...action.payload.pageList];
      return stateCopy;
    }

    default:
      return state;
  }
};

export default patientListReducer;
