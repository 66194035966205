import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { usePatientFormikContext } from 'screens/Patients/store';
import { getPatientDevices } from 'services/patientService';
import Device from 'types/ApiModels/Patients/device';

const DevicesMock: Device[] = [
  {
    id: 5,
    deleted: null,
    model: 'SmartDeviceX',
    device_id: 'smDx4000',
    created_at: '2022-04-21T11:36:44.703922Z',
    is_active: true,
    patient: 5,
    type: {
      id: 1,
      is_fda_approved: true,
      measures: [1],
      name: 'SMFX300',
    },
  },
  {
    id: 4,
    deleted: null,
    model: 'MegaMeasrer600',
    device_id: 'sdfs-2312-211',
    created_at: '2022-04-21T11:36:01.937320Z',
    is_active: true,
    patient: 5,
    type: {
      id: 1,
      is_fda_approved: true,
      measures: [1, 2],
      name: 'SMFX300',
    },
  },
];

const usePatientManagementDevices = (): [
  devices: Device[],
  setDevices: Dispatch<SetStateAction<Device[]>>
] => {
  const { selectedPatient } = usePatientFormikContext();
  const [devices, setDevices] = useState([]);

  const fetchDevices = useCallback(async () => {
    const res = await getPatientDevices(selectedPatient.id);
    setDevices(res);
  }, [selectedPatient.id]);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  return [devices, setDevices];
};

export default usePatientManagementDevices;
