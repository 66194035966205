import CardAdd from 'components/CardAdd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { PatientManagementFormikValues } from '../types';
import AllergiesList from './AllergiesList';

interface AllergiesCardProps {
  handleAdd: () => void;
  handleEdit: (id: number) => void;
  queueDelete: (id: number) => void;
}

const AllergiesCard: FC<AllergiesCardProps> = ({
  handleAdd,
  handleEdit,
  queueDelete,
}: AllergiesCardProps) => {
  const {
    values: { ALLERGIES: allergies },
    setFormikState,
  } = useFormikContext<PatientManagementFormikValues>();

  const handleDeleteHistory = (id: number) => {
    setFormikState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        ALLERGIES: prev.values.ALLERGIES.filter((fh) => fh.id !== id),
      },
    }));
    id > 0 && queueDelete(id);
  };
  return (
    <CardAdd count={allergies?.length ?? 0} handleAdd={handleAdd} title="Allergies">
      <AllergiesList
        allergies={allergies ?? []}
        handleHistoryDelete={handleDeleteHistory}
        handleHistoryEdit={handleEdit}
      />
    </CardAdd>
  );
};
export default AllergiesCard;
