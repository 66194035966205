import { TypeaheadInputProps } from 'react-bootstrap-typeahead/types/types';
import styles from './styles.module.css';
import { ReactComponent as MagGlass } from 'assets/icons/mag_glass.svg';

/**
 * To use with rb-typeahead
 * @returns
 */
export const SearchBarInput = ({
  inputRef,
  referenceElementRef,
  ...inputProps
}: TypeaheadInputProps) => (
  <div className={`d-flex gap ${styles.main}`}>
    <MagGlass />
    <input
      ref={(ref) => {
        inputRef(ref);
        referenceElementRef(ref);
      }}
      {...inputProps}
    />
  </div>
);
