import React from 'react';
import styles from './styles.module.css';
import { Table } from 'react-bootstrap';
import Checkbox from '../form/Checkbox';

/**
 * Render table cell
 * @name render
 * @param {Object} props
 * @returns {React.ReactNode} - Table cell
 */

/**
 * @name onRowClick
 * @param {Object} rowData
 * @returns {MouseEventHandler}
 */

/**
 * GenericTable
 * @param {String[]} categories
 * @param {Object[][]} data - Array of arrays of data, each array represents a category and each element in the array represents a row
 * @param {Object[]} columns - Array of columns
 * @param {String} columns[].title - Title of the column
 * @param {String} columns[].key - Key of the column
 * @param {render} columns[].render - Type of the column
 * @param {Boolean} columns[].hidden - Class name of the column
 * @param {string} columns[].textAlign - String that represents the alignment in the column
 * @param {Object} columns[].style - Object that applies style to column element
 * @param {onRowClick} onRowClick - Callback function
 */
const CategoriesTable = ({
  categories,
  tableClassName,
  columns = [],
  data = [],
  onRowClick = () => null,
  rowClassName,
  selectable = false,
  selected = [],
}) => {
  return (
    <Table className={`${styles.genericTable} ${tableClassName || ''}`} borderless size="sm">
      <thead>
        <tr>
          {selectable && (
            <th className={styles.select}>
              <Checkbox
                value={selected.length === data.length}
                onChange={() => (selected = [...data])}
              />
            </th>
          )}
          {columns.map((column, index) => (
            <React.Fragment key={index}>
              {!column.hidden ? (
                <th className="font-size-small" style={{ textAlign: column.textAlign }}>
                  {column.title}
                </th>
              ) : (
                <th className="font-size-small"></th>
              )}
            </React.Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {data && data.length > 0 ? (
          data.map((categoryData, i) => (
            <React.Fragment key={`data_${i}`}>
              {categoryData && categoryData.length > 0 ? (
                <React.Fragment>
                  <tr className={styles.category}>
                    <th className={`font-size-small`}>{categories[i]}</th>
                    {columns.slice(1).map((index) => (
                      <th className={`font-size-small`} key={index}></th>
                    ))}
                  </tr>
                  {categoryData.map((row, j) => (
                    <tr
                      className={` ${styles['element']} ${rowClassName || ''}`}
                      onClick={() => onRowClick(row)}
                      key={j}
                    >
                      {selectable && (
                        <td className={styles.select}>
                          <Checkbox
                            value={selected.length === data.length}
                            onChange={() => (selected = [...selected, row])}
                          />
                        </td>
                      )}
                      {columns.map((column) => (
                        <React.Fragment key={`data_${i}_${column.key}`}>
                          {!column.hidden && (
                            <td
                              className="font-size-medium"
                              style={{
                                textAlign: column.textAlign,
                                ...column.style,
                              }}
                            >
                              {column.render ? column.render({ ...row }) : row[column.key]}
                            </td>
                          )}
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              ) : null}
            </React.Fragment>
          ))
        ) : (
          <tr>
            <td colSpan={columns.length + (selectable ? 1 : 0)}>
              <div className="text-center">
                <span className="font-size-medium">No data available</span>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default CategoriesTable;
