import {
  EmergencyContactFormikValues,
  EmergencyContactsFormikKeys,
} from 'components/PatientManagementForm/types';

const emptyUser = {
  firstName: '',
  lastName: '',
  relationship: '',
  contactEmail: '',
  contactPhone: '',
};

const createNewEmergencyContact = (user = emptyUser): EmergencyContactFormikValues => {
  const { firstName, relationship, contactEmail, contactPhone, lastName } = user;
  return {
    [EmergencyContactsFormikKeys.FIRST_NAME]: firstName ?? '',
    [EmergencyContactsFormikKeys.LAST_NAME]: lastName ?? '',
    [EmergencyContactsFormikKeys.RELATIONSHIP]: relationship ?? '',
    [EmergencyContactsFormikKeys.CONTACT_EMAIL]: contactEmail ?? '',
    [EmergencyContactsFormikKeys.CONTACT_PHONE]: contactPhone ?? '',
  };
};

export default createNewEmergencyContact;
