import { vytracAxios } from 'ajax';
import { PaginatedResponse } from 'types/ApiModels/General';
import {
  Encounter,
  EncounterCategory,
  EncounterConversation,
  EncounterCreate,
} from 'types/ApiModels/Patients/encounter';
import { objectToUrlParams, requestWrapper } from 'util/utils';

const URI = 'patient/encounter';

const encounterUris = {
  base: `${URI}/`,
  byId: (id: number) => `${URI}/${id}/`,
  categories: `${URI}/categories/`,
  filesById: (id: number) => `${URI}/${id}/files/`,
};

export const getEncounterById = (encounterId: number) => {
  return requestWrapper(vytracAxios.get<Encounter>(encounterUris.byId(encounterId)));
};

export const getEncounterCategories = async () => {
  const res = await vytracAxios.get<EncounterCategory[]>(encounterUris.categories);
  return res.data;
};

export const createEncounter = (
  encounterInfo: AtLeast<
    EncounterCreate,
    'patient' | 'category' | 'subject' | 'twilio_conversation_sid'
  >
) => requestWrapper(vytracAxios.post<Encounter>(encounterUris.base, encounterInfo));

export const updateEncounterOwner = (encounterId: number, userId: number) => {
  return requestWrapper(
    vytracAxios.patch(encounterUris.byId(encounterId), {
      owner: userId,
    })
  );
};

/**
 * Search by querying subject or provider name
 * @param query
 */
export const searchEncounter = async (query: {
  patient?: string;
  search?: string;
  owner?: string;
}) => {
  if (Object.keys(query).length === 0) return null;
  const queryParam = objectToUrlParams(query);
  const [res, error] = await requestWrapper<PaginatedResponse<EncounterConversation>>(
    vytracAxios.get(`${encounterUris.base}${queryParam}`)
  );
  if (error) {
    return null;
  }
  return res;
};
