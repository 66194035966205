import { Conversation } from '@twilio/conversations';
import { MessageWithMention, splitMentionMultiple } from 'components/message-with-mention';
import { SearchBarAlt, SearchBarInput } from 'components/SearchBar';
import UserBubble from 'components/UserBubble';
import { ChangeEventHandler, useCallback, useRef, useState } from 'react';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { useHistory } from 'react-router-dom';
import { getMessageFromConversation, searchConversations } from 'services/conversations-service';
import { useTwilioClient } from 'store/twilio-client';
import { ConversationMetadata, MessageSimplified } from 'types/ApiModels/conversations';
import { formatMessageTimeStamp } from 'util/dateUtils';
import tabEnum from '../../../../util/tabEnum';
import styles from './styles.module.css';

const ONE_MESSAGE = 1;

const TeamNotesSearchItem = ({
  message,
  metadata,
}: {
  message: MessageSimplified;
  metadata: ConversationMetadata;
}) => {
  const [first_name, last_name] = metadata?.full_name?.split(' ') ?? ['?', '?'];
  const parsedAttributes =
    typeof message.attributes === 'string' ? JSON.parse(message.attributes) : message.attributes;
  const body = splitMentionMultiple(message.body, parsedAttributes['mentions']);
  //TODO: improve
  return (
    <div aria-label="search result" className="d-flex gap-sm align-items-center">
      <UserBubble
        user={{
          first_name,
          last_name,
          imageUrl: metadata?.photo_thumbnail ?? '',
        }}
      />
      <div className="d-flex flex-column gap-sm">
        <p className="m-0 p-0 font-size-medium">
          {body?.length ? <MessageWithMention body={body} senderId={metadata?.id} /> : message.body}
        </p>
        <p className="m-0 p-0 font-size-small text-gray-low-risk font-weight-md">
          {formatMessageTimeStamp(new Date(message.date_created))}
        </p>
      </div>
    </div>
  );
};

const TeamNotesSearch = ({
  conversationSid,
  getMetadataByParticipantSid,
  scrollToOption,
}: {
  conversationSid: string;
  getMetadataByParticipantSid: (participantSid: string) => ConversationMetadata;
  scrollToOption: (option: MessageSimplified) => void;
}) => {
  const { client } = useTwilioClient();
  //need to cache the conversations we're fetching, they should not be many, theres always gonna be the main thread and subthread may exist
  const conversationPool = useRef<Record<string, Conversation>>({});
  const [messageResults, setMessageResults] = useState<MessageSimplified[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const search = useCallback(
    async (search: string) => {
      if (!client || !conversationSid) return;
      setIsLoading(true);
      const results: MessageSimplified[] = [];
      const searchResult = (await searchConversations({ conversation: conversationSid, search }))
        .results;
      for (const res of searchResult) {
        const isSubthread = !!res.twilio_subthread_sid;
        const sid = isSubthread ? res.twilio_subthread_sid : res.twilio_conversation_sid;
        const hasIndex = !!res.twilio_message_index;
        //todo: we will eventually only retrieve messages from sdk rather than the endpoint way but ftb we need both approaches
        if (hasIndex) {
          if (!conversationPool.current[sid]) {
            conversationPool.current[sid] = await client.getConversationBySid(sid);
          }
          const message = (
            await conversationPool.current[sid].getMessages(ONE_MESSAGE, res.twilio_message_index)
          ).items?.[0];
          results.push({
            attributes: message.attributes,
            body: message.body,
            conversation_sid: message.conversation.sid,
            sid: message.sid,
            date_updated: message.dateUpdated.toISOString(),
            date_created: message.dateUpdated.toISOString(),
            index: message.index,
            media: message.attachedMedia?.[0]?.sid ?? '',
            participant_sid: message.participantSid,
          });
          continue;
        }
        const message = await getMessageFromConversation(sid, res.twilio_message_sid);
        message && results.push(message);
      }
      results.sort((a, b) => (new Date(a.date_created) > new Date(b.date_created) ? -1 : 1));
      setMessageResults(results);
      setIsLoading(false);
    },
    [client, conversationSid]
  );

  return (
    <AsyncTypeahead
      id="team notes search"
      onSearch={search}
      options={messageResults}
      labelKey="body"
      renderInput={SearchBarInput}
      className={styles.search}
      onChange={(options: MessageSimplified[]) => {
        const [option] = options;
        scrollToOption(option);
      }}
      renderMenu={(results: MessageSimplified[], menuProps) => {
        //Id rather leave them unused than using delete keyword
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { newSelectionPrefix, paginationText, renderMenuItemChildren, ...striped } =
          menuProps;

        return (
          <Menu {...striped} text="" className={styles.menu}>
            {results.map((result, index) => (
              <MenuItem option={result} position={index} key={index}>
                <TeamNotesSearchItem
                  message={result}
                  metadata={getMetadataByParticipantSid(result.participant_sid)}
                />
              </MenuItem>
            ))}
          </Menu>
        );
      }}
      placeholder="Search keyword or mention"
      isLoading={isLoading}
    />
  );
};

interface TeamNotesMessagesHeaderProps {
  handleFiltersButtonClick?: (filter: any) => void;
  showTeamNotesLink: boolean;
  conversationSid: string;
  getMetadataByParticipantSid: (participantSid: string) => ConversationMetadata;
  scrollToOption: (option: MessageSimplified) => void;
}

const TeamNotesMessagesHeader = ({
  handleFiltersButtonClick,
  showTeamNotesLink,
  conversationSid,
  getMetadataByParticipantSid,
  scrollToOption,
}: TeamNotesMessagesHeaderProps) => {
  const history = useHistory();
  return (
    <div className="d-flex justify-content-between w-100">
      <div className="d-flex gap align-items-center">
        <div style={{ whiteSpace: 'nowrap' }}>Team notes</div>
        {/* <TeamNotesDateFilter /> */}
      </div>
      <div className="flex-grow-1"></div>
      <div className="d-flex gap">
        {showTeamNotesLink && (
          <button
            className={styles.goToTeamNotesButton}
            onClick={() => {
              history.replace(tabEnum.TEAM_NOTES);
            }}
          >
            Go to team notes
          </button>
        )}

        <TeamNotesSearch
          conversationSid={conversationSid}
          getMetadataByParticipantSid={getMetadataByParticipantSid}
          scrollToOption={scrollToOption}
        />
        {/* <FiltersButton onFiltersClick={handleFiltersButtonClick} /> */}
      </div>
    </div>
  );
};

export default TeamNotesMessagesHeader;
