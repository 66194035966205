import { FC, ReactNode, useState } from 'react';
import { RegisterPatientCards } from '../types';
import RegisterPatientContext from './RegisterPatientContext';

interface RegisterPatientContextProviderProps {
  children: ReactNode;
}

const RegisterPatientContextProvider: FC<RegisterPatientContextProviderProps> = ({
  children,
}: RegisterPatientContextProviderProps) => {
  const [registerValues, setRegisterValues] = useState();
  const [currentCard, setCurrentCard] = useState(RegisterPatientCards.CREATE_ACCOUNT_FORM);

  return (
    <RegisterPatientContext.Provider
      value={{
        currentCard,
        setCurrentCard,
        registerValues,
        setRegisterValues,
      }}
    >
      {children}
    </RegisterPatientContext.Provider>
  );
};
export default RegisterPatientContextProvider;
