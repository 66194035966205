import { FC, useMemo } from 'react';
import { useRegisterPatientContext } from '../store';
import { mapRegisterPatientCardToComponent } from '../utils';

const CurrentRegisterPatientCard: FC = () => {
  const { currentCard } = useRegisterPatientContext();
  const Component = useMemo(() => mapRegisterPatientCardToComponent[currentCard], [currentCard]);

  return <Component />;
};

export default CurrentRegisterPatientCard;
