import { Message } from '@twilio/conversations';
import { ReactComponent as EmptyChatsIcon } from 'assets/icons/chats_empty.svg';
import Card from 'components/Card';
import EmptyList from 'components/EmptyList';
import SendMessageBoxPowered from 'components/SendMessageBox/send-message-box-powered';
import { UserBubbleGroupUser } from 'components/UserBubbleGroup';
import VytracSpinner from 'components/vytrac-spinner';
import { useFetchList } from 'hooks';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { getKeywordsTemplates } from 'services/administrationService';
import colors from 'styles/colors';
import { ConversationMetadata, MessageSimplified } from 'types/ApiModels/conversations';
import MentionItemCustom from 'types/Shared/MentionItemCustom';
import { getMessageAttributes } from '../utils';
import TeamNotesMessage from './team-notes-message/team-notes-message';
import TeamNotesMessagesHeader from './team-notes-messages-header';
import TeamNotesQuickFilters from './team-notes-quick-filters';

export type LoadMessagesFunction = () => Promise<Message[]>;

interface TeamNotesMessagesListProps {
  messages: Message[];
  isLoading: boolean;
  getMetadataByParticipantSID: (participantSID: string) => ConversationMetadata;
  selfMetadata: ConversationMetadata;
  loadMoreMessages: LoadMessagesFunction;
  areMessagesLoading: boolean;
  virtuosoHandler?: MutableRefObject<VirtuosoHandle>;
}

const TeamNotesMessagesVirtual = ({
  messages,
  loadMoreMessages,
  getMetadataByParticipantSid,
  selfMetadata,
  virtuosoHandler,
}: {
  messages: Message[];
  loadMoreMessages: LoadMessagesFunction;
  getMetadataByParticipantSid: (participantSid: string) => ConversationMetadata;
  selfMetadata: ConversationMetadata;
  virtuosoHandler?: MutableRefObject<VirtuosoHandle>;
}) => {
  const [firstItemIndex, setFirstItemIndex] = useState(messages?.[0]?.index);
  const oldLastMessageIdx = useRef<number>(messages?.[messages?.length - 1]?.index);
  const oldMessages = useRef<Message[]>(messages);
  /**
   * TODO:  I want to show some sort of overlay if the user receives messages while looking at other stuff around
   */
  const handleLoadMoreMessages = async () => {
    const moreMessages = await loadMoreMessages();
    if (moreMessages) {
      setFirstItemIndex((idx) => idx - moreMessages.length);
    }
    return moreMessages;
  };

  return (
    <>
      <Virtuoso
        data={messages}
        /**
         * TODO: Need to dynamically set firstitem index so that virtuoso knows that it should not shift the whole list
         * when items are prepended with `loadMoreMessages`
         */
        firstItemIndex={firstItemIndex}
        initialTopMostItemIndex={messages?.[messages?.length - 1]?.index}
        startReached={handleLoadMoreMessages}
        followOutput={(isAtBottom) => {
          if (messages[messages.length - 1].index !== oldLastMessageIdx.current && isAtBottom) {
            oldLastMessageIdx.current = messages[messages.length - 1].index;
            return 'smooth';
          }
          return false;
        }}
        ref={virtuosoHandler}
        computeItemKey={(i, item) => item.sid}
        itemContent={(index, m) => (
          <TeamNotesMessage
            dateCreated={m.dateCreated}
            attributes={getMessageAttributes(m.attributes)}
            body={m.body}
            senderMetadata={getMetadataByParticipantSid(m.participantSid)}
            selfMetadata={selfMetadata}
            containerClassName={index === messages.length - 1 ? 'pb-2' : ''}
          />
        )}
      />
    </>
  );
};

const TeamNotesMessagesList = ({
  messages,
  isLoading,
  selfMetadata,
  getMetadataByParticipantSID,
  loadMoreMessages,
  areMessagesLoading,
  virtuosoHandler,
}: TeamNotesMessagesListProps) => {
  if (messages?.length && !isLoading)
    return (
      <TeamNotesMessagesVirtual
        getMetadataByParticipantSid={getMetadataByParticipantSID}
        loadMoreMessages={loadMoreMessages}
        messages={messages}
        selfMetadata={selfMetadata}
        virtuosoHandler={virtuosoHandler}
      />
    );
  return (
    <EmptyList
      Icon={EmptyChatsIcon}
      className="my-5"
      title="There are no messages yet"
      fill="#EBE3F1"
    >
      {isLoading ? <VytracSpinner /> : undefined}
    </EmptyList>
  );
};

const TeamNotesNoPermission = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Card
      className="d-flex flex-column flex-grow-1 card-bg-border"
      bodyClassName="d-flex flex-column flex-grow-1 overflow-hidden justify-content-around"
      headers={[
        <div className="d-flex justify-content-between w-100" key="team-notes-header">
          <div className="d-flex gap align-items-center">
            <div style={{ whiteSpace: 'nowrap' }}>Team notes</div>
            {/* <TeamNotesDateFilter /> */}
          </div>
          <div className="flex-grow-1"></div>
        </div>,
      ]}
    >
      <div className={`flex-grow-1`}>
        <EmptyList
          Icon={EmptyChatsIcon}
          title="You do not have permissions to see this conversation"
          fill={colors.highRiskAlerts}
        >
          {isLoading ? <VytracSpinner /> : undefined}
        </EmptyList>
      </div>
    </Card>
  );
};

interface TeamNotesMessagesProps {
  conversationSid: string;
  messages: Message[];
  loadMoreMessages: LoadMessagesFunction;
  showTeamNotesLink?: boolean;
  getMetadataByParticipantSID: (participantSID: string) => ConversationMetadata;
  selfMetadata: ConversationMetadata;
  handleSendMessage: (message: string, mentions: MentionItemCustom[]) => void;
  participants?: UserBubbleGroupUser[];
  isLoading;
  hasNoPermissions: boolean;
  scrollToOption: (option: MessageSimplified) => void;
  virtuosoHandler?: MutableRefObject<VirtuosoHandle>;
}

export const TeamNotesMessages = ({
  conversationSid,
  messages,
  showTeamNotesLink = false,
  loadMoreMessages,
  getMetadataByParticipantSID,
  selfMetadata,
  handleSendMessage,
  participants,
  isLoading,
  hasNoPermissions,
  scrollToOption,
  virtuosoHandler,
}: TeamNotesMessagesProps) => {
  const [keywords] = useFetchList(getKeywordsTemplates);

  if (hasNoPermissions) {
    return <TeamNotesNoPermission isLoading={isLoading} />;
  }

  return (
    <Card
      className="d-flex flex-column flex-grow-1 card-bg-border"
      bodyClassName="d-flex flex-column flex-grow-1 overflow-hidden justify-content-around"
      headers={[
        <TeamNotesMessagesHeader
          key={0}
          showTeamNotesLink={showTeamNotesLink}
          conversationSid={conversationSid}
          getMetadataByParticipantSid={getMetadataByParticipantSID}
          scrollToOption={scrollToOption}
        />,
        <TeamNotesQuickFilters key={1} />,
      ]}
    >
      <div
        // className={`flex-grow-1 ${styles['messages-container']}`}
        className={`flex-grow-1`}
        // ref={(ref) => {
        //   if (!ref) return;
        //   ref.scrollTop = ref.scrollHeight;
        // }}
      >
        <TeamNotesMessagesList
          messages={messages}
          getMetadataByParticipantSID={getMetadataByParticipantSID}
          isLoading={isLoading}
          selfMetadata={selfMetadata}
          areMessagesLoading={isLoading}
          loadMoreMessages={loadMoreMessages}
          virtuosoHandler={virtuosoHandler}
        />
      </div>
      {hasNoPermissions || isLoading ? null : (
        <SendMessageBoxPowered
          noPadding={false}
          handleSendMessage={handleSendMessage}
          participants={participants}
          keywords={keywords?.map((k) => ({ display: k?.name, id: `keyword-${k?.name}` })) ?? []}
          mentionables={
            participants?.map((p) => ({
              display: `${p?.first_name} ${p?.last_name}`,
              id: `mention-${p.id}`,
            })) ?? []
          }
        />
      )}
    </Card>
  );
};
