import React from 'react';
import TimeSpent from 'types/ApiModels/Patients/TimeSpent';

interface ITimeSpentContainer {
  className: string;
  timeSpent: TimeSpent;
}

const printTime = (valueInSeconds: number) => {
  if (!valueInSeconds) return '0m 0s';

  const minutes = Math.floor(valueInSeconds / 60);
  const remSeconds = valueInSeconds % 60;
  if (minutes < 10) return `0${minutes}m ${remSeconds}s`;
  else return `${minutes}m ${remSeconds}s`;
};

const TimeSpentContainer = ({ className, timeSpent }: ITimeSpentContainer) => {
  return (
    <span className={className} style={{ whiteSpace: 'nowrap' }}>
      {timeSpent ? `${printTime(timeSpent.call)} • ${printTime(timeSpent.video)}` : '-'}
    </span>
  );
};

export default TimeSpentContainer;
