import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { usePatientFormikContext } from 'screens/Patients/store';
import Button from '../../../../../../../components/Button';
import EmergencyButton from '../../../EmergencyButton';
import tabEnum from '../../util/tabEnum';
import routeEnum from './util/routeEnum';
import routeMapEnum from './util/routeMapEnum';

const QuestionnaireActions = () => {
  const history = useHistory();
  const { selectedPatient } = usePatientFormikContext();

  const originRoute = `/patients/${selectedPatient?.id}/${tabEnum.QUESTIONNAIRES}`;
  const route = routeMapEnum[routeEnum.CREATE_QUESTIONNAIRE](originRoute);

  const AbstractButton = (props) => <Button {...props} className="mx-1" />;

  return (
    <div className="d-flex">
      <Switch>
        <Route exact path={originRoute}>
          <AbstractButton
            label="Create new questionnaire"
            variant="add"
            onClick={() => {
              history.push(route);
            }}
          />
        </Route>
      </Switch>
      <EmergencyButton />
    </div>
  );
};

export default QuestionnaireActions;
