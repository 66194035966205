import shieldLogo from 'assets/images/secure-shield.png';
import DigitToken from 'components/DigitToken';
import { Field, Form, Formik } from 'formik';
import { ChangeEvent, ClipboardEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import { handleInputChange } from 'react-select/dist/declarations/src/utils';
import {
  sendVerificationCodeEmail,
  verifySecurityTokenForPasswordReset,
} from 'services/userService';
import { VerificationCode } from 'types/ApiModels/Login';
import { loginViews } from 'types/Login';
import { setBreadcrumTitle } from 'util/themeUtils';
import { throttle } from 'util/utils';
import { onSubmit } from '../../Login/LoginForm/util';

const VERIFICATION_CODE_LENGTH = 6;

const VerificationToken = ({
  previousValues,
  changeView,
  setPreviousValues,
  isResetting,
  setIsResetting,
  userEmail,
  setValidCode,
  onCancel = () => null,
}) => {
  const [numbers, setNumbers] = useState([]);
  const [code, setCode] = useState('');
  const [codeIsInvalid, setCodeIsInvalid] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setCode(numbers.join(''));
  }, [numbers]);

  const resetCodeValidity = () => setCodeIsInvalid(false);

  const handleTokenChange = () => {
    resetCodeValidity();
  };

  return (
    <div className="d-flex h-100 flex-column">
      <div>
        <Row className="vertical-container">
          <Col className="line-container">
            <div className="grey-line-inv"></div>
          </Col>
          <Col className="line-container">
            <div className="green-line-inv"></div>
          </Col>
        </Row>

        <div className="centered-logo">
          <div className="logo-container">
            <img className="shield-logo" alt="" src={shieldLogo} />
          </div>
        </div>

        <p className="text-left normal-text top25 dynamic-font-heading" style={{ marginTop: 30 }}>
          Welcome to <span className="bold-blue text-bolder">{setBreadcrumTitle()}</span>
        </p>

        <p className="text-left smaller-text dynamic-font-normal">
          <b>
            {previousValues.emailToMessage
              ? previousValues.emailToMessage
              : `Enter your 6 digit verification code sent to ${userEmail}`}
          </b>
          . Didn&apos;t receive your code yet?{' '}
          <button
            type="button"
            className="link"
            onClick={throttle(
              () => sendVerificationCodeEmail(previousValues.emailToMessage || userEmail),
              5000
            )}
          >
            Send it again .
          </button>
        </p>
      </div>
      <Formik
        initialValues={{ usernameEmailPhone: '' }}
        onSubmit={async (values, { setFieldError }) => {
          if (isResetting) {
            const res = await verifySecurityTokenForPasswordReset(userEmail, code);
            if (res.status === 200) {
              const json: VerificationCode = await res.json();
              setValidCode(json);
              changeView(loginViews.resetPassword);
            } else setCodeIsInvalid(true);
            return;
          }
          //TODO: this looks wonky, We should not spread here, just send what is needed
          await onSubmit(
            {
              ...previousValues,
              email: previousValues?.email ? previousValues.email : userEmail,
              code,
            },
            changeView,
            history,
            setPreviousValues
          );
        }}
      >
        {({ handleBlur, handleChange }) => (
          <Form className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-column flex-grow-1 gap-lg justify-content-center align-items-center">
              <div className="d-flex justify-content-center">
                <DigitToken
                  length={VERIFICATION_CODE_LENGTH}
                  onChange={handleTokenChange}
                  setValue={setNumbers}
                  value={numbers}
                  className="dynamic-font-huge"
                />
              </div>
              {codeIsInvalid ? (
                <div className="dynamic-font-normal text-danger">
                  The code you supplied is not correct, please try again.
                </div>
              ) : null}
            </div>
            <div className="d-flex justify-content-center">
              <Field
                name="usernameEmailPhone"
                type="hidden"
                defaultValue=""
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <Row className="d-flex justify-content-between">
              <Button onClick={onCancel} className="dynamic-font-normal text-bold" variant="light">
                CANCEL
              </Button>
              <Button
                disabled={numbers.filter((n) => n >= 0).length !== VERIFICATION_CODE_LENGTH}
                type="submit"
                className="dynamic-font-normal text-bold"
              >
                CONTINUE
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default VerificationToken;
