import Card from 'components/Card';
import GenericTable from 'components/GenericTable';
import { useDialog } from 'components/VyTracLayout/store/hooks';
import { FC, useMemo, useState } from 'react';
import { createPatientDevice, patchPatientDevice } from 'services/patientService';
import DeviceMeasureType from 'types/ApiModels/Patients/device-measure-type';
import DeviceType from 'types/ApiModels/Patients/device-type';
import { usePatientManagementDevices } from '../hooks';
import usePatientManagementDeviceTypes from '../hooks/use-patient-management-device-types';
import AddDeviceModal from './add-device-modal';
import { DevicesCardHeader } from './devices-card-header';
import { devicesColumns, mapDeviceToDeviceRow } from './utils';

interface DevicesCardProps {
  devicesMeasureTypes: DeviceMeasureType[];
  patientId: number;
}

const DevicesCard: FC<DevicesCardProps> = ({
  devicesMeasureTypes,
  patientId,
}: DevicesCardProps) => {
  const [showAddDeviceModal, setShowAddDeviceModal] = useState(false);
  const { errorDialog, scrollTop } = useDialog();

  const [devices, setDevices] = usePatientManagementDevices();
  const [deviceTypes, setDeviceTypes] = usePatientManagementDeviceTypes();

  const devicesRows = useMemo(() => {
    return devices?.map((d) => mapDeviceToDeviceRow(d, devicesMeasureTypes));
  }, [devices, devicesMeasureTypes]);

  const handleCloseModal = () => {
    setShowAddDeviceModal(false);
  };
  const handleAddDevice = () => {
    setShowAddDeviceModal(true);
  };
  const handleStatusClick = (deviceId: number) => async () => {
    const device = devices.find((d) => d.id === deviceId);
    setDevices((ds) => {
      const foundDeviceIdx = ds.findIndex((d) => d.id === deviceId);
      const devicesCopy = [...ds];
      const deviceToUpdate = devicesCopy[foundDeviceIdx];
      devicesCopy[foundDeviceIdx] = { ...deviceToUpdate, is_active: !deviceToUpdate.is_active };
      return devicesCopy;
    });
    const [, error] = await patchPatientDevice(patientId, deviceId, {
      is_active: !device.is_active,
    });
    if (error) {
      setDevices((ds) => {
        const foundDeviceIdx = ds.findIndex((d) => d.id === deviceId);
        const devicesCopy = [...ds];
        devicesCopy[foundDeviceIdx] = device;
        return devicesCopy;
      });
      errorDialog('Error', 'Error while trying to save your device changes');
    }
  };
  const handleCreateDevice = async (deviceType: DeviceType, serialNumber: string) => {
    const [created, error] = await createPatientDevice(patientId, {
      patient: patientId,
      device_id: serialNumber,
      is_active: true,
      model: deviceType.name,
      type_id: deviceType.id,
      created_at: new Date().toISOString(),
    });
    if (error) {
      errorDialog('Error', 'Error creating device for patient');
      scrollTop();
    } else {
      setDevices((d) => {
        return [...d, created];
      });
    }
    setShowAddDeviceModal(false);
  };

  const [hoveringId, setHoveringId] = useState<number>();
  const handleHover = (id: number) => () => {
    setHoveringId(id);
  };
  const handleHoverOut = () => {
    setHoveringId(null);
  };

  return (
    <>
      <Card
        headers={[
          <DevicesCardHeader
            devicesCount={devices.length}
            handleAddDevice={handleAddDevice}
            key="header"
          />,
        ]}
        className="card-bg-border"
        bodyClassName="p-0"
      >
        <GenericTable
          columns={devicesColumns({ handleStatusClick, handleHover, handleHoverOut, hoveringId })}
          data={devicesRows}
        />
      </Card>
      {showAddDeviceModal ? (
        <AddDeviceModal
          onClose={handleCloseModal}
          handleCreate={handleCreateDevice}
          show={showAddDeviceModal}
          deviceTypes={deviceTypes}
          setDeviceTypes={setDeviceTypes}
          deviceMeasureTypes={devicesMeasureTypes}
        />
      ) : null}
    </>
  );
};
export default DevicesCard;
