import moment from 'moment';
import { IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';

export const countAdherenceRequests = (
  timelineActions: IPatientTimelineAction[],
  blockStartDate: string
): number => {
  if (!blockStartDate) return 0;
  const blockStartMoment = moment(blockStartDate);
  const blockEndMoment = blockStartMoment.clone().add(30, 'days');
  const vytalActions = timelineActions.filter(
    (action) =>
      action.event_type === 'vitals_request' &&
      blockStartMoment.isSameOrBefore(action.start_date) &&
      blockEndMoment.isSameOrAfter(action.start_date)
  );
  return vytalActions.length;
};
