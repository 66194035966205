/**
 * Warning this may fall out of sync with backend and should be kept updated. Sadly we need this statically on frontend to be able to correctly set auth throughout theapp
 */
export enum PermissionKeys {
  'DASHBOARD' = 'DASHBOARD',
  'PATIENTS' = 'PATIENTS',
  'PATIENTS:list' = 'PATIENTS:list',
  'PATIENTS:patient_status' = 'PATIENTS:patient_status',
  'PATIENTS:patient_timeline' = 'PATIENTS:patient_timeline',
  'PATIENTS:team_notes' = 'PATIENTS:team_notes',
  'PATIENTS:care_plan' = 'PATIENTS:care_plan',
  'PATIENTS:patient_reports' = 'PATIENTS:patient_reports',
  'PATIENTS:encounters' = 'PATIENTS:encounters',
  'PATIENTS:goals_threshold' = 'PATIENTS:goals_threshold',
  'PATIENTS:questionnaires' = 'PATIENTS:questionnaires',
  'PATIENTS:patient_management' = 'PATIENTS:patient_management',
  'PATIENT_CASES' = 'PATIENT_CASES',
  'SCHEDULE' = 'SCHEDULE',
  'APPOINTMENTS' = 'APPOINTMENTS',
  'MESSAGES' = 'MESSAGES',
  'TEMPLATES' = 'TEMPLATES',
  'TEMPLATES:care_plan_templates' = 'TEMPLATES:care_plan_templates',
  'TEMPLATES:questionnaires' = 'TEMPLATES:questionnaires',
  'TEMPLATES:surveys' = 'TEMPLATES:surveys',
  'REVENUE_MANAGEMENT' = 'REVENUE_MANAGEMENT',
  'ADMINISTRATION' = 'ADMINISTRATION',
  'ADMINISTRATION:dashboard' = 'ADMINISTRATION:dashboard',
  'ADMINISTRATION:providers' = 'ADMINISTRATION:providers',
  'ADMINISTRATION:roles' = 'ADMINISTRATION:roles',
  'ADMINISTRATION:patients' = 'ADMINISTRATION:patients',
  'ADMINISTRATION:patient_settings' = 'ADMINISTRATION:patient_settings',
  'ADMINISTRATION:forms' = 'ADMINISTRATION:forms',
  'ADMINISTRATION:email_settings' = 'ADMINISTRATION:email_settings',
  'ADMINISTRATION:account_settings' = 'ADMINISTRATION:account_settings',
  'ADMINISTRATION:reports' = 'ADMINISTRATION:reports',
  'ADMINISTRATION:rules_engine' = 'ADMINISTRATION:rules_engine',
  'ADMINISTRATION:messages' = 'ADMINISTRATION:messages',
  'ADMINISTRATION:care_plan_templates' = 'ADMINISTRATION:care_plan_templates',
  'ADMINISTRATION:audit_tracking' = 'ADMINISTRATION:audit_tracking',
  'ANALYTICS' = 'ANALYTICS',
  'RESOURCES' = 'RESOURCES',
  'SUPPORT' = 'SUPPORT',
}
