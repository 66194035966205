import { ReactComponent as AppleStore } from 'assets/icons/get_in_apple_store.svg';
import { ReactComponent as GooglePlay } from 'assets/icons/get_in_google_play.svg';
import Card from 'components/Card';
import { FC } from 'react';
import { useRegisterContext } from 'screens/Register/store';
import styles from '../styles.module.css';

const CompleteRegistrationCard: FC = () => {
  const { email } = useRegisterContext();

  return (
    <Card
      className={`vytrac-card ${styles['register-patient-card']}`}
      bodyClassName="d-flex flex-column gap-md text-center"
    >
      <h1 className="font-size-extra-large font-weight-md">
        &#127881; <br />
        Registration complete
      </h1>
      <p className="font-size-large">Your account has been set up using {email}</p>

      <p className="font-size-large font-weight-md">Download the application from</p>

      <div className="d-flex flex-column gap-lg justify-content-center align-items-center">
        <a href="#googlePlay">
          <GooglePlay className="cursor-pointer" />
        </a>
        <a href="#appleStore">
          <AppleStore className="cursor-pointer" />
        </a>
      </div>
    </Card>
  );
};
export default CompleteRegistrationCard;
