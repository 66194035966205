import React, { CSSProperties, HTMLProps, useMemo } from 'react';
import styles from './styles.module.css';

interface INumberContianer extends HTMLProps<HTMLDivElement> {
  dayNumber: number;
  isInBlock: boolean;
  isAdhered: boolean;
  isDisplayed: boolean;
}
export const NumberContainer = ({
  dayNumber,
  isInBlock = false,
  isAdhered = false,
  isDisplayed = false,
  onClick,
}: INumberContianer) => {
  const isAdheredStyle: CSSProperties = {
    backgroundColor: '#80CCE4',
    color: 'white',
  };

  const isNotBlockStyle: CSSProperties = {
    background: ' inherit',
  };

  const isDisplayedStyle: CSSProperties = {
    border: '1px solid #8FAAC4',
  };

  const getContainerStyle = () => {
    let containerStyle: CSSProperties = {};
    if (isAdhered) containerStyle = { ...isAdheredStyle };

    if (!isInBlock) containerStyle = { ...containerStyle, ...isNotBlockStyle };

    if (isDisplayed) containerStyle = { ...containerStyle, ...isDisplayedStyle };

    return containerStyle;
  };

  return (
    <div
      key={`value-${dayNumber}`}
      className={styles.numberContainer}
      style={getContainerStyle()}
      onClick={onClick}
    >
      {dayNumber}
    </div>
  );
};
export default NumberContainer;
