import { AxiosError } from 'axios';
import Card from 'components/Card';
import GenericTable from 'components/GenericTable';
import { PaginationControlsAlt } from 'components/Pagination/PaginationControls';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import SearchFilterHeader from 'components/SearchFilterHeader';
import { useDialog } from 'components/VyTracLayout/store/hooks';
import { useDebouncedSearch } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAdministrationContext } from 'screens/Administration/store';
import {
  appendCreatedCareTeam,
  appendUpdatedCareTeam,
  resetDeleteCareTeams,
  resetUpdateCreatedCareTeams,
  resetUpdatedCareTeams,
  setAdminCareTeamBook,
  setCareTeamsBookAndMatchTable,
  setCreateCareTeam,
  setCurrentCareTeamId,
  setSubmitCareTeamForm,
  updateCareTeamDelete,
} from 'screens/Administration/store/CareTeam';
import { useAdminCareTeams } from 'screens/Administration/store/hooks';
import {
  bulkUpdateCareTeams,
  createCareTeams,
  deleteCareTeams,
  searchCareTeams,
} from 'services/administrationService';
import { CareTeam, CareTeamCreateUpdateRequest } from 'types/ApiModels/CareTeam';
import { findInBook } from 'util/bookUtils';
import { getErrorMessage, negativeLocalIdCreator } from 'util/utils';
import AdminCareTeamActions from './AdminCareTeamActions';
import CareTeamCreateEditModal from './CareTeamCreateEditModal';
import CareTeamPatientModal from './CareTeamPatientModal';
import CareTeamProviderModal from './CareTeamProviderModal';
import styles from './styles.module.css';
import { CareTeamCreateEditFormikFields, CareTeamRow } from './types';
import { createCareTeamColumn, mapCareTeamToCareTeamRow } from './utils';

const createNegativeId = negativeLocalIdCreator();

const AdminCareTeam = () => {
  const { setActions } = useAdministrationContext();
  const [showProvidersModal, setShowProvidersModal] = useState(false);
  const [showPatientsModal, setShowPatientsModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { confirmationDialog, errorDialog } = useDialog();

  const [
    {
      careTeamsBookFromTable,
      deletedCareTeams,
      currentCareTeamId,
      createdCareTeams,
      updatedCareTeams,
    },
    { book, currentPage, first, last, next, pages, previous },
    dispatch,
  ] = useAdminCareTeams();

  useEffect(() => {
    dispatch(
      setSubmitCareTeamForm(() => {
        setShowConfirmationModal(true);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCareTeamsBookAndMatchTable(book));
  }, [book, dispatch]);

  useEffect(() => {
    dispatch(
      setCreateCareTeam(() => {
        setShowCreateEditModal(true);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setActions(AdminCareTeamActions);
  }, []);

  const handleFiltersClick = () => {};

  const handleDelete = useCallback(
    (id: number) => {
      //remove from table and add to list of deleted.
      dispatch(updateCareTeamDelete(id, currentPage));
    },
    [dispatch, currentPage]
  );

  const handlePreventDelete = useCallback(() => {
    setShowDeleteWarning(true);
  }, []);

  const handleDeleteWithPrevent = useCallback(
    (id: number, patientsCount: number, providersCount: number) => {
      if (patientsCount || (providersCount && id > 0)) {
        handlePreventDelete();
        return;
      }
      handleDelete(id);
    },
    [handleDelete, handlePreventDelete]
  );

  const handleEdit = useCallback(
    (careTeamId: number) => {
      dispatch(setCurrentCareTeamId(careTeamId));
      setShowCreateEditModal(true);
      setIsEditing(true);
    },
    [dispatch]
  );

  const handlePatientCountClick = useCallback(
    (careTeamId: number) => {
      dispatch(setCurrentCareTeamId(careTeamId));
      setShowPatientsModal(true);
    },
    [dispatch]
  );
  const handleProviderCountClick = useCallback(
    (careTeamId: number) => {
      dispatch(setCurrentCareTeamId(careTeamId));
      setShowProvidersModal(true);
    },
    [dispatch]
  );

  const handleProviderModalClose = () => {
    setShowProvidersModal(false);
  };

  const handlePatientModalClose = () => {
    setShowPatientsModal(false);
  };

  const columns = useMemo(() => {
    return createCareTeamColumn({
      styles,
      handleEdit,
      handleDeleteWithPrevent,
      handlePatientCountClick,
      handleProviderCountClick,
    });
  }, [handleDeleteWithPrevent, handleEdit, handlePatientCountClick, handleProviderCountClick]);

  const { search, handleSearch, mappedSearchResult } = useDebouncedSearch<CareTeam, CareTeamRow>(
    searchCareTeams,
    mapCareTeamToCareTeamRow
  );

  const currentPageCareTeams = useMemo(
    () => careTeamsBookFromTable?.[currentPage]?.results.map(mapCareTeamToCareTeamRow) ?? [],
    [careTeamsBookFromTable, currentPage]
  );

  const bulkDeleteCareTeams = async (careTeams: number[]) => {
    if (!careTeams.length) return [null, null];
    try {
      const res = await deleteCareTeams(careTeams);
      return [res, null];
    } catch (error) {
      return [null, (error as AxiosError).response.data];
    }
  };
  const bulkCreateCareTeams = async (
    careTeams: CareTeamCreateUpdateRequest[]
  ): Promise<[res: CareTeam[] | null, error: any]> => {
    if (!careTeams.length) return [null, null];
    try {
      const res = await createCareTeams(careTeams);
      return [res, null];
    } catch (error) {
      return [null, (error as AxiosError).response.data];
    }
  };

  const handleSubmitCareTeamChanges = async () => {
    //update
    const [, updatedError] = await bulkUpdateCareTeams(
      updatedCareTeams.map((uct) => ({
        id: uct.id,
        name: uct.name,
        tiers: uct.tiers.map((t) => ({
          id: t.id < 0 ? undefined : t.id,
          name: t.name,
          number: t.number,
          providers: t.providers,
        })),
      }))
    );
    //create
    const [created, createError] = await bulkCreateCareTeams(createdCareTeams);
    //delete
    const [, deleteError] = await bulkDeleteCareTeams(deletedCareTeams);

    const errorMessage = getErrorMessage([updatedError, createError, deleteError]);

    if (errorMessage) {
      errorDialog('Error', `Error occurred while saving changes: ${errorMessage}`);
    } else {
      confirmationDialog('Success', 'Data saved successfully');
      dispatch(setAdminCareTeamBook(careTeamsBookFromTable));
      dispatch(resetUpdatedCareTeams());
      dispatch(resetDeleteCareTeams());
      created && dispatch(resetUpdateCreatedCareTeams(created));
    }
    setShowConfirmationModal(false);
  };

  const currentCareTeam = useMemo(() => {
    const [, result] = findInBook(careTeamsBookFromTable, currentCareTeamId);
    return result;
  }, [careTeamsBookFromTable, currentCareTeamId]);

  const handleCancelCreateEdit = () => {
    setShowCreateEditModal(false);
    dispatch(setCurrentCareTeamId(null));
  };
  const handleConfirmCreateEdit = (values: CareTeamCreateEditFormikFields) => {
    setShowCreateEditModal(false);
    if (isEditing) {
      dispatch(appendUpdatedCareTeam(values));
    } else {
      dispatch(appendCreatedCareTeam({ ...values, id: createNegativeId() }));
    }
    dispatch(setCurrentCareTeamId(null));
    setIsEditing(false);
  };

  return (
    <Card
      className="card-bg-border"
      headers={[
        <SearchFilterHeader
          handleSearch={handleSearch}
          onFiltersClick={handleFiltersClick}
          title="Care teams"
          search={search}
          key="care-team-header"
        />,
      ]}
    >
      <GenericTable
        columns={columns}
        data={search ? mappedSearchResult : currentPageCareTeams}
        headerRowClassName="d-flex gap align-items-center"
        rowClassName="d-flex gap align-items-center"
      />
      <div className="d-flex justify-content-end">
        <PaginationControlsAlt
          currentPage={currentPage}
          handleFirst={first}
          handleLast={last}
          handleNext={next}
          handlePrevious={previous}
          maxPage={pages}
        />
      </div>
      {showProvidersModal ? (
        <CareTeamProviderModal onClose={handleProviderModalClose} show={showProvidersModal} />
      ) : null}
      {showPatientsModal ? (
        <CareTeamPatientModal onClose={handlePatientModalClose} show={showPatientsModal} />
      ) : null}
      {showCreateEditModal ? (
        <CareTeamCreateEditModal
          currentCareTeam={currentCareTeam}
          onCancel={handleCancelCreateEdit}
          onConfirm={handleConfirmCreateEdit}
          show={showCreateEditModal}
          onDelete={handleDeleteWithPrevent}
        />
      ) : null}

      <SaveChangesConfirmationModal
        variant="danger"
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={handleSubmitCareTeamChanges}
        show={showConfirmationModal}
      />

      <SaveChangesConfirmationModal
        variant="caution"
        onCancel={() => setShowDeleteWarning(false)}
        title="Warning"
        message={
          'Deleting a team is only possible if the team has no assigned providers or patients.'
        }
        show={showDeleteWarning}
        buttons="understand"
      />
    </Card>
  );
};
export default AdminCareTeam;
