import PatientManagementAddButton from 'components/PatientManagementAddButton';
import { PatientManagementFormikKeys } from 'components/PatientManagementForm/types';
import TextSurroundedWithLines from 'components/TextSurroundedWithLines';
import { useFormikContext } from 'formik';
import createNewEmergencyContact from '../util/createNewEmergencyContact';
import EmergencyContactInfoTable from './EmergencyContactInfoTable/EmergencyContactInfoTable';
import styles from './styles.module.css';

const EmergencyContacts = ({ formData, handleChange, errors, touched = null }) => {
  // Context
  const { setFormikState, setFieldTouched } = useFormikContext();

  // Functions and subroutines
  const addNewEmergencyContact = () =>
    setFormikState(({ values, ...prevFormikState }) => {
      return {
        ...prevFormikState,
        values: {
          ...values,
          [PatientManagementFormikKeys.EMERGENCY_CONTACTS]: [
            ...values[PatientManagementFormikKeys.EMERGENCY_CONTACTS],
            createNewEmergencyContact(undefined),
          ],
        },
      };
    });

  const handleChangeTouched = (name) => (e) => {
    setFieldTouched(name, true);
    handleChange(e);
  };

  return (
    <div className="dashboard-detail pb-0">
      <div className={`dashboard-item-header ${styles.header}`}>
        <span className="font-weight-md">
          Emergency contacts {formData.length ? ` (${formData.length})` : ''}{' '}
        </span>
        <PatientManagementAddButton onClick={addNewEmergencyContact} />
      </div>
      {formData.length > 0 ? (
        <div className={styles.body}>
          {formData.map((contact, index) => {
            return (
              <EmergencyContactInfoTable
                title={index === 0 ? 'primary emergency contact' : 'other emergency contact'}
                contactInformation={contact}
                index={index}
                onChange={handleChangeTouched}
                errors={errors}
                touched={touched}
                key={`emergency-contact-table-${contact.id}`}
              />
            );
          })}
        </div>
      ) : (
        <div className="p-3">
          <TextSurroundedWithLines
            text="No emergency contacts added"
            className="font-size-medium text-gray-low-risk"
          />
        </div>
      )}
    </div>
  );
};

export default EmergencyContacts;
