import { CareTeam } from '../CareTeam';

export enum InvitationTypes {
  PROVIDER = 'provider',
  PATIENT = 'patient',
}

interface Invitation {
  type: InvitationTypes;
  hash: string;
  email: string;
  care_team: CareTeam;
}

export default Invitation;
