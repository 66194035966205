import { Tagitem } from 'components/Tagitem';
import { FormCheck } from 'react-bootstrap';
import styles from './styles.module.css';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { useMemo } from 'react';
import { get12hFormatObject } from 'util/dateUtils';
interface ActivityProps {
  time: number;
  activityName: string;
  population: string;
  selected: boolean;
  toggled: boolean;
  setToggled: (value: boolean) => void;
  day: number;
  index: number;
  label: string;
  readonly: boolean;
}
const Activity = ({
  time,
  activityName,
  population,
  selected,
  toggled,
  setToggled,
  day,
  index,
  label,
  readonly,
}: ActivityProps) => {
  const amPmTime = useMemo(() => get12hFormatObject(time), [time]);
  return (
    <div className={styles['row']} style={{ backgroundColor: selected && '#E7EAED' }}>
      <div className="d-flex gap w-100 align-items-center">
        <div style={{ fontWeight: selected && 600 }}>
          <span>
            {amPmTime.hour12Format} {amPmTime.ampm}
          </span>
        </div>
        <div style={{ backgroundColor: selected && '#393E48' }} className={styles['dot']}></div>
        {!readonly && (
          <FormCheck
            id={`${activityName}_${population}_${time}_${day}_${index}`}
            type="switch"
            checked={toggled}
            onChange={() => setToggled(!toggled)}
          />
        )}
        <div style={{ fontWeight: selected && 600 }}>
          {activityName} {label}
        </div>
      </div>
      <div className="d-flex gap align-items-center">
        {population && (
          <Tagitem
            text={population}
            style={{ backgroundColor: selected && '#282556', color: selected && 'white' }}
            className={styles['tag']}
          />
        )}
        {selected && !readonly && <DragIcon fill="#8FAAC3" />}
      </div>
    </div>
  );
};
export default Activity;
