import React from 'react';
import { FamilyHistory, Allergy, Surgery } from 'types/ApiModels/Patients/Patient';
import styles from './styles.module.css';

interface IHistoryPreview {
  title: string;
  records: FamilyHistory[] | Allergy[] | Surgery[];
  singular: string;
  plural: string;
  onClick?: () => void;
}

const SurgeryHistory = ({ title, records, singular, plural, onClick }: IHistoryPreview) => {
  return (
    <div className={styles.main}>
      <div className={styles.title}>{title}</div>
      <button className={styles.historyButton} onClick={onClick}>
        {records?.length} {records?.length === 1 ? singular : plural}
      </button>
    </div>
  );
};
export default SurgeryHistory;
