import styles from '../../styles.module.css';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import DropdownMenu from 'components/DropdownMenu';
import { SelectFormik, TextInputFormik } from 'components/FormikComponents';
import { getStyles } from '../../../utils/getStyles';
import { AddRow } from '../../Steps';
import { useMemo } from 'react';
import { familyHistoryRelationshipOptions } from 'util/option-utils';

const FamilyHistory = () => {
  const { values, touched, errors } = useFormikContext<InvitePatient>();
  const openError = useMemo(() => errors.familyHistory?.length > 0 || false, [errors]);

  const relationValue = useMemo(() => {
    return values.familyHistory?.length > 0
      ? values.familyHistory.map((_, index) => {
          const relation = values.familyHistory[index].relationToPatient;
          return {
            label: familyHistoryRelationshipOptions.find((e) => e.value === relation)?.label,
            value: relation,
          };
        })
      : [];
  }, [values.familyHistory]);
  return (
    <DropdownMenu name="Family history" open={openError}>
      <FieldArray validateOnChange={false} name="familyHistory">
        {({ push, remove }) => (
          <>
            {values.familyHistory && values.familyHistory.length > 0 ? (
              values.familyHistory.map((_, index) => (
                <div className="d-flex flex-column gap" key={index}>
                  <div className={styles['row']}>
                    <div className="d-flex gap px-3 pt-3 mb-2">
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Relation to patient
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <SelectFormik
                          options={familyHistoryRelationshipOptions}
                          value={relationValue[index]}
                          name={`familyHistory[${index}].relationToPatient`}
                          placeholder="Assign relationship..."
                          styles={getStyles(
                            errors,
                            touched,
                            `familyHistory[${index}].relationToPatient`
                          )}
                        />
                        <ErrorMessage name={`familyHistory[${index}].relationToPatient`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Medical condition
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <TextInputFormik
                          className="w-100"
                          name={`familyHistory[${index}].medicalCondition`}
                          style={getStyles(
                            errors,
                            touched,
                            `familyHistory[${index}].medicalCondition`
                          )}
                        />
                        <ErrorMessage name={`familyHistory[${index}].medicalCondition`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon fill="#8FAAC3" stroke="#8FAAC3" />
                      </div>
                    </div>
                  </div>
                  <div className={styles['row']}>
                    <div className="d-flex gap px-3 mb-2">
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Details
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <TextInputFormik
                          className="w-100"
                          name={`familyHistory[${index}].details`}
                          style={getStyles(errors, touched, `familyHistory[${index}].details`)}
                        />
                        <ErrorMessage name={`familyHistory[${index}].details`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  {index === values.familyHistory.length - 1 && (
                    <AddRow
                      name="family history"
                      onClick={() =>
                        push({ relationToPatient: '', medicalCondition: '', details: '' })
                      }
                    />
                  )}
                </div>
              ))
            ) : (
              <AddRow
                name="family history"
                onClick={() => push({ relationToPatient: '', medicalCondition: '', details: '' })}
              />
            )}
          </>
        )}
      </FieldArray>
    </DropdownMenu>
  );
};
export default FamilyHistory;
