import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getPatientDeviceTypes } from 'services/patientService';
import DeviceType from 'types/ApiModels/Patients/device-type';

const usePatientManagementDeviceTypes = (): [
  deviceTypes: DeviceType[],
  setDeviceTypes: Dispatch<SetStateAction<DeviceType[]>>
] => {
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>();

  const fetchDeviceTypes = async () => {
    const res = await getPatientDeviceTypes();

    setDeviceTypes(res.results);
  };

  useEffect(() => {
    fetchDeviceTypes();
  }, []);

  return [deviceTypes, setDeviceTypes];
};

export default usePatientManagementDeviceTypes;
