import { vytracAxios } from 'ajax';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PaginatedResponse } from 'types/ApiModels/General';

export interface PaginatedRequestHook<T> {
  first: () => void;
  next: () => void;
  previous: () => void;
  last: () => void;
  getPage: (number) => void;
  book: Record<number, PaginatedResponse<T>>;
  currentPage: number;
  pages: number;
  pageSize: number;
  count: number;
  init: () => Promise<void>;
  extra?: any;
  refetch: () => void;
}

const mapAdditionalParams = (params: Record<string, unknown>): string => {
  if (!params) return '';
  let result = '';
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      result += `&${key}=${params[key]}`;
    }
  }
  return result;
};

export const usePaginatedRequest = <T>(
  kickStartUri: string,
  pageSize = 8,
  initialFeed?: Record<number, PaginatedResponse<T>>,
  autoInitialize = true,
  additionalParams?: Record<string, unknown>
): PaginatedRequestHook<T> => {
  const [paginatedCache, setPaginatedCache] =
    useState<Record<number, PaginatedResponse<T>>>(initialFeed);
  const [currentPage, setCurrentPage] = useState(null);
  const [pages, setPages] = useState<number>(null);
  const [count, setCount] = useState<number>(null);

  const defaultUri = useMemo(
    () => `${kickStartUri}?size=${pageSize}${mapAdditionalParams(additionalParams)}`,
    [kickStartUri, pageSize, additionalParams]
  );
  const fetchResultInitial = useCallback(async () => {
    try {
      const res = await vytracAxios.get<PaginatedResponse<T>>(defaultUri);
      setPaginatedCache(() => {
        return { 1: res.data };
      });
      setCurrentPage(1);
      setPages(res.data.pages);
      setCount(res.data.count);
    } catch (error) {
      throw error;
    }
  }, [defaultUri]);

  const fetchResult = useCallback(
    async (uri: string, force?: boolean) => {
      try {
        const pageParam = new URLSearchParams(uri.split('?')[1]).get('page');
        const page = isNaN(parseInt(pageParam)) ? 1 : parseInt(pageParam);
        if (paginatedCache?.[page] && !force) {
          return;
        }
        const res = await vytracAxios.get<PaginatedResponse<T>>(uri);
        setPaginatedCache((pc) => {
          if (!pc) return { [page]: res.data };
          if (pc[page] && !force) {
            return pc;
          }
          const pcCopy = { ...pc };
          pcCopy[page] = res.data;
          return pcCopy;
        });
      } catch (error) {
        throw error;
      }
    },
    [paginatedCache]
  );

  useEffect(() => {
    if (!initialFeed && autoInitialize) {
      fetchResultInitial();
      return;
    }
    setPaginatedCache(initialFeed);
    setCurrentPage((page: number) => (page ? page : 1));
  }, [autoInitialize, fetchResultInitial, initialFeed]);

  const currentPageResult = useMemo(
    () => paginatedCache?.[currentPage],
    [currentPage, paginatedCache]
  );
  const init = useCallback((): Promise<void> => fetchResultInitial(), [fetchResultInitial]);

  const refetch = useCallback(() => fetchResult(defaultUri, true), [defaultUri, fetchResult]);

  return {
    first: () => {
      fetchResult(defaultUri);
      setCurrentPage(1);
    },
    next: () => {
      if (currentPageResult?.next) {
        fetchResult(currentPageResult.next);
        setCurrentPage((cp: number) => cp + 1);
      }
    },
    previous: () => {
      if (currentPageResult?.previous) {
        fetchResult(currentPageResult.previous);
        setCurrentPage((cp: number) => cp - 1);
      }
    },
    last: () => {
      fetchResult(`${defaultUri}&page=${pages}`);
      setCurrentPage(pages);
    },
    getPage: (number) => {
      fetchResult(`${defaultUri}&page=${number}`);
      setCurrentPage(number);
    },
    book: paginatedCache,
    currentPage,
    pages,
    pageSize,
    count,
    init,
    refetch,
    ...(currentPageResult && { extra: currentPageResult.extra }),
  };
};
