import DatePicker from 'components/DatePicker';
import Select from 'components/form/Select';
import NormalNote from 'components/Notes/components/NormalNote';
import ScheduleNote from 'components/Notes/components/ScheduledNote';
import PatientProfileRing from 'components/patient/PatientProfileRing';
import UserBubble from 'components/UserBubble';
import UserSelect from 'components/UserSelect';
import React, { useState } from 'react';
import { getPatientsAutocompleteList, getSinglePatient } from 'services/patientService';
import { getProvidersAutocompleteList, getSingleProvider } from 'services/providersService';
import { Appointment, AppointmentType } from 'types/ApiModels/Appointments/Appointment';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { Provider } from 'types/ApiModels/Providers/Provider';
import DatePickerValue from 'types/Shared/DatePicker';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';
import { mapPatientSearchToResult, mapProviderSearchToResult } from '../utils';
import styles from './styles.module.css';

const typeOptions = Object.values(AppointmentType);

interface IBody {
  selectedAppointment: Appointment;
  setSelectedAppointment: (appointment: Appointment) => void;
}
export const Body = ({ selectedAppointment, setSelectedAppointment }: IBody) => {
  const [showTeamNote, setShowTeamNote] = useState<boolean>(false);
  const [showScheduleNote, setShowScheduleNote] = useState<boolean>(false);
  const [showProvidersScheduleNote, setShowProvidersScheduleNote] = useState<boolean>(false);
  const [showPatientNote, setShowPatientNote] = useState<boolean>(false);
  const [patientValue, setPatientValue] = useState<PatientGetSingle>(null);
  const [providerValue, setProviderValue] = useState<Provider>(null);

  const onAppointmentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAppointment({
      ...selectedAppointment,
      appointment_type: e.target.value as AppointmentType,
    });
  };

  const onStartDateChange = (pickerValue: DatePickerValue) => {
    const newStartDate = convertDatepickToDate(pickerValue);
    setSelectedAppointment({
      ...selectedAppointment,
      appointment_from: newStartDate.toISOString(),
    });
  };

  const onPatientChange = async (id: number) => {
    const newPatient = await getSinglePatient(id);
    setPatientValue(newPatient);
    setSelectedAppointment({ ...selectedAppointment, patient_id: id });
  };

  const onProviderChange = async (id: number) => {
    const newProvider = await getSingleProvider(id);
    setProviderValue(newProvider);
    setSelectedAppointment({ ...selectedAppointment, provider_id: id });
  };

  return (
    <div className="d-flex flex-column font-size-big">
      <div className={styles.rowContainer}>
        <div>Type</div>
        <div className={styles.select}>
          <Select
            currentValue={selectedAppointment?.appointment_type}
            options={typeOptions}
            selectClassName={styles.typeSelect}
            onChange={onAppointmentTypeChange}
          />
        </div>
      </div>
      <div className={styles.rowContainer}>
        <div>Date & Time</div>
        {selectedAppointment?.appointment_from && (
          <DatePicker
            placeHolder="Select Date"
            containerClassName={styles.pickerContainer}
            inputClassName={styles.pickerInput}
            value={convertDateToDatePickValue(
              new Date(selectedAppointment?.appointment_from),
              true
            )}
            onChange={onStartDateChange}
            hasFooter
          />
        )}
      </div>
      <div className={styles.rowContainer}>
        <div>Patient</div>
        <UserSelect
          containerClassName={styles.userSelect}
          labelClassName="ml-2"
          value={
            patientValue
              ? {
                  profileImg: <PatientProfileRing currentPatient={patientValue} size={30} />,
                  name: `${patientValue.user.first_name} ${patientValue.user.last_name}`,
                }
              : null
          }
          onChange={onPatientChange}
          onSearch={(searchValue: string) => getPatientsAutocompleteList(searchValue, 5)}
          searchResultsMapper={mapPatientSearchToResult}
          valuePlaceholder="Select a patient..."
          inputPlaceholder="Search patient..."
        />
      </div>
      <div className={styles.rowContainer}>
        <div>Provider</div>
        <UserSelect
          containerClassName={styles.userSelect}
          value={
            providerValue
              ? {
                  profileImg: (
                    <UserBubble
                      user={{
                        first_name: providerValue.first_name,
                        last_name: providerValue.last_name,
                      }}
                      containerClassNameList={[styles.providerImg]}
                    />
                  ),
                  name: `${providerValue.first_name} ${providerValue.last_name}`,
                }
              : null
          }
          onChange={onProviderChange}
          onSearch={(searchValue: string) => getProvidersAutocompleteList(searchValue, 5)}
          searchResultsMapper={mapProviderSearchToResult}
          valuePlaceholder="Select a provider..."
          inputPlaceholder="Search provider..."
        />
      </div>
      <NormalNote
        currentValue={'test value'}
        label="Add Team Note"
        boxShadow={styles.boxShadowTop}
        onChangeCheckbox={() => setShowTeamNote(!showTeamNote)}
        checked={showTeamNote}
        onNoteChange={() => null}
        textAreaClassName={styles.textArea}
      />
      <ScheduleNote
        label="Add Note to My Schedule"
        boxShadow={styles.boxShadowTop}
        currentValue={'test value'}
        onChangeCheckbox={() => setShowScheduleNote(!showScheduleNote)}
        checked={showScheduleNote}
        onNoteChange={() => null}
        textAreaClassName={styles.textArea}
      />
      <ScheduleNote
        currentValue={'test value'}
        label="Add Note to providers schedule"
        boxShadow={styles.boxShadowTop}
        onChangeCheckbox={() => setShowProvidersScheduleNote(!showProvidersScheduleNote)}
        checked={showProvidersScheduleNote}
        onNoteChange={() => null}
        textAreaClassName={styles.textArea}
      />
      <NormalNote
        currentValue={'test value'}
        label="Send Note to Patient"
        boxShadow={styles.boxShadowTop}
        onChangeCheckbox={() => setShowPatientNote(!showPatientNote)}
        checked={showPatientNote}
        onNoteChange={() => null}
        textAreaClassName={styles.textArea}
      />
    </div>
  );
};
