const createNewAddress = (
  address = '',
  apartmentNumber = '',
  city = '',
  state = '',
  zipCode = ''
) => ({
  address,
  apt: apartmentNumber,
  city,
  state,
  zipCode,
});

export default createNewAddress;
