import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { RegisterPatientCards } from '../types';

interface RegisterValues {
  password: string;
  phoneNumber: string;
}
interface RegiterPatientContextState {
  currentCard: RegisterPatientCards;
  setCurrentCard: Dispatch<SetStateAction<RegisterPatientCards>>;
  registerValues: RegisterValues;
  setRegisterValues: Dispatch<SetStateAction<RegisterValues>>;
}

const RegisterPatientContext = createContext<RegiterPatientContextState>(null);

export const useRegisterPatientContext = () => useContext(RegisterPatientContext);

export default RegisterPatientContext;
