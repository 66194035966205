import Card from 'components/Card';
import SwitchInput from 'components/SwitchInput';
import { useState } from 'react';

const ProviderActiveStatus = () => {
  const [active, setActive] = useState(false);
  const [vacationMode, setVacationMode] = useState(false);

  const handleActiveChange = () => {
    setActive((a) => !a);
  };
  const handleVacationModeChange = () => {
    setVacationMode((vm) => !vm);
  };
  return (
    <Card
      className="w-100 card-bg-border"
      bodyClassName="d-flex flex-column gap"
      headers={['Active status']}
    >
      <SwitchInput label="Active" onChange={handleActiveChange} value={active} />
      <SwitchInput label="Vacation mode" onChange={handleVacationModeChange} value={vacationMode} />
    </Card>
  );
};
export default ProviderActiveStatus;
