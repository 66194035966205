import Button from 'components/Button';

const Footer = ({ onClose, onSave }) => {
  return (
    <div className="d-flex justify-content-between w-100">
      <Button label="Cancel" variant="cancel" onClick={onClose} />
      <Button label="confirm" variant="confirm" onClick={onSave} />
    </div>
  );
};
export default Footer;
