import passwordLogo from 'assets/images/password.png';
import { ErrorMessage, Form, Formik } from 'formik';
import { CSSProperties } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { requestPasswordReset } from 'services/userService';
import { loginViews } from 'types/Login';
import PasswordInput from '../../../components/PasswordInput';
import { useLoginContext } from '../store';
import { ResetPasswordValidationSchema } from '../ValidationSchemas';
const invalidColor = 'red';

enum resetPasswordFields {
  password = 'password',
  confirmPassword = 'confirmPassword',
}

const ResetPassword = () => {
  const { validCode, changeView, setIsResettingPassword } = useLoginContext();

  const handleSubmit = async ({ password }) => {
    try {
      const res = await requestPasswordReset(password, validCode);
      if (res.status === 200) {
        changeView(loginViews.login);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const invalidStyle: CSSProperties = { borderBottomColor: invalidColor };

  return (
    <div className="d-flex flex-column h-100">
      <div>
        <Row className="vertical-container">
          <Col className="line-container">
            <div className="grey-line-inv"></div>
          </Col>
          <Col className="line-container">
            <div className="green-line-inv"></div>
          </Col>
        </Row>

        <div className="centered-logo">
          <div className="logo-container">
            <img className="shield-logo" alt="" src={passwordLogo} />
          </div>
        </div>

        <p className="text-left normal-text dynamic-font-heading" style={{ marginTop: 30 }}>
          Reset my password
        </p>

        <p className="text-left smaller-text dynamic-font-normal">Set your new password</p>
      </div>

      <Formik
        initialValues={{
          [resetPasswordFields.password]: '',
          [resetPasswordFields.confirmPassword]: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={ResetPasswordValidationSchema}
      >
        {({ errors, touched, isValid }) => (
          <Form className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-column flex-grow-1">
              <div className="flex-grow-1 d-flex flex-column justify-content-center">
                <p className="grey-text dynamic-font-normal">Password</p>
                <PasswordInput
                  name={resetPasswordFields.password}
                  invalidStyle={
                    touched[resetPasswordFields.password] &&
                    errors[resetPasswordFields.password] &&
                    invalidStyle
                  }
                />
                <p
                  className="grey-text dynamic-font-normal"
                  style={{
                    fontSize: 8,
                    marginTop: 5,
                    color:
                      touched[resetPasswordFields.password] && errors[resetPasswordFields.password]
                        ? invalidColor
                        : '',
                  }}
                >
                  {touched[resetPasswordFields.password] && errors[resetPasswordFields.password] ? (
                    <ErrorMessage name={resetPasswordFields.password} />
                  ) : null}
                </p>
                <p className="grey-text dynamic-font-normal">Confirm password</p>
                <PasswordInput
                  name={resetPasswordFields.confirmPassword}
                  invalidStyle={
                    touched[resetPasswordFields.confirmPassword] &&
                    errors[resetPasswordFields.confirmPassword] &&
                    invalidStyle
                  }
                />
                <p
                  className="grey-text dynamic-font-normal"
                  style={{
                    fontSize: 8,
                    marginTop: 5,
                    color:
                      touched[resetPasswordFields.confirmPassword] &&
                      errors[resetPasswordFields.confirmPassword]
                        ? invalidColor
                        : '',
                  }}
                >
                  {touched[resetPasswordFields.confirmPassword] &&
                  errors[resetPasswordFields.confirmPassword] ? (
                    <ErrorMessage name={resetPasswordFields.confirmPassword} />
                  ) : null}
                </p>
              </div>
            </div>

            <Row className="d-flex justify-content-between">
              <Button
                onClick={() => {
                  setIsResettingPassword(false);
                  return changeView(loginViews.login);
                }}
                variant="light"
                className="dynamic-font-normal text-bold"
              >
                CANCEL
              </Button>
              <Button disabled={!isValid} type="submit" className="dynamic-font-normal text-bold">
                SET NEW PASSWORD
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default ResetPassword;
