import Card from 'components/Card';
import CardHeaderSearchFilter from 'components/card-header-search-filter';
import GenericTable from 'components/GenericTable';
import { PaginationControlsAlt } from 'components/Pagination/PaginationControls';
import { useDebouncedSearch } from 'hooks';
import { FC, useMemo } from 'react';
import { useAdminPatientActivities } from 'screens/Administration/store/hooks';
import { searchPatientTrackingActivities } from 'services/administrationService';
import { getTimeTrackingActivityColumns, mapPatientTrackingActivityToRow } from './utils';

const TimeTrackingActivityCard: FC = ({}) => {
  const [{ templates: patientActivityTemplates }, pagination] = useAdminPatientActivities();

  const columns = useMemo(() => {
    if (!patientActivityTemplates) return [];
    return getTimeTrackingActivityColumns(patientActivityTemplates);
  }, [patientActivityTemplates]);

  const currentPageData = useMemo(() => {
    if (!pagination?.book) return null;
    return pagination.book[pagination.currentPage]?.results ?? [];
  }, [pagination.book, pagination.currentPage]);

  const rowData = useMemo(
    () => currentPageData?.map(mapPatientTrackingActivityToRow),
    [currentPageData]
  );

  const { search, handleSearch, mappedSearchResult } = useDebouncedSearch(
    searchPatientTrackingActivities,
    mapPatientTrackingActivityToRow
  );

  const handleFiltersChange = () => {};

  return (
    <Card
      className="card-bg-border"
      headers={[
        <CardHeaderSearchFilter
          handleFiltersChange={handleFiltersChange}
          handleSearch={handleSearch}
          search={search}
          key="header"
          title="Activities"
        />,
      ]}
    >
      <GenericTable
        columns={columns}
        data={search ? mappedSearchResult : rowData}
        headerRowClassName="d-flex align-items-center"
        rowClassName="d-flex align-items-center"
      />
      <div className="d-flex justify-content-end">
        <PaginationControlsAlt
          currentPage={pagination.currentPage}
          handleFirst={pagination.first}
          handleLast={pagination.last}
          handleNext={pagination.next}
          handlePrevious={pagination.previous}
          maxPage={pagination.pages}
        />
      </div>
    </Card>
  );
};
export default TimeTrackingActivityCard;
