import { Table } from 'react-bootstrap';
import { resolveClassName } from '../../util/utils';
import './styles.css';

const HeadersList = ({ headers }) =>
  headers.map((item) => (
    <th key={`patient-info-table-header-${item}`} style={{ fontWeight: 500 }}>
      {item}
    </th>
  ));

const GenericPatientInfoTable = ({ tableName, tableHeader, tableBody, containerClassNameList }) => {
  return (
    <div className={resolveClassName(containerClassNameList, 'table-main-container')}>
      <Table borderless size="sm">
        <thead>
          <tr>
            <th style={{ fontWeight: 500 }}>{tableName}</th>
            <HeadersList headers={tableHeader} />
          </tr>
        </thead>
        <tbody>
          {tableBody.map((row, rowIdx) => (
            <tr key={`patient-info-table-row-${rowIdx}`}>
              {row.map((col, colIdx) => (
                <td
                  key={`patient-info-table-cell-${colIdx}`}
                  className="font-size-medium align-middle"
                >
                  {col}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default GenericPatientInfoTable;
