export async function getSurvey(id) {
  //TODO: connect to the server
  // const response = await axios.get('survey/id');
  const response = {
    data: {
      id: id,
      name: `Survey ${id}`,
    },
  };

  return Promise.resolve(response.data);
}

export async function getSurveyList(type) {
  //TODO: connect to the server
  // const response = await axios.get('survey/');
  let response;
  switch (type) {
    case 'providers':
      response = {
        data: [
          {
            id: 1,
            name: 'End of encounter',
            questions: 0,
          },
          {
            id: 2,
            name: 'After video call',
            questions: 0,
          },
          {
            id: 3,
            name: 'After audio call',
            questions: 0,
          },
        ],
      };
      break;
    case 'patients':
      response = {
        data: [
          {
            id: 4,
            name: 'End of encounter',
            questions: 0,
          },
          {
            id: 5,
            name: 'After video call',
            questions: 0,
          },
          {
            id: 6,
            name: 'After audio call',
            questions: 0,
          },
          {
            id: 7,
            name: 'Hospitalization survey',
            questions: 0,
          },
        ],
      };
      break;
    default:
      throw new Error('Invalid type');
  }
  return Promise.resolve(response.data);
}
