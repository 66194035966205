import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CalendarControl from './components/CalendarControl';
import CarePlanCalendar from './components/CarePlanCalendar';

const CarePlanBody = () => {
  return (
    <Container className="px-0">
      <Row>
        <Col className="px-0" lg={2} style={{ minWidth: '260px' }}>
          <CalendarControl />
        </Col>
        <Col className="px-0">
          <CarePlanCalendar />
        </Col>
      </Row>
    </Container>
  );
};

export default CarePlanBody;
