import { DialogsContextProvider } from 'components/VyTracLayout/store';
import RegisterLayout from 'screens/Register/RegisterLayout';
import CurrentRegisterPatientCard from './CurrentRegisterPatientCard';
import { RegisterPatientContextProvider } from './store';

const RegisterPatient = () => {
  return (
    <RegisterPatientContextProvider>
      <DialogsContextProvider>
        <RegisterLayout>
          <CurrentRegisterPatientCard />
        </RegisterLayout>
      </DialogsContextProvider>
    </RegisterPatientContextProvider>
  );
};
export default RegisterPatient;
