import React from 'react';
import ContactInformation from './ContactInformation';
import AddressInformation from './AddressInformation/AddressInformation';
import InsuranceInformation from './InsuranceInformation';
import DemographicInfo from './DemographicInfo/DemographicInfo';
import styles from './styles.module.css';
import {
  PersonalInformationFormikKeys,
  PersonalInformationFormikValues,
} from 'components/PatientManagementForm/types';
import Race from 'types/ApiModels/Users/Race';
import Ethnicity from 'types/ApiModels/Users/Ethnicity';
import Religion from 'types/ApiModels/Users/Religion';
import Insurance from 'types/ApiModels/Patients/Insurance';
import Sex from 'types/ApiModels/Users/Sex';

export interface IPersonalInformation {
  formData?: PersonalInformationFormikValues;
  handleChange?;
  errors?;
  touched;
  languages?: unknown[];
  handleAddLanguage?: () => void;
  races?: Race[];
  ethnicities?: Ethnicity[];
  religions?: Religion[];
  insurances?: Insurance[];
  maritalStatus?: MaritalStatus[];
  sexOptions?: Sex[];
}

const PersonalInformation = ({
  formData,
  handleChange,
  errors,
  touched,
  languages,
  handleAddLanguage,
  ethnicities,
  races,
  religions,
  insurances,
  maritalStatus,
  sexOptions,
}: IPersonalInformation) => (
  <div className="dashboard-detail">
    <div className="dashboard-item-header w-100 justify-content-between align-items-center">
      Personal information
      <button className={styles.resetPassword}>reset password</button>
    </div>

    <div className="personal-information-body-container">
      <div>
        <ContactInformation
          formData={formData[PersonalInformationFormikKeys.CONTACT_INFORMATION]}
          handleChange={handleChange}
          errors={errors ? errors[PersonalInformationFormikKeys.CONTACT_INFORMATION] : undefined}
          touched={touched?.[PersonalInformationFormikKeys.CONTACT_INFORMATION]}
        />
      </div>
      <div>
        <AddressInformation
          formData={formData[PersonalInformationFormikKeys.ADDRESS]}
          handleChange={handleChange}
          errors={errors ? errors[PersonalInformationFormikKeys.ADDRESS] : undefined}
          touched={touched?.[PersonalInformationFormikKeys.ADDRESS]}
        />
      </div>
      <div>
        <InsuranceInformation
          formData={formData[PersonalInformationFormikKeys.INSURANCE]}
          handleChange={handleChange}
          errors={errors ? errors[PersonalInformationFormikKeys.INSURANCE] : undefined}
          touched={touched?.[PersonalInformationFormikKeys.INSURANCE]}
          insurances={insurances}
        />
      </div>
      <div>
        <DemographicInfo
          formData={formData[PersonalInformationFormikKeys.DEMOGRAPHIC_INFO]}
          handleChange={handleChange}
          errors={errors ? errors[PersonalInformationFormikKeys.DEMOGRAPHIC_INFO] : undefined}
          touched={touched?.[PersonalInformationFormikKeys.DEMOGRAPHIC_INFO]}
          languages={languages}
          handleAddLanguage={handleAddLanguage}
          ethnicities={ethnicities}
          races={races}
          religions={religions}
          maritalStatus={maritalStatus}
          sexOptions={sexOptions}
        />
      </div>
    </div>
  </div>
);

export default PersonalInformation;
