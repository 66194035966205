import EditDeleteActionButtons from 'components/EditDeleteActionButtons';
import TextSurroundedWithLines from 'components/TextSurroundedWithLines';
import { FC } from 'react';
import { Allergy } from 'types/ApiModels/Patients/Patient';
import styles from '../../PatientManagementForm.module.css';

interface AllergiesListProps {
  allergies: Allergy[];
  handleHistoryDelete: (id: number) => void;
  handleHistoryEdit: (id: number) => void;
}

const AllergiesList: FC<AllergiesListProps> = ({
  allergies,
  handleHistoryDelete,
  handleHistoryEdit,
}: AllergiesListProps) => {
  if (!allergies?.length)
    return <TextSurroundedWithLines text="No known history" className="text-gray-low-risk" />;

  return (
    <div className="d-flex flex-column gap-sm">
      {allergies.map((a) => (
        <div className={`d-flex flex-column gap ${styles.card}`} key={a.id}>
          <div className="d-flex justify-content-between">
            <p className="m-0 p-0 font-size-big">{a.name}</p>
            <div className={styles['action-buttons']}>
              <EditDeleteActionButtons
                handleDelete={() => handleHistoryDelete(a.id)}
                handleEdit={() => handleHistoryEdit(a.id)}
              />
            </div>
          </div>
          <p className="m-0 p-0">{a.details}</p>
        </div>
      ))}
    </div>
  );
};
export default AllergiesList;
