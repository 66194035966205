import React, { FC } from 'react';
import styles from './styles.module.css';
import { ReactComponent as LeftIcon } from 'assets/icons/arrow_left.svg';
import { ReactComponent as RightIcon } from 'assets/icons/arrow_right.svg';

interface TodoSelectorProps {
  toDoSelected: number;
  totalAlerts: number;
  leftClick: () => void;
  rightClick: () => void;
}
const ToDoSelector: FC<TodoSelectorProps> = ({
  toDoSelected,
  totalAlerts,
  leftClick,
  rightClick,
}) => {
  return (
    <div className={styles.main}>
      <LeftIcon onClick={leftClick} fill="#8FAAC3" />
      <div
        className="font-size-medium"
        style={{ width: '55px', textAlign: 'center', userSelect: 'none' }}
      >
        {toDoSelected + 1} of {totalAlerts}
      </div>
      <RightIcon onClick={rightClick} fill="#8FAAC3" />
    </div>
  );
};

export default ToDoSelector;
