import { createContext, Dispatch, MutableRefObject, useContext } from 'react';
import { accountSettingsInitialState, AccountSettingsState } from './AccountSettings';
import { AllAdminActions } from './administrationReducer';
import { careTeamsInitialState, CareTeamState } from './CareTeam';
import { AdminDashboardState, dashboardInitialState } from './Dashboard';
import { emailSettingsInitialState, EmailSettingsState } from './EmailSettings';
import { formsInitialState, FormState } from './Forms';
import { notesKeywordsInitialState, NotesKeywordsState } from './patient-settings/notes-keywords';
import {
  patientActivitiesInitialState,
  PatientActivitiesState,
} from './patient-settings/patient-activities';
import { AdminPatientsState, patientsInitialState } from './Patients';
import { AdminPopulationsState, populationsInitialState } from './Populations';
import { providerInitialState, ProviderState } from './Providers';
import { specialtiesInitialState, SpecialtyState } from './Specialties';
import { AdminUsersState, usersInitialState } from './Users';

export interface AdministrationStateV2 {
  users: AdminUsersState;
  dashboard: AdminDashboardState;
  providers: ProviderState;
  specialties: SpecialtyState;
  patients: AdminPatientsState;
  populations: AdminPopulationsState;
  formError?: string;
  emailSettings: EmailSettingsState;
  careTeams: CareTeamState;
  patientActivities: PatientActivitiesState;
  accountSettings: AccountSettingsState;
  notesKeywords: NotesKeywordsState;
  forms: FormState;
}

export const initialAdministrationStateV2: AdministrationStateV2 = {
  dashboard: dashboardInitialState,
  providers: providerInitialState,
  specialties: specialtiesInitialState,
  patients: patientsInitialState,
  users: usersInitialState,
  populations: populationsInitialState,
  emailSettings: emailSettingsInitialState,
  careTeams: careTeamsInitialState,
  patientActivities: patientActivitiesInitialState,
  accountSettings: accountSettingsInitialState,
  notesKeywords: notesKeywordsInitialState,
  forms: formsInitialState,
};

export interface AdminFetchRegistry {
  specialties: {
    list: boolean;
  };
  emailSettings: {
    list: boolean;
    shortCodes: boolean;
    types: boolean;
  };
  patients: {
    list: boolean;
  };
  careTeams: {
    list: boolean;
  };
  forms: {
    list: boolean;
  };
}

export const adminFetchRegistryInitialValues: AdminFetchRegistry = {
  specialties: {
    list: false,
  },
  emailSettings: {
    list: false,
    shortCodes: false,
    types: false,
  },
  patients: {
    list: false,
  },
  careTeams: {
    list: false,
  },
  forms: {
    list: false,
  },
};

export interface AdministrationContextV2State {
  administrationStateV2: AdministrationStateV2;
  dispatch: Dispatch<AllAdminActions>;
  fetchRegistry: MutableRefObject<AdminFetchRegistry>;
}

const AdministrationContextV2 = createContext<AdministrationContextV2State>(
  {} as AdministrationContextV2State
);

export const useAdministrationContextV2 = () => useContext(AdministrationContextV2);

export default AdministrationContextV2;
