import { useEffect } from 'react';
import { useTwilioConversationsGlobal } from 'store/twilio/use-twilio-conversations-global';

/**
 * Fetch all conversations from a list of sids and load them in the store
 * @param conversationSids list of sids
 * @returns
 */
export const useTwilioConversationsRework = (conversationSids: string[]) => {
  const { upsertConversations } = useTwilioConversationsGlobal();

  useEffect(() => {
    upsertConversations(conversationSids);
  }, [conversationSids, upsertConversations]);
};
