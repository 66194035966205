import { createPatientAction, updatePatientAction } from 'services/patientService';
import { createTrackingLog } from 'services/trackingService';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { updatePreviousActionsRecurrence } from 'util/calendarUtils/actionRecurrence/absolute/updatePreviousActionsRecurrence';
import { editTrackingLog } from './TrackingLogs';

export const updateAllEvents = async (
  selectedPatientId: number,
  trackingActivityId: number,
  patientActions: IPatientCarePlanAction[],
  selectedTimelineAction: IPatientTimelineAction
): Promise<IPatientCarePlanAction[]> => {
  let updatedPatientActions = [...patientActions];

  if (selectedTimelineAction.id >= 0) {
    // means selected action is original
    const selectedActionIndex = updatedPatientActions.findIndex(
      (a) => a.id === selectedTimelineAction.id
    );

    // update action based on selected action
    await updatePatientAction(selectedPatientId, selectedTimelineAction);
    updatedPatientActions[selectedActionIndex] = selectedTimelineAction as IPatientCarePlanAction;
  } else {
    // update recurrence of previous actions
    const updatedOriginalAction = updatePreviousActionsRecurrence(
      selectedTimelineAction,
      updatedPatientActions,
      'patient'
    );
    await updatePatientAction(selectedPatientId, updatedOriginalAction);

    //create new original action based on selected action
    const newCarePlanAction = await createPatientAction(selectedPatientId, selectedTimelineAction);
    updatedPatientActions = [...updatedPatientActions, newCarePlanAction];
  }

  if (trackingActivityId)
    createTrackingLog({
      ...editTrackingLog,
      description: 'update all event from recurrence',
      object_id:
        selectedTimelineAction.id >= 0
          ? selectedTimelineAction.id
          : selectedTimelineAction.originalActivityId,
      patient: selectedPatientId,
      activity: trackingActivityId,
    });

  return updatedPatientActions;
};
