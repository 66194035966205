import Button from 'components/Button';
import { useAdminPatientActivities } from 'screens/Administration/store/hooks';

const ActivitiesActions = () => {
  const [
    {
      createdTemplates: createdActivityTemplateIds,
      deletedTemplates: deletedActivityTemplates,
      updatedTemplates: updatedActivityTemplateIds,
      submitTemplates: submitPatientActivityTemplates,
      currentDefaultTemplateId: currentDefaultActivityTemplateId,
      resetChanges,
    },
    ,
  ] = useAdminPatientActivities();
  const isDirty =
    Boolean(
      createdActivityTemplateIds.length ||
        deletedActivityTemplates.length ||
        updatedActivityTemplateIds.length
    ) || currentDefaultActivityTemplateId !== null;
  return (
    <div className="d-flex gap-sm">
      <Button label="cancel" variant="dull" disabled={!isDirty} onClick={resetChanges} />
      <Button
        label="save changes"
        variant="confirm"
        disabled={!isDirty}
        onClick={submitPatientActivityTemplates}
      />
    </div>
  );
};
export default ActivitiesActions;
