import { InsuranceFormikValues } from 'components/PatientManagementForm/types/PatientManagementFormikValues';

const createNewInsuranceProvider = (
  id = 0,
  subscriberNumber = '',
  groupNumber = '',
  insuranceId = 0
): InsuranceFormikValues => ({
  id,
  subscriberNumber,
  groupNumber,
  insuranceId,
});

export default createNewInsuranceProvider;
