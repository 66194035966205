import { PaginatedRequestHook, usePaginatedRequest } from 'hooks';
import { Dispatch, useCallback, useEffect } from 'react';
import PatientGet from 'types/ApiModels/Patients/PatientGet';
import { useAdministrationContextV2 } from '../AdministrationContextV2';
import { AllAdminActions } from '../administrationReducer';
import { AdminPatientsState, setPatientsBook } from '../Patients';

export const useAdminPatients = (): [
  AdminPatientsState,
  PaginatedRequestHook<PatientGet>,
  Dispatch<AllAdminActions>
] => {
  const {
    administrationStateV2: { patients },
    dispatch,
    fetchRegistry,
  } = useAdministrationContextV2();

  const paginationRequest = usePaginatedRequest('patient/', 10, patients.patientsBook, false);

  const patientsInit = useCallback(async () => {
    fetchRegistry.current.patients.list = true;
    await paginationRequest.init();
    fetchRegistry.current.patients.list = false;
  }, [fetchRegistry, paginationRequest]);

  useEffect(() => {
    if (!patients.patientsBook && !fetchRegistry.current.patients.list) {
      patientsInit();
    }
  }, [fetchRegistry, patients.patientsBook, patientsInit]);

  useEffect(() => {
    dispatch(setPatientsBook(paginationRequest.book));
  }, [dispatch, paginationRequest.book]);

  return [patients, paginationRequest, dispatch];
};
