import ProviderProfileForm from 'components/Provider/Account/ProviderProfileForm';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import { Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { ProviderDetailsFormValues } from 'screens/Administration/AdministrationContent/AdminTabs/AdminProviders/ProviderDetails/ProviderDetails';
import { mapProviderFormToProvider } from 'screens/Administration/AdministrationContent/AdminTabs/AdminProviders/ProviderDetails/utils';
import ProviderDetailsValidationSchema from 'screens/Administration/AdministrationContent/AdminTabs/AdminProviders/ProviderDetails/utils/ProviderDetailsValidationSchema';
import { updateProvider } from 'services/providersService';
import { convertDateToDatePickValue } from 'util/dateUtils';
import { localIdCreator } from 'util/utils';
import {
  setProviderProfileAction,
  setProviderProfileFormDirty,
  setProviderProfileFormReset,
  setProviderProfileSubmitForm,
} from '../store/providerProfileActionCreators';
import { useProviderProfileContext } from '../store/ProviderProfileContextProvider';
import { useProfileMyAccountLists } from './hooks';
import ProfileMyAccountActions from './profile-my-account-actions';

const createLocalAddressId = localIdCreator();

const ProfileMyAccount: FC = () => {
  const {
    providerProfileState: {
      optionsInfo: { currentProvider, genderOptions, languages, roles, specialties, titles },
    },
    dispatch,
  } = useProviderProfileContext();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [commitingValues, setCommitingValues] = useState<ProviderDetailsFormValues>();

  useEffect(() => {
    dispatch(setProviderProfileAction(ProfileMyAccountActions));
  }, [dispatch]);

  useProfileMyAccountLists();

  const handleSaveAccountChanges = async () => {
    const newProviderValues = mapProviderFormToProvider(commitingValues, currentProvider.is_active);
    const res = await updateProvider(currentProvider.id, newProviderValues);
    setShowConfirmModal(false);
  };

  if (!currentProvider) return <></>;

  return (
    <Formik
      initialValues={{
        identification: {
          firstName: currentProvider?.first_name ?? '',
          lastName: currentProvider?.last_name ?? '',
          middleName: currentProvider?.middle_name ?? '',
          dateOfBirth: currentProvider?.date_birth
            ? convertDateToDatePickValue(new Date(currentProvider?.date_birth))
            : null,
          gender: currentProvider?.sex ?? null,
          title: currentProvider?.title?.id ?? 0,
          language: currentProvider?.languages?.map((l) => l.id) ?? [],
          accessRoles: currentProvider?.roles?.map((r) => r.id) ?? [],
        },
        personalInformation: {
          contactInformation: {
            email: currentProvider?.email ?? '',
            phoneNumber1: currentProvider?.phone_number1 ?? '',
            phoneNumber2: currentProvider?.phone_number2 ?? '',
          },
          addressInformation: currentProvider?.address?.length
            ? currentProvider.address.map((a) => ({
                home: a.home,
                apt: a.apt,
                state: a.state,
                zipCode: a.zip_code,
                city: a.city,
                id: createLocalAddressId(),
              }))
            : [{ home: '', apt: '', state: '', city: '', zipCode: '', id: createLocalAddressId() }],
        },
        specialties: currentProvider?.speciality?.map((s) => s.id) ?? [],
      }}
      // validationSchema={ProviderDetailsValidationSchema}
      onSubmit={(values) => {
        setCommitingValues(values);
        setShowConfirmModal(true);
      }}
      validationSchema={ProviderDetailsValidationSchema}
    >
      {({ submitForm, resetForm, dirty }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setProviderProfileFormReset(resetForm));
          dispatch(setProviderProfileSubmitForm(submitForm));
        }, [resetForm, submitForm]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setProviderProfileFormDirty(dirty));
        }, [dirty]);
        return (
          <>
            <ProviderProfileForm
              selectedProvider={currentProvider}
              genderOptions={genderOptions}
              languages={languages}
              roles={roles}
              titles={titles}
              specialties={specialties}
              allowProfilePicUpload
            />
            <SaveChangesConfirmationModal
              show={showConfirmModal}
              onCancel={() => setShowConfirmModal(false)}
              onConfirm={handleSaveAccountChanges}
            />
          </>
        );
      }}
    </Formik>
  );
};
export default ProfileMyAccount;
