export const mapPatientToList = (p) => ({
  username: p.user.username,
  adherence: p.adherence,
  alertsNumber:
    p.avatar_status.normal_readings + p.avatar_status.read_alerts + p.avatar_status.yellow_alerts,
  //TODO: check what is this
  issuesNumber: 0,
  patient: {
    firstName: p.user.first_name,
    lastName: p.user.last_name,
    profileImage: p.user.imageUrl,
    id: p.id,
  },
  profileCompleted: {
    completed: 0,
    totalSections: 0,
  },
  satisfaction: p.satisfaction,
  score: p.score,
  populations: p.populations.map((p) => ({
    text: p.name,
  })),
  //TODO: check what this is exactly and how it should display
  timeSpent: '',
});
