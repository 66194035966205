import { ErrorMessage, useFormikContext } from 'formik';
import React from 'react';
import { ReactComponent as RoundIcon } from 'assets/icons/alerts/circle_alert_icon.svg';
import { ReactComponent as CriticalIcon } from 'assets/icons/alerts/critical_alert_icon.svg';
import { ThresholdFormikKeys, ThresholdFormikValues } from '../../util/thresholdFormikUtils';
import InputFieldWithUnit from './InputFieldWithUnit';
import styles from './styles.module.css';
import { AlertType } from '../util';
import useThresholds from '../../util/useThresholds';

const AlertIcon = (type: AlertType) => {
  switch (type) {
    case AlertType.CRITICAL:
      return <CriticalIcon className={styles.icon} />;
    case AlertType.RED:
      return <RoundIcon className={styles.icon} fill="#F5485C" />;
    case AlertType.YELLOW:
      return <RoundIcon className={styles.icon} fill="#F8CA54" />;
    case AlertType.BASELINE:
      return <RoundIcon className={styles.icon} fill="#8FAAC3" />;
    default:
      return null;
  }
};

interface ThresholdControlProps {
  alertType: AlertType;
  displayHeaders?: boolean;
  unit: string;
  maxValueName?: ThresholdFormikKeys;
  minValueName?: ThresholdFormikKeys;
  nextMinValueName?: ThresholdFormikKeys;
}

const ThresholdControl = ({
  alertType,
  displayHeaders = false,
  unit,
  maxValueName,
  minValueName,
  nextMinValueName,
}: ThresholdControlProps) => {
  const { values, errors } = useFormikContext<ThresholdFormikValues>();

  const { onThresholdChange } = useThresholds();

  const offsetPercentageMax = 10;
  //     useMemo(
  //     () => Math.trunc((values[maxValueName] / values[thresholdFormikKeys.YELLOW2] - 1) * 100),
  //     [values, maxValueName]
  //   );

  const offsetPercentageMin = 10;
  //     useMemo(
  //     () => Math.trunc((values[minValueName] / values[thresholdFormikKeys.YELLOW1] - 1) * 100),
  //     [values, minValueName]
  //   );

  return (
    <>
      <div className={`${styles.row} ${styles['row-header']}`}>
        <div className="d-flex align-items-center">
          {AlertIcon(alertType)}
          <div className={styles.title}>{alertType}</div>
        </div>
        <div className={styles.headers}>{displayHeaders && 'Max Value'}</div>
        <div className={styles.headers}>{displayHeaders && 'Min Value'}</div>
      </div>
      <div className={`${styles.row} ${styles['row-controls']}`}>
        <div className={styles.range}>
          {alertType === AlertType.CRITICAL
            ? `Trigger values ${maxValueName ? 'under' : 'above'}`
            : 'Range'}
        </div>

        <div className={styles.field}>
          {maxValueName && values[maxValueName] !== undefined && (
            <InputFieldWithUnit
              name={maxValueName}
              unit={unit}
              offsetPercentage={offsetPercentageMax}
              minValue={values[minValueName ?? nextMinValueName] as number}
              onChange={onThresholdChange(maxValueName)}
            />
          )}
        </div>
        <div className={styles.field}>
          {minValueName && values[minValueName] !== undefined && (
            <InputFieldWithUnit
              name={minValueName}
              unit={unit}
              offsetPercentage={offsetPercentageMin}
              minValue={values[nextMinValueName] as number}
              onChange={onThresholdChange(minValueName)}
            />
          )}
        </div>
      </div>
      {errors[minValueName] || errors[maxValueName] ? (
        <div className={styles['threshold-error-message']}>
          {errors[minValueName] ? <ErrorMessage name={minValueName} /> : null}
          {errors[maxValueName] ? <ErrorMessage name={maxValueName} /> : null}
        </div>
      ) : null}
    </>
  );
};

export default ThresholdControl;
