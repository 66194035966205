import { LabeledField } from 'components/FormikComponents';
import { Tag } from 'components/Tagitem';
import TagList from 'components/TagList';
import { useFormikContext } from 'formik';
import { FC, useMemo } from 'react';
import { Language } from 'types/ApiModels/Users';
import styles from '../styles.module.css';
import addTagIcon from 'assets/icons/add_tag_icon.svg';
import deleteTagIcon from 'assets/icons/remove_tag_icon.svg';

interface ProviderLanguagesFieldProps {
  label: string;
  name: string;
  languages: Language[];
  handleAdd: () => void;
}

const ProviderLanguagesField: FC<ProviderLanguagesFieldProps> = ({
  label,
  name,
  languages,
  handleAdd,
}: ProviderLanguagesFieldProps) => {
  const { values, setFieldTouched, setFieldValue } =
    useFormikContext<{ identification: { language: number[] } }>();

  const currentSelectedLanguages = useMemo(
    () => languages?.filter((l) => values.identification.language.includes(l.id)) ?? [],
    [languages, values.identification.language]
  );

  const handleRemoveLanguage = (languageId: number) => {
    setFieldTouched(name, true);
    setFieldValue(
      name,
      values.identification.language.filter((lid) => lid !== languageId),
      true
    );
  };
  return (
    <LabeledField className={`${styles['field-container']} flex-grow-1`} name={name} label={label}>
      <TagList
        className="w-100 bg-light-gray py-1 rounded"
        tagList={[
          ...currentSelectedLanguages.map<Tag>((s) => ({
            text: s.name,
            icon: (
              <img
                src={deleteTagIcon}
                onClick={() => handleRemoveLanguage(s.id)}
                height={16}
                width={16}
                className="cursor-pointer"
              />
            ),
          })),
          {
            text: 'Add',
            icon: <img src={addTagIcon} height={16} width={16} />,
            className: 'cursor-pointer',
            onClick: handleAdd,
          },
        ]}
      />
    </LabeledField>
  );
};
export default ProviderLanguagesField;
