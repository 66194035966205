import Card from 'components/Card';
import { CardAddHeader } from 'components/CardAdd';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import TextSurroundedWithLines from 'components/TextSurroundedWithLines';
import { useEffect, useState } from 'react';
import { useKeywordsTemplates } from 'screens/Administration/store/hooks';
import { NotesKeywordsActionCreators } from 'screens/Administration/store/patient-settings/notes-keywords';
import {
  createKeywordsTemplatesBulk,
  deleteKeywordTemplatesBulk,
  patchKeywordsTemplatesBulk,
} from 'services/administrationService';
import { KeywordsTemplate } from 'types/ApiModels/Administration';
import { negativeLocalIdCreator } from 'util/utils';
import PatientSettingsItem from '../../patient-settings-item';
import AddKeywordTemplateModal from './add-keyword-template-modal';
import { AddKeywordTemplateFormikValues } from './add-keyword-template-modal/add-keyword-template-model';
import styles from './styles.module.css';

const {
  upsertCreated,
  upsertUpdated,
  appendDeleted,
  removeCreated,
  setCurrentDefaultId,
  setSubmit,
  setReset,
  clearChanges,
  upsertTemplate,
  syncRemoveTemplates,
} = NotesKeywordsActionCreators;

const createLocalNegativeId = negativeLocalIdCreator();

const KeyWordTemplatesCard = () => {
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
  const [showAddConfirmationModal, setShowAddConfirmationModal] = useState(false);
  const [currentActivityTemplate, setCurrentActivityTemplate] = useState<KeywordsTemplate>(null);
  const [localTemplates, setLocalTemplates] = useState<KeywordsTemplate[]>(null);

  const [{ templates, createdTemplates, updatedTemplates, deletedTemplates }, dispatch] =
    useKeywordsTemplates();

  useEffect(() => {
    setLocalTemplates(templates);
  }, [templates]);

  const handleAdd = () => {
    setShowAddTemplateModal(true);
  };
  const handleModalClose = () => {
    setCurrentActivityTemplate(null);
    setShowAddTemplateModal(false);
  };

  const handleActiveChange = (id: number) => () => {
    id < 0 ? dispatch(upsertCreated(id)) : dispatch(upsertUpdated(id));
    setLocalTemplates((lpat) =>
      lpat.map((at) => (at.id === id ? { ...at, is_active: !at.is_active } : at))
    );
  };
  const handleDelete = (id: number) => () => {
    setLocalTemplates((lpat) => lpat.filter((at) => at.id !== id));
    if (id > 0) {
      dispatch(appendDeleted(id));
    } else {
      dispatch(removeCreated(id));
    }
  };
  const handleEdit = (id: number) => () => {
    setCurrentActivityTemplate(localTemplates.find((lpat) => lpat.id === id));
    setShowAddTemplateModal(true);
  };
  const handleDefaultChange = (id: number) => () => {
    // dispatch(toggleDefaultActivityTemplate(id));
    dispatch(setCurrentDefaultId(id));
    setLocalTemplates((ts) => ts.map((t) => ({ ...t, is_default: t.id === id })));
  };

  const handleSubmitAddUpdate = (values: AddKeywordTemplateFormikValues) => {
    if (values.id < 0) {
      dispatch(upsertCreated(values.id));
    } else {
      dispatch(upsertUpdated(values.id));
    }
    setLocalTemplates((lpat) => {
      const existingIdx = lpat.findIndex((c) => c.id === values.id);
      if (existingIdx === -1) {
        return [...lpat, values];
      }
      const copy = [...lpat];
      copy[existingIdx] = values;
      return copy;
    });
  };

  const handleCommit = async () => {
    const toUpdate = localTemplates.flatMap<AtLeast<KeywordsTemplate, 'id'>>((lpat) => {
      if (updatedTemplates.includes(lpat.id)) {
        //we must tell apart what was modified and what wasnt;
        const previousValues = templates.find((t) => t.id === lpat.id);
        const purged = {
          id: lpat.id,
          is_active: lpat.is_active === previousValues.is_active ? undefined : lpat.is_active,
          is_default: lpat.is_default === previousValues.is_default ? undefined : lpat.is_default,
          name: lpat.name === previousValues.name ? undefined : lpat.name,
        };
        return Object.keys(purged).length === 0 ? [] : [purged];
      }
      return [];
    });
    const toCreate = localTemplates.filter((lpat) => createdTemplates.includes(lpat.id));
    // create
    const [created, createError] = await createKeywordsTemplatesBulk(
      toCreate.map((c) => ({ ...c, id: undefined }))
    );
    if (created) {
      dispatch(upsertTemplate(created));
    }
    // update
    const [updated, updateError] = await patchKeywordsTemplatesBulk(toUpdate);
    if (updated) {
      dispatch(upsertTemplate(updated));
    }
    // delete
    const [, deleteError] = await deleteKeywordTemplatesBulk(
      deletedTemplates.map((id) => ({ id }))
    );
    if (!deleteError) {
      dispatch(syncRemoveTemplates(deletedTemplates));
    }
    setShowAddConfirmationModal(false);
    [createError, updateError, deleteError].forEach((err) => err && console.error(err));
  };

  useEffect(() => {
    dispatch(
      setSubmit(() => {
        setShowAddConfirmationModal(true);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    const reset = () => {
      dispatch(clearChanges());
      setLocalTemplates(templates);
    };
    dispatch(setReset(reset));
  }, [dispatch, templates]);
  return (
    <Card
      className="card-bg-border"
      headers={[
        <CardAddHeader onAdd={handleAdd} title="Activity templates" key="card-add-header" />,
      ]}
    >
      {localTemplates?.length ? (
        <>
          <div className="d-flex mb-1">
            <div className={`flex-basis-10 ${styles.text}`}>DEFAULT</div>
            <div className="flex-grow-1"></div>
          </div>
          <div className="d-flex flex-column gap-sm">
            {localTemplates?.map((pat, idx) => (
              <PatientSettingsItem
                handleActiveChange={handleActiveChange(pat.id)}
                handleDefaultchange={handleDefaultChange(pat.id)}
                handleDelete={handleDelete(pat.id)}
                handleEdit={handleEdit(pat.id)}
                is_active={pat.is_active}
                is_default={pat.is_default}
                name={pat.name}
                containerClassName={idx === 0 ? 'flex-grow-10' : ''}
                key={pat.id}
              />
            ))}
          </div>
        </>
      ) : (
        <TextSurroundedWithLines text="No keywords added" />
      )}
      <AddKeywordTemplateModal
        existingNames={localTemplates?.map((lpat) => lpat.name) ?? []}
        show={showAddTemplateModal}
        handleClose={handleModalClose}
        handleConfirm={handleSubmitAddUpdate}
        current={currentActivityTemplate}
        idCreator={createLocalNegativeId}
      />
      <SaveChangesConfirmationModal
        onCancel={() => {
          setShowAddConfirmationModal(false);
        }}
        show={showAddConfirmationModal}
        onConfirm={handleCommit}
      />
      <SaveChangesConfirmationModal
        onCancel={() => {
          setShowConfirmDeleteModal(true);
        }}
        onConfirm={() => {}}
        show={showConfirmDeleteModal}
      />
    </Card>
  );
};
export default KeyWordTemplatesCard;
