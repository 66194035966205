import React, { FC, useCallback, useMemo } from 'react';
import AlertSummary from 'types/ApiModels/Patients/AlertSummary';
import { rpmActionTypeToIcon } from 'util/rpmActionTypeToIcon';
import { sortAlerts } from './utils';
import styles from './styles.module.css';
import AlertItem from 'components/Alertitem';

interface AlertGroupProps {
  alertList: AlertSummary[];
  color: string;
}
export const AlertGroup: FC<AlertGroupProps> = ({ alertList, color }) => {
  const sortedList = useMemo(() => {
    return sortAlerts(alertList);
  }, [alertList]);

  const renderIcon = useCallback(
    (alert_: AlertSummary) => {
      const Icon = rpmActionTypeToIcon[alert_.vital_type];
      return (
        <div className="d-flex align-content-center" style={{ marginRight: '10px' }}>
          <div className={styles.circle} style={{ borderColor: color }}>
            {Icon && <Icon width={'12px'} height={'10px'} color={color} />}
          </div>
          <div className="d-flex align-items-center font-size-medium" style={{ color }}>
            {alert_.count}
          </div>
        </div>
      );
    },
    [color]
  );

  if (alertList.length === 0) return null;
  return (
    <div className="d-flex">
      {sortedList.length > 2 ? (
        <>
          {sortedList.slice(0, 2).map((alert_) => renderIcon(alert_))}
          <div style={{ marginRight: '5px' }}>
            <AlertItem
              text={`+${sortedList.slice(2).reduce((n, { count }) => n + count, 0)}`}
              color={color}
            />
          </div>
        </>
      ) : (
        sortedList.map((alert_) => renderIcon(alert_))
      )}
    </div>
  );
};
export default AlertGroup;
