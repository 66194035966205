import { Provider, ProviderUpdateRequest } from 'types/ApiModels/Providers/Provider';

export const mapProviderToUpdateProvider = (p: Provider): ProviderUpdateRequest => ({
  address: p.address,
  date_birth: p.date_birth,
  email: p.email,
  first_name: p.first_name,
  languages_id: p.languages.map((l) => l.id),
  last_name: p.last_name,
  middle_name: p.middle_name,
  phone_number1: p.phone_number1,
  phone_number2: p.phone_number2,
  roles_id: p.roles.map((r) => r.id),
  sex: p.sex,
  speciality_id: p.speciality.map((s) => s.id),
  title_id: p.title ? p.title.id : null,
  // photo: p.photo ? 'photoplaceholder' : p.photo,
  is_active: p.is_active.is_active,
});

export interface ProviderRow {
  provider: {
    first_name: string;
    last_name: string;
    image_url: string;
    username: string;
  };
  id: number;
  patients: number;
  'follow-ups': number;
  'open-cases': number;
  completed: number;
  satisfaction: number;
  title: string;
  roles: string;
  speciality: string[];
  status: string;
  action: string;
  active: boolean;
}

export const mapProviderToProviderRow = (p: Provider): ProviderRow => ({
  'follow-ups': p.follow,
  'open-cases': p.open_cases,
  action: '',
  active: p.is_active,
  completed: p.completed_cases,
  id: p.id,
  patients: p.patients,
  roles: p.roles.map((r) => r.name).join(', '),
  satisfaction: p.satisfaction ?? 0,
  speciality: p.speciality.map((s) => s.name),
  //TODO: what is this. Active, vacation mode? where should this come from
  status: 'Active',
  title: p.title?.name,
  provider: {
    username: p.username,
    first_name: p.first_name,
    last_name: p.last_name,
    image_url: p.photo,
  },
});
