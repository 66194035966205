import Button from 'components/Button';
import VytracModal from 'components/Modal';
import { SearchBarAlt } from 'components/SearchBar';
import { useDebouncedSearch } from 'hooks';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { searchPatientDeviceTypes } from 'services/patientService';
import DeviceMeasureType from 'types/ApiModels/Patients/device-measure-type';
import DeviceType from 'types/ApiModels/Patients/device-type';
import { DeviceMeasuresCell, DeviceModelCell } from '../device-table-cells';
import styles from './styles.module.css';

interface AddDeviceModalProps {
  onClose: () => void;
  show: boolean;
  handleCreate: (deviceType: DeviceType, serialNumber: string) => void;
  deviceTypes: DeviceType[];
  setDeviceTypes: Dispatch<SetStateAction<DeviceType[]>>;
  deviceMeasureTypes: DeviceMeasureType[];
}

const AddDeviceModal: FC<AddDeviceModalProps> = ({
  onClose,
  show,
  handleCreate,
  deviceTypes,
  deviceMeasureTypes,
}: AddDeviceModalProps) => {
  const [step, setStep] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState<DeviceType>();
  const [serialNumber, setSerialNumber] = useState<string>();

  const handleDeviceClick = (d: DeviceType) => {
    setSelectedDevice(d);
    setStep(1);
  };

  const [hoveringId, setHoveringId] = useState<number>();

  const handleHover = (id: number) => () => {
    setHoveringId(id);
  };
  const handleHoverOut = () => {
    setHoveringId(null);
  };

  const mapDeviceTypeToRow = useCallback(
    (d: DeviceType) => (
      <div
        className={`d-flex justify-content-between pb-2 ${styles['device-select']}`}
        key={d.id}
        onClick={() => handleDeviceClick(d)}
      >
        <DeviceModelCell isFdaApproved={d.is_fda_approved} name={d.name} />
        <DeviceMeasuresCell
          handleHover={handleHover(d.id)}
          handleHoverOut={handleHoverOut}
          showDetail={d.id === hoveringId}
          types={d.measures.map((dmtId) => deviceMeasureTypes.find((dt) => dt.id === dmtId)?.name)}
        />
      </div>
    ),
    [deviceMeasureTypes, hoveringId]
  );
  const { handleSearch, search, mappedSearchResult } = useDebouncedSearch(
    searchPatientDeviceTypes,
    mapDeviceTypeToRow
  );
  const StepComponent = useMemo(
    () => [
      <div key="step-1" className="px-3 font-size-medium">
        <SearchBarAlt onChange={handleSearch} value={search} placeholder="Search devices..." />
        <div className={`d-flex flex-column gap-sm py-3 cursor-pointer`}>
          {search ? mappedSearchResult : deviceTypes ? deviceTypes.map(mapDeviceTypeToRow) : null}
        </div>
      </div>,

      <div key="step-2" className="px-3 font-size-medium">
        <div className="d-flex gap align-items-center ">
          <label htmlFor="serial-number" className="m-0">
            Device serial number
          </label>

          <div className={`flex-grow-1 p-1 `}>
            <input
              type="text"
              name="serial-number"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
              className="vytrac-white-input p-1 w-100 text-right"
            />
          </div>
        </div>
      </div>,
    ],
    [deviceTypes, handleSearch, mapDeviceTypeToRow, mappedSearchResult, search, serialNumber]
  );

  return (
    <VytracModal
      title="Add device"
      centered
      show={show}
      onClose={onClose}
      bodyClassName="position-relative"
      body={StepComponent[step]}
      footer={
        step === 1 ? (
          <div className={`d-flex justify-content-between w-100`}>
            <Button
              variant="dull"
              label={'back'}
              onClick={() => {
                setStep(0);
                setSelectedDevice(null);
              }}
            />
            <Button
              variant="confirm"
              label="add device"
              disabled={!serialNumber}
              onClick={() => handleCreate(selectedDevice, serialNumber)}
            />
          </div>
        ) : null
      }
    />
  );
};
export default AddDeviceModal;
