import { Client, Conversation, ConversationUpdateReason } from '@twilio/conversations';
import { useAuthContext } from 'auth';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getConversationsMetadata, impersonatePatient } from 'services/twilio';
import {
  addConversation,
  appendConversations,
  removeConversation,
  setConversations,
  setConversationsPaginator,
  setLastMessage,
  setParticipants,
  setUnreadCount,
  upsertConversation,
  useConversationsContext,
} from 'store/twilio-conversations';
import ConversationType from 'types/Shared/conversation-type';
import {
  getConversationAttributes,
  mapConversationMetadataAllSettledToTwilioConversation,
} from '../utils';

const LAST_MESSAGE = 1;

const useSubscribedConversations = (
  twilioClient: Client,
  filterFunction?: (conversation: Conversation) => boolean
) => {
  const { currentUser } = useAuthContext();
  const {
    conversationState: { conversations, conversationsPaginator },
    dispatch,
  } = useConversationsContext();
  const [areConversationsLoading, setAreConversationsLoading] = useState(true);

  const fetchInitialConversations = useCallback(
    async (client: Client) => {
      setAreConversationsLoading(true);
      const conversationsPaginator = await client.getSubscribedConversations();
      dispatch(setConversationsPaginator(conversationsPaginator));

      const filteredConversations = filterFunction
        ? conversationsPaginator.items.filter(filterFunction)
        : conversationsPaginator.items;

      const metadataAllSettled = await getConversationsMetadata(filteredConversations);
      const conversationsMapped = filteredConversations.map(
        mapConversationMetadataAllSettledToTwilioConversation(metadataAllSettled)
      );
      dispatch(setConversations(conversationsMapped));
      setAreConversationsLoading(false);
    },
    [dispatch]
  );

  const identityByParticipantSID = useMemo(
    () =>
      conversations.reduce((res, c) => {
        c.participants.forEach((p) => {
          res[p.sid] = p.identity;
        });
        return res;
      }, {}),
    [conversations]
  );

  useEffect(() => {
    if (!twilioClient) return;
    fetchInitialConversations(twilioClient);
  }, [fetchInitialConversations, twilioClient]);

  const conversationParticipantIdentities = useMemo(() => {
    return conversations.reduce<string[]>((res, c) => {
      const participantIds = c.participants.flatMap((p) => {
        if (res.includes(p.identity)) return [];
        return [p.identity];
      });
      return [...res, ...participantIds];
    }, []);
  }, [conversations]);

  const loadMoreConversations = useCallback(async () => {
    if (conversationsPaginator?.hasNextPage) {
      const nextPaginator = await conversationsPaginator.nextPage();
      dispatch(setConversationsPaginator(nextPaginator));
      const metadataAllSettled = await getConversationsMetadata(nextPaginator.items);
      dispatch(
        appendConversations(
          nextPaginator.items.map(
            mapConversationMetadataAllSettledToTwilioConversation(metadataAllSettled)
          )
        )
      );
    }
  }, [conversationsPaginator, dispatch]);

  const conversationSids = useMemo(() => {
    return conversations.map((c) => c.ref.sid);
  }, [conversations]);

  const conversationUpdateReasonMap = useMemo(
    () => ({
      lastMessage: async ({ conversation }: { conversation: Conversation }) => {
        if (conversationSids.includes(conversation.sid)) {
          const newLastMessage = (await conversation.getMessages(LAST_MESSAGE)).items?.[0];
          const unreadCount = newLastMessage.index - conversation.lastReadMessageIndex;
          dispatch(
            setLastMessage({
              ref: conversation,
              lastMessage: newLastMessage,
              unreadCount,
              participants: undefined,
            })
          );
        } else {
          const [metadataAllSettled] = await getConversationsMetadata([conversation]);
          const metadata =
            metadataAllSettled.status === 'fulfilled'
              ? metadataAllSettled.value
              : { lastMessage: undefined, participants: [], unreadCount: 0 };
          dispatch(upsertConversation({ ref: conversation, ...metadata }));
        }
      },
      attributes: async ({
        conversation,
        newAttributes,
      }: {
        conversation: Conversation;
        newAttributes;
      }) => {
        const TRIGGER_CONVERSATION_ATTRIBUTE_CHANGE = 2;
        const messages = await conversation.getMessages();
        if (
          messages.items.length === TRIGGER_CONVERSATION_ATTRIBUTE_CHANGE &&
          newAttributes.conversation_type === ConversationType.CareTeamRequestFollowUp
        ) {
          const patientClient = await impersonatePatient(newAttributes.patient_identity);
          //provider does not have twilio permissions to be able to remove all the other participants so we must impersonate the patient who's the one that created the conversation
          try {
            const patientConversation = await patientClient.getConversationBySid(conversation.sid);
            const participants = await patientConversation.getParticipants();
            const removeParticipantsPromise = participants.flatMap((p) => {
              if (
                p.identity === newAttributes.patient_identity ||
                p.identity === currentUser?.twilio_identity
              )
                return [];
              return [patientConversation.removeParticipant(p)];
            });
            await Promise.all(removeParticipantsPromise);
            const updatedParticipants = await conversation.getParticipants();
            dispatch(setParticipants(updatedParticipants));
          } catch (error) {
            console.error('Error while trying to impersonate patient', error);
          }
        }
      },
      lastReadMessageIndex: async ({ conversation }: { conversation: Conversation }) => {
        if (conversation.lastMessage?.index === undefined) return;
        const unreadCount = conversation?.lastMessage.index - conversation.lastReadMessageIndex;
        dispatch(setUnreadCount({ conversationSID: conversation.sid, unreadCount }));
      },
    }),
    [conversationSids, currentUser?.twilio_identity, dispatch]
  );

  useEffect(() => {
    if (!twilioClient || areConversationsLoading) return;
    const onConversationUpdated = async ({
      conversation,
      updateReasons,
    }: {
      conversation: Conversation;
      updateReasons: ConversationUpdateReason[];
    }) => {
      if (!filterFunction?.(conversation)) return;
      const newAttributes = getConversationAttributes(conversation.attributes);
      updateReasons.forEach((ur) =>
        conversationUpdateReasonMap[ur]?.({ conversation, newAttributes })
      );
    };
    twilioClient.on('conversationUpdated', onConversationUpdated);

    return () => {
      twilioClient.removeListener('conversationUpdated', onConversationUpdated);
    };
  }, [areConversationsLoading, conversationUpdateReasonMap, filterFunction, twilioClient]);

  useEffect(() => {
    const onConversationJoined = async (conversation: Conversation) => {
      console.debug('[EVENT] --- Conversation joined');
      if (!filterFunction?.(conversation)) return;
      const [metadataAllSettled] = await getConversationsMetadata([conversation]);
      const metadata =
        metadataAllSettled.status === 'fulfilled'
          ? metadataAllSettled.value
          : { lastMessage: undefined, participants: [], unreadCount: 0 };
      dispatch(addConversation({ ref: conversation, ...metadata }));
    };
    const onRemoveConversation = (conversation: Conversation) => {
      if (!filterFunction?.(conversation)) return;
      console.debug('[EVENT] -- ', 'onRemoveConversation');
      dispatch(removeConversation(conversation.sid));
    };
    if (twilioClient) {
      twilioClient.on('conversationJoined', onConversationJoined);
      twilioClient.on('conversationRemoved', onRemoveConversation);
    }
    return () => {
      twilioClient && twilioClient.removeListener('conversationJoined', onConversationJoined);
      twilioClient && twilioClient.removeListener('conversationRemoved', onRemoveConversation);
    };
  }, [dispatch, filterFunction, twilioClient]);

  return useMemo(
    () => ({
      conversations,
      areConversationsLoading,
      loadMoreConversations,
      conversationParticipantIdentities,
      identityByParticipantSID,
    }),
    [
      areConversationsLoading,
      conversationParticipantIdentities,
      conversations,
      loadMoreConversations,
      identityByParticipantSID,
    ]
  );
};

export default useSubscribedConversations;
