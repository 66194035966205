import { REQUIRED_MESSAGE } from 'util/formikUtils';
import { array, number, object, string } from 'yup';

export const InviteProviderValidationSchema = object().shape({
  email: string()
    .email("Provider's email must be a valid email address")
    .required("Provider's email is required"),
  careTeamId: number().nullable().required(REQUIRED_MESSAGE),
  careTeamTierId: number().nullable().required(REQUIRED_MESSAGE),
  specialtiesIds: array()
    .of(number())
    .required('You must select at least one specialty')
    .min(1, 'You must select at least one specialty'),
  rolesIds: array()
    .of(number())
    .required('You must select at least one role')
    .min(1, 'You must select at least one role'),
});
