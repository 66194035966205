import { NavLink } from 'react-router-dom';
import styles from './styles.module.css';

interface ControlButtonLinkProps {
  to: string;
  children: string;
  className?: string;
  exact?: boolean;
}
const ControlButtonLink = ({ to, children, className, exact }: ControlButtonLinkProps) => (
  <NavLink
    activeClassName={styles.selected}
    className={`${styles['button-control']} ${className}`}
    exact={exact}
    to={to}
  >
    {children}
  </NavLink>
);
export default ControlButtonLink;
