import { TeamNotesMessagesContainer } from '../team-notes/team-notes-messages';
import TeamNotesStickyNotes from '../team-notes/team-notes-sticky-notes';
import RecentActivity from './recent-activity';
import styles from './styles.module.css';

const PatientStatus = () => {
  return (
    <div className={`${styles.statusContainer} container-with-padding h-100 gap`}>
      <div className={styles.statusCol}>
        <RecentActivity />
      </div>
      <div className={`${styles.statusCol} d-flex flex-column gap`}>
        <TeamNotesMessagesContainer />
        <TeamNotesStickyNotes stickyNotes={[]} />
      </div>
    </div>
  );
};
export default PatientStatus;
