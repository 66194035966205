import Button from 'components/Button';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import {
  setOpenedBroadcast,
  setOpenedBroadcastTemplate,
} from 'screens/messages/store/messages-action-creators';
import { useMessagesContext } from 'screens/messages/store/messages-context';

const BroadcastActions = () => {
  const { pathname } = useLocation();

  const {
    state: { selectedBroadcast, openedBroadcastTemplate, openedBroadcast },
    dispatch,
  } = useMessagesContext();

  const showCreate = useMemo(
    () =>
      (pathname.includes('templates') &&
        (openedBroadcastTemplate === null || openedBroadcastTemplate.id != null)) ||
      (!pathname.includes('templates') && (openedBroadcast == null || openedBroadcast.id != null)),
    [openedBroadcastTemplate, openedBroadcast, pathname]
  );

  const showSend = useMemo(
    () => !pathname.includes('templates') && selectedBroadcast.length > 0,
    [pathname, selectedBroadcast]
  );

  const showDelete = useMemo(
    () =>
      (pathname.includes('templates') && openedBroadcastTemplate?.id >= 0) ||
      selectedBroadcast.length > 0,
    [pathname, selectedBroadcast, openedBroadcastTemplate]
  );
  const showCancel = useMemo(
    () =>
      (pathname.includes('templates') &&
        openedBroadcastTemplate !== null &&
        openedBroadcastTemplate.id == null) ||
      (pathname.includes('templates') && openedBroadcast && openedBroadcast?.id == null),
    [openedBroadcastTemplate, openedBroadcast, pathname]
  );

  const handleCreate = useCallback(() => {
    if (pathname.includes('templates')) {
      dispatch(
        setOpenedBroadcastTemplate({
          title: '',
          via: [],
          to: [],
          time: null,
          text: '',
        })
      );
    } else {
      dispatch(
        setOpenedBroadcast({
          title: '',
          via: [],
          to: [],
          time: null,
          text: '',
        })
      );
    }
  }, [dispatch, pathname]);

  const handleCancel = useCallback(() => {
    if (pathname.includes('templates')) {
      dispatch(setOpenedBroadcastTemplate(null));
    } else {
      dispatch(setOpenedBroadcast(null));
    }
  }, [dispatch, pathname]);

  return (
    <div className="d-flex gap">
      {showSend && <Button label="send selected" />}
      {showCreate && <Button label="create new broadcast" onClick={handleCreate} />}
      {showDelete && <Button label="delete selected" variant="delete" />}
      {showCancel && <Button label="Cancel" variant="delete" onClick={handleCancel} />}
    </div>
  );
};
export default BroadcastActions;
