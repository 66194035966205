import VytracModal from 'components/Modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Header from './Header';
import styles from './styles.module.css';
import { addExceptionsToActions, mapPopulationDataToModal, PopulationModalData } from './utils';
import Footer from './Footer';
import { getTemplateCarePlanList } from 'services/templatesService';
import PopulationsBody from './Body';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import { ITemplateCarePlan, ITemplateCarePlanAction } from 'types/ApiModels/Templates/CarePlan';
import { ActionException } from 'types/ApiModels/CarePlan/action';

interface PopulationsModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: (data: IPatientCarePlanAction[]) => void;
  onSelectChange?: (populations: number[]) => void;
  selectPopulationIds?: number[];
  patientActions?: IPatientCarePlanAction[];
  patientPopulations?: number[];
}

const PopulationsModal = ({
  show,
  onClose,
  onConfirm,
  selectPopulationIds,
  onSelectChange,
  patientActions = [],
  patientPopulations = [],
}: PopulationsModalProps) => {
  // These are the actions displayed in the modal
  const [actionsByDay, setActionsByDay] = useState<PopulationModalData[]>([]);

  const [_selectPopulations, setSelectPopulations] = useState<number[]>(selectPopulationIds ?? []);
  useEffect(() => setSelectPopulations(selectPopulationIds ?? []), [selectPopulationIds]);
  const _onSelectChange = useMemo(
    () =>
      onSelectChange
        ? onSelectChange
        : (populations: number[]) => setSelectPopulations(populations),
    [onSelectChange]
  );

  const noGoalsActions = useMemo(
    () => patientActions.filter((action) => action.event_type !== 'goal'),
    [patientActions]
  );

  // These are the actions that come from the API and also includes the actions created when dragging another action.
  // These actions will be used to add them the exceptions needed to create the Care Plan matching the modal
  const [carePlanActions, setCarePlanActions] = useState<
    (ITemplateCarePlanAction & { template?: number })[]
  >([]);
  // These are the exceptions that will be used to create the Care Plan matching the modal
  const [actionExceptions, setActionExceptions] = useState<ActionException[]>([]);

  // These are the Care Plan templates from the API
  const [templates, setTemplates] = useState<ITemplateCarePlan[]>([]);

  const patientPopulationsTemplates: ITemplateCarePlan[] = useMemo(
    () => [
      ...templates.filter(
        (e) =>
          patientPopulations.some((pop) => pop === e.id) &&
          noGoalsActions.some((a) => a.template === e.id)
      ),
      ...(noGoalsActions.length > 0
        ? [
            {
              name: 'Other',
              id: null,
              actions: [],
            },
          ]
        : []),
    ],
    [templates, patientPopulations, noGoalsActions]
  );

  useEffect(() => {
    // Only setActions for selected populations/Care Plan templates
    const toSetTemplatesActions: IPatientCarePlanAction[] = templates
      .filter((e) => _selectPopulations.some((pop) => pop === e.id))
      .flatMap((template) => template.actions?.map((a) => ({ ...a, template: template.id })));
    if (
      toSetTemplatesActions.length > 0 ||
      noGoalsActions.length > 0 ||
      _selectPopulations?.length === 0
    ) {
      setActionsByDay((prev) =>
        mapPopulationDataToModal(
          templates.filter((e) => _selectPopulations.some((pop) => pop === e.id)),
          _selectPopulations,
          noGoalsActions,
          patientPopulations,
          prev
        )
      );

      setCarePlanActions(
        toSetTemplatesActions.filter((a) => _selectPopulations.some((pop) => pop === a?.template))
      );
    }
  }, [templates, _selectPopulations]);

  useEffect(() => {
    // Fetch the templates from the API in case they weren't fetched yet
    const fetchTemplates = async () => {
      if (templates.length > 0) return;
      try {
        const templatesList = await getTemplateCarePlanList();

        // Set the templates and Modal Actions
        setTemplates(
          templatesList.map((t) => ({
            ...t,
            actions: t.actions?.filter((a) => a.event_type !== 'goal'),
          }))
        );
        setActionsByDay(
          mapPopulationDataToModal(
            templates,
            _selectPopulations,
            noGoalsActions,
            patientPopulations
          )
        );
      } catch (e) {
        throw e;
      }
    };
    fetchTemplates();
  }, [_selectPopulations, templates, noGoalsActions]);

  // Called when the user clicks on cancel or outside the modal
  const handleClose = useCallback(() => {
    onClose();
    setActionExceptions([]);
    setTemplates([]);
    setSelectPopulations([]);
  }, [setActionExceptions, setTemplates, onClose]);

  return (
    <VytracModal
      size="lg"
      headerClassName={styles['title']}
      title={
        <Header
          populations={_selectPopulations}
          onChange={_onSelectChange}
          patientPopulations={patientPopulations ?? []}
        />
      }
      body={
        <PopulationsBody
          actionsByDay={actionsByDay}
          setActionsByDay={setActionsByDay}
          setActionExceptions={setActionExceptions}
          setCarePlanActions={setCarePlanActions}
          carePlanActions={carePlanActions}
          populations={_selectPopulations}
          patientPopulations={patientPopulationsTemplates ?? []}
        />
      }
      footer={
        <Footer
          onClose={handleClose}
          onSave={() => {
            //Create the Patient Care Plan Actions based on the existent actions and the exceptions
            onConfirm(addExceptionsToActions(actionExceptions, carePlanActions));
          }}
        />
      }
      bodyClassName="p-0"
      onClose={handleClose}
      show={show}
    />
  );
};
export default PopulationsModal;
