import patientIcon from 'assets/icons/patient-icon.svg';
import CardWithLinkButton from 'components/CardWithLinkButton';
import { useCallback, useEffect } from 'react';
import { useAdministrationContext, useAdministrationContextV2 } from 'screens/Administration/store';
import { setPatientSignups } from 'screens/Administration/store/Dashboard/dashboardActionCreators';
import { toggleShowInvitePatient } from 'screens/Administration/store/Patients';
import { getPatientsSignups } from 'services/administrationService';
import styles from '../AdminDashboard.module.css';
import PatientSignups from './PatientSignups';

const NewPatientSignups = () => {
  const { setState } = useAdministrationContext();
  const {
    administrationStateV2: {
      dashboard: { patientSignups },
    },
    dispatch,
  } = useAdministrationContextV2();

  const fetchPatientSignups = useCallback(async () => {
    if (!patientSignups) {
      const res = await getPatientsSignups();
      dispatch(setPatientSignups(res.results));
    }
  }, [dispatch, patientSignups]);

  useEffect(() => {
    fetchPatientSignups();
  }, [fetchPatientSignups]);

  return (
    <CardWithLinkButton
      bodyClassName={`d-flex flex-column ${styles['dashboard-card']}`}
      header={{
        linkLabel: 'Invite patient',
        text: 'New patient sign-ups',
        onClick: () => {
          dispatch(toggleShowInvitePatient());
        },
      }}
    >
      {!patientSignups?.length ? (
        <div className="d-flex flex-column gap-md justify-content-center align-items-center w-100 flex-grow-1">
          <img src={patientIcon} alt="no new provider sign-ups" />
          <div>There are no new registered patients</div>
        </div>
      ) : (
        <PatientSignups patientSignups={patientSignups} />
      )}
    </CardWithLinkButton>
  );
};
export default NewPatientSignups;
