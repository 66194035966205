import React from 'react';
import styles from './styles.module.css';

const PatientManagementAddButton = ({onClick: handleClick}) => (
  <span className={styles.main} onClick={handleClick}>
    Add
  </span>
);

export default PatientManagementAddButton;
