import VytracLayout from 'components/VyTracLayout';
import { useHistory } from 'react-router-dom';

export const NotFound = () => {
  const history = useHistory();

  const handleGoToDashboard = () => {
    history.push('/dashboard');
  };
  return (
    <VytracLayout selectedRoute="">
      <article className="d-flex flex-column flex-grow-1 w-100 h-100 justify-content-center align-items-center">
        <section>
          Whoops, the page you tried to visit is not available or you do not have the right
          permissions to see it
        </section>
        <section>
          <button type="button" className="link" onClick={handleGoToDashboard}>
            Go to dashboard
          </button>
        </section>
      </article>
    </VytracLayout>
  );
};
