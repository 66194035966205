import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.css';
import TabRouteMap from '../../../../../../util/TabRouteMap';
import TabEnum from '../../../../../../util/TabEnum';

const Actions = () => {
  const history = useHistory();

  return (
    <div className={styles.rpmTemplateActions}>
      <Button
        label="create new template"
        onClick={() => history.push(`${TabRouteMap[TabEnum.CAREPLAN_TEMPLATES]}/create`)}
      />
    </div>
  );
};
export default Actions;
