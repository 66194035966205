import GenericPatientInfoTable from 'components/GenericPatientInfoTable';
import {
  DemographicInfoFormikValues,
  PatientManagementFormikKeys,
  PersonalInformationFormikKeys,
} from 'components/PatientManagementForm/types';
import DemographicFormikKeys from 'components/PatientManagementForm/types/DemographicFormikKeys';
import PatientManagementInput from 'components/PatientManagementInput';
import PatientManagementSelect from 'components/PatientManagementSelect';
import Ethnicity from 'types/ApiModels/Users/Ethnicity';
import Race from 'types/ApiModels/Users/Race';
import Religion from 'types/ApiModels/Users/Religion';
import Sex from 'types/ApiModels/Users/Sex';
import SelectValueLabel from 'types/Shared/SelectValueLabel';
import LanguagesField from './LanguagesField';
import styles from './styles.module.css';

interface DemographicInfoProps {
  formData: DemographicInfoFormikValues;
  handleChange;
  errors;
  touched;
  languages;
  handleAddLanguage;
  races: Race[];
  religions: Religion[];
  ethnicities: Ethnicity[];
  maritalStatus: MaritalStatus[];
  sexOptions: Sex[];
}

const renderValueLabelOption = (opt: SelectValueLabel) => (
  <option value={opt.value} key={opt.value} className="text-capitalize">
    {opt.label}
  </option>
);

const DemographicInfo = ({
  formData,
  handleChange,
  errors,
  touched,
  languages,
  handleAddLanguage,
  races,
  ethnicities,
  religions,
  maritalStatus,
  sexOptions,
}: DemographicInfoProps) => {
  // Functions and subroutines
  const createDemographicInfoBodyList = (formData, handleChange, errors) => {
    const demographicInfo = formData;

    const optionsByKey = {
      [DemographicFormikKeys.ETHNICITY]:
        ethnicities?.map((e) => ({ value: e.id, label: e.name })) ?? [],
      [DemographicFormikKeys.MARITAL_STATUS]: maritalStatus?.map((mso) => ({
        value: mso.id,
        label: mso.name,
      })),
      [DemographicFormikKeys.RACE]: races?.map((r) => ({ value: r.id, label: r.name })) ?? [],
      [DemographicFormikKeys.RELIGION]:
        religions?.map((r) => ({ value: r.id, label: r.name })) ?? [],
      [DemographicFormikKeys.GENDER]: sexOptions?.map((sex) => ({
        value: sex.id,
        label: sex.name,
      })),
    };
    // Functions and subroutines
    const transformDemographicInfoIntoArray = (demographicInfo: DemographicInfoFormikValues) => {
      return [
        'Demographic information regarding the patient',
        ...Object.keys(demographicInfo).map((key: DemographicFormikKeys) => {
          if (
            [
              DemographicFormikKeys.MARITAL_STATUS,
              DemographicFormikKeys.RACE,
              DemographicFormikKeys.RELIGION,
              DemographicFormikKeys.ETHNICITY,
              DemographicFormikKeys.GENDER,
            ].includes(key)
          )
            return (
              <PatientManagementSelect
                value={demographicInfo[key]}
                onChange={handleChange}
                name={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.DEMOGRAPHIC_INFO}.${key}`}
                error={errors && touched?.[key] ? errors[key] : undefined}
                options={optionsByKey[key]}
                renderOption={renderValueLabelOption}
              />
            );
          else if (key === 'language') {
            return (
              <LanguagesField
                name={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.DEMOGRAPHIC_INFO}.${key}`}
                languages={languages}
                handleAdd={handleAddLanguage}
              />
            );
          } else {
            return (
              <PatientManagementInput
                value={demographicInfo[key]}
                onChange={handleChange}
                name={`${PatientManagementFormikKeys.PERSONAL_INFORMATION}.${PersonalInformationFormikKeys.DEMOGRAPHIC_INFO}.${key}`}
                error={errors && touched?.[key] ? errors[key] : undefined}
              />
            );
          }
        }),
      ];
    };

    return [transformDemographicInfoIntoArray(demographicInfo)];
  };

  // Variables and constants
  const title = 'demographic info';
  const header = ['Gender identity', 'Marital Status', 'Race', 'Ethnicity', 'Language', 'Religion'];
  const body = createDemographicInfoBodyList(formData, handleChange, errors);

  return (
    <GenericPatientInfoTable
      tableName={title}
      tableHeader={header}
      tableBody={body}
      containerClassNameList={[styles.main]}
    />
  );
};

export default DemographicInfo;
