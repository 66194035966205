import Button from 'components/Button';
import Card from 'components/Card';
import SearchFilterHeader from 'components/SearchFilterHeader';
import { useAdministrationContext } from 'screens/Administration/store';
import TableBody from './TableBody';

export const NotificationsActions = () => {
  const { state } = useAdministrationContext();
  return (
    <div className="d-flex">
      <Button
        variant="cancel"
        label="Cancel"
        className="mr-2"
        disabled={!state.roles?.hasUnsavedChanges}
      />
      <Button
        variant="confirm"
        label="Save Changes"
        className="mr-2"
        disabled={!state.roles?.hasUnsavedChanges}
        onClick={() => {}}
      />
      <Button label="Add notification" onClick={() => {}} />
    </div>
  );
};

const Notifications = () => {
  return (
    <div className="p-3">
      <Card
        headers={[
          <SearchFilterHeader
            handleSearch={() => {}}
            search={null}
            onFiltersClick={() => undefined}
            title="Notification rules"
            searchPlaceholder="Search specialties..."
            key="notifications-header"
          />,
        ]}
        bodyClassName="bg-white p-0"
        className="bg-white"
        style={{ borderRadius: '5px' }}
      >
        <TableBody />
      </Card>
    </div>
  );
};

export default Notifications;
