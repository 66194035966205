export enum CreateAccountFormikKeys {
  PHONE_NUMBER = 'phoneNumber',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
}
export interface CreateAccountFormikValues {
  [CreateAccountFormikKeys.PHONE_NUMBER]: string;
  [CreateAccountFormikKeys.PASSWORD]: string;
  [CreateAccountFormikKeys.CONFIRM_PASSWORD]: string;
}

export enum RegisterPatientCards {
  CREATE_ACCOUNT_FORM = 'createAccount',
  REGISTER_COMPLETE = 'registerComplete',
  INVALID_INVITATION = 'invalidInvitation',
  CHECKING_INVITATION = 'checkingInvitation',
}
