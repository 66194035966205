import AlertSummary from 'types/ApiModels/Patients/AlertSummary';

/**
 *
 * @param alertsSummary
 * @returns array of sorted alerts by count
 */
export const sortAlerts = (alertsSummary: AlertSummary[]): AlertSummary[] => {
  return alertsSummary.sort((a, b) => b.count - a.count);
};
