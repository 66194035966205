import { Moment } from 'moment';
import {
  createPatientAction,
  deletePatientAction,
  updatePatientAction,
} from 'services/patientService';
import { createTrackingLog } from 'services/trackingService';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { addExceptionToRecurrence } from 'util/calendarUtils/actionRecurrence/absolute/addExceptionToRecurrence';
import { generateFutureRecurrence } from 'util/calendarUtils/actionRecurrence/absolute/generateFutureRecurrence';
import { deleteTrackingLog } from './TrackingLogs';

export const deleteOnlyThisEvent = async (
  selectedPatientId: number,
  trackingActivityId: number,
  patientActions: IPatientCarePlanAction[],
  selectedTimelineAction: IPatientTimelineAction,
  blockStartDate: Moment
): Promise<IPatientCarePlanAction[]> => {
  let updatedPatientActions = [...patientActions];
  let originalActionIndex: number;
  let originalAction: IPatientCarePlanAction;

  if (selectedTimelineAction.id >= 0) {
    // means selected action is original
    originalActionIndex = updatedPatientActions.findIndex(
      (action) => action.id === selectedTimelineAction.id
    );
    originalAction = { ...updatedPatientActions[originalActionIndex] };
    // generate recurrent action starting from next action
    const nextRecurrentAction = generateFutureRecurrence(blockStartDate, originalAction);
    if (nextRecurrentAction) {
      const newCarePlanAction = await createPatientAction(selectedPatientId, nextRecurrentAction);
      updatedPatientActions = [...updatedPatientActions, newCarePlanAction];
    }

    // delete original action
    updatedPatientActions.splice(originalActionIndex, 1);
    await deletePatientAction(selectedPatientId, originalAction.id);
  } else {
    // means action is recurrent generated
    const updatedOriginalAction = addExceptionToRecurrence(
      originalActionIndex,
      updatedPatientActions,
      selectedTimelineAction
    );
    await updatePatientAction(selectedPatientId, updatedOriginalAction);
  }

  if (trackingActivityId)
    createTrackingLog({
      ...deleteTrackingLog,
      description: 'Delete particular action from recurrence',
      object_id:
        selectedTimelineAction.id >= 0
          ? selectedTimelineAction.id
          : selectedTimelineAction.originalActivityId,
      patient: selectedPatientId,
      activity: trackingActivityId,
    });

  return updatedPatientActions;
};
