import addCircleButtonSrc from 'assets/icons/plus_button_icon.svg';
import FiltersButton from 'components/FiltersButton';

const StickyNotesCardHeader = ({ stickyNotesLength, handleFiltersButtonClick = () => {} }) => {
  return (
    <div className="d-flex justify-content-between w-100 align-items-center">
      <div>Sticky Notes {stickyNotesLength ? `(${stickyNotesLength})` : ''}</div>
      <div className="d-flex align-items-center gap">
        <img src={addCircleButtonSrc} height={20} width={20} alt="add sticky note button" />
        <FiltersButton onFiltersClick={handleFiltersButtonClick} />
      </div>
    </div>
  );
};
export default StickyNotesCardHeader;
