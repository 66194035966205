import Button from 'components/Button';
import VytracModal from 'components/Modal';
import React from 'react';
import { usePatientFormikContext } from 'screens/Patients/store';
import PopulationPatient from 'types/ApiModels/Patients/PopulationPatient';

interface IDeleteConfirmationModal {
  selectedPopulation: PopulationPatient;
  onClose: () => void;
  onDelete: (populationId: number) => void;
}
const DeleteConfirmationModal = ({
  selectedPopulation,
  onClose,
  onDelete,
}: IDeleteConfirmationModal) => {
  const { selectedPatient } = usePatientFormikContext();

  const onDeleteClick = () => {
    onDelete(selectedPopulation.id);
    onClose();
  };

  return (
    <VytracModal
      title="Delete Population"
      body={
        <>
          Are you sure you wish to delete <b>{selectedPopulation?.name}</b> from{' '}
          {selectedPatient.user.first_name} {selectedPatient.user.last_name}
        </>
      }
      bodyClassName="font-size-big"
      centered
      footer={
        <div className="d-flex w-100 justify-content-between">
          <Button label="Cancel" variant="cancel" onClick={onClose} />
          <Button label="Delete" variant="delete" onClick={onDeleteClick} />
        </div>
      }
      onClose={onClose}
      show={selectedPopulation !== null}
    />
  );
};
export default DeleteConfirmationModal;
