import { deletePatientAction, updatePatientAction } from 'services/patientService';
import { createTrackingLog } from 'services/trackingService';
import { IPatientCarePlanAction, IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import { updatePreviousActionsRecurrence } from 'util/calendarUtils/actionRecurrence/absolute/updatePreviousActionsRecurrence';
import { deleteTrackingLog } from './TrackingLogs';

export const deleteAllEvents = async (
  selectedPatientId: number,
  trackingActivityId: number,
  patientActions: IPatientCarePlanAction[],
  selectedTimelineAction: IPatientTimelineAction
): Promise<IPatientCarePlanAction[]> => {
  const updatedPatientActions = [...patientActions];

  if (selectedTimelineAction.id >= 0) {
    // means selected action is original
    const selectedActionIndex = updatedPatientActions.findIndex(
      (a) => a.id === selectedTimelineAction.id
    );

    await deletePatientAction(selectedPatientId, selectedTimelineAction.id);
    updatedPatientActions.splice(selectedActionIndex, 1);
  } else {
    // update recurrence of previous actions
    const updatedOriginalAction = updatePreviousActionsRecurrence(
      selectedTimelineAction,
      updatedPatientActions,
      'patient'
    );
    await updatePatientAction(selectedPatientId, updatedOriginalAction);
  }

  if (trackingActivityId)
    createTrackingLog({
      ...deleteTrackingLog,
      description: 'Delete all actions from recurrence',
      object_id:
        selectedTimelineAction.id >= 0
          ? selectedTimelineAction.id
          : selectedTimelineAction.originalActivityId,
      patient: selectedPatientId,
      activity: trackingActivityId,
    });

  return updatedPatientActions;
};
