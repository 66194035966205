import { PopulationWithCount } from 'types/ApiModels/Patients/Population';
import { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { getPopulations } from 'services/administrationService';
interface HeaderProps {
  onChange: (populations: number[]) => void;
  populations: number[];
  patientPopulations: number[];
}
const Header = ({ populations: populationIds, onChange, patientPopulations }: HeaderProps) => {
  // const [allPopulations] = useAdminPopulations();
  const [allPopulations, setAllPopulations] = useState<PopulationWithCount[]>([]);

  useEffect(() => {
    const fetchPopulations = async () => {
      try {
        setAllPopulations(await getPopulations());
      } catch (exception) {
        throw exception;
      }
    };

    fetchPopulations();
  }, []);

  const populations = useMemo(() => {
    if (!allPopulations || allPopulations.length === 0 || !populationIds) return [];
    return allPopulations.filter((p) => populationIds.includes(p.id));
  }, [allPopulations, populationIds]);

  const mapPopulationsToSelect = (population: PopulationWithCount) => {
    const label = population.name;
    return { label, value: population.id };
  };
  return (
    <div className="d-flex gap align-items-center">
      <div>Add population</div>
      <div className="d-flex gap flex-grow-1">
        <Select
          isMulti
          value={populations.map(mapPopulationsToSelect)}
          placeholder="Select new populations to add..."
          onChange={(values) => {
            const newPops = values.filter((e) => e.value).map((v) => v.value);
            onChange(newPops);
          }}
          options={allPopulations
            ?.filter((p) => !patientPopulations.some((patientPop) => patientPop === p.id))
            .map(mapPopulationsToSelect)}
          name="populations"
          styles={{
            control: (base) => ({ ...base, border: 'none' }),
            container: (base) => ({ ...base, width: '95%' }),
          }}
          noOptionsMessage={() => 'Patient already has all available populations'}
        />
      </div>
    </div>
  );
};

export default Header;
