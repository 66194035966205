import styles from './styles.module.css';
import Select from 'components/form/Select';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createTrackingLog, getTrackingActivities } from 'services/trackingService';
import { ITrackingLog, TrackingAction } from 'types/ApiModels/Tracking/TrackingLog';
import { TrackingActivityTemplate } from 'types/ApiModels/Administration';
import { createIdTimeMap } from './utils/createIdTimeMap';
import Stopwatch from 'components/Stopwatch';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { setSelectedActivity } from 'screens/Patients/store/PatientTracking';

interface IActivityTracker {
  patientId: number;
  selectedTab: string;
}

const ActivityTracker = ({ patientId, selectedTab }: IActivityTracker) => {
  const {
    dispatch,
    patientState: {
      patientTracking: { selectedActivity },
    },
  } = usePatientContext();
  const [activityOptions, setActivityOptions] = useState<TrackingActivityTemplate[]>([]);
  const [isTracking, setIsTracking] = useState<boolean>(false);
  const [idTimeMap, setIdTimeMap] = useState<Map<number, number>>(null);
  const [trackingDurationInMs, setTrackingDurationInMs] = useState<number>(0);
  const prevTabRef = useRef<string>(selectedTab);
  const prevActivityRef = useRef<TrackingActivityTemplate>(null);

  const resetTrackingVariables = useCallback(() => {
    prevTabRef.current = selectedTab;
    prevActivityRef.current = selectedActivity;
    setTrackingDurationInMs(0);
  }, [selectedTab]);

  const sendTrackingLog = useCallback(() => {
    if (!trackingDurationInMs) return;
    if (!selectedActivity) return;

    const newLog: ITrackingLog = {
      tab: prevTabRef.current,
      duration: trackingDurationInMs,
      action: TrackingAction.VIEW,
      description: null,
      object_type: null,
      object_id: null,
      activity: selectedActivity.id,
      patient: patientId,
    };
    createTrackingLog(newLog);
  }, [patientId, selectedActivity?.id, trackingDurationInMs]);

  const updateTimePerActivity = (id: number) => {
    setIdTimeMap((prevState) => {
      return prevState.set(id, prevState.get(id) + 1);
    });
  };

  /** Get activities on component mount */
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const activities = await getTrackingActivities();
        setActivityOptions(activities);
        setIdTimeMap(createIdTimeMap(activities));
      } catch (exception) {
        throw exception;
      }
    };

    fetchActivities();
  }, []);

  useEffect(() => {
    if (!activityOptions.length || !idTimeMap?.size) return;

    /** Set default Activity and start tracking */
    const defaultActivity = activityOptions.find((activity) => activity.is_default);
    if (defaultActivity) {
      dispatch(setSelectedActivity(defaultActivity));
      setIsTracking(true);
    }
  }, [activityOptions, idTimeMap?.size]);

  /** Time variables management */
  useEffect(() => {
    const interval = setInterval(() => {
      if (isTracking) {
        updateTimePerActivity(selectedActivity.id);
        setTrackingDurationInMs(trackingDurationInMs + 1000);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [isTracking, trackingDurationInMs, selectedActivity?.id]);

  /**
   * Tracking log triggers
   */

  // Send log on tab change
  useEffect(() => {
    if (!isTracking) return;
    sendTrackingLog();
    resetTrackingVariables();
  }, [selectedTab]);

  // Send log every 60 seconds
  useEffect(() => {
    if (!isTracking) return;
    if (trackingDurationInMs < 60000) return;
    sendTrackingLog();
    resetTrackingVariables();
  }, [trackingDurationInMs]);

  // Send log on activity change
  useEffect(() => {
    if (isTracking && trackingDurationInMs) {
      sendTrackingLog();
      resetTrackingVariables();
    }
    prevActivityRef.current = selectedActivity;
  }, [selectedActivity]);

  /** Send log on timer stop */
  useEffect(() => {
    if (isTracking || !prevActivityRef.current) return;
    sendTrackingLog();
    resetTrackingVariables();
  }, [isTracking]);

  // Send log on component unmount
  useEffect(() => {
    return () => {
      sendTrackingLog();
    };
  }, []);

  const onActivityChange = useCallback(
    (newValue: string) => {
      const newActivity = activityOptions.find((activity) => activity.name === newValue);
      dispatch(setSelectedActivity(newActivity));
    },
    [dispatch, activityOptions]
  );

  return (
    <div className={styles.main}>
      <div className={styles.selectContainer}>
        <div className={styles.title}>tracking activity</div>
        <hr className="my-0" />
        <Select
          selectClassName={styles.activitySelect}
          placeholder="Select activity"
          currentValue={selectedActivity?.name}
          onChange={(e) => onActivityChange(e.target.value)}
          options={activityOptions.map((option) => option.name)}
        />
      </div>
      <Stopwatch
        isTracking={isTracking}
        disabled={selectedActivity === null}
        toggleIsTracking={() => setIsTracking(!isTracking)}
        elapsedTimeInSeconds={idTimeMap?.get(selectedActivity?.id)}
        displayTime
      />
    </div>
  );
};

export default ActivityTracker;
