import { TimePeriodOptions } from 'components/TimePeriodSelect';
import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';
import Adherence from 'types/ApiModels/Patients/Adherence';
import MiniatureHeader from './components/MiniatureHeader';
import NumberContainer from './components/NumberContainer';

const getDayArray = (startDate: Moment, endDate: Moment): Moment[] => {
  const currentDay = startDate.clone();
  let dayArray = [];
  while (currentDay.isBefore(endDate)) {
    dayArray = [...dayArray, currentDay.clone()];
    currentDay.add(1, 'days');
  }

  return dayArray;
};

const getDaysDisplayed = (timePeriodSelected: TimePeriodOptions, selectedDate: Date): Moment[] => {
  switch (timePeriodSelected) {
    case TimePeriodOptions.DAY: {
      return [moment(selectedDate).startOf('day')];
    }

    case TimePeriodOptions.WEEK: {
      const firstValue = moment(selectedDate).startOf('week');
      const lastValue = moment(selectedDate).endOf('week');
      return getDayArray(firstValue, lastValue);
    }

    default:
      return [];
  }
};

const isDayIncluded = (daySelected: Moment, dayArray: Moment[]) => {
  if (!dayArray) return false;
  for (const day of dayArray) {
    if (day.isSame(daySelected)) return true;
  }
  return false;
};

interface IMiniatureCalendar {
  adherence: Adherence;
  carePlanActivationDate: string;
  timePeriodSelected: TimePeriodOptions;
  selectedDate: Date;
  setSelectedDate: (newDate: Date) => void;
}

const MiniatureCalendar = ({
  adherence,
  carePlanActivationDate,
  timePeriodSelected,
  selectedDate,
  setSelectedDate,
}: IMiniatureCalendar) => {
  const adherenceStartDate = useMemo(() => {
    if (adherence.care_plan_from) {
      return moment(adherence.care_plan_from, 'YYYY-MM-DD');
    } else if (carePlanActivationDate) {
      return moment(carePlanActivationDate, 'YYYY-MM-DD');
    } else return moment();
  }, [adherence?.care_plan_from, carePlanActivationDate]);

  const adherenceEndDate = useMemo(() => {
    if (adherenceStartDate) {
      return adherenceStartDate.clone().add(30, 'days');
    } else return null;
  }, [adherenceStartDate]);

  const days = useMemo(() => {
    if (adherenceStartDate && adherenceEndDate) {
      return getDayArray(
        adherenceStartDate.clone().startOf('week'),
        adherenceEndDate.clone().endOf('week')
      );
    } else return [];
  }, [adherenceEndDate, adherenceStartDate]);

  const daysDisplayed = useMemo(() => {
    if (timePeriodSelected && selectedDate) {
      return getDaysDisplayed(timePeriodSelected, selectedDate);
    } else return [];
  }, [timePeriodSelected, selectedDate]);

  const daysAdhered = useMemo(() => {
    let array: Moment[] = [];
    if (adherence.adherence_days) {
      for (const adherenceDay of adherence.adherence_days) {
        array = [...array, moment(adherenceDay, 'YYYY-MM-DD').startOf('day')];
      }
      return array;
    } else return [];
  }, [adherence.adherence_days]);

  return (
    <div>
      <MiniatureHeader />
      <div>
        {Array.from(Array(6).keys()).map((rowNumber: number) => (
          <div key={`row-${rowNumber}`} className="d-flex justify-content-around">
            {days &&
              days
                .filter((_, index: number) => Math.floor(index / 7) === rowNumber)
                .map((day: Moment, index: number) => (
                  <NumberContainer
                    dayNumber={day.date()}
                    key={`col-${rowNumber}-value-${index}`}
                    isInBlock={
                      day.isSameOrAfter(adherenceStartDate) && day.isSameOrBefore(adherenceEndDate)
                    }
                    isDisplayed={isDayIncluded(day, daysDisplayed)}
                    isAdhered={isDayIncluded(day, daysAdhered)}
                    onClick={() => setSelectedDate(day.toDate())}
                  />
                ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MiniatureCalendar;
