import DefaultImage from 'components/DefaultImage';
import {
  IdentificationFormikKeys,
  PatientManagementFormikKeys,
} from 'components/PatientManagementForm/types';
import PatientManagementInput from 'components/PatientManagementInput';
import PatientManagementSelect from 'components/PatientManagementSelect';
import { useContext } from 'react';
import { mockPatientList } from 'screens/ProviderDashboard/utils';
import { useAppQuery } from 'store/use-app-query';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
import { PatientFormikContext } from '../../../../../../store/PatientFormikContext';
import styles from './styles.module.css';

interface IPatientIdentification {
  patient: PatientGetSingle;
  formData: any;
  handleChange: any;
  errors: any;
}
const PatientIdentification = ({
  patient,
  formData,
  handleChange,
  errors,
}: IPatientIdentification) => {
  // Context
  const { data: sexOptions } = useAppQuery('sex');

  return (
    <div className="dashboard-detail">
      <div className="dashboard-item-header font-weight-md">Identification</div>
      <div className={styles.body}>
        <div className={styles.photoContainer}>
          {patient.user?.imageUrl ? (
            <img className={styles.photo} src={patient.user?.imageUrl} alt="" />
          ) : (
            <DefaultImage sex={patient.user?.sex} className={styles.photo} />
          )}
        </div>
        <div className={styles['info-container']}>
          <div>
            <p className={`${styles.title} font-size-medium`}>Legal first name</p>
            <div className={`${styles.value} font-size-medium`}>
              <PatientManagementInput
                value={formData.firstName}
                name={`${PatientManagementFormikKeys.IDENTIFICATION}.${IdentificationFormikKeys.FIRST_NAME}`}
                onChange={handleChange}
                error={errors ? errors[IdentificationFormikKeys.FIRST_NAME] : undefined}
              />
            </div>
          </div>

          <div>
            <p className={`${styles.title} font-size-medium`}>Legal last name</p>
            <div className={`${styles.value} font-size-medium`}>
              <PatientManagementInput
                value={formData.lastName}
                name={`${PatientManagementFormikKeys.IDENTIFICATION}.${IdentificationFormikKeys.LAST_NAME}`}
                onChange={handleChange}
                error={errors ? errors[IdentificationFormikKeys.LAST_NAME] : undefined}
              />
            </div>
          </div>

          <div>
            <p className={`${styles.title} font-size-medium`}>Legal middle name</p>
            <div className={`${styles.value} font-size-medium`}>
              <PatientManagementInput
                value={formData.middleName}
                name={`${PatientManagementFormikKeys.IDENTIFICATION}.${IdentificationFormikKeys.MIDDLE_NAME}`}
                onChange={handleChange}
                error={errors ? errors[IdentificationFormikKeys.MIDDLE_NAME] : undefined}
              />
            </div>
          </div>

          <div>
            <p className={`${styles.title} font-size-medium`}>Date of birth</p>
            <div className={`${styles.value} font-size-medium`}>
              <PatientManagementInput
                value={formData.dateOfBirth}
                name={`${PatientManagementFormikKeys.IDENTIFICATION}.${IdentificationFormikKeys.BIRTHDATE}`}
                onChange={handleChange}
                type="date"
                error={errors ? errors[IdentificationFormikKeys.BIRTHDATE] : undefined}
              />
            </div>
          </div>

          <div>
            <p className={`${styles.title} font-size-medium`}>Sex assigned at birth</p>
            <div className={`${styles.value} font-size-medium`}>
              <PatientManagementSelect
                value={formData.sex}
                name={`${PatientManagementFormikKeys.IDENTIFICATION}.${IdentificationFormikKeys.SEX}`}
                onChange={handleChange}
                error={errors ? errors[IdentificationFormikKeys.SEX] : undefined}
                options={sexOptions}
                renderOption={({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientIdentification;
