import { TrackingActivityTemplate } from 'types/ApiModels/Administration';
import { PatientTrackingAction, PatientTrackingActionTypes } from './patientTrackingActionTypes';

export const setSelectedActivity = (
  selectedActivity: TrackingActivityTemplate
): PatientTrackingAction => ({
  type: PatientTrackingActionTypes.SET_SELECTED_ACTIVITY,
  payload: {
    selectedActivity,
  },
});
