import { ActionException } from 'types/ApiModels/CarePlan/action';
import { IRelativeTime } from 'types/ApiModels/CarePlan/RelativeTime';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import {
  ITemplateActionRecurrence,
  ITemplateCarePlan,
  ITemplateCarePlanAction,
} from 'types/ApiModels/Templates/CarePlan';
import { diffStrDateDays } from 'util/dateUtils';
import { rpmActionTypeToString } from 'util/rpmActionTypeToString';

export interface PopulationModalAction {
  id: number;
  day: number;
  time: number;
  minutes: number;
  activity: string;
  event_type: string;
  type: string;
  name: string;
  toggled: boolean;
  position: number | null;
  goal_name: string | null;
  file: string | null;
  recurrence: ITemplateActionRecurrence;
  medication_name: string | null;
  medication_quantity: string | null;
  medication_method: string | null;
  end_date_type: string | null;
  end_date_duration: number | null;
  questionnaire_when: string | null;
  questionnaire_when_minutes: number | null;
  resource_when: string | null;
  resource_when_minutes: number | null;
  questionnaire: number | null;
  patient_message?: string | null;
  is_part_of_rpm_plan?: boolean;
  team_note?: string;
  my_schedule_note?: string;
  resource: number | null;
  template: number;
  label: string;
  week_number?: number;
  day_of_week?: number;
  readonly: boolean;
}
export interface PopulationModalData {
  conflicts: boolean;
  actions: PopulationModalAction[];
}

const addToMap = <X extends IPatientCarePlanAction>(
  actionsPerDay: Map<number, PopulationModalData>,
  action: X,
  day: number,
  population: ITemplateCarePlan,
  selectedPopulations: number[],
  readonly?: boolean,
  checkToggle?: boolean,
  toggled?: boolean
) => {
  const current = actionsPerDay.get(day) || { conflicts: false, actions: [] };
  const hasConflicts =
    current.conflicts ||
    current.actions.some(
      (a) =>
        action.event_type === a.event_type &&
        action.type === a.type &&
        a.time ===
          parseInt(
            (action.relative_start_date
              ? action.relative_start_date.time
              : action.start_date.slice(11)
            ).split(':')[0]
          )
    );
  const week = action.relative_start_date
    ? `(Week ${action.relative_start_date.week_number}`
    : `(In ${
        Math.floor(diffStrDateDays(action.start_date, new Date().toISOString()) / 7) + 1
      } weeks`;

  let label = `${week})`;
  if (action.recurrence !== null) {
    label =
      action.recurrence.repeats_type === 'every_month'
        ? `${week}, every 30 days)`
        : action.recurrence.repeats_type === 'time_period'
        ? `${week}, every ${action.recurrence.repeats_param} days)`
        : action.recurrence.repeats_type === 'start_of_adherence_block'
        ? `At start of Adherence)`
        : action.recurrence.repeats_type === 'every_week' ||
          action.recurrence.repeats_type === 'every_day'
        ? ''
        : `${week},)`;
  }
  if (action.recurrence?.multiple_times_a_day) {
    action.recurrence.multiple_times_hours.forEach((time) => {
      actionsPerDay.set(day, {
        ...current,
        conflicts: hasConflicts,
        actions: [
          ...current.actions,
          {
            id: action.id,
            day: day,
            time: time.hour,
            minutes: time.minute,
            activity:
              action.event_type === 'vitals_request'
                ? rpmActionTypeToString[action.type]
                : action.event_type,
            event_type: action.event_type,
            type: action.type,
            name: population?.name ?? 'Other',
            toggled: !checkToggle ? selectedPopulations.includes(population?.id) : toggled,
            position: action.position,
            goal_name: action.goal_name,
            file: action.file,
            medication_name: action.medication_name,
            medication_quantity: action.medication_quantity,
            medication_method: action.medication_method,
            end_date_type: action.end_date_type,
            end_date_duration: action.end_date_duration,
            questionnaire_when: action.questionnaire_when,
            questionnaire_when_minutes: action.questionnaire_when_minutes,
            resource_when: action.resource_when,
            resource_when_minutes: action.resource_when_minutes,
            questionnaire: action.questionnaire,
            resource: action.resource,
            template: population?.id,
            recurrence: action.recurrence,
            label: label,
            week_number: action.relative_start_date
              ? action.relative_start_date.week_number
              : diffStrDateDays(action.start_date, new Date().toISOString()),
            day_of_week: action.relative_start_date
              ? action.relative_start_date.day_of_week
              : new Date(action.start_date).getDay(),
            readonly: readonly,
          },
        ],
      });
    });
  } else {
    actionsPerDay.set(day, {
      ...current,
      conflicts: hasConflicts,
      actions: [
        ...current.actions,
        {
          id: action.id,
          day: day,
          time: parseInt(
            (action.relative_start_date
              ? action.relative_start_date.time
              : action.start_date.slice(11)
            ).split(':')[0]
          ),
          minutes: parseInt(
            (action.relative_start_date
              ? action.relative_start_date.time
              : action.start_date.slice(11)
            ).split(':')[1]
          ),
          activity:
            action.event_type === 'vitals_request'
              ? rpmActionTypeToString[action.type]
              : action.event_type,
          event_type: action.event_type,
          type: action.type,
          name: population?.name ?? 'Other',
          toggled: !checkToggle ? selectedPopulations.includes(population?.id) : toggled,
          position: action.position,
          goal_name: action.goal_name,
          file: action.file,
          medication_name: action.medication_name,
          medication_quantity: action.medication_quantity,
          medication_method: action.medication_method,
          end_date_type: action.end_date_type,
          end_date_duration: action.end_date_duration,
          questionnaire_when: action.questionnaire_when,
          questionnaire_when_minutes: action.questionnaire_when_minutes,
          resource_when: action.resource_when,
          resource_when_minutes: action.resource_when_minutes,
          questionnaire: action.questionnaire,
          resource: action.resource,
          template: population?.id,
          recurrence: action.recurrence,
          label: label,
          week_number: action.relative_start_date
            ? action.relative_start_date.week_number
            : diffStrDateDays(action.start_date, new Date().toISOString()),
          day_of_week: action.relative_start_date
            ? action.relative_start_date.day_of_week
            : new Date(action.start_date).getDay(),
          readonly: readonly,
        },
      ],
    });
  }
};

export const mapPopulationDataToModal = (
  populationData: ITemplateCarePlan[],
  selectedPopulations: number[],
  patientActions: IPatientCarePlanAction[],
  patientPopulations: number[],
  actions: PopulationModalData[] = []
): PopulationModalData[] => {
  const actionsPerDay: Map<number, PopulationModalData> = new Map<number, PopulationModalData>();

  populationData.forEach((population) => {
    if (!selectedPopulations.includes(population.id)) return;

    population.actions?.forEach((actionTemplate) => {
      const action = { ...actionTemplate, template: population.id };
      if (action.recurrence !== null && action.recurrence.type == 'repeats') {
        if (action.recurrence.repeats_type === 'every_day') {
          Array.from(Array(7).keys()).forEach((day) => {
            addToMap(actionsPerDay, action, day % 7, population, selectedPopulations);
          });
        } else if (action.recurrence.repeats_type === 'start_of_adherence_block') {
          addToMap(actionsPerDay, action, 7, population, selectedPopulations);
        } else {
          addToMap(
            actionsPerDay,
            action,
            action.relative_start_date.day_of_week % 7,
            population,
            selectedPopulations
          );
        }
      } else if (action.recurrence === null) {
        addToMap(
          actionsPerDay,
          action,
          action.relative_start_date.day_of_week % 7,
          population,
          selectedPopulations
        );
      }
    });
  });

  patientActions.forEach((action) => {
    const readonly = true;
    const population = populationData.find((e) => e.id === action.template);
    if (action.recurrence !== null && action.recurrence.type == 'repeats') {
      if (action.recurrence.repeats_type === 'every_day') {
        Array.from(Array(7).keys()).forEach((day) => {
          addToMap(actionsPerDay, action, day % 7, population, selectedPopulations, readonly);
        });
      } else if (action.recurrence.repeats_type === 'start_of_adherence_block') {
        addToMap(actionsPerDay, action, 7, population, selectedPopulations, readonly);
      } else {
        addToMap(
          actionsPerDay,
          action,
          new Date(action.start_date).getDay(),
          population,
          selectedPopulations,
          readonly
        );
      }
    } else if (action.recurrence === null) {
      addToMap(
        actionsPerDay,
        action,
        new Date(action.start_date).getDay(),
        population,
        selectedPopulations,
        readonly
      );
    }
  });

  const actionsArray = Array(7)
    .fill(0)
    .map((_, i) => {
      const day = i;
      const current: PopulationModalData = actionsPerDay.get(day) || {
        conflicts: false,
        actions: [],
      };
      current.actions.sort((a, b) => a.time - b.time);
      if (current.actions.length > 0) {
        current.actions.forEach((a, idx) => {
          const prevState = actions[day]?.actions.find(
            (ac) =>
              ac.id === a.id &&
              ac.activity === a.activity &&
              ac.time === a.time &&
              ac.type === a.type &&
              a.day === a.day &&
              a.name === ac.name
          );
          if (prevState) {
            current.actions[idx].toggled = prevState.toggled;
          } else {
            current.actions[idx].toggled = true;
          }
        });
      }
      return current;
    });
  return actionsArray;
};

const findNextDate = (
  action: ITemplateCarePlanAction & { template?: number },
  relativeExceptions: IRelativeTime[]
) => {
  const currentStartDate = action.relative_start_date;
  if (action.recurrence) {
    if (action.recurrence.type === 'repeats') {
      if (action.recurrence.repeats_type === 'every_day') {
        let numberOfDays = currentStartDate.day_of_week;
        let i = 0;
        const exceptionCopy = [...relativeExceptions];
        exceptionCopy.sort((a, b) => a.day_of_week - b.day_of_week);
        while (i < exceptionCopy.length && exceptionCopy[i].day_of_week == numberOfDays) {
          i++;
          numberOfDays = (numberOfDays + 1) % 7;
        }
        return { ...currentStartDate, day_of_week: numberOfDays };
      }
    }
  }
  return null;
};

export const addExceptionsToActions = (
  exceptions: ActionException[],
  actions: (ITemplateCarePlanAction & { template?: number })[]
): IPatientCarePlanAction[] => {
  //Actions to return
  const patientActions: IPatientCarePlanAction[] = [];

  //We are going to check each action for exceptions to add
  actions.forEach((action) => {
    let patientAction: IPatientCarePlanAction;

    const relative_exceptions: IRelativeTime[] = [];

    //If exception was created add it
    exceptions.forEach((exception) => {
      if (exception.actionId === action.id) {
        relative_exceptions.push({
          day_of_week: exception.day,
          time: exception.exception.time,
          week_number: exception.exception.week_number,
        });
      }
    });

    if (relative_exceptions.length > 0) {
      action.relative_start_date = findNextDate(action, relative_exceptions);
    }

    //If the action has recurrence
    if (action.recurrence !== null) {
      //If the recurrence is from custom range,add it.
      //If the recurrence is of type repeats and is every week or every month, we check if there are any exceptions, if there are, we dont add
      //the action since it was completely removed.
      if (
        action.recurrence.type === 'custom_range' ||
        (action.recurrence.repeats_type === 'every_day' && relative_exceptions.length < 7) ||
        (action.recurrence.repeats_type === 'every_week' && relative_exceptions.length === 0) ||
        (action.recurrence.repeats_type === 'every_month' && relative_exceptions.length === 0)
      ) {
        patientAction = {
          ...action,
          recurrence: {
            ...action.recurrence,
            end_date: null,
            relative_exceptions: relative_exceptions,
          },
          is_part_of_rpm_plan: true,
          team_note: null,
          my_schedule_note: null,
          patient_message: null,
        };
      }
    }
    //If the action hasn't got recurrence but there are no exceptions, add the action
    else if (relative_exceptions.length === 0) {
      patientAction = {
        ...action,
        recurrence: null,
        is_part_of_rpm_plan: true,
        team_note: null,
        my_schedule_note: null,
        patient_message: null,
      };
    }
    //If the action hasn't got recurrence and there is an exception, then we don't add the action since it was completely removed
    //else { do nothing }

    //If the action is not null, add it to the array
    patientAction && patientActions.push(patientAction);
  });
  return patientActions;
};
