import Button from 'components/Button';
import PatientManagementForm from 'components/PatientManagementForm';
import { PatientManagementFormikValues } from 'components/PatientManagementForm/types';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import { useDialog } from 'components/VyTracLayout/store/hooks';
import { Formik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAdministrationContext, useAdministrationContextV2 } from 'screens/Administration/store';
import { useAdminSinglePatient } from 'screens/Administration/store/hooks';
import {
  setSinglePatientArchive,
  setSinglePatientFormDirty,
  setSinglePatientFormReset,
  setSinglePatientFormSubmit,
} from 'screens/Administration/store/Patients';
import createPatientManagementFormikInitialValues from 'screens/Patients/Content/components/PatientTabs/components/PatientManagement/util/createPatientManagementFormikInitialValues';
import PatientManagementValidationSchema from 'screens/Patients/Content/components/PatientTabs/components/PatientManagement/util/PatientManagementValidationSchema';
import onPatientManagementSubmit from 'screens/Patients/Content/components/PatientTabs/components/PatientManagement/util/submitPatientManagementForm';
import { usePatientFormikContext } from 'screens/Patients/store';
import { getSinglePatient } from 'services/patientService';
import { getErrorMessage } from 'util/utils';
import { useSurgeryFamilyAllergyHandlers } from './hooks';

const AdminPatientDetailsActions = () => {
  const {
    administrationStateV2: {
      patients: { singlePatientFormDirty, resetSinglePatientForm, submitSinglePatientForm },
    },
  } = useAdministrationContextV2();

  return (
    <div className={`d-flex gap`}>
      <Button
        label="cancel"
        variant="dull"
        disabled={!singlePatientFormDirty}
        onClick={resetSinglePatientForm}
      />
      <Button
        label="save changes"
        disabled={!singlePatientFormDirty}
        onClick={submitSinglePatientForm}
      />
    </div>
  );
};

const AdminPatientDetails = () => {
  const { setActions, setBreadcrumbPathList, breadcrumbPathList } = useAdministrationContext();
  const { patientId } = useParams<{ patientId: string }>();
  const { setSelectedPatient } = usePatientFormikContext();
  const [showConfirmArchive, setShowConfirmArchive] = useState(false);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [committingValues, setCommittingValues] = useState<PatientManagementFormikValues>();
  const { confirmationDialog, errorDialog } = useDialog();

  const [{ selectedPatient, resetSinglePatientForm, patientsBook }, dispatch] =
    useAdminSinglePatient();

  const {
    allergiesToDelete,
    allergiesToUpdate,
    familyHistoryToDelete,
    familyHistoryToUpdate,
    surgeriesToDelete,
    surgeriesToUpdate,
    handlers,
  } = useSurgeryFamilyAllergyHandlers();

  //need to sync reducer state with this formik context
  useEffect(() => {
    setSelectedPatient(selectedPatient);
  }, [selectedPatient, setSelectedPatient]);

  //TODO: we're lying to this useEffect, but if we tell the truth we cause a rerender loop (must fix context to avoid this).
  useEffect(() => {
    setActions(AdminPatientDetailsActions);
  }, []);

  useEffect(() => {
    const displayName = selectedPatient?.user?.first_name
      ? `${selectedPatient.user.first_name} ${selectedPatient.user.last_name}`
      : selectedPatient?.user?.username;
    if (!displayName) return;
    if (breadcrumbPathList[breadcrumbPathList.length - 1].title === displayName) return;
    setBreadcrumbPathList([...breadcrumbPathList.slice(0, 3), { title: displayName, url: '' }]);
  }, [selectedPatient, breadcrumbPathList]);

  const fetchSinglePatient = useCallback(
    async (patientId: number) => {
      const res = await getSinglePatient(patientId);
      setSelectedPatient(res);
    },
    [setSelectedPatient]
  );

  useEffect(() => {
    const id = parseInt(patientId);
    if (!isNaN(id)) {
      fetchSinglePatient(id);
    }
  }, [patientId, setSelectedPatient, patientsBook, fetchSinglePatient]);

  useEffect(() => {
    dispatch(
      setSinglePatientArchive(() => {
        setShowConfirmArchive(true);
      })
    );
  }, [dispatch]);

  const formikInitialValues = useMemo(
    () =>
      selectedPatient ? createPatientManagementFormikInitialValues({ selectedPatient }) : null,
    [selectedPatient]
  );

  if (!selectedPatient) return <></>;

  const handleSubmitSinglePatient = async () => {
    const toDelete = {
      familyHistory: familyHistoryToDelete,
      surgery: surgeriesToDelete,
      allergy: allergiesToDelete,
    };

    const toUpdate = {
      familyHistory: familyHistoryToUpdate,
      surgery: surgeriesToUpdate,
      allergy: allergiesToUpdate,
    };

    const [{ familyHistory, allergies, surgeries }, errorsArray] = await onPatientManagementSubmit(
      committingValues,
      selectedPatient,
      toDelete,
      toUpdate
    );
    setShowConfirmSave(false);

    const errorMessage = getErrorMessage(errorsArray);

    if (errorMessage) {
      errorDialog('Error', 'Failed to save information: ' + errorMessage);
      return;
    }
    confirmationDialog('Success', 'Information saved successfully');
    resetSinglePatientForm({
      values: {
        ...committingValues,
        FAMILY_HISTORY: [
          ...committingValues.FAMILY_HISTORY.filter((fh) => fh.id > 0),
          ...(familyHistory ? familyHistory : []),
        ],
        ALLERGIES: [
          ...committingValues.ALLERGIES.filter((a) => a.id > 0),
          ...(allergies ? allergies : []),
        ],
        SURGERIES: [
          ...committingValues.SURGERIES.filter((s) => s.id > 0),
          ...(surgeries ? surgeries : []),
        ],
      },
    });
    setCommittingValues(null);
  };

  const handleArchivePatient = () => {
    //TODO
  };

  return (
    <Formik
      initialValues={formikInitialValues}
      validationSchema={PatientManagementValidationSchema}
      onSubmit={(values) => {
        setCommittingValues(values);
        setShowConfirmSave(true);
      }}
      validateOnChange
    >
      {({ submitForm, resetForm, dirty }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setSinglePatientFormDirty(dirty));
        }, [dirty]);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setSinglePatientFormSubmit(submitForm));
        }, [submitForm]);

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          dispatch(setSinglePatientFormReset(resetForm));
        }, [resetForm]);
        return (
          <>
            <PatientManagementForm surgeryFamilyAllergyHandlers={handlers} />
            <SaveChangesConfirmationModal
              show={showConfirmArchive}
              onCancel={() => setShowConfirmArchive(false)}
              message={`Are you sure you want to archive patient: ${selectedPatient.user.first_name} ${selectedPatient.user.last_name}`}
              onConfirm={handleArchivePatient}
            />
            <SaveChangesConfirmationModal
              show={showConfirmSave}
              onCancel={() => setShowConfirmSave(false)}
              onConfirm={handleSubmitSinglePatient}
            />
          </>
        );
      }}
    </Formik>
  );
};
export default AdminPatientDetails;
