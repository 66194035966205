import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import DropdownMenu from 'components/DropdownMenu';
import { PhoneNumberFormik, SelectFormik, TextInputFormik } from 'components/FormikComponents';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { useMemo } from 'react';
import { getInsurances } from 'services/patientService';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import SelectValueLabel from 'types/Shared/SelectValueLabel';
import { useFetchList } from 'hooks';
import { getStyles } from '../../../utils/getStyles';
import { AddRow } from '../../Steps';
import styles from '../../styles.module.css';

const Insurance = () => {
  const { values, errors, touched } = useFormikContext<InvitePatient>();
  const openError = useMemo(() => errors.insurance?.length > 0 || false, [errors]);
  const [insurances] = useFetchList(getInsurances);
  const options = useMemo(() => {
    return insurances.map<SelectValueLabel>((i) => ({ label: i.name, value: i.id }));
  }, [insurances]);
  return (
    <DropdownMenu name="Insurance" open={openError}>
      <FieldArray validateOnChange={false} name="insurance">
        {({ push, remove }) => (
          <>
            {values.insurance && values.insurance.length > 0 ? (
              values.insurance.map((_, index) => (
                <div className="d-flex flex-column gap" key={index}>
                  <div className={styles['row']}>
                    <div className="d-flex gap px-3 pt-3 mb-2">
                      <div
                        style={{ flexBasis: '10%' }}
                        className={`d-flex ${styles['form-label']} font-size-medium`}
                      >
                        Name
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <SelectFormik
                          options={options}
                          name={`insurance[${index}].insuranceId`}
                          stylesContainer={getStyles(
                            errors,
                            touched,
                            `insurance[${index}].insuranceId`
                          )}
                          className="font-size-medium"
                        />
                        <ErrorMessage name={`insurance[${index}].name`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Subscriber number
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <PhoneNumberFormik
                          className="w-100"
                          name={`insurance[${index}].subscriberNumber`}
                          style={getStyles(errors, touched, `insurance[${index}].subscriberNumber`)}
                        />
                        <ErrorMessage name={`insurance[${index}].subscriberNumber`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon fill="#8FAAC3" stroke="#8FAAC3" />
                      </div>
                    </div>
                    <div className={styles['row']}>
                      <div className="d-flex gap px-3 pt-3 mb-2">
                        <div
                          style={{ flexBasis: '1%' }}
                          className={`d-flex ${styles['form-label']} font-size-medium`}
                        >
                          Group number
                        </div>
                        <div className={`flex-grow-1 d-flex flex-column gap`}>
                          <TextInputFormik
                            className="w-100"
                            name={`insurance[${index}].groupNumber`}
                            style={getStyles(errors, touched, `insurance[${index}].groupNumber`)}
                          />
                          <ErrorMessage name={`insurance[${index}].groupNumber`}>
                            {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                          </ErrorMessage>
                        </div>
                      </div>
                    </div>
                  </div>
                  {index === values.insurance.length - 1 && (
                    <AddRow
                      name="insurance"
                      onClick={() =>
                        push({ insuranceId: undefined, subscriberNumber: '', groupNumber: '' })
                      }
                    />
                  )}
                </div>
              ))
            ) : (
              <AddRow
                name="insurance"
                onClick={() => push({ insuranceId: '', subscriberNumber: '', groupNumber: '' })}
              />
            )}
          </>
        )}
      </FieldArray>
    </DropdownMenu>
  );
};
export default Insurance;
