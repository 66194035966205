import { authUserAxios, vytracAxios } from 'ajax';
import { useConversationsStore } from 'store/twilio';
import { PaginatedResponse } from 'types/ApiModels/General';
import { LoginRequest, VerificationCode } from 'types/ApiModels/Login';
import RegisterUserRequest from 'types/ApiModels/Login/RegisterUserRequest';
import { IPatientCarePlanAction } from 'types/ApiModels/Patients/CarePlan';
import { Language, MinifiedUser, User } from 'types/ApiModels/Users';
import Ethnicity from 'types/ApiModels/Users/Ethnicity';
import Race from 'types/ApiModels/Users/Race';
import Religion from 'types/ApiModels/Users/Religion';
import Sex from 'types/ApiModels/Users/Sex';
import { IColorScheme } from 'types/ApiModels/Users/User';
import { objectToUrlParams } from 'util/utils';
import { host } from '../util/apiUtils';
import { clearTokens, getAccessToken, getRefreshToken } from './tokenService';
import { clearTwilioTokenFromLocalStorage } from './twilio';

const baseEndpoint = 'api/users';

const getGenericHeaders = (token?: string) => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Bearer ${token || getAccessToken().token}`,
});

const noAuthHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const getCurrentUserInfo = async () => {
  const res = await authUserAxios.get('/me/');
  const json: MinifiedUser = await res.data;
  setPortalScheme(json.color_scheme);
  return json;
};

export const updateUser = async (
  id,
  first_name,
  last_name,
  middle_name,
  birth_date,
  sex,
  email,
  primary_phone_number,
  second_phone_number,
  languages
): Promise<User> => {
  const response = await authUserAxios.put(`/${id}/`, {
    first_name,
    last_name,
    middle_name,
    birth_date: birth_date.toISOString(),
    email,
    sex: sex,
    phone_number: primary_phone_number,
    second_phone_number,
    languages,
  });

  return await response.data;
};

export const getSexOptions = async () => {
  const response = await authUserAxios.get<Sex[]>('/sex/');
  return response.data;
};

export const getEthnicityOptions = async () => {
  const response = await vytracAxios.get<Ethnicity[]>('/patient/ethnicity/');
  return response.data;
};

export const getRaceOptions = async () => {
  const response = await vytracAxios.get<Race[]>('/patient/race/');
  return response.data;
};

export const getReligionOptions = async () => {
  const response = await vytracAxios.get<Religion[]>('/patient/religion/');
  return response.data;
};

export const getMaritalStatusOptions = async () => {
  const response = await vytracAxios.get<MaritalStatus[]>('/patient/maritalstatus/');
  return response.data;
};

export const logOut = async () => {
  const res = authUserAxios.post('/logout/', {
    refresh_token: getRefreshToken(),
  });
  clearTokens();
  clearTwilioTokenFromLocalStorage();
  useConversationsStore.getState().clearConversationsStore();
  return res;
};

export const login = async (values: LoginRequest) => {
  Object.keys(values).forEach((key) => {
    if (values[key] === '' || values[key] === null) {
      delete values[key];
    }
  });
  try {
    const response = await fetch(`${host}/${baseEndpoint}/login/`, {
      method: 'POST',
      headers: noAuthHeaders,
      body: JSON.stringify(values),
    });
    let result: { codeSentTo?: number; error?; message? } = {};
    if (response.status === 200) {
      result = await response.json();
    } else if (response.status === 201 || response.status === 204) {
      result = {
        codeSentTo: response.status,
        message: await response.json(),
      };
    } else if (response.status === 400) {
      result = {
        error: response.status,
        message: await response.json(),
      };
    } else if (response.status === 404) {
      result = {
        error: response.status,
        message: await response.json(),
      };
    } else if (response.status === 500) {
      result = {
        error: response.status,
        message: 'A server error has occurred, please try again later',
      };
    }
    return result;
  } catch (exception) {
    throw exception;
  }
};

export const checkInvitation = async (email, hash) => {
  const values = {
    email,
    hash,
  };
  const response = await fetch(`${host}/${baseEndpoint}/check_invitation/`, {
    method: 'POST',
    headers: noAuthHeaders,
    body: JSON.stringify(values),
  });
  if (response.status === 200) {
    return await response.json();
  } else throw new Error(`Request return with status code: ${response.status}`);
};
export const sendVerificationCodeEmail = (email: string) => {
  try {
    return fetch(`${host}/${baseEndpoint}/request-reset-email/`, {
      method: 'POST',
      headers: noAuthHeaders,
      body: JSON.stringify({
        email,
      }),
    });
  } catch (exception) {
    //swalllow, if the request fails we don't want to show anything (however request should never fail for client)
  }
};

export const verifySecurityTokenForPasswordReset = (email: string, token: string) => {
  try {
    return fetch(`${host}/${baseEndpoint}/request-reset-email/`, {
      method: 'POST',
      headers: noAuthHeaders,
      body: JSON.stringify({
        email,
        code: token,
      }),
    });
  } catch (error) {}
};

export const requestPasswordReset = (password: string, code: VerificationCode) => {
  try {
    return fetch(`${host}/${baseEndpoint}/password-reset-complete/`, {
      method: 'PATCH',
      headers: noAuthHeaders,
      body: JSON.stringify({
        password,
        ...code,
      }),
    });
  } catch (error) {}
};

export const registerUser = (values: RegisterUserRequest) => {
  //TODO: do we really this here?
  Object.keys(values).forEach((key) => {
    if (values[key] === '' || values[key] === null) {
      delete values[key];
    }
  });
  try {
    return fetch(`${host}/${baseEndpoint}/account/`, {
      method: 'POST',
      headers: noAuthHeaders,
      body: JSON.stringify(values),
    });
  } catch (exception) {
    throw exception;
  }
};

export const getLanguages = async () => {
  try {
    const res = await authUserAxios.get<Language[]>('language/');
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const uploadProfilePicture = async (photoData: Blob) => {
  try {
    const data = new FormData();
    data.append('photo', photoData);
    const res = await authUserAxios.post('profile_picture/', data);
    return res.data;
  } catch (error) {
    console.error(error);
  }
};

export const addActionsToCarePlan = async (
  patientId: number,
  actions: IPatientCarePlanAction[]
) => {
  try {
    const res = await vytracAxios.post(`/patient/${patientId}/rpm_plan/action/`, actions);
    return res.data;
  } catch (e) {
    throw e;
  }
};
export const checkEmail = async (email: string) => {
  try {
    const params = objectToUrlParams({ email });
    const data = await authUserAxios.get(`checkemail/${params}`);
    if (data.status === 200) return false;
    return true;
  } catch (error) {
    return true;
  }
};

export const setPortalScheme = (scheme?: IColorScheme) => {
  if (!scheme) return;
  const css = document.documentElement;
  css?.style.setProperty('--primary_color', scheme.primary_color);
  css?.style.setProperty('--secundary_color', scheme.secondary_color);
  css?.style.setProperty('--hover_color', scheme.hover_color);
};
