import CardAdd from 'components/CardAdd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { PatientManagementFormikValues } from '../types';
import FamilyHistoryList from './FamilyHistoryList';

interface FamilyHistoryCardProps {
  handleAdd: () => void;
  handleEdit: (id: number) => void;
  queueDelete: (id: number) => void;
}
const FamilyHistoryCard: FC<FamilyHistoryCardProps> = ({
  handleAdd,
  handleEdit,
  queueDelete,
}: FamilyHistoryCardProps) => {
  const {
    values: { FAMILY_HISTORY: familyHistory },
    setFormikState,
  } = useFormikContext<PatientManagementFormikValues>();

  const handleDeleteHistory = (id: number) => {
    setFormikState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        FAMILY_HISTORY: prev.values.FAMILY_HISTORY.filter((fh) => fh.id !== id),
      },
    }));
    id > 0 && queueDelete(id);
  };

  return (
    <CardAdd count={familyHistory?.length} handleAdd={handleAdd} title="Family history">
      <FamilyHistoryList
        familyHistory={familyHistory ?? []}
        handleHistoryDelete={handleDeleteHistory}
        handleHistoryEdit={handleEdit}
      />
    </CardAdd>
  );
};
export default FamilyHistoryCard;
