import React, { useState } from 'react';
import CarePlanBody from './components/Body/CarePlanBody';
import PatientCarePlanHeader from './components/Header';
import { DragDropContext, DragUpdate } from 'react-beautiful-dnd';
import {
  createActivity,
  getDraggedEventType,
  transferActivity,
} from '../../../../../../../../../util/dragUtils';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import {
  setCarePlanModalEventType,
  setPatientActions,
  setPreviousPatientActions,
  setSelectedAction,
  setTimelineActions,
} from 'screens/Patients/store/PatientCarePlan/patientCarePlanActionCreators';
import { IPatientTimelineAction } from 'types/ApiModels/Patients/CarePlan';
import NullActivationDateModal from '../NullActivationDateModal';

const PatientCarePlanTimeline = () => {
  const {
    dispatch,
    patientState: {
      patientCarePlan: { patientActions, timelineActions, carePlanState },
    },
  } = usePatientContext();

  const [showNullActivationDateModal, setShowNullActivationDateModal] = useState<boolean>(false);

  const onDragEnd = (dragUpdate: DragUpdate) => {
    if (carePlanState.activation_date === null) {
      // care plan activation date is not set
      setShowNullActivationDateModal(true);
      return;
    }

    if (dragUpdate.destination.droppableId === 'calendar-control') {
      // item was dropped on calendar controls
      return;
    }

    // set previous state if user hits cancel on modal
    dispatch(setPreviousPatientActions());

    const modalEventType = getDraggedEventType(dragUpdate.draggableId, patientActions);
    dispatch(setCarePlanModalEventType(modalEventType));

    if (dragUpdate.draggableId.startsWith('actionButton')) {
      // action button drag
      const newAction = createActivity(modalEventType, dragUpdate.destination.droppableId);
      dispatch(setSelectedAction(newAction as IPatientTimelineAction));
      dispatch(setPatientActions([...patientActions, newAction]));
    } else {
      // activity drag
      const actionId = Number(dragUpdate.draggableId);
      const updatedTimelineActions = [...timelineActions];
      const selectedActionIndex = updatedTimelineActions.findIndex(
        (a: IPatientTimelineAction) => a.id === actionId
      );

      const transferredAction = transferActivity(
        updatedTimelineActions[selectedActionIndex],
        dragUpdate.source.droppableId,
        dragUpdate.destination.droppableId
      );
      updatedTimelineActions[selectedActionIndex] = transferredAction;
      dispatch(setTimelineActions(updatedTimelineActions));
      dispatch(setSelectedAction(transferredAction));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="dashboard-detail pb-0">
        <div className="dashboard-item-header">
          <PatientCarePlanHeader />
        </div>
        <div>
          <CarePlanBody />
        </div>
      </div>
      <NullActivationDateModal
        show={showNullActivationDateModal}
        onConfirm={() => setShowNullActivationDateModal(false)}
        message="To create an action there needs to be a valid activation date"
      />
    </DragDropContext>
  );
};

export default PatientCarePlanTimeline;
