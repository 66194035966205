import Patient from 'types/ApiModels/Patients/Patient';
import { User } from 'types/ApiModels/Users';
import profile from 'assets/images/dashboard/profile.png';
import patient1 from 'assets/images/dashboard/patient.png';
import patient2 from 'assets/images/dashboard/patient2.png';
import { Provider } from 'types/ApiModels/Providers/Provider';
import PatientGetSingle from 'types/ApiModels/Patients/PatientGetSingle';
const today: string = new Date().toISOString();

const careTeam: Provider[] = [
  {
    id: 2,
    first_name: 'M',
    last_name: 'S',
    middle_name: '',
    date_birth: today,
    sex: 'F',
    photo: '',
    username: 'ms',
    phone_number1: '',
    phone_number2: '',
    address: [],
    title: { id: 1, name: 'provider' },
    speciality: [],
    languages: [],
    patients: 2,
    follow: 2,
    open_cases: 2,
    completed_cases: 2,
    activity_points_total: null,
    activity_points_detail: [],
    roles: [],
    user_id: 1,
    email: 'ms@email.com',
    is_active: true,
    satisfaction: null,
  },
  {
    id: 2,
    first_name: 'F',
    last_name: 'T',
    middle_name: '',
    date_birth: today,
    sex: 'F',
    photo: '',
    username: 'ft',
    phone_number1: '',
    phone_number2: '',
    address: [],
    title: { id: 1, name: 'provider' },
    speciality: [],
    languages: [],
    patients: 2,
    follow: 2,
    open_cases: 2,
    completed_cases: 2,
    activity_points_total: null,
    activity_points_detail: [],
    roles: [],
    user_id: 1,
    email: 'ft@email.com',
    is_active: true,
    satisfaction: null,
  },
  {
    id: 2,
    first_name: 'D',
    last_name: 'S',
    middle_name: '',
    date_birth: today,
    sex: 'F',
    photo: '',
    username: 'ds',
    phone_number1: '',
    phone_number2: '',
    address: [],
    title: { id: 1, name: 'provider' },
    speciality: [],
    languages: [],
    patients: 2,
    follow: 2,
    open_cases: 2,
    completed_cases: 2,
    activity_points_total: null,
    activity_points_detail: [],
    roles: [],
    user_id: 1,
    email: 'ds@email.com',
    is_active: true,
    satisfaction: null,
  },
];

export const currentUser: User = {
  id: 1,
  username: 'vytrac',
  email: 'vytrac@mail.com',
  first_name: 'Vytrac',
  last_name: 'Vytrac',
  full_name: 'Vytrac Vytrac',
  activity_points: 0,
  date_created: today,
  date_joined: today,
  groups: [],
  imageUrl: profile,
  is_active: true,
  is_email_verified: true,
  is_role_verified: true,
  is_staff: true,
  is_superuser: true,
  languages: [],
  last_login: today,
  password: '',
  receive_newsletter: false,
  timezone: '',
  user_permissions: [],
};

export const patientMockList: (Patient & {
  alerts: {
    red_alerts: number;
    critical_alerts: number;
    yellow_alerts: number;
    missed_measures: number;
  };
})[] = [
  {
    id: 2934293,
    emergency_contact: [],
    address: [],
    insurances: [],
    adherence: {
      adherence_days: [],
      care_plan_from: '',
      care_plan_to: '',
    },
    avatar_status: {
      adherence: 10,
      read_alerts: 10,
      yellow_alerts: 10,
      normal_readings: 70,
    },
    timespent: {
      video: 10,
      call: 0,
    },
    notes: 5,
    satisfaction: 80,
    deleted: false,
    score: 250,
    gender_identity: 'female',
    marital_status: 'married',
    date_created: new Date().toISOString(),
    is_active: true,
    is_adhering: true,
    race: null,
    ethnicity: null,
    religion: null,
    primary_care_physicial: 'Thomas Wayne',
    created_by: currentUser,
    user: {
      id: 2,
      username: 'DianaPrince',
      email: 'princessthemy@email.com',
      first_name: 'Diana',
      last_name: 'Prince',
      full_name: 'Diana Prince',
      activity_points: 250,
      date_created: today,
      date_joined: today,
      groups: [],
      imageUrl: patient2,
      is_active: true,
      is_email_verified: false,
      is_role_verified: false,
      is_staff: false,
      is_superuser: false,
      languages: [],
      last_login: today,
      password: '',
      receive_newsletter: false,
      timezone: '',
      user_permissions: [],
      birth_date: today,
    },
    care_taker: careTeam,
    populations: [],
    care_champions: [],
    alerts: {
      red_alerts: 7,
      critical_alerts: 4,
      yellow_alerts: 5,
      missed_measures: 10,
    },
    surgeries: [],
    allergies: [],
    documents: [],
    familyhistory: [],
    is_archived: false,
    missed_calls_red_alert: 0,
    missed_calls_yellow_alert: 0,
  },
  {
    id: 2934293,
    emergency_contact: [],
    address: [],
    insurances: [],
    adherence: {
      adherence_days: [],
      care_plan_from: '',
      care_plan_to: '',
    },
    avatar_status: {
      adherence: 10,
      read_alerts: 10,
      yellow_alerts: 30,
      normal_readings: 50,
    },
    timespent: {
      video: 10,
      call: 0,
    },
    notes: 5,
    satisfaction: 80,
    deleted: false,
    score: 250,
    gender_identity: 'male',
    marital_status: 'married',
    date_created: new Date().toISOString(),
    is_active: true,
    is_adhering: true,
    race: null,
    ethnicity: null,
    religion: null,
    primary_care_physicial: 'Thomas Wayne',
    created_by: currentUser,
    user: {
      id: 2,
      username: 'JohnConstantine',
      email: 'JohnConstantine@email.com',
      first_name: 'John',
      last_name: 'Constantine',
      full_name: 'John Constantine',
      activity_points: 249,
      date_created: today,
      date_joined: today,
      groups: [],
      imageUrl: patient1,
      is_active: true,
      is_email_verified: false,
      is_role_verified: false,
      is_staff: false,
      is_superuser: false,
      languages: [],
      last_login: today,
      password: '',
      receive_newsletter: false,
      timezone: '',
      user_permissions: [],
      birth_date: today,
    },
    care_taker: careTeam,
    populations: [],
    care_champions: [],
    alerts: {
      critical_alerts: 3,
      yellow_alerts: 10,
      missed_measures: 10,
      red_alerts: 0,
    },
    surgeries: [],
    allergies: [],
    documents: [],
    familyhistory: [],
    is_archived: false,
    missed_calls_red_alert: 0,
    missed_calls_yellow_alert: 0,
  },
];

export const singlePatient: Patient = {
  id: 1,
  emergency_contact: [],
  address: [],
  insurances: [],
  avatar_status: {
    adherence: 10,
    read_alerts: 20, //AKA RED ALERTS :/
    yellow_alerts: 20,
    normal_readings: 50,
  },
  adherence: {
    adherence_days: [],
    care_plan_from: '',
    care_plan_to: '',
  },
  timespent: {
    video: 5,
    call: 0,
  },
  notes: 0,
  satisfaction: 90,
  deleted: false,
  score: 100,
  gender_identity: 'Male',
  marital_status: 'Married',
  date_created: '',
  is_active: true,
  is_adhering: true,
  race: '',
  ethnicity: '',
  religion: '',
  primary_care_physicial: '',
  created_by: null,
  care_champions: [],
  care_taker: [],
  populations: [],
  user: {
    id: 1,
    username: 'johnconstantine',
    first_name: 'John',
    last_name: 'Constantine',
    email: '',
    full_name: 'John Constantine',
    activity_points: 100,
    imageUrl: patient1,
  } as User,
  surgeries: [],
  allergies: [],
  documents: [],
  familyhistory: [],
  is_archived: false,
  missed_calls_red_alert: 0,
  missed_calls_yellow_alert: 0,
};

export const patientForPatientCase: PatientGetSingle = {
  id: 1,
  address: [],
  insurances: [],
  emergency_contact: [],
  avatar_status: {
    adherence: 10,
    read_alerts: 20, //AKA RED ALERTS :/
    yellow_alerts: 20,
    normal_readings: 50,
  },
  adherence: {
    adherence_days: [],
    care_plan_from: '',
    care_plan_to: '',
  },
  // timespent: {
  //   video: 0,
  //   call: 0,
  // },
  // notes: 0,
  satisfaction: '90',
  // deleted: false,
  urgency_score: 100,
  health_score: 100,
  gender_identity: 'Male',
  marital_status: 'Married',
  date_created: '',
  is_active: true,
  // is_adhering: true,
  race: { id: 0, name: '' },
  ethnicity: { id: 0, name: '' },
  religion: { id: 0, name: '' },
  // primary_care_physicial: '',
  // created_by: null,
  care_champions: [],
  // care_taker: [],
  populations: [],
  user: {
    id: 1,
    username: 'johnconstantine',
    first_name: 'John',
    middle_name: '',
    last_name: 'Constantine',
    email: '',
    birth_date: new Date().toISOString(),
    photo: '',
    photo_thumbnail: '',
    about_me: '',
    imageUrl: '',
    languages: [],
    phone_number: '111-111-111',
    second_phone_number: '222-222-222',
    sex: 'M',
    timezone: '',
    //TODO: I think this might come properly from BE later, since it's a mock object but beware this cast
  },
  surgeries: [],
  allergies: [],
  documents: [],
  familyhistory: [],
  missed_calls_red_alert: 0,
  missed_calls_yellow_alert: 0,
  symptoms: [],
  diseases: [],
  alerts_summary: [],
  activity_points: 0,
  status: '',
  has_mobile_app: false,
  care_team: null,
  is_archived: true,
  preferred_contact_method: 'video',
  twilio_conversation_sid: undefined,
  twilio_identity: undefined,
  alerts: undefined,
};
