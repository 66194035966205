import Card from 'components/Card';
import { useDialogContext } from 'components/VyTracLayout/store';
import { FC } from 'react';
import { useRegisterContext } from 'screens/Register/store';
import styles from '../styles.module.css';
import CreatePatientAccountForm from './CreatePatientAccountForm';

const CreatePatientAccountCard: FC = () => {
  const { email } = useRegisterContext();
  const { dialog: Dialog } = useDialogContext();
  return (
    <Card
      headers={[
        <div key="header-main" className="font-size-large font-weight-md">
          Registration details
        </div>,
        ,
        <div key="header-secondary" className="font-size-big font-weight-md">
          Welcome <b>{email}</b> !
        </div>,
      ]}
      className={`vytrac-card ${styles['register-patient-card']}`}
      bodyClassName="p-0"
    >
      {Dialog && <Dialog />}
      <CreatePatientAccountForm />
    </Card>
  );
};
export default CreatePatientAccountCard;
