import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAdministrationContext, useAdministrationContextV2 } from 'screens/Administration/store';
import { toggleShowInvitePatient } from 'screens/Administration/store/Patients';
import { toggleShowInviteProvider } from 'screens/Administration/store/Providers';
import InvitePatientModal from 'components/InvitePatientModal/InvitePatientModal';
import InviteProviderModal from '../AdminProviders/ProvidersTable/InviteProviderModal';
import NewPatientSignups from './NewPatientSignups';
import NewProvidersSignups from './NewProvidersSignups';
import NewRolesAdded from './NewRolesAdded';
import NewRulesAdded from './NewRulesAdded';
import PatientActivityPoints from './PatientActivityPoints';
import ProviderActivityPoints from './ProviderActivityPoints';

export const AdminDashboardActions = () => <></>;

const AdminDashboard = () => {
  const { setActions } = useAdministrationContext();
  const {
    administrationStateV2: {
      providers: { showInviteProvider },
      patients: { showInvitePatient },
    },
    dispatch,
  } = useAdministrationContextV2();

  useEffect(() => {
    setActions(AdminDashboardActions);
  }, []);

  const handlePatientModalCloseConfirm = () => {
    dispatch(toggleShowInvitePatient());
  };

  const handleProviderModalCloseConfirm = () => {
    dispatch(toggleShowInviteProvider());
  };

  return (
    <>
      <Row className="pt-4">
        <Col className="d-flex flex-column gap">
          <Row className="w-100">
            <NewProvidersSignups />
          </Row>
          <Row>
            <ProviderActivityPoints />
          </Row>
        </Col>
        <Col className="d-flex flex-column gap">
          <Row>
            <NewPatientSignups />
          </Row>
          <Row>
            <PatientActivityPoints />
          </Row>
        </Col>
        <Col>
          <Row>
            <NewRolesAdded />
          </Row>
        </Col>
        <Col>
          <Row>
            <NewRulesAdded />
          </Row>
        </Col>
      </Row>
      <InvitePatientModal
        show={showInvitePatient}
        handleClose={handlePatientModalCloseConfirm}
        handleConfirm={handlePatientModalCloseConfirm}
      />
      <InviteProviderModal
        show={showInviteProvider}
        onClose={handleProviderModalCloseConfirm}
        onConfirm={handleProviderModalCloseConfirm}
      />
    </>
  );
};

export default AdminDashboard;
