import { vytracAxios } from 'ajax';
import { AxiosError } from 'axios';
import { TrackingActivityTemplate } from 'types/ApiModels/Administration';
import { PaginatedResponse } from 'types/ApiModels/General';
import { ITrackingLog } from 'types/ApiModels/Tracking/TrackingLog';

const API_COMPONENT = 'tracking';

export const getTrackingActivities = async () => {
  try {
    const res = await vytracAxios.get<TrackingActivityTemplate[]>(`${API_COMPONENT}/activities/`);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const createTrackingActivityTemplateBulk = async (
  activityTemplates: Omit<TrackingActivityTemplate, 'id'>[]
): Promise<[res: TrackingActivityTemplate[], error: AxiosError]> => {
  if (!activityTemplates.length) return [null, null];
  try {
    const res = await vytracAxios.post<TrackingActivityTemplate[]>(
      `${API_COMPONENT}/activities/`,
      activityTemplates
    );
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const updateTrackingActivityTemplatesBulk = async (
  activityTemplates: TrackingActivityTemplate[]
) => {
  if (!activityTemplates.length) return [null, null];
  try {
    const res = await vytracAxios.put<TrackingActivityTemplate[]>(
      `${API_COMPONENT}/activities/`,
      activityTemplates
    );
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const patchTrackingActivityTemplatesBulk = async (
  activityTemplates: AtLeast<TrackingActivityTemplate, 'id'>[]
) => {
  if (!activityTemplates.length) return [null, null];
  try {
    const res = await vytracAxios.patch<TrackingActivityTemplate[]>(
      `${API_COMPONENT}/activities/`,
      activityTemplates
    );
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const deleteTrackingActivityTemplateBulk = async (activityIds: { id: number }[]) => {
  if (!activityIds.length) return [null, null];
  try {
    const res = await vytracAxios.delete(`${API_COMPONENT}/activities/`, { data: activityIds });
    return [res.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const getTrackingLogs = async () => {
  const response = await vytracAxios.get<PaginatedResponse<ITrackingLog>>('tracking/');
  return response.data.results;
};

export const createTrackingLog = async (log: ITrackingLog) => {
  const response = await vytracAxios.post<ITrackingLog>('tracking/', log);
  return response.data;
};
