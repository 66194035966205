import CardWithLinkButton from 'components/CardWithLinkButton';
import { useState } from 'react';

const ProviderActivityPoints = () => {
  const [providerActivityPoints, setProviderActivityPoints] = useState([]);
  return (
    <CardWithLinkButton
      header={{
        linkLabel: 'Open',
        text: 'Provider activity points',
      }}
    >
      {providerActivityPoints?.length ? null : 'No data available'}
    </CardWithLinkButton>
  );
};
export default ProviderActivityPoints;
