import React, { useMemo, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import { usePatientFormikContext } from 'screens/Patients/store';
import {
  setCarePlanState,
  setSelectedDate,
} from 'screens/Patients/store/PatientCarePlan/patientCarePlanActionCreators';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { partialUpdateCarePlan } from 'services/patientService';
import NullActivationDateModal from '../../../NullActivationDateModal';
import styles from './styles.module.css';
import { countAdherenceRequests } from './utils/countAdherenceRequests';

const PatientCarePlanHeader = () => {
  const { selectedPatient } = usePatientFormikContext();
  const {
    dispatch,
    patientState: {
      patientCarePlan: { carePlanState, timelineActions },
    },
  } = usePatientContext();

  const [showNullActivationDateModal, setShowNullActivationDateModal] = useState<boolean>(false);

  const onActivate = async () => {
    if (carePlanState.activation_date) {
      const updatedCarePlan = await partialUpdateCarePlan(selectedPatient.id, {
        is_active: !carePlanState.is_active,
      });
      dispatch(setCarePlanState(updatedCarePlan));
    } else {
      setShowNullActivationDateModal(true);
    }
  };

  const onTodayClick = () => {
    dispatch(setSelectedDate(new Date()));
  };

  const adherenceRequests = useMemo(
    () => countAdherenceRequests(timelineActions, selectedPatient.adherence.care_plan_from),
    [timelineActions, selectedPatient.adherence.care_plan_from]
  );

  return (
    <div className={styles.patientCarePlanHeader}>
      <NullActivationDateModal
        show={showNullActivationDateModal}
        message={'To activate the care plan there needs to be an activation date'}
        onConfirm={() => setShowNullActivationDateModal(false)}
      />
      <span>Patient care plan</span>
      <FormCheck
        id="carePlan-activation"
        type="switch"
        checked={carePlanState.activation_date !== null && carePlanState.is_active}
        onChange={onActivate}
      />
      <button className="font-size-medium" onClick={onTodayClick}>
        Today
      </button>
      <div
        style={{ color: adherenceRequests < 16 ? '#EF2F4B' : '#86B463' }}
      >{`Adherence requests ${adherenceRequests}/30`}</div>
    </div>
  );
};

export default PatientCarePlanHeader;
