import EditDeleteActionButtons from 'components/EditDeleteActionButtons';
import TextSurroundedWithLines from 'components/TextSurroundedWithLines';
import { FC } from 'react';
import { FamilyHistory } from 'types/ApiModels/Patients/Patient';
import styles from '../../PatientManagementForm.module.css';

interface FamilyHistoryListProps {
  familyHistory: FamilyHistory[];
  handleHistoryDelete: (id: number) => void;
  handleHistoryEdit: (id: number) => void;
}

const FamilyHistoryList: FC<FamilyHistoryListProps> = ({
  familyHistory,
  handleHistoryDelete,
  handleHistoryEdit,
}: FamilyHistoryListProps) => {
  if (!familyHistory?.length)
    return <TextSurroundedWithLines text="No known history" className="text-gray-low-risk" />;

  return (
    <div className="d-flex flex-column gap-sm">
      {familyHistory.map((fh) => (
        <div className={`d-flex flex-column gap ${styles.card}`} key={fh.id}>
          <div className="d-flex justify-content-between">
            <p className="m-0 p-0 font-size-big">{fh.name}</p>
            <div className={styles['action-buttons']}>
              <EditDeleteActionButtons
                handleDelete={() => handleHistoryDelete(fh.id)}
                handleEdit={() => handleHistoryEdit(fh.id)}
              />
            </div>
          </div>
          <p className={`m-0 p-0 text-gray-low-risk text-capitalize`}>{fh.relationship}</p>
          <p className="m-0 p-0">{fh.details}</p>
        </div>
      ))}
    </div>
  );
};
export default FamilyHistoryList;
