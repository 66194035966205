import ActivityTimeline from 'components/ActivityTimeline';
import ActivityTimelineContext from 'components/ActivityTimeline/store';
import { mapActionsToActivities } from 'components/ActivityTimeline/utils';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { usePatientFormikContext } from 'screens/Patients/store';
import {
  setCarePlanModalEventType,
  setPreviousPatientActions,
  setSelectedAction,
  setSelectedDate,
  setTimelineActions,
} from 'screens/Patients/store/PatientCarePlan/patientCarePlanActionCreators';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import { Activity, ActivityType } from 'types/Patients/PatientTimeline/ActivityTimeline';
import DatePickerValue from 'types/Shared/DatePicker';
import { generateTimelineActions } from 'util/calendarUtils/actionRecurrence/absolute/generateRecurrentActions';
import { convertDatepickToDate, convertDateToDatePickValue } from 'util/dateUtils';

const calendarHeight = '690px';

const CarePlanCalendar = () => {
  const {
    selectedPatient: {
      adherence: { care_plan_from },
    },
  } = usePatientFormikContext();

  const {
    dispatch,
    patientState: {
      patientCarePlan: {
        carePlanState,
        selectedDate,
        patientActions,
        timelineActions,
        periodSelected,
      },
    },
  } = usePatientContext();

  useEffect(() => {
    if (!care_plan_from) return;
    const defualtDate = moment(care_plan_from, 'YYYY-MM-DD').toDate();
    dispatch(setSelectedDate(defualtDate));
  }, [care_plan_from, dispatch]);

  useEffect(() => {
    if (!patientActions) return;
    const blockStartDate = care_plan_from
      ? new Date(care_plan_from)
      : new Date(carePlanState.updated_at);
    const newTimelineActions = generateTimelineActions(
      patientActions,
      selectedDate,
      blockStartDate
    );

    dispatch(setTimelineActions(newTimelineActions));
  }, [carePlanState.updated_at, care_plan_from, patientActions, selectedDate, dispatch]);

  const onActivityClick = (_, activityId: number) => {
    const clickedIdx = timelineActions.findIndex((a) => a.id === activityId);
    const clickedAction = { ...timelineActions[clickedIdx] };
    clickedAction.previousStartDate = clickedAction.start_date;

    const updatedTimelineActions = [...timelineActions];
    updatedTimelineActions[clickedIdx] = clickedAction;
    dispatch(setTimelineActions(updatedTimelineActions));
    dispatch(setSelectedAction(clickedAction));

    const eventType = clickedAction.event_type;
    dispatch(setCarePlanModalEventType(eventType));
    dispatch(setPreviousPatientActions());
  };

  const setTimelineDate = useCallback(
    (datePickerValue: DatePickerValue) => {
      const dateValue = convertDatepickToDate(datePickerValue);
      dispatch(setSelectedDate(dateValue));
    },
    [dispatch]
  );

  const timelineActivities = useMemo(() => {
    if (care_plan_from) {
      const adherenceBlock: Activity = {
        start_date: moment(care_plan_from, 'YYYY-MM-DD').toISOString(),
        end_date: moment(care_plan_from, 'YYYY-MM-DD').add(30, 'days').toISOString(),
        type: ActivityType.ADHERENCE_BLOCK,
      };
      return [adherenceBlock, ...mapActionsToActivities(timelineActions)];
    }

    return mapActionsToActivities(timelineActions);
  }, [timelineActions, care_plan_from]);

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ maxHeight: calendarHeight, overflow: 'auto' }}>
        <ActivityTimelineContext.Provider
          value={{
            activities: timelineActivities,
            currentSelectedDate: convertDateToDatePickValue(selectedDate),
            setCurrentSelectedDate: setTimelineDate,
            clickable: true,
            onActivityClick,
            draggable: true,
            carePlanFrom: moment(care_plan_from, 'YYYY-MM-DD').toDate(),
          }}
        >
          <ActivityTimeline currentView={periodSelected} style={{ minHeight: calendarHeight }} />
        </ActivityTimelineContext.Provider>
      </div>
    </div>
  );
};

export default CarePlanCalendar;
