import { Reducer } from 'react';
import { accountSettingsReducer } from './AccountSettings';
import {
  AccountSettingsAction,
  AccountSettingsActionTypes,
} from './AccountSettings/accountSettingsActionTypes';
import { AdministrationAction, AdministrationTypes } from './administrationActionTypes';
import { AdministrationStateV2 } from './AdministrationContextV2';
import { AdminCareTeamAction, AdminCareTeamActionTypes, careTeamReducer } from './CareTeam';
import { adminDashboardReducer, DashboardAction, DashboardActionTypes } from './Dashboard';
import { emailSettingsReducer } from './EmailSettings';
import {
  EmailSettingAction,
  EmailSettingActionTypes,
} from './EmailSettings/emailSettingsActionTypes';
import { formReducer } from './Forms';
import { FormAction, FormActionTypes } from './Forms/formActionTypes';
import {
  NotesKeywordsAction,
  NotesKeywordsActionTypes,
  notesKeywordsReducer,
} from './patient-settings/notes-keywords';
import {
  PatientActivitiesAction,
  PatientActivitiesActionTypes,
  patientActivitiesReducer,
} from './patient-settings/patient-activities';
import { adminPatientsReducer, PatientAction, PatientActionTypes } from './Patients';
import {
  AdminPopulationAction,
  AdminPopulationActionTypes,
  adminPopulationsReducer,
} from './Populations';
import { adminProvidersReducer, ProviderAction, ProviderActionTypes } from './Providers';
import { adminSpecialtyReducer, SpecialtyAction, SpecialtyActionTypes } from './Specialties';
import { AdminUsersAction, AdminUsersActionTypes, adminUsersReducer } from './Users';

export type AllAdminActions =
  | AdministrationAction
  | ProviderAction
  | SpecialtyAction
  | DashboardAction
  | PatientAction
  | AdminUsersAction
  | AdminPopulationAction
  | EmailSettingAction
  | AdminCareTeamAction
  | PatientActivitiesAction
  | AccountSettingsAction
  | NotesKeywordsAction
  | FormAction;

enum AdminActionSubType {
  Provider = 'Provider',
  Admin = 'Admin',
  Specialty = 'Specialty',
  Dashboard = 'Dashboard',
  Patient = 'Patient',
  User = 'User',
  Population = 'Population',
  EmailSetting = 'EmailSetting',
  CareTeam = 'CareTeam',
  PatientActivities = 'PatientActivities',
  AccountSettings = 'AccountSettings',
  NotesKeywords = 'NotesKeywords',
  Form = 'Form',
}

const getSubType = (action: AllAdminActions) => {
  if (action.type in AdminUsersActionTypes) return AdminActionSubType.User;
  if (action.type in ProviderActionTypes) return AdminActionSubType.Provider;
  if (action.type in AdministrationTypes) return AdminActionSubType.Admin;
  if (action.type in SpecialtyActionTypes) return AdminActionSubType.Specialty;
  if (action.type in DashboardActionTypes) return AdminActionSubType.Dashboard;
  if (action.type in PatientActionTypes) return AdminActionSubType.Patient;
  if (action.type in AdminPopulationActionTypes) return AdminActionSubType.Population;
  if (action.type in EmailSettingActionTypes) return AdminActionSubType.EmailSetting;
  if (action.type in AdminCareTeamActionTypes) return AdminActionSubType.CareTeam;
  if (action.type in PatientActivitiesActionTypes) return AdminActionSubType.PatientActivities;
  if (action.type in AccountSettingsActionTypes) return AdminActionSubType.AccountSettings;
  if (action.type in NotesKeywordsActionTypes) return AdminActionSubType.NotesKeywords;
  if (action.type in FormActionTypes) return AdminActionSubType.Form;
  throw Error(
    `Did not found any reducer for your action type: ${action.type}. Please check thoroughly that your action-types enum has a unique value across other slices as well as make sure that your enum key and value are exactly the same: ACTION_1="ACTION_1" ✅`
  );
};

const generalAdministrationReducer = (state, action: AdministrationAction) => {
  switch (action.type) {
    case AdministrationTypes.CLEAR_FORM_ERROR: {
      return { ...state, formError: undefined };
    }
    case AdministrationTypes.SET_FORM_ERROR: {
      return { ...state, formError: action.payload.error };
    }
    default: {
      return state;
    }
  }
};
const administrationReducer: Reducer<AdministrationStateV2, AllAdminActions> = (
  state: AdministrationStateV2,
  action: AllAdminActions
): AdministrationStateV2 => {
  const subType = getSubType(action);
  switch (subType) {
    case AdminActionSubType.User: {
      const newUserState = adminUsersReducer(state.users, action as AdminUsersAction);
      return { ...state, users: newUserState };
    }
    case AdminActionSubType.Provider: {
      const newProviderState = adminProvidersReducer(state.providers, action as ProviderAction);
      return { ...state, providers: newProviderState };
    }
    case AdminActionSubType.Admin: {
      return generalAdministrationReducer(state, action as AdministrationAction);
    }
    case AdminActionSubType.Specialty: {
      return {
        ...state,
        specialties: adminSpecialtyReducer(state.specialties, action as SpecialtyAction),
      };
    }
    case AdminActionSubType.Dashboard: {
      return {
        ...state,
        dashboard: adminDashboardReducer(state.dashboard, action as DashboardAction),
      };
    }
    case AdminActionSubType.Patient: {
      return { ...state, patients: adminPatientsReducer(state.patients, action as PatientAction) };
    }
    case AdminActionSubType.Population: {
      return {
        ...state,
        populations: adminPopulationsReducer(state.populations, action as AdminPopulationAction),
      };
    }
    case AdminActionSubType.EmailSetting: {
      return {
        ...state,
        emailSettings: emailSettingsReducer(state.emailSettings, action as EmailSettingAction),
      };
    }
    case AdminActionSubType.CareTeam: {
      return {
        ...state,
        careTeams: careTeamReducer(state.careTeams, action as AdminCareTeamAction),
      };
    }
    case AdminActionSubType.PatientActivities: {
      return {
        ...state,
        patientActivities: patientActivitiesReducer(
          state.patientActivities,
          action as PatientActivitiesAction
        ),
      };
    }
    case AdminActionSubType.AccountSettings: {
      return {
        ...state,
        accountSettings: accountSettingsReducer(
          state.accountSettings,
          action as AccountSettingsAction
        ),
      };
    }
    case AdminActionSubType.NotesKeywords: {
      return {
        ...state,
        notesKeywords: notesKeywordsReducer(state.notesKeywords, action as NotesKeywordsAction),
      };
    }
    case AdminActionSubType.Form: {
      return {
        ...state,
        forms: formReducer(state.forms, action as FormAction),
      };
    }
    default: {
      return state;
    }
  }
};

export default administrationReducer;
