import { vytracAxios } from 'ajax';
import moment from 'moment';
import { IActiveScheduleState } from 'screens/Schedule/components/Content/components/FirstColumn/components/ActiveSchedule/store/reducers/ActiveScheduleReducer';
import { formatDate } from 'util/dateUtils';

interface ISchedule {
  id: string;
  name: string;
  weekToggles?: any;
  dayShift?: boolean;
  secondShift?: boolean;
  thirdShift?: boolean;
  selectedHours?: [];
  new?: boolean;
}

export interface IActiveScheduleProps {
  userId: string;
  scheduleId: string;
  name: string;
  activeUntil: boolean;
  endDate?: Date;
  vacationMode: boolean;
  startDateVacation?: Date;
  endDateVacation?: Date;
  vacationMessage?: string;
  outOfOffice: boolean;
  outOfOfficeDate?: Date;
  outOfOfficeMessage: string;
}

const apiToSchedule = (apiSchedule) => {
  const startOfWeek = moment().startOf('week').startOf('day');

  //weekToggles
  const weekToggles = apiSchedule.days.map((day) => day.active);

  //selectedHours need to be as a date from the start of the week
  const selectedHours = apiSchedule.days
    .flatMap((day, index) =>
      day.hours?.map((hour) =>
        moment(startOfWeek)
          .add(index, 'days')
          .add(hour > 0 ? hour : 24, 'hours')
          .toDate()
      )
    )
    .filter((hour) => hour);

  const parsedData: ISchedule = {
    id: apiSchedule.id,
    name: apiSchedule.name,
    weekToggles: weekToggles,
    selectedHours: selectedHours,
    dayShift: apiSchedule.day_shift || false,
    secondShift: apiSchedule.second_shift || false,
    thirdShift: apiSchedule.third_shift || false,
    new: apiSchedule.new || false,
  };
  return parsedData;
};

const scheduleToApi = (schedule: ISchedule) => {
  const mappedDays = schedule.selectedHours.reduce((acc, curr) => {
    const currentMoment = moment(curr);
    const index = currentMoment.day();
    if (!acc[index]) {
      acc[index] = {
        active: schedule.weekToggles[index],
        hours: [currentMoment.hour()],
      };
    } else {
      acc[index].hours.push(currentMoment.hour());
    }
    return acc;
  }, []);

  schedule.weekToggles.forEach((day, index) => {
    if (!mappedDays[index]) {
      mappedDays[index] = {
        active: day,
        hours: [],
      };
    }
  });
  return {
    day_shift: schedule.dayShift,
    second_shift: schedule.secondShift,
    third_shift: schedule.thirdShift,
    name: schedule.name,
    days: mappedDays,
  };
};

const activeScheduleToApi = (schedule: IActiveScheduleProps) => {
  return {
    user_id: schedule.userId,
    schedule_id: schedule.scheduleId,
    schedule_name: schedule.name,
    active_until: schedule.activeUntil,
    end_date: schedule.endDate?.toISOString(),
    vacation_mode: schedule.vacationMode,
    start_date_vacation: schedule.startDateVacation?.toISOString(),
    end_date_vacation: schedule.endDateVacation?.toISOString(),
    vacation_message: schedule.vacationMessage,
    is_out_of_office: schedule.outOfOffice,
    out_of_office_date: formatDate(schedule.outOfOfficeDate, 'yyyy-mm-dd'),
    out_of_office_message: schedule.outOfOfficeMessage,
  };
};

const apiToActiveScheduleProps = (apiSchedule) => {
  const parsedData: IActiveScheduleState = {
    scheduleId: apiSchedule.schedule_id,
    name: apiSchedule.schedule_name,
    activeUntil: apiSchedule.active_until,
    activeUntilDate: apiSchedule.end_date,
    vacationMode: apiSchedule.vacation_mode,
    startDateVacation: apiSchedule.start_date_vacation,
    endDateVacation: apiSchedule.end_date_vacation,
    vacationMessage: apiSchedule.vacation_message,
    outOfOffice: apiSchedule.is_out_of_office,
    outOfOfficeDate: apiSchedule.out_of_office_date,
    outOfOfficeMessage: apiSchedule.out_of_office_message,
  };
  return parsedData;
};

export async function getSchedule(id) {
  const response = await vytracAxios.get(`schedule/${id}`);
  return apiToSchedule(response.data);
}

export async function updateSchedule(id, data: ISchedule) {
  const apiSchedulue = scheduleToApi(data);
  const response = await vytracAxios.put(`schedule/${id}/`, apiSchedulue);
  return apiToSchedule(response.data);
}

export async function createSchedule(data: ISchedule) {
  const apiSchedulue = scheduleToApi(data);
  const response = await vytracAxios.post('schedule/', apiSchedulue);
  return apiToSchedule(response.data);
}

export async function getScheduleList() {
  try {
    const response = await vytracAxios.get(`schedule/`);
    const parsedSchedules = response.data.map(apiToSchedule);
    return parsedSchedules;
  } catch (exception) {
    throw exception;
  }
}

export async function createScheduleActiveProps(data: IActiveScheduleProps) {
  try {
    const apiActiveSchedule = activeScheduleToApi(data);
    const response = await vytracAxios.post(`schedule/active/${data.userId}/`, apiActiveSchedule);
    return apiToActiveScheduleProps(response.data);
  } catch (exception) {
    throw exception;
  }
}

export async function getActiveScheduleProps(id) {
  try {
    const response = await vytracAxios.get(`schedule/active/${id}/`);
    return apiToActiveScheduleProps(response.data);
  } catch (exception) {
    throw exception;
  }
}

export async function deleteSchedule(id) {
  try {
    await vytracAxios.delete(`schedule/${id}/`);
  } catch (exception) {
    throw exception;
  }
}
