import PatientManagementForm from 'components/PatientManagementForm';
import { PatientManagementFormikValues } from 'components/PatientManagementForm/types';
import SaveChangesConfirmationModal from 'components/SaveChangesConfirmationModal';
import { useDialog } from 'components/VyTracLayout/store/hooks';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSurgeryFamilyAllergyHandlers } from 'screens/Administration/AdministrationContent/AdminTabs/AdminPatients/AdminPatientDetails/hooks';
import { usePatientFormikContext } from 'screens/Patients/store';
import { usePatientContext } from 'screens/Patients/store/PatientContext';
import {
  setFormDirty,
  setResetPatientForm,
  setSubmitPatientForm,
} from 'screens/Patients/store/PatientManagement';
import { getErrorMessage } from 'util/utils';
import './styles.css';
import createPatientManagementFormikInitialValues from './util/createPatientManagementFormikInitialValues';
import PatientManagementValidationSchema from './util/PatientManagementValidationSchema';
import onPatientManagementSubmit from './util/submitPatientManagementForm';

const PatientManagement = () => {
  const { selectedPatient } = usePatientFormikContext();
  const [committingValues, setCommittingValues] = useState<PatientManagementFormikValues>();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { errorDialog, confirmationDialog } = useDialog();

  const {
    dispatch,
    patientState: {
      patientManagement: { resetPatientForm },
    },
  } = usePatientContext();
  const {
    allergiesToDelete,
    allergiesToUpdate,
    familyHistoryToDelete,
    familyHistoryToUpdate,
    handlers,
    surgeriesToDelete,
    surgeriesToUpdate,
  } = useSurgeryFamilyAllergyHandlers();

  if (!selectedPatient) return <></>;

  const handleSubmit = async () => {
    const toDelete = {
      familyHistory: familyHistoryToDelete,
      surgery: surgeriesToDelete,
      allergy: allergiesToDelete,
    };

    const toUpdate = {
      familyHistory: familyHistoryToUpdate,
      surgery: surgeriesToUpdate,
      allergy: allergiesToUpdate,
    };

    const [{ familyHistory, allergies, surgeries }, errorsArray] = await onPatientManagementSubmit(
      committingValues,
      selectedPatient,
      toDelete,
      toUpdate
    );
    setShowConfirmationModal(false);

    const errorMessage = getErrorMessage(errorsArray);

    if (errorMessage) {
      errorDialog('Error', 'Failed to save information: ' + errorMessage);
      return;
    }
    confirmationDialog('Success', 'Information saved successfully');
    resetPatientForm({
      values: {
        ...committingValues,
        FAMILY_HISTORY: [
          ...committingValues.FAMILY_HISTORY.filter((fh) => fh.id > 0),
          ...(familyHistory ? familyHistory : []),
        ],
        ALLERGIES: [
          ...committingValues.ALLERGIES.filter((a) => a.id > 0),
          ...(allergies ? allergies : []),
        ],
        SURGERIES: [
          ...committingValues.SURGERIES.filter((s) => s.id > 0),
          ...(surgeries ? surgeries : []),
        ],
      },
    });
    setCommittingValues(null);
  };

  const handlePreSubmit = (values: PatientManagementFormikValues) => {
    setCommittingValues(values);
    setShowConfirmationModal(true);
  };
  return (
    <>
      <Formik
        initialValues={createPatientManagementFormikInitialValues({ selectedPatient })}
        validationSchema={PatientManagementValidationSchema}
        onSubmit={handlePreSubmit}
      >
        {({ resetForm, submitForm, dirty }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            dispatch(setResetPatientForm(resetForm));
          }, [resetForm]);
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            dispatch(setSubmitPatientForm(submitForm));
          }, [submitForm]);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            dispatch(setFormDirty(dirty));
          }, [dirty]);

          return <PatientManagementForm surgeryFamilyAllergyHandlers={handlers} />;
        }}
      </Formik>
      <SaveChangesConfirmationModal
        show={showConfirmationModal}
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={handleSubmit}
      />
    </>
  );
};

export default PatientManagement;
