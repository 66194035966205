import React from 'react';
import { IGoalThreshold } from 'types/ApiModels/Patients/Goal';
import { getChartUnits, isDoubleThreshold } from 'util/goalChart';
import { ThresholdFormikKeys } from '../util/thresholdFormikUtils';
import ThresholdControl from './ThresholdControl';
import { AlertType } from './util';

interface GoalAlertsProps {
  goal: IGoalThreshold;
}
const GoalsAlerts = ({ goal }: GoalAlertsProps) => {
  const chartUnits = getChartUnits(goal.type);
  return (
    <div>
      {isDoubleThreshold(goal.type) && (
        <>
          <ThresholdControl
            alertType={AlertType.CRITICAL}
            minValueName={ThresholdFormikKeys.UPPER_RED_ZONE_TOP}
            unit={chartUnits}
            displayHeaders
          />
          <ThresholdControl
            alertType={AlertType.RED}
            maxValueName={ThresholdFormikKeys.UPPER_RED_ZONE_TOP}
            minValueName={ThresholdFormikKeys.UPPER_RED_ZONE_BOTTOM}
            unit={chartUnits}
            nextMinValueName={ThresholdFormikKeys.UPPER_YELLOW_ZONE_BOTTOM}
          />
          <ThresholdControl
            alertType={AlertType.YELLOW}
            maxValueName={ThresholdFormikKeys.UPPER_RED_ZONE_BOTTOM}
            minValueName={ThresholdFormikKeys.UPPER_YELLOW_ZONE_BOTTOM}
            unit={chartUnits}
            nextMinValueName={ThresholdFormikKeys.LOWER_YELLOW_ZONE_TOP}
          />
        </>
      )}
      <ThresholdControl
        alertType={AlertType.BASELINE}
        maxValueName={ThresholdFormikKeys.UPPER_YELLOW_ZONE_BOTTOM}
        minValueName={ThresholdFormikKeys.LOWER_YELLOW_ZONE_TOP}
        unit={chartUnits}
        displayHeaders={!isDoubleThreshold(goal.type)}
        nextMinValueName={ThresholdFormikKeys.LOWER_RED_ZONE_TOP}
      />
      <ThresholdControl
        alertType={AlertType.YELLOW}
        maxValueName={ThresholdFormikKeys.LOWER_YELLOW_ZONE_TOP}
        minValueName={ThresholdFormikKeys.LOWER_RED_ZONE_TOP}
        unit={chartUnits}
        nextMinValueName={ThresholdFormikKeys.LOWER_RED_ZONE_BOTTOM}
      />
      <ThresholdControl
        alertType={AlertType.RED}
        maxValueName={ThresholdFormikKeys.LOWER_RED_ZONE_TOP}
        minValueName={ThresholdFormikKeys.LOWER_RED_ZONE_BOTTOM}
        unit={chartUnits}
      />
      <ThresholdControl
        alertType={AlertType.CRITICAL}
        maxValueName={ThresholdFormikKeys.LOWER_RED_ZONE_BOTTOM}
        unit={chartUnits}
      />
    </div>
  );
};

export default GoalsAlerts;
