import { Field } from 'formik';
import React, { useMemo, useRef } from 'react';
import styles from './styles.module.css';
import PropTypes from 'prop-types';

interface InputFieldWithUnitProps {
  name: string;
  unit: string;
  offsetPercentage: number;
  minValue: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const InputFieldWithUnit = ({
  name,
  unit,
  offsetPercentage,
  minValue,
  onChange,
}: InputFieldWithUnitProps) => {
  const inputRef = useRef<HTMLInputElement>();
  const handleFocus = () => {
    inputRef.current.select();
  };

  const parsedOffsetPercentage = useMemo(() => {
    if (!offsetPercentage) return '-';
    return offsetPercentage > 0 ? `+${offsetPercentage}%` : `-${offsetPercentage}%`;
  }, [offsetPercentage]);

  return (
    <>
      <div className={`${styles['input-unit-grid']} ${styles['input-unit-percentage-container']}`}>
        <div className={styles['input-unit-grid']}>
          <Field name={name} className={styles.field}>
            {({ field }) => {
              return (
                <input
                  className={styles.field}
                  type="number"
                  {...field}
                  onChange={(e) => {
                    onChange(e);
                    field.onChange(e);
                  }}
                  onFocus={handleFocus}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  style={{
                    width: `${field.value?.toString().length || 10}ch`,
                  }}
                  min={minValue}
                  ref={inputRef}
                />
              );
            }}
          </Field>
          <div>{unit}</div>
        </div>
        <div className={styles['percentage-offset']}>{parsedOffsetPercentage}</div>
      </div>
    </>
  );
};

InputFieldWithUnit.propTypes = {
  /**Name of the input field, must match FormikKey */
  name: PropTypes.string,
  unit: PropTypes.string,
  /**Extra event handler for change event (formik's event handler is already executed on change) */
  onChange: PropTypes.func,
  offsetPercentage: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};

export default InputFieldWithUnit;
