const mockedStickyNotes = [
  {
    id: '23c3a483-e5e5-493d-9cd6-5f39026d2994',
    title:
      'habitasse platea dictumst etiam faucibus cursus urna ut tellus nulla',
    content:
      'Sed sagittis. Nam congue, risus semper porta volutpat, quam pede lobortis ligula, sit amet eleifend pede libero quis orci. Nullam molestie nibh in lectus.',
    notificationDate: '2021-10-27T06:24:09Z',
  },
  {
    id: 'a2125827-2902-45d6-9885-b1c399a83cb3',
    title: 'aliquet massa id lobortis convallis tortor risus dapibus augue vel',
    content: 'Sed ante. Vivamus tortor. Duis mattis egestas metus.',
    notificationDate: '2021-05-11T04:06:04Z',
  },
  {
    id: '020ee98e-06c1-4e82-85ca-858e74a58665',
    title: 'imperdiet sapien urna pretium nisl ut volutpat sapien arcu sed',
    content:
      'Praesent id massa id nisl venenatis lacinia. Aenean sit amet justo. Morbi ut odio.',
    notificationDate: '2021-08-15T23:04:10Z',
  },
  {
    id: '305d2132-ab1a-4b94-bfa5-ccb7d3f8985e',
    title: 'pede libero quis orci nullam molestie nibh in lectus pellentesque',
    content:
      'Donec diam neque, vestibulum eget, vulputate ut, ultrices vel, augue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi. Integer ac neque.',
    notificationDate: '2021-08-14T08:23:43Z',
  },
  {
    id: 'fcc61d0c-db27-4338-838f-e0aa444e7dad',
    title:
      'molestie sed justo pellentesque viverra pede ac diam cras pellentesque',
    content:
      'Praesent id massa id nisl venenatis lacinia. Aenean sit amet justo. Morbi ut odio.',
    notificationDate: '2021-05-23T20:10:25Z',
  },
  {
    id: '57f6fa1e-bd11-473e-ad5e-711023625241',
    title: 'eros viverra eget congue eget semper rutrum nulla nunc purus',
    content:
      'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus vestibulum sagittis sapien. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    notificationDate: '2021-11-06T20:52:36Z',
  },
  {
    id: '42e04728-ed28-4f09-956f-646850047733',
    title: 'imperdiet sapien urna pretium nisl ut volutpat sapien arcu sed',
    content:
      'Morbi porttitor lorem id ligula. Suspendisse ornare consequat lectus. In est risus, auctor sed, tristique in, tempus sit amet, sem.',
    notificationDate: '2021-04-27T17:15:08Z',
  },
  {
    id: 'b2e5addc-79be-4f02-a140-230b19f10288',
    title:
      'suspendisse accumsan tortor quis turpis sed ante vivamus tortor duis',
    content:
      'Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus vestibulum sagittis sapien. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
    notificationDate: '2021-11-15T21:22:26Z',
  },
  {
    id: '20833c77-72ab-49c5-a9e8-2fe0eeea2e13',
    title: 'turpis adipiscing lorem vitae mattis nibh ligula nec sem duis',
    content:
      'Vestibulum ac est lacinia nisi venenatis tristique. Fusce congue, diam id ornare imperdiet, sapien urna pretium nisl, ut volutpat sapien arcu sed augue. Aliquam erat volutpat.',
    notificationDate: '2021-09-21T03:44:04Z',
  },
  {
    id: '2abc63a9-ac35-4d40-a158-a490618f5df1',
    title: 'phasellus id sapien in sapien iaculis congue vivamus metus arcu',
    content:
      'Pellentesque at nulla. Suspendisse potenti. Cras in purus eu magna vulputate luctus.',
    notificationDate: '2021-01-31T12:38:41Z',
  },
];

export default mockedStickyNotes;
