import { PHONE_NUMBER_VALIDATION_MESSAGE, REQUIRED_MESSAGE } from 'util/formikUtils';
import { phoneNumberRegex, usernameRegex } from 'util/regexs';
import * as yup from 'yup';

export const RegisterValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .min(8, 'Must contain at least 8 characters')
    .matches(/\d/, 'Password must contain at least one number')
    .matches(
      /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g,
      'Password must contain at least one special character'
    ),
  confirm_password: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  phone_number: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(phoneNumberRegex, PHONE_NUMBER_VALIDATION_MESSAGE),
});
