import { mapCarePlanActionTypeToIcon } from 'components/AlertIcons/utils/mapAlertTypeToIcon';
import { mapActionTypeToLabel } from 'components/RPM/utils/typeMapper';
import { FC } from 'react';
import colors from 'styles/colors';
import { CarePlanActionType } from 'types/ApiModels/CarePlan/ActionType';

interface DeviceMeasureIconLabelProps {
  type: CarePlanActionType;
}

const DeviceMeasureIconLabel: FC<DeviceMeasureIconLabelProps> = ({
  type,
}: DeviceMeasureIconLabelProps) => {
  const Icon = mapCarePlanActionTypeToIcon[type];
  const label = mapActionTypeToLabel(type);
  if (!Icon) return <></>;
  return (
    <div className="d-flex gap">
      <div>{<Icon width="12" height="12" color={colors.lowRiskAlerts} />}</div>
      <div className="text-nowrap">{label}</div>
    </div>
  );
};
export default DeviceMeasureIconLabel;
