import Button from 'components/Button';
import { TextInputFormik } from 'components/FormikComponents';
import vytracSelectStyle from 'components/VytracStyledSelect/vytracSelectStyle';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import Select from 'react-select';
import { inviteProvider } from 'services/administrationService';
import { InviteProviderValidationSchema } from '../util/ValidationSchema';
import styles from './styles.module.css';
import { useState } from 'react';
import CareTeamAsyncSelect, { CareTeamSelectOption } from 'components/CareTeamAsyncSelect';
import CareTeamProviderInviteField from './CareTeamProviderInviteField';
import CareTeamTierProviderInviteField from './CareTeamTierProviderInviteField';
import { CareTeam } from 'types/ApiModels/CareTeam';

const Footer = ({ onCancel }) => {
  return (
    <div className="d-flex w-100 justify-content-between">
      <Button label="cancel" variant="cancel" onClick={onCancel} />
      <Button label="invite" type="submit" />
    </div>
  );
};

interface InviteProviderFormProps {
  onCancel: () => void;
  onConfirm: () => void;
  specialtiesList: { label: string; value: number }[];
  rolesList: { label: string; value: number }[];
}

export interface InviteProviderFormikValues {
  email: string;
  careTeamId: number;
  rolesIds: number[];
  specialtiesIds: number[];
  careTeamTierId: number;
}

const InviteProviderForm = ({
  onConfirm,
  specialtiesList,
  rolesList,
  onCancel,
}: InviteProviderFormProps) => {
  const [formError, setFormError] = useState('');
  const [currentCareTeam, setCurrentCareTeam] = useState<CareTeamSelectOption>(null);

  const handleCareTeamChange = (values: CareTeamSelectOption) => {
    setCurrentCareTeam(values);
  };

  const handleCareTeamTierCreate = (ct: CareTeam) => {
    setCurrentCareTeam({ careTeam: ct, label: ct.name, value: ct.id });
  };
  return (
    <Formik<InviteProviderFormikValues>
      initialValues={{
        email: '',
        careTeamId: null,
        rolesIds: [],
        specialtiesIds: [],
        careTeamTierId: null,
      }}
      validationSchema={InviteProviderValidationSchema}
      onSubmit={async (values) => {
        const [_, error] = await inviteProvider({
          email: values.email,
          roles: values.rolesIds,
          specialties: values.specialtiesIds,
          careTeamId: values.careTeamId,
          careTeamTierId: values.careTeamTierId,
        });
        if (error) {
          setFormError(typeof error === 'string' ? error : Object.values(error).join('\n'));
          return;
        }
        onConfirm();
      }}
    >
      {({ setFieldValue, errors, values, touched }) => (
        <Form>
          <div className="d-flex gap flex-column">
            <div className={`d-flex justify-content-between align-items-center`}>
              <div className={`d-flex ${styles.label} font-size-medium`}>Provider email</div>
              <div className="flex-grow-1">
                <TextInputFormik className="w-100 font-size-medium" name="email" />
              </div>
            </div>
            {errors.email && touched.email ? (
              <div className="d-flex">
                <div className={styles['label']}></div>
                <ErrorMessage name="email">
                  {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                </ErrorMessage>
              </div>
            ) : null}
            {/** */}
            <div className="d-flex overflow-visible align-items-center">
              <div className={`d-flex ${styles['label']} font-size-medium `}>Care team</div>
              <CareTeamProviderInviteField
                onChange={handleCareTeamChange}
                value={currentCareTeam}
              />
            </div>
            {values['careTeamId'] ? (
              <div className="d-flex overflow-visible align-items-center">
                <div className={`d-flex ${styles['label']} font-size-medium `}>Tier</div>
                <CareTeamTierProviderInviteField
                  careTeam={currentCareTeam.careTeam}
                  onTierCreate={handleCareTeamTierCreate}
                />
              </div>
            ) : (
              <></>
            )}
            {/**specialties */}
            <div className="d-flex overflow-visible align-items-center">
              <div className={`d-flex ${styles['label']} font-size-medium `}>Specialty</div>
              <div className="flex-grow-1">
                <FieldArray
                  name="specialtiesIds"
                  render={() => (
                    <div>
                      <Select
                        isMulti
                        options={specialtiesList}
                        onChange={(values) => {
                          setFieldValue(
                            'specialtiesIds',
                            values.map((v) => v.value)
                          );
                        }}
                        styles={vytracSelectStyle}
                        placeholder="Assign specialties.."
                        className="font-size-medium"
                      />
                    </div>
                  )}
                />
                {errors.specialtiesIds &&
                touched.specialtiesIds &&
                typeof errors.specialtiesIds === 'string' ? (
                  <ErrorMessage name="specialtiesIds">
                    {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                  </ErrorMessage>
                ) : null}
              </div>
            </div>
            <div className="d-flex overflow-visible align-items-center">
              <div className={`d-flex ${styles['label']} font-size-medium `}>Access role</div>
              <div className="flex-grow-1">
                <FieldArray
                  name="rolesIds"
                  render={() => (
                    <div>
                      <Select
                        isMulti
                        options={rolesList}
                        onChange={(values) => {
                          setFieldValue(
                            'rolesIds',
                            values.map((v) => v.value)
                          );
                        }}
                        styles={vytracSelectStyle}
                        placeholder="Assign roles..."
                        className="font-size-medium"
                      />
                    </div>
                  )}
                />
                {errors.rolesIds && touched.rolesIds && typeof errors.rolesIds === 'string' ? (
                  <ErrorMessage name="rolesIds">
                    {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                  </ErrorMessage>
                ) : null}
              </div>
            </div>
            {formError ? <div className="text-danger font-size-medium">{formError}</div> : null}
            <Footer onCancel={onCancel} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InviteProviderForm;
