import React from 'react';
import GenericButton from '../GenericButton';
import deleteX from '../../assets/icons/deleteX.png';
import styles from './styles.module.css';

const PatientManagementEliminateButton = ({onClick}) => (
  <GenericButton
    className={styles.main}
    icon={deleteX}
    alt="delete"
    onClick={onClick}
  />
);

export default PatientManagementEliminateButton;
