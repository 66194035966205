import DatePicker from 'components/DatePicker';
import { TextInputFormik } from 'components/FormikComponents';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import InvitePatient from 'types/ApiModels/Administration/InvitePatient';
import { getStyles } from '../../../utils/getStyles';
import DropdownMenu from 'components/DropdownMenu';
import styles from '../../styles.module.css';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { AddRow } from '../../Steps';
import { useMemo } from 'react';
const Surgeries = () => {
  const { values, setFieldValue, touched, errors } = useFormikContext<InvitePatient>();
  const openError = useMemo(() => errors.surgeries?.length > 0 || false, [errors]);
  return (
    <DropdownMenu name="Surgery" open={openError}>
      <FieldArray validateOnChange={false} name="surgeries">
        {({ push, remove }) => (
          <>
            {values.surgeries && values.surgeries.length > 0 ? (
              values.surgeries.map((surgery, index) => (
                <div className="d-flex flex-column gap" key={index}>
                  <div className={styles['row']}>
                    <div className="d-flex gap px-3 pt-3 mb-2">
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Surgery name
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <TextInputFormik
                          className="w-100"
                          name={`surgeries[${index}].name`}
                          style={getStyles(errors, touched, `surgeries[${index}].name`)}
                        />
                        <ErrorMessage name={`surgeries[${index}].name`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Date of surgery
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <DatePicker
                          onChange={(date) => setFieldValue(`surgeries[${index}].date`, date)}
                          value={surgery.date}
                          format="MM.DD.YYYY"
                          hasFooter={false}
                          inputClassName="vytrac-input rounded px-2"
                          containerClassName="vytrac-input rounded px-2"
                          style={getStyles(errors, touched, `surgeries[${index}].date`)}
                        />
                        {/* <ErrorMessage name={`surgeries[${index}].date`}>
                    {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                  </ErrorMessage> */}
                      </div>
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon fill="#8FAAC3" stroke="#8FAAC3" />
                      </div>
                    </div>
                  </div>
                  <div className={styles['row']}>
                    <div className="d-flex gap px-3 mb-2">
                      <div className={`d-flex ${styles['form-label']} font-size-medium`}>
                        Surgery details
                      </div>
                      <div className={`flex-grow-1 d-flex flex-column gap`}>
                        <TextInputFormik
                          className="w-100"
                          name={`surgeries[${index}].details`}
                          style={getStyles(errors, touched, `surgeries[${index}].details`)}
                        />
                        <ErrorMessage name={`surgeries[${index}].details`}>
                          {(msg) => <div className="text-danger font-size-small">{msg}</div>}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                  {index === values.surgeries.length - 1 && (
                    <AddRow
                      name="surgery"
                      onClick={() => push({ name: '', date: '', details: '' })}
                    />
                  )}
                </div>
              ))
            ) : (
              <AddRow name="surgery" onClick={() => push({ name: '', date: '', details: '' })} />
            )}
          </>
        )}
      </FieldArray>
    </DropdownMenu>
  );
};
export default Surgeries;
