import TodoAlert, { CompletedAlertInfo } from 'components/TodoAlert';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { IDashboardToDoAlert } from 'types/ApiModels/Dashboard/DashboardToDo';
import ToDoSelector from './components/ToDoSelector';
import styles from './styles.module.css';

interface PatientTodoProps {
  alerts: IDashboardToDoAlert[];
}
const PatientToDo: FC<PatientTodoProps> = ({ alerts }) => {
  const [toDoSelected, setToDoSelected] = useState<number>(0);

  const filteredAlerts = useMemo(() => alerts.filter((alert) => !!alert.vytal), [alerts]);

  const leftButtonClick = useCallback(() => {
    if (toDoSelected === 0) return;
    setToDoSelected(toDoSelected - 1);
  }, [toDoSelected]);

  const rightButtonClick = useCallback(() => {
    if (toDoSelected === filteredAlerts.length - 1) return;
    setToDoSelected(toDoSelected + 1);
  }, [toDoSelected, filteredAlerts]);

  return (
    <div className={styles.main}>
      <div>
        <div className={styles.title}>patient to do</div>
        {filteredAlerts.length > 0 && (
          <ToDoSelector
            toDoSelected={toDoSelected}
            totalAlerts={filteredAlerts.length}
            leftClick={leftButtonClick}
            rightClick={rightButtonClick}
          />
        )}
      </div>
      <hr className="my-0" />
      {filteredAlerts.length ? (
        <>
          {filteredAlerts[toDoSelected].is_completed ? (
            <CompletedAlertInfo />
          ) : (
            <TodoAlert event={filteredAlerts[toDoSelected]} />
          )}
        </>
      ) : (
        <div className={styles.disabledText}>There are no pending alerts</div>
      )}
    </div>
  );
};

export default PatientToDo;
