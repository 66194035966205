import MentionItemCustom from 'types/Shared/MentionItemCustom';

export const splitMentionMultiple = (
  phrase: string,
  keys: MentionItemCustom[]
): (MentionItemCustom | string)[] => {
  const result = [];
  if (!keys) return result;
  keys.forEach((k) => {
    const currentString =
      result?.length && typeof result[result.length - 1] === 'string'
        ? result[result.length - 1]
        : phrase;
    const location = currentString.indexOf(k.display);
    if (location === -1) return;
    const initial = currentString.substring(0, location);
    const post = currentString.substring(location + k.display.length);
    if (currentString === phrase) {
      result.push(...[initial, k, post].filter(Boolean));
      //continue to next iteration
      return;
    }
    result[result.length - 1] = initial;
    result.push(...[k, post].filter(Boolean));
  });
  return result;
};
