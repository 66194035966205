import Button from 'components/Button';
import { addBlankEmailSetting } from 'screens/Administration/store/EmailSettings/emailSettingActionCreators';
import { useAdminEmailSettings } from 'screens/Administration/store/hooks';

const EmailSettingsActions = () => {
  const [, dispatch] = useAdminEmailSettings();
  return (
    <div className={`d-flex gap`}>
      <Button label="add email" onClick={() => dispatch(addBlankEmailSetting())} />
    </div>
  );
};

export default EmailSettingsActions;
