import { PhoneNumberFormik, SelectFormik, TextInputFormik } from 'components/FormikComponents';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { useState } from 'react';
import { IAccount } from 'types/Administration/Account/Account';
import { dateFormatOptions, timezoneOptions } from '../utils/SelectOptions';
import styles from './styles.module.css';

const FirstColumn = (account) => {

  const formik = useFormikContext<IAccount>();
  const [selectedSignature, setSelectedSignature] = useState('');

  const handleChangeSignature = (e) => {
    const url =URL.createObjectURL(e.target.files[0]);
    formik.setFieldValue('signature', e.target.value)
    setSelectedSignature(url)
  }

  const setOptions = (options) => {
    const SelectOptions = []
    options.forEach(opt => {
      SelectOptions.push({value: opt, label: opt})
    })
    return SelectOptions
  }

  return (
    <div className={styles.container}>
      <h6 className="ml-2">Account settings</h6>
      <hr className={styles.horizontalLine}></hr>
      <div className="d-flex">
        <div className={styles.sectionTitle}>
          <div>GENERAL SUPPORT</div>
          <div className={styles.inputContainer}>
            <span className={styles.description}>Support emails and phone number</span>
          </div>
        </div>
        <div className="m-3">
          <div className={styles.inputTitle}>General Email</div>
          <div className={styles.inputContainer}>
            <TextInputFormik name="generalEmail" className={styles.input} />
            <ErrorMessage name="generalEmail">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="m-3">
          <div className={styles.inputTitle}>General Phone</div>
          <div className={styles.inputContainer}>
            <PhoneNumberFormik className={styles.input} name="generalPhone" />
            <ErrorMessage name="generalPhone">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="m-3">
          <div className={styles.inputTitle}>Tech Support</div>
          <div className={styles.inputContainer}>
            <TextInputFormik className={styles.input} name="techSupportEmail" />
            <ErrorMessage name="techSupportEmail">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
      </div>
      <hr className={styles.horizontalLine}></hr>
      <div className="">
        <div className={styles.sectionTitle}>
          <div>DETAILS</div>
        </div>
        <div className="m-2 pl-1 d-flex">
          <div className={styles.inputTitleMargin}>Address</div>
          <div className={`w-100 ml-5 ${styles.inputContainer}`}>
            <TextInputFormik className={styles.input} name="address" />
            <ErrorMessage name="address">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="m-2 pl-1 d-flex">
          <div className={styles.inputTitleMargin}>Other Details</div>
          <div className={`w-100 ml-5 ${styles.inputContainer}`}>
            <TextInputFormik className={styles.input} name="secondAddress" />
          </div>
        </div>
      </div>
      <hr className={styles.horizontalLine}></hr>
      <div className="">
        <div className={styles.sectionTitle}>
          <div>APPLICATION LINK</div>
        </div>
        <div className="m-1 pl-2 d-flex">
          <div className={styles.inputTitleMargin}>iOS Patient App</div>
          <div className={`w-100 ml-5 ${styles.inputContainer}`}>
            <TextInputFormik className={styles.input} name="iosPatientApp" />
            <ErrorMessage name="iosPatientApp">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="m-1 pl-2 d-flex">
          <div className={styles.inputTitleMargin}>iOS Provider App</div>
          <div className={`w-100 ml-5 ${styles.inputContainer}`}>
            <TextInputFormik className={styles.input} name="iosProviderApp" />
            <ErrorMessage name="iosProviderApp">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="m-1 pl-2 mt-4 d-flex">
          <div className={styles.inputTitleMargin}>Android Patient App</div>
          <div className={`w-100 ml-5 ${styles.inputContainer}`}>
            <TextInputFormik className={styles.input} name="androidPatientApp" />
            <ErrorMessage name="androidPatientApp">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="m-1 pl-2 d-flex">
          <div className={styles.inputTitleMargin}>Android Provider App</div>
          <div className={`w-100 ml-5 ${styles.inputContainer}`}>
            <TextInputFormik className={styles.input} name="androidProviderApp" />
            <ErrorMessage name="androidProviderApp">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="m-1 pl-2 mt-4 d-flex">
          <div className={styles.inputTitleMargin}>Patient Web Portal</div>
          <div className={`w-100 ml-5 ${styles.inputContainer}`}>
            <TextInputFormik className={styles.input} name="patientWebPortal" />
            <ErrorMessage name="patientWebPortal">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
      </div>
      <hr className={styles.horizontalLine}></hr>
      <div className="d-flex">
        <div className={styles.sectionTitle}>
          <div>LOCATION OPTIONS</div>
          <div className={styles.inputContainer}>
            <span className={styles.description}>Time format</span>
          </div>
        </div>
        <div className="m-3">
        <div className={styles.inputTitle}>Country Code</div>
          <div className={styles.inputContainer}>
            <TextInputFormik name="countryCode" className={`${styles.input}`} />
            <ErrorMessage name="countryCode">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="m-3">
          <div className={styles.inputTitle}>Timezone</div>
          <div className={styles.inputContainer}>
            <SelectFormik options={setOptions(timezoneOptions)} className={styles.select} name="timezone" />
            <ErrorMessage name="timezone">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
        <div className="m-3">
          <div className={styles.inputTitle}>Date Format</div>
          <div className={styles.inputContainer}>
            <SelectFormik options={setOptions(dateFormatOptions)} className={styles.select} name="dateFormat" />
            <ErrorMessage name="dateFormat">
              {(msg) => <div className="text-danger font-size-small">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
      </div>
      <hr className={styles.horizontalLine}></hr>
      <div className="d-flex">
        <div className={styles.sectionTitle}>
          <div>DEFAULT PROVIDER</div>
          <div className={styles.inputContainer}>
            <span className={styles.description}>
              {`Last update ${account.updatedAt} by ${account.updatedBy}`}
            </span>
          </div>
        </div>
        <div className="m-3">
          <div className={styles.inputTitle}>National Provider Identifier</div>
          <div className={styles.inputContainer}>
            <TextInputFormik
              className={styles.inputDefaultProvider}
              name="nationalProviderIdentifier"
            />
          </div>
        </div>
        <div className="m-3 w-25">
          <div className={styles.inputTitle}>Signature</div>
          <div className={styles.signatureContainer}>
          <Field name="signature">
            {({ field }) => (
              <input
                {...field}
                className={styles.signature}
                placeholder='change'
                id="img"
                name="img"
                type="file"
                accept="image/*"
                onChange={(e) => {handleChangeSignature(e)}}
              />
            )}
          </Field>
          <span className={styles.changeSpan}>change</span>
          {selectedSignature && <img src={selectedSignature} className={styles.signatureImg} alt="img" />}
          </div>
          <div className={styles.practiceIconContainer}>
         
        </div>
        </div>
      </div>
    </div>
  );
};

export default FirstColumn;
