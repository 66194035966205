import AccessRolesModal from 'components/BulletedModals/AccessRolesModal';
import Card from 'components/Card';
import { LabeledField, SelectFormik, TextInputFormik } from 'components/FormikComponents';
import DatePickerFormik from 'components/FormikComponents/date-picker-formik';
import LanguagesModal from 'components/BulletedModals/LanguagesModal';
import { useFormikContext } from 'formik';
import { ChangeEvent, useRef, useState } from 'react';
import { uploadProfilePicture } from 'services/userService';
import { Role } from 'types/ApiModels/Administration';
import { Provider, Title } from 'types/ApiModels/Providers/Provider';
import { Language } from 'types/ApiModels/Users';
import Sex from 'types/ApiModels/Users/Sex';
import ProviderAccessRolesField from './ProviderAccessRolesField';
import ProviderLanguagesField from './ProviderLanguagesField';
import styles from './styles.module.css';

interface IdentificationFormValues {
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: string;
  gender: string;
  title: string;
  language: number[];
  accessRoles: number[];
}

const formikFields = [
  {
    label: 'first name',
    name: 'identification.firstName',
  },

  {
    label: 'last name',
    name: 'identification.lastName',
  },
  {
    label: 'middle name',
    name: 'identification.middleName',
  },
  {
    label: 'date of birth',
    name: 'identification.dateOfBirth',
    type: 'datepicker',
  },
  {
    name: 'identification.gender',
    label: 'gender',
    type: 'select',
  },
  {
    label: 'title',
    name: 'title',
  },
  {
    name: 'identification.language',
    label: 'language',
  },
];

const formikFieldMap = {
  firstName: {
    label: 'first name',
    name: 'identification.firstName',
  },
  lastName: {
    label: 'last name',
    name: 'identification.lastName',
  },
  middleName: {
    label: 'middle name',
    name: 'identification.middleName',
  },
  dateOfBirth: {
    label: 'date of birth',
    name: 'identification.dateOfBirth',
  },
  gender: {
    label: 'Gender',
    name: 'identification.gender',
  },
  title: {
    label: 'title',
    name: 'identification.title',
  },
  language: {
    label: 'language',
    name: 'identification.language',
  },
  roles: {
    label: 'access roles',
    name: 'identification.accessRoles',
  },
};

interface ProviderIdentificationProps {
  selectedProvider: Provider;
  titles: Title[];
  languages: Language[];
  genderOptions: Sex[];
  roles: Role[];
  allowProfilePicUpload?: boolean;
}

const ProviderIdentification = ({
  selectedProvider,
  titles,
  languages,
  genderOptions,
  roles,
  allowProfilePicUpload = false,
}: ProviderIdentificationProps) => {
  const photoInputRef = useRef<HTMLInputElement>();
  const [photoError, setPhotoError] = useState('');
  const [photoUrl, setPhotoUrl] = useState<string>('');
  const [showRolesModal, setShowRolesModal] = useState(false);
  const [showLanguageModal, setShowLanguageModal] = useState(false);

  const { values, setFieldValue, setFieldTouched } =
    useFormikContext<{ identification: IdentificationFormValues }>();

  const handlePhotoUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const maxPhotoSize = 1e6;

    if (photoInputRef.current.files[0].size > maxPhotoSize) {
      setPhotoError('Maximum file size: 1MB');
    } else {
      setPhotoUrl(e.target.value);
      const res = await uploadProfilePicture(photoInputRef.current.files[0]);
      res && setPhotoUrl(res.photo);
    }
  };

  return (
    <Card className="w-100 card-bg-border" headers={['Identification']}>
      <div className="d-flex gap-md">
        <div>
          <div
            className={`d-flex justify-content-center align-items-center ${styles['photo-container']}`}
          >
            <div
              className={`${styles['photo']} ${allowProfilePicUpload ? 'cursor-pointer' : ''}`}
              onClick={() => {
                if (allowProfilePicUpload) {
                  setPhotoError('');
                  photoInputRef.current?.click();
                }
              }}
            >
              {selectedProvider.photo ? (
                <img src={photoUrl ? photoUrl : selectedProvider.photo} height={100} width={100} />
              ) : null}
              {allowProfilePicUpload ? (
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  ref={photoInputRef}
                  onChange={handlePhotoUpload}
                />
              ) : null}
            </div>
          </div>
          {photoError ? <div className="text-danger font-size-small mt-1">{photoError}</div> : null}
        </div>
        <div className="d-flex flex-wrap gap-lg py-3">
          <LabeledField
            className={styles['field-container']}
            name={formikFieldMap.firstName.name}
            label={formikFieldMap.firstName.label}
          >
            <TextInputFormik name={formikFieldMap.firstName.name} />
          </LabeledField>
          <LabeledField
            className={styles['field-container']}
            name={formikFieldMap.lastName.name}
            label={formikFieldMap.lastName.label}
          >
            <TextInputFormik name={formikFieldMap.lastName.name} />
          </LabeledField>
          <LabeledField
            className={styles['field-container']}
            name={formikFieldMap.middleName.name}
            label={formikFieldMap.middleName.label}
          >
            <TextInputFormik name={formikFieldMap.middleName.name} />
          </LabeledField>
          <LabeledField
            className={styles['field-container']}
            name={formikFieldMap.dateOfBirth.name}
            label={formikFieldMap.dateOfBirth.label}
          >
            <DatePickerFormik name={formikFieldMap.dateOfBirth.name} />
          </LabeledField>
          <LabeledField
            className={styles['field-container']}
            name={formikFieldMap.gender.name}
            label={formikFieldMap.gender.label}
          >
            <SelectFormik
              placeholder=""
              options={genderOptions.map((go) => ({ value: go.id, label: go.name }))}
              name={formikFieldMap.gender.name}
              defaultValue={
                selectedProvider.sex
                  ? {
                      value: selectedProvider.sex,
                      label: genderOptions.find((go) => go.id === selectedProvider.sex)?.name,
                    }
                  : null
              }
            />
          </LabeledField>
          <LabeledField
            className={styles['field-container']}
            name={formikFieldMap.title.name}
            label={formikFieldMap.title.label}
          >
            <SelectFormik
              placeholder=""
              options={titles.map((t) => ({ value: t.id, label: t.name }))}
              name={formikFieldMap.title.name}
              defaultValue={
                selectedProvider.title?.id
                  ? { value: selectedProvider.title.id, label: selectedProvider.title.name }
                  : null
              }
            />
          </LabeledField>
          <ProviderLanguagesField
            handleAdd={() => {
              setShowLanguageModal(true);
            }}
            label={formikFieldMap.language.label}
            name={formikFieldMap.language.name}
            languages={languages}
          />
          <ProviderAccessRolesField
            handleAdd={() => {
              setShowRolesModal(true);
            }}
            label={formikFieldMap.roles.label}
            name={formikFieldMap.roles.name}
            roles={roles}
          />
        </div>
      </div>
      {/* kind of redundant but need the component to force unmount so it reloads initial state for assignedRoles */}
      {showRolesModal ? (
        <AccessRolesModal
          handleClose={() => setShowRolesModal(false)}
          roles={roles}
          show={showRolesModal}
          handleConfirm={(rids: number[]) => {
            setFieldTouched(formikFieldMap.roles.name, true);
            setFieldValue(formikFieldMap.roles.name, rids, true);
            setShowRolesModal(false);
          }}
          assignedRolesIds={values.identification.accessRoles}
        />
      ) : null}
      {showLanguageModal ? (
        <LanguagesModal
          handleClose={() => setShowLanguageModal(false)}
          assignedLanguagesIds={values.identification.language}
          handleConfirm={(lids: number[]) => {
            setFieldTouched(formikFieldMap.language.name, true);
            setFieldValue(formikFieldMap.language.name, lids, true);
            setShowLanguageModal(false);
          }}
          languages={languages}
          show={showLanguageModal}
        />
      ) : null}
    </Card>
  );
};
export default ProviderIdentification;
