import { PhoneNumberFormik } from 'components/FormikComponents';
import PatientManagementEliminateButton from 'components/PatientManagementEliminateButton';
import {
  EmergencyContactsFormikKeys,
  PatientManagementFormikKeys,
} from 'components/PatientManagementForm/types';
import PatientManagementInput from 'components/PatientManagementInput';
import PatientManagementSelect from 'components/PatientManagementSelect';
import { ErrorMessage, useFormikContext } from 'formik';
import React, { Fragment } from 'react';
import { Table } from 'react-bootstrap';
import { RelationShipEnum } from 'types/ApiModels/Patients/Relationship';
import styles from './styles.module.css';

const EmergencyContactInfoTable = ({
  title,
  contactInformation,
  index,
  onChange,
  errors,
  touched,
}) => {
  // Context
  const { setFormikState } = useFormikContext();

  const createTableBody = (contactInformation, index, handleChangeTouched, errors) => {
    const identifierMap = {
      [EmergencyContactsFormikKeys.FIRST_NAME]: 'First name',
      [EmergencyContactsFormikKeys.LAST_NAME]: 'Last name',
      [EmergencyContactsFormikKeys.RELATIONSHIP]: 'Relationship',
      [EmergencyContactsFormikKeys.CONTACT_EMAIL]: 'Contact email',
      [EmergencyContactsFormikKeys.CONTACT_PHONE]: 'Contact phone',
    };
    const tdClassName = 'font-size-medium';

    return Object.values(EmergencyContactsFormikKeys).map((key) => {
      const error =
        errors && errors[index] && touched?.[index]?.[key] ? errors[index][key] : undefined;
      const name = `${PatientManagementFormikKeys.EMERGENCY_CONTACTS}[${index}][${key}]`;
      return (
        <tr key={`${styles.test} emergency-contacts-row-${key}`}>
          <td className={`${tdClassName} ${styles.identifier}`}>{identifierMap[key]}</td>
          <td className={tdClassName}>
            {key === EmergencyContactsFormikKeys.CONTACT_PHONE ? (
              <Fragment key={`${PatientManagementFormikKeys.EMERGENCY_CONTACTS}[${key}]`}>
                <PhoneNumberFormik
                  key={`${PatientManagementFormikKeys.EMERGENCY_CONTACTS}[${index}][${key}]`}
                  name={name}
                  className={` w-100 form-control form-control-sm bg-light-gray ${
                    touched?.[index]?.[key] && errors?.[index]?.[key] ? 'border-red' : ''
                  }`}
                />
                <ErrorMessage name={name}>
                  {(msg) => <div className="text-danger font-size-medium">{msg}</div>}
                </ErrorMessage>
              </Fragment>
            ) : key === EmergencyContactsFormikKeys.RELATIONSHIP ? (
              <PatientManagementSelect
                error={error}
                options={Object.values(RelationShipEnum)}
                name={name}
                onChange={handleChangeTouched(name)}
                value={contactInformation[key]}
              />
            ) : (
              <PatientManagementInput
                value={contactInformation[key]}
                onChange={handleChangeTouched(name)}
                name={name}
                error={error}
                key={`${PatientManagementFormikKeys.EMERGENCY_CONTACTS}-${key}`}
              />
            )}
          </td>
        </tr>
      );
    });
  };

  const deleteEmergencyContact = (deletedIndex) =>
    setFormikState(({ values, ...prevFormikState }) => ({
      ...prevFormikState,
      values: {
        ...values,
        [PatientManagementFormikKeys.EMERGENCY_CONTACTS]: values[
          PatientManagementFormikKeys.EMERGENCY_CONTACTS
        ].filter((_, index) => index !== deletedIndex),
      },
    }));

  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <span>{title}</span>
        <PatientManagementEliminateButton onClick={() => deleteEmergencyContact(index)} />
      </div>
      <Table size="sm" borderless>
        <tbody>{createTableBody(contactInformation, index, onChange, errors)}</tbody>
      </Table>
    </div>
  );
};

export default EmergencyContactInfoTable;
